<template>
  <div class="user">
    <basic-container>
      <div class="corporate-box">
        <div class="corporate-item ">
          <p class="mt15">账户名称：{{accountFlowForm.accountName}}</p>
          <p class="mt15">支付渠道：{{accountFlowForm.paymentChannelName }}</p>
          <p class="mt15">钱包账号：{{accountFlowForm.walletId}}</p>
        </div>

      </div>
      <avue-crud ref="crud" :option="option" :page.sync="page" :table-loading="listLoading" :data="list"
        :summary-method="summaryMethod" @on-load="getList" @size-change="sizeChange" @current-change="currentChange"
        @search-change="handleFilter" @search-reset="resetFilter">
        <!-- <template #menuLeft>
          <el-button class="filter-item" type="primary" icon="el-icon-download" @click="handleExport">导出</el-button>
        </template> -->

        <template slot="inOut" slot-scope="scope">
          <el-tag effect="dark" :type="scope.row.inOut === '1' ? 'success' : 'danger'">{{ scope.row.inOut === "1" ? "收入" :
            "支出" }}</el-tag>
        </template>
        <template slot="transAmount" slot-scope="scope">
          <span>
            <el-tag>{{ scope.row.transAmount }}</el-tag>
          </span>
        </template>
        <template slot="bakTotleAmount" slot-scope="scope">
          <span>
            <el-tag type="info">{{ scope.row.bakTotleAmount }}</el-tag>
          </span>
        </template>
        <template slot="newTotleAmount" slot-scope="scope">
          <span>
            <el-tag type="danger">{{ scope.row.newTotleAmount }}</el-tag>
          </span>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
  import {
    PAGE
  } from "@/const/global.js";
  const tableOption = {
    searchMenuSpan: 12,
    searchLabelWidth: 80,
    searchMenuPosition: "left",
    searchShowBtn: false,
    border: false,
    index: false,
    indexLabel: "序号",
    stripe: true,
    menu: false,
    menuWidth: 160,
    header: true,
    labelWidth: 130,
    columnBtn: false, //隐藏表头设置
    editBtn: false,
    delBtn: false,
    align: "center",
    addBtn: false,
   searchShowBtn:false,
 column: [{
        label: "收支",
        prop: "txnType",
        type: "select",
      },
      {
        label: "科目",
        prop: "sourceTypeDesc",
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
      {
        label: "交易金额",
        prop: "amount",
      },
      {
        label: "交易时间",
        prop: "postedAtDesc",
        type: "date",
        format: "yyyy-MM-dd",
        searchRange: true,
        valueFormat: "yyyy-MM-dd",
        editDisplay: true,
        addDisplay: true,
        search: true,
        searchSpan: 8,
        
        span: 12,
      },
      {
        label: "交易前金额",
        prop: "amountBefore",
      },
      {
        label: "交易后金额",
        prop: "amountAfter",
      },
    ],
  };

  export default {
    name: "TableUser",
    components: {},
    props: {
      accountFlowForm: {
        type: Object,
        default: "",
      }
    },
    data() {
      return {
        searchItmes: {},
        option: tableOption,
        searchItmes: {},
        page: PAGE,
        list: [],
        listLoading: true,
      };
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
      getList(page, params) {
        const {
          currentPage,
          pageSize
        } = page;
        params = params ? params : {
          ...this.searchItmes
        }
        if(params.postedAtDesc){
          params.postedAtStart = params.postedAtDesc[0]
          params.postedAtEnd = params.postedAtDesc[1]
        }
        params.pageNum = page.currentPage
        params.pageSize = page.pageSize
        params.accountId = this.accountFlowForm.accountNo;
        this.listLoading = true;
        this.doPost("bium-portal/controller/fin-ops/va/listPage", params).then(({
          data
        }) => {
          this.listLoading = false;
          if (data.result.records) {
            this.list = data.result.records || []
            this.page.total = data.result.total || 0
          }
        });
      },

      sizeChange(pageSize) {
        this.page.pageSize = pageSize;
      },
      currentChange(current) {
        this.page.currentPage = current;
      },
      handleFilter(param, done) {
        this.page.currentPage = 1;
        this.searchItmes = param ? param : {};
        this.getList(this.page, param);
        done();
      },
      resetFilter() {
        this.page.currentPage = 1;
        this.searchItmes = {};
        this.getList(this.page, this.searchItmes);
      },

      summaryMethod({
        data
      }) {
        let sum = []
        if (data && data.length) {
          let transAmountSum = 0
          for (let i = 0; i < data.length; i++) {
            if (data[i].inOut == '1') {
              transAmountSum += data[i].transAmount || 0 //收入
            } else {
              transAmountSum -= data[i].transAmount || 0 //支出
            }
          }
          sum = ['', '', '', transAmountSum.toFixed(3)]
        }
        return sum
      },
      handleExport() {
        if (this.page.total > 0) {
          this.listLoading = true;
          const {
            inOut
          } = this.searchItmes;
          const api = "/cap/accountFlow/exportAccountAllFlow";
          let queryParams = `current=0&size=${this.page.total}&accountId=${this.accountId}&name=${this.accountName}`;
          debugger;
          if (inOut) queryParams += `&inOut=${inOut}`;
          doExportExcel(`${api}?${queryParams}`)
            .then((res) => {
              // 获取headers中的filename文件名
              downExcel(res);
              this.listLoading = false;
            })
            .catch(() => {
              this.listLoading = false;
            });
        } else {
          this.$notify.error("暂无流水明细可导出。");
        }
      },
    },
  };
</script>
