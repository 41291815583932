var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "document_containter" }, [
      _c("h1", { staticClass: "tc" }, [_vm._v("隐私政策")]),
      _c("h3", [_vm._v("关于本隐私政策")]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "必米供应链平台（下称“我们”）非常重视用户信息和隐私的保护。必米供应链平台在本政策中指北京联动世纪科技发展有限公司或其它与您订立协议的必米供应链平台主体。我们使用您的个人信息的目的是为了实现必米供应链平台的产品与服务相关的功能。在使用我们提供的产品与服务前，请您务必仔细阅读并透彻理解必米供应链平台《隐私政策》（下称“本隐私政策”、“本政策”）。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "本政策仅适用于必米供应链平台在中国境内（为本政策之目的，不包含港澳台）提供的产品与服务。我们希望通过本隐私政策向您说明我们在您使用必米供应链平台的产品与服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。重点内容我们已采用粗体特别提示，希望您在阅读浏览时特别关注。一旦您使用或继续使用必米供应链平台产品与服务，即表示您同意我们按照本政策处理您的相关信息。如果您使用必米供应链平台在中国境外提供的产品与服务，我们会向您提供必米供应链平台国际《隐私政策》，请您阅读了解。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("最近更新日期：2023年6月12日  生效日期：2022年6月12日"),
      ]),
      _c("br"),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("一、我们收集您的哪些信息")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "您选择使用我们的产品与服务，可能需要向我们提供或可能允许我们收集的信息包括："
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "1、身份信息：用户的姓名、身份证件号码、住址、电话号码、电子邮箱地址等。当您需要注册为机构用户时，则包括企业名称、统一社会信用代码等工商信息，法定代表人、被授权人的个人身份信息。机构用户在提供个人信息之前，请确保已经事先获得信息主体的合法授权。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "2、用户提供的业务信息：为使用我们产品与服务之必要，您可自主选择提供的业务信息中，可能包含个人信息，我们将根据协议约定及本政策依法予以处理。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "3、设备和日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会（1）接收并记录您所使用的设备相关信息（如操作系统及版本）；（2）自动收集您对我们服务的详细使用情况，作为有关网络日志保存。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "本隐私政策中个人信息是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。请您谨慎并留意个人敏感信息，您同意您的个人敏感信息我们可以按本隐私政策所述的目的和方式来处理。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("二、我们如何收集和使用您的信息")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "我们面向的用户包括使用我们服务的自然人、法人或其它组织，本政策所称用户包含个人用户和机构用户。我们收集您信息的目的主要是为了向您提供安全、高效以及个性化的服务体验。收集的信息包括您直接、主动向我们提供的信息，以及我们自行或通过合作的查询服务提供机构查询并获取的信息。我们会出于本政策以下目的收集和使用您的个人信息："
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（一）您须授权我们收集和使用您个人信息的情形"),
      ]),
      _c("p", { staticClass: "str_2" }, [_vm._v("1、注册实名制管理")]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "您在注册使用我们的服务时我们需要首先认证您身份的真实性，为此您需要提供必要的身份信息。我们会将您的身份信息提交给合法持有该信息的第三方身份验证机构，以便对您所提供信息的准确性进行核对。如果您不提供上述信息则无法使用根据中国相关法律法规必须进行实名制管理的相关服务。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("2、保障交易安全所必须的功能"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "为提高您使用我们的产品与服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的浏览信息、交易信息、您常用的软件信息、设备信息等手段来判断您的账号风险。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("3、个性化推荐、收集反馈与发送通知"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "我们可能会利用您提供的以及我们自行采集的用户信息为您推荐个性化的产品与服务。您的反馈对帮助我们改进服务而言极为珍贵，为了跟踪您的反馈，我们可能会使用您所提供的个人信息与您联系，并保留记录。我们可能会不时使用您的个人信息来发送重要通知，例如与第三方融资服务相关的通知和我们的条款、条件和政策变更。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("4、资格、信用能力审核（如适用）"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "您在使用我们的服务时我们会使用您的个人基本信息、个人身份信息、个人财产信息及其他在具体业务开展过程中基于您的同意而采集或从第三方获取的信息以评估您的信用状况，进行风险管理及控制。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("5、电子签章授权委托（如适用）"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "为了向您提供更好的服务（包括为达成服务目的或更好地为本人及/或融资人提供服务而进行的数据建模、数据测试等），我们会在取得您的授权之后将您的必要个人信息（包括本人身份证号、手机号等信息）转授权给我们的关联公司及/或受托提供服务的合作伙伴/服务提供方，并提供给相关RA机构（如有）及CA机构，我们将严格按照本隐私政策及个人信息授权书（如有）使用上述信息。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("6、使用第三方服务（如适用）"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "如您选择通过我们使用第三方服务，可能需要您提供企业证件类型及证件号码、办公地、营业执照影像；申请人姓名、个人身份证号码及影像、银行卡号及预留手机号；申请人配偶姓名、身份证号码及影像；公司联系人姓名、联系电话；企业年销售额、财务报表、企业银行账户及预留手机号。其中，如使用票据贴现/商票质押融资等涉及商票的服务，还可能需要您提供票据影像、票据类型、到期日期及承兑人。以上您提供的信息会提交给第三方机构用于您申请或使用相应服务。不同机构要求的信息不同，具体以相应机构要求为准。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "为给您带来更好的体验，我们会持续努力改进我们的产品与服务，因此可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。如发生上述情形，我们将通过更新本政策、页面提示等方式另行向您说明，在征得您的同意后再开始收集、使用。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（二）我们从第三方获得您个人信息的情形"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "我们可能从关联方、第三方合作伙伴获取您授权共享的相关信息。我们将在符合相关法律规定，并依据与关联方或者第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（三）无需征得您授权同意可收集、使用您个人信息的情形"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "根据相关法律法规、监管要求及国家标准，在以下情形中，我们可以在不征得您的授权同意情况下收集、使用一些必要的个人信息："
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（1）与我们履行法律法规规定的义务相关的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（2）与国家安全、国防安全直接相关的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（3）与公共安全、公共卫生、重大公共利益直接相关的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（4）与犯罪侦查、起诉、审判和判决执行等直接相关的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（6）所涉及的个人信息是您自行向社会公众公开的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（7）根据您与我们签订和履行合同所必需的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（8）从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（9）用于维护我们产品与服务的安全稳定运行所必需的，例如发现、处置产品与服务故障；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（10）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（11）出于公共利益开展统计或学术研究所必需，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（12）法律法规规定的其他情形。"),
      ]),
      _c("p", { staticClass: "str_2" }),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("三、我们如何使用Cookie和同类技术")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "1、 Cookie 。为确保网站或小程序正常运转，我们会在您的计算机或移动设备上储存名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站或小程序能够储存您的偏好数据。我们不会将Cookie用于本政策所述目的之外的任何用途，您可根据自己的偏好留存或删除Cookie。您可清除软件内保存的所有Cookie，当您手动清除后您的相关信息即已删除。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "2、Cookie 同类技术。除 Cookie外， 我们平台上还可能包含一些电子图像（以下简称“单像素GIF文件”或“网络Beacon”或“埋点”），使用这些同类技术可以计算浏览网页的用户或访问某些Cookie，以作用户行为数据统计分析；我们通过这些同类技术可能收集您浏览网页活动的信息，例如：Internet协议（IP）地址，浏览器类型，Internet服务提供商（ISP），参考/退出页面，操作系统，日期/时间戳，和点击数据流等。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("四、我们如何共享、转让、公开披露您的个人信息")]),
      ]),
      _c("p", { staticClass: "str_2" }, [_vm._v("（一）共享")]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "与关联公司间共享：为了便于我们更好地提供服务，您同意并授权将您提交或我们搜集的用户信息共享给我们的关联公司。我们只会共享必要的个人信息，如果我们改变个人信息的使用及处理目的，将再次征求您的授权同意。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "与授权合作伙伴共享（如适用）：在使用我们提供的某些服务时，您将通过签署《个人信息授权书》等形式授权将您提交或我们搜集的用户信息共享给我们的合作方（包括但不限于征信机构、资金提供方或其他第三方合作机构），以为您提供更好的用户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [_vm._v("（二）转让")]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "我们不会将您的个人信息转让给任何公司、组织或个人，除非发生下列情况："
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（1）事先获得您的明确同意；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（2）根据所适用的法律法规、行业规定、法律程序要求、诉讼/仲裁、政府的强制命令、监管要求所必须要求提供的；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（3）为了保护国家安全、公共安全以及您和其他个人的重大合法权益而需要转让您的个人信息；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（4）您自行公开的或者我们能从其他合法公开渠道获取到您的个人信息。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "如果发生合并、收购或破产清算，将可能涉及到个人信息转让，此种情况下我们会要求新的持有您个人信息的公司、组织继续受本政策的约束。如果本政策中约定的个人信息的收集、处理方式发生任何改变，该公司、组织将重新向您征求授权同意。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [_vm._v("（三）公开披露")]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "除非获取您的明确同意，我们不会公开披露您的个人信息。基于法律、法律程序、诉讼或政府主管部门强制性要求的情况下，为了保护国家安全、公共安全以及您和其他个人的重大合法权益，我们可能会向有权机关或公众披露您的个人信息。但我们保证，在上述情况发生时，我们会要求披露请求方必须出具与之相应的有效法律文件，并对被披露的信息采取符合法律和业界标准的安全防护措施。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("五、我们如何储存和保护您的个人信息")]),
      ]),
      _c("p", { staticClass: "str_2" }, [_vm._v("（一）您个人信息的储存")]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "1、我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求。而我们判断前述期限的标准包括："
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（1）完成与您相关的交易目的、维护相关交易及业务记录、应对可能的查询或投诉；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（2）保证我们为您提供的服务安全及质量；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（3）是否存在保留期限的其他特别约定。"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "请您注意，在您的个人信息超出保留期间（保留期限通常为达成本政策所述目的之日起不高于五年）后，我们会根据适用法律的要求删除您的个人信息或匿名化处理；此外，在注销账户后，我们将停止为您提供产品和服务，并根据您的要求，删除您的个人信息或匿名化处理，法律法规另有规定的除外。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "2、如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照中国境内法律法规政策的要求以及我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（二）我们保护您个人信息的技术与措施"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "1、数据安全技术措施。我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问、公开披露、使用、修改，避免数据的损坏或丢失。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "2、为保护个人信息采取的其他安全措施。我们仅允许有必要知晓这些信息的必米供应链平台及必米供应链平台关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与必米供应链平台的合作关系。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "3、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "4、在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("六、您如何管理您的个人信息")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "必米供应链平台非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括："
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（一）访问和更正您的个人信息"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "您有权访问和更正您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式行使数据权利："
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（1）您的账户信息：在使用我们提供的某些服务时，您可以在PC 端个人管理菜单栏中查阅您提交给必米供应链平台的个人信息。移动端或小程序段具体路径为：账户名称、个人信息资料、常用信息设置。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（2）对于不适用以上方式的服务，您在使用该产品与服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [_vm._v("（二）删除您的个人信息")]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("在以下情形中，您可以向我们提出删除个人信息的请求："),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（1）如果我们处理个人信息的行为违反法律法规；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（2）如果我们收集、使用您的个人信息，却未征得您的同意；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（3）如果我们处理个人信息的行为违反了与您的约定；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（4）如果您不再使用我们的产品与服务，或您注销了账号；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（5）如果我们不再为您提供我们的产品与服务。"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "若我们决定响应您的删除请求，将在30个工作日内完成删除。我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从我们的服务中删除信息后，因为适用的法律要求或安全技术原因，我们可能无法立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息或实现匿名。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("3、改变您授权同意的范围或撤回您的授权"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "在使用我们提供的某些服务时，您可以通过关闭设备功能、在必米供应链平台web应用或小程序-我的-设置-隐私设置中改变您授权我们继续收集个人信息的范围。您可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [_vm._v("4、注销账户及其它")]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "如以上方式不适用于您所使用的服务，您可以联系我们的客服团队行使您的数据权利。您可以通过本政策所列联系方式联系我们进行注销账户的操作。您注销账户后，我们将停止为您提供产品与服务，除法律法规另有规定外，我们将在30个工作日内删除您的个人信息。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [_vm._v("5、响应您的请求")]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的30天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("在以下情形中，按照法律法规要求，我们将无法响应您的请求："),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（1）与国家安全、国防安全有关的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（2）与公共安全、公共卫生、重大公共利益有关的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（3）与犯罪侦查、起诉和审判和判决执行等有关的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（4）有充分证据表明您存在主观恶意或滥用权利的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [_vm._v("（6）涉及商业秘密的。")]),
      _c("p", { staticClass: "str_2" }),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("七、我们如何保护未成年人的个人信息")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "我们基于企业商事行为向用户提供相关产品与服务，原则上不接受无工作单位的未成年人进行注册使用。如有事实证明未成年人未取得监护人同意的情况下注册使用了我们的产品与服务，我们将设法尽快删除相关信息。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("八、您的信息如何在全球范围转移")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，暂不涉及个人信息的出境，未来若有将另行征求用户授权并明示跨境传输规则。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("九、本政策如何更新")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "1、我们的隐私政策可能变更。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。当本政策发生变更时，我们会在必米供应链平台网站或小程序、必米供应链平台移动端上提前以公告、弹窗或以其他适当方式向您展示变更后的政策。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("十、如何联系我们")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "如您对本隐私政策有任何疑问、意见或建议，或您在使用我们的产品与服务时，就您的个人信息相关事宜有任何意见或建议，您可以通过以下方式与我们联系。一般情况下，我们将在收到您的反馈并验证您的身份后十五天内答复您的相关请求。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }),
      _c("p", { staticClass: "str_2" }, [_vm._v("必米供应链平台")]),
      _c("p", { staticClass: "str_2" }, [_vm._v("电话：")]),
      _c("p", { staticClass: "str_2" }, [_vm._v("邮箱：")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }