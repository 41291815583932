<template>
    <div >
        <el-form ref="cForm" :model="cForm" :rules="cRules" :label-position="'right'" style="padding-right: 20px;"
        label-width="120px" size="small">
        <div class="mt10 inner-box">
          <el-form-item label="案例详情：" prop="comment">
            <el-input :rows="4" type="textarea" v-model="cForm.comment" placeholder="请输入案例详情" />
          </el-form-item>
        </div>
        <div class="tc">
          <el-button type="primary" size="medium"  @click="submit">提交</el-button>
        </div>
      </el-form>
    </div>
</template>
<script>
import { defineComponent } from 'vue'

// Component.registerHooks(['beforeRouteLeave']) //ts用法

export default defineComponent({
    name: 'CaseRecord',
    components: {},
    props: {
    rowForm: {
      type: Object,
      default: () => { }
    },
  },
    data() {
        return {
            cForm:{},
            cRules: {
              comment: [{ required: true, trigger: 'change', message: '请输入案例详情' }],
      }
        }
    },
    created() { },
    methods: {
      submit(){
        this.$refs['cForm'].validate((valid) => {
        if (valid) {
          this.doPost('/bium-portal/controller/fin-ops/audit/saveCase', {
            auditId: this.rowForm.id,
            enterpriseId:this.rowForm.enterpriseId,
            ...this.cForm
          }).then(({ data }) => {
            if (data && data.result) {
              this.$parent.handleClose()
            }
          })
        }
      })
      }
    },
})
</script>

<style scoped lang="scss">

</style>