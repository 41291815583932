var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("avue-crud", {
    ref: "crud2",
    attrs: {
      "table-loading": _vm.listLoading,
      page: _vm.page,
      data: _vm.dataList,
      option: _vm.option,
    },
    on: {
      "update:page": function ($event) {
        _vm.page = $event
      },
      "on-load": _vm.getList,
      "size-change": _vm.sizeChange,
      "current-change": _vm.currentChange,
      "refresh-change": _vm.handleRefreshChange,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }