<template>
    <div class="selection-box">
        <section class="acc-container">
            <el-divider content-position="left" class="title-divider">
                机构对账单下载
            </el-divider>
            <div class="selection-box">
                <el-form ref="payForm" :model="payForm" :rules="payRules" :label-position="'right'" label-width="80px"
                    size="small">
                    <div class="mt10 inner-box">
                        <el-form-item label="支付机构" prop="liveStreamPlat">
                            <el-select style="width: 350px;" v-model="payForm.value" placeholder="请选择">
                                <el-option v-for="item in options" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="日期" prop="billDate">
                            <el-date-picker :picker-options="pickerOptions" valueFormat='yyyy-MM-dd' style="width: 350px;" v-model="payForm.billDate" type="date" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>

                    </div>
                    <el-form-item>
                        <el-button type="primary" size="medium" :loading="loading" :disabled="!payForm.billDate"
                            @click="saveExcel()">下载</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </section>
    </div>
</template>
<script>
export default ({
    name: 'order',
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            loading: false,
            options: [{
                value: 'Ping++',
                label: 'Ping++'
            }, ],
            payForm: {
                value: 'Ping++',
                billDate: null,
            },
            payRules: {}

        }
    },
    created() { },
    mounted() { },

    methods: {
        saveExcel() {
            this.$refs.payForm.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.doPost('/bium-portal/controller/fin-ops/clearing/tradeBill', this.payForm).then(res => {
                    	this.loading = false;
                      if(res.data.result.downloadUrl){
                        window.open(res.data.result.downloadUrl)
                      }
                    }).catch(e => {
                    	this.loading = false;
                    });
                } else {
                    return false;
                }
            });
        }
    },
})
</script>

<style scoped lang="scss"></style>
