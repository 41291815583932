<template>
  <div class="user">
    <basic-container class="full-condition">
      <avue-crud
        ref="crud"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange"
      >
        <template slot="incomeAmt" slot-scope="scope">
          <span>
            <el-tag>{{ scope.row.incomeAmt }} </el-tag>&nbsp;&nbsp;
          </span>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import { PAGE } from "@/const/global.js";
import { doGet } from "@/router/axios";
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchMenuPosition: "center",
  searchIndex: 3,
  searchIcon: true,
  searchShowBtn: false,
  border: false,
  printBtn: true,
  index: false,
  indexLabel: "序号",
  header: false,
  stripe: true,
  menuAlign: "center",
  menuWidth: 200,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: "center",
  menu: false,
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: "id",
      prop: "id",
      span: 24,
      hide: true,
      editDisplay: false,
      addDisplay: false,
    },
    {
      fixed: true,
      label: "企业",
      prop: "enterName",
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      span: 12,
      align: "left",
      width: 250,
      overHidden: true,
    },
    {
      label: "订单编号",
      prop: "orderNo",
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      span: 12,
      width: 200,
      overHidden: true,
    },
    {
      label: "渠道",
      prop: "channelName",
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      span: 12,
      width: 120,
      overHidden: true,
    },
    {
      label: "商品",
      prop: "goodsName",
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      span: 12,
      align: "left",
      width: 400,
      overHidden: true,
    },
    {
      label: "收益总金额",
      prop: "incomeAmt",
      editDisplay: true,
      addDisplay: true,
      span: 12,
      width: 160,
      overHidden: true,
    },
    {
      label: "收益时间",
      prop: "incomeTime",
      editDisplay: true,
      addDisplay: true,
      span: 12,
      width: 160,
      overHidden: true,
    },
    {
      label: "发放时间",
      prop: "giveTime",
      editDisplay: true,
      addDisplay: true,
      span: 12,
      width: 160,
      overHidden: true,
    },
    {
      label: "收益率(%)",
      prop: "incomeRate",
      editDisplay: true,
      addDisplay: true,
      span: 12,
      width: 80,
      overHidden: true,
    },
    {
      label: "状态",
      prop: "status",
      type: "select",
      dicUrl: "/dict/type/D000125",
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      span: 12,
      width: 160,
      overHidden: true,
    },
    {
      label: "创建时间",
      prop: "createTime",
      editDisplay: false,
      addDisplay: false,
      span: 12,
      width: 160,
      overHidden: true,
    },
  ],
};

export default {
  name: "TableUser",
  components: {},
  props: {
    chanId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchItmes: {},
      option: tableOption,
      page: PAGE,
      list: [],
      selectedList: [],
      listLoading: true,
    };
  },
  computed: {},
  watch: {},
  methods: {
    getList(page, params) {
      const { currentPage, pageSize } = page;
      params = params ? params : { ...this.searchItmes };
      params.current = currentPage > 0 ? currentPage - 1 : 0;
      params.size = pageSize;
      params.channelId = this.chanId;
      this.listLoading = true;
      doGet("/rent/orderChannelIncome/queryPage", params).then(({ data }) => {
        if (data && data.data) {
          this.list = data.data.dataList || [];
          this.page.total = data.data.totalElements || 0;
        }
        this.listLoading = false;
      });
    },

     sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.currentPage = 1;
      this.searchItmes = param ? param : {};
      this.getList(this.page, param);
      done();
    },
    resetFilter() {
      this.page.currentPage = 1;
      this.searchItmes = {};
      this.getList(this.page, this.searchItmes);
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes);
    },
  },
};
</script>
