<template>
	<div class="product-financing-plan">
		<basic-container>
			<avue-crud ref="crud" :table-loading="isLoading" v-model:search="form" :page.sync="page" :option="option"
				:data="dataList" @on-load="getList" @size-change="sizeChange" @current-change="currentChange"
				@search-change="handleFilter" @search-reset="resetFilter" @refresh-change="handleRefreshChange">
				<!-- 列表左上角操作按钮 -->
				<template slot="menu" slot-scope="scope">
					<el-button type="text"  @click="downChange(scope)"
					 v-if="scope.row.sourceType != 'ACTIVE_REPAY' && scope.row.sourceType != 'RESERVE_DEPOSIT'&& scope.row.sourceType != 'RESERVE_BALANCE'&& scope.row.sourceType != 'RESERVE_RELEASE'">
						电子回单
					</el-button>
				</template>
			</avue-crud>
		</basic-container>
	</div>
</template>

<script>
	import {
		PAGE
	} from '@/const/global.js'
	import {
		doExportPostExcel,
		downExcel,
		doImg
	} from '@/router/axios'

	export default {
		name: 'ProductFinancingPlan',
		props: {},
		data() {
			return {
				form: {},
				isLoading: true,
				searchItmes: {},
				dataList: [],
				downLoading: false,
				option: {
					searchMenuSpan: 24,
					searchLabelWidth: 100,
					searchMenuPosition: 'center',
					searchIndex: 3,
					searchIcon: false,
					searchShowBtn: false,
					border: false,
					sumColumnList: [],
					printBtn: false,
					index: false,
					indexLabel: '序号',
					stripe: true,
					menualign: 'left',
					menuWidth: 140,
					labelWidth: 100,
					editBtn: false,
					delBtn: false,
					align: 'left',
					addBtn: false,
					searchShowBtn: false,
					column: [{
							label: '交易帐户户名',
							prop: 'accountName',
							width: 280,
							align: 'left',
							search: true,
							searchSpan: 8,
						},
						{
							label: '平台账户',
							prop: 'paymentType',
							width: 100,
							align: 'left',
							dicData: [{
									label: 'PING++',
									value: 1
								},
								{
									label: '富友支付',
									value: 2
								}
							],
						}, {
							label: '账户类型',
							prop: 'accountType',
							width: 280,
							align: 'left',
							dicData: [{
									label: '商户监管账户',
									value: 1
								},
								{
									label: '平台监管户',
									value: 2
								},
								{
									label: '资金方监管账户',
									value: 3
								},
								{
									label: '通道费账户',
									value: 4
								},
								{
									label: '渠道账户',
									value: 5
								},
								{
									label: '商户提现账户',
									value: 11
								},
								{
									label: '平台提现账户',
									value: 12
								},
								{
									label: '资金方提现账户',
									value: 13
								},
								{
									label: '通道提现账户',
									value: 14
								},
								{
									label: '渠道提现账户',
									value: 15
								},
							],
						},
						{
							label: '交易账号',
							prop: 'accountId',
							align: 'left',
							search: true,
							searchSpan: 8,
							width: 180,
						},
						{
							label: '交易流水号',
							prop: 'requestId',
							align: 'left',
							width: 240,
						},
						{
							label: '银行交易流水号',
							prop: 'source',
							align: 'left',
							width: 180,
						}, {
							label: '交易金额',
							prop: 'amount',
							align: 'left',
						}, {
							label: '交易后余额',
							prop: 'availableBalance',
							align: 'left',
							width: 100,
						}, {
							label: '冻结金额',
							prop: 'reservedBalance',
							align: 'left',
						}, {
							label: '交易类型',
							prop: 'sourceType',
							align: 'left',
							dicData: [{
									label: '主动还款',
									value: 'ACTIVE_REPAY'
								},
								{
									label: '支付',
									value: 'PAYMENT'
								},
								{
									label: '支付手续费',
									value: 'PAYMENT_FEE'
								},
								{
									label: '支付退款',
									value: 'PAYMENT_REFUND'
								},
								{
									label: '充值',
									value: 'DEPOSIT'
								},
								{
									label: '提现',
									value: 'WITHDRAW'
								},
								{
									label: '冻结入账金额',
									value: 'RESERVE_DEPOSIT'
								},
								{
									label: '冻结余额',
									value: 'RESERVE_BALANCE'
								},
								{
									label: '解冻',
									value: 'RESERVE_RELEASE'
								},
							],
						},
						{
							label: '交易对手账户名称',
							prop: 'counterpartyAccountName',
							align: 'left',
							width: 250,
						},
						{
							label: '交易对手账户类型',
							prop: 'counterpartyAccountType',
							align: 'left',
							width: 170,
							dicData: [{
									label: '商户监管账户',
									value: 1
								},
								{
									label: '平台监管户',
									value: 2
								},
								{
									label: '资金方监管账户',
									value: 3
								},
								{
									label: '通道费账户',
									value: 4
								},
								{
									label: '渠道账户',
									value: 5
								},
								{
									label: '商户提现账户',
									value: 11
								},
								{
									label: '平台提现账户',
									value: 12
								},
								{
									label: '资金方提现账户 ',
									value: 13
								},
								{
									label: '通道提现账户',
									value: 14
								},
								{
									label: '渠道提现账户',
									value: 15
								},
							],
						},
						{
							label: '交易对手账号',
							prop: 'counterpartyAcctId',
							width: 180,
							align: 'left',
						},
						{
							label: '交易发起时间',
							prop: 'createdAt',
							align: 'left',
							width: 150,
						},
						{
							label: '交易完成时间',
							prop: 'postedAt',
							align: 'left',
							width: 180,
						},
						{
							label: '交易状态',
							prop: 'procStatus',
							align: 'left',
							dicData: [{
									label: '待处理',
									value: 'Pending'
								},
								{
									label: '处理中',
									value: 'Processing'
								},
								{
									label: '处理成功',
									value: 'Succeeded'
								},
								{
									label: '处理失败',
									value: 'Failed'
								},
								{
									label: '已取消',
									value: 'Cancelled'
								}
							],
						},
						{
							label: '备注',
							prop: 'remark',
							align: 'left',
							width: 280,
						},
					],
				},
				page: PAGE,
				downLoad: {},
			}
		},
		watch: {},
		created() {},
		mounted() {},
		methods: {
			/** 获取列表数据 */
			getList(page, params) {
				const api = 'bium-portal/controller/fin-ops/va/virAcctTxnPage'
				params = params ?
					params : {
						...this.searchItmes,
					}
				params.pageNum = page.currentPage
				params.pageSize = page.pageSize
				this.isLoading = true
				this.downLoad = params
				this.doPost(api, params).then(({
					data
				}) => {
					if (data.result.records) {
						this.dataList = data.result.records || []
						this.page.total = data.result.total || 0
						if(this.dataList.length){
							this.dataList.forEach(item=>{
								
								if(!item.counterpartyAccountName){
									item.counterpartyAccountName = '--'
								}
								if(!item.counterpartyAccountType){
									item.counterpartyAccountType = '--'
								}
							})
						}
					}
					this.isLoading = false
				})
			},
			downChange(row) {
				const api = 'bium-portal/controller/fin-ops/va/electronicByReceiptId'
				this.listLoading = this.downLoading = true
				let newForm = this.removeEmptyValues({
					source: row.row.source
				});
				doImg(api, newForm)
					.then((res) => {
						let a = document.createElement('a')
						  const blob = new Blob([res.data], { type: "application/pdf" });
                        a.href = URL.createObjectURL(blob)
                            a.download = `${row.row.source}.pdf`
                            document.body.appendChild(a)
                            a.click()
						// const blob = new Blob([res.data], { type: "application/pdf" });
                        // window.open(window.URL.createObjectURL(blob), "_blank");
					})
					.catch(() => {
						this.listLoading = this.downLoading = false
					})
			},
			/** 分页变化，存储 */
			sizeChange(pageSize) {
				this.page.pageSize = pageSize
			},
			/** 当前页码变化，存储 */
			currentChange(current) {
				this.page.currentPage = current
			},
			/** 点击搜索栏搜索 */
			handleFilter(param, done) {
				this.page.currentPage = 1
				this.searchItmes = param ? param : {},
					this.getList(this.page, param)
				done()
			},
			/** 重置条件搜索 */
			resetFilter() {
				this.page.currentPage = 1
				this.searchItmes = {}
				this.getList(this.page, this.searchItmes)
			},
			/** 手动刷新列表 */
			handleRefreshChange() {
				this.getList(this.page, this.searchItmes)
			},

			removeEmptyValues(obj) {
				for (const key in obj) {
					if (obj[key] === null || obj[key] === undefined) {
						delete obj[key];
					} else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
						delete obj[key];
					}
				}
				return obj;
			}
		},
	}
</script>

<style lang="scss" scoped>
::v-deep .el-col:nth-last-child(2) {
	.el-form-item__label {
		width: fit-content !important;
	}

	.el-form-item__content {
		margin-left: 100px !important;
	}
}
</style>