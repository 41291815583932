<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :data="list"
        :summary-method="summaryMethod"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
      >
        <template #menuLeft>
          <el-button
            class="filter-item"
            type="primary"
            icon="el-icon-download"
            @click="handleExport"
            >导出</el-button
          >
        </template>

        <template slot="inOut" slot-scope="scope">
          <el-tag
            effect="dark"
            :type="scope.row.inOut === '1' ? 'success' : 'danger'"
            >{{ scope.row.inOut === "1" ? "收入" : "支出" }}</el-tag
          >
        </template>
        <template slot="transAmount" slot-scope="scope">
          <span>
            <el-tag>{{ scope.row.transAmount }}</el-tag>
          </span>
        </template>
        <template slot="bakTotleAmount" slot-scope="scope">
          <span>
            <el-tag type="info">{{ scope.row.bakTotleAmount }}</el-tag>
          </span>
        </template>
        <template slot="newTotleAmount" slot-scope="scope">
          <span>
            <el-tag type="danger">{{ scope.row.newTotleAmount }}</el-tag>
          </span>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import { PAGE } from "@/const/global.js";
import { doGet, doExportExcel, downExcel } from "@/router/axios";
const tableOption = {
  searchMenuSpan: 12,
  searchLabelWidth: 80,
  searchMenuPosition: "left",
  searchShowBtn: false,
  border: false,
  index: false,
  indexLabel: "序号",
  stripe: true,
  showSummary: true,
  menu: false,
  menuWidth: 160,
  header: true,
  labelWidth: 130,
  columnBtn: false, //隐藏表头设置
  editBtn: false,
  delBtn: false,
  align: "center",
  addBtn: false,
 searchShowBtn:false,
 column: [
    {
      label: "收支",
      prop: "inOut",
      type: "select",
      dicUrl: "/dict/type/D000123",
      search: true,
      searchSpan: 12,
    },
    {
      label: "科目",
      prop: "ioName",
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      label: "交易金额",
      prop: "transAmount",
    },
    {
      label: "交易时间",
      prop: "flowTime",
      type: "datetime",
      format: "yyyy-MM-dd HH:mm:ss",
      valueFormat: "yyyy-MM-dd HH:mm:ss",
    },
    {
      label: "原账户金额",
      prop: "bakTotleAmount",
    },
    {
      label: "新账户金额",
      prop: "newTotleAmount",
    },
    {
      label: "摘要",
      prop: "remark",
    },
  ],
};

export default {
  name: "TableUser",
  components: {},
  props: {
    accountId: {
      type: String,
      default: "",
    },
    accountName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      searchItmes: {},
      option: tableOption,
      searchItmes: {},
      page: PAGE,
      list: [],
      listLoading: true,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    getList(page, params) {
      const { currentPage, pageSize } = page;
      params = params ? params : { ...this.searchItmes };
      params.current = currentPage > 0 ? currentPage - 1 : 0;
      params.size = pageSize;
      params.accountId = this.accountId;
      this.listLoading = true;
      doGet("/cap/accountFlow/queryPage", params).then(({ data }) => {
        if (data && data.data) {
          const { dataList, totalElements } = data.data;
          this.list = dataList || [];
          this.page.total = totalElements || 0;
        }
        this.listLoading = false;
      });
    },

    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.currentPage = 1;
      this.searchItmes = param ? param : {};
      this.getList(this.page, param);
      done();
    },
    resetFilter() {
      this.page.currentPage = 1;
      this.searchItmes = {};
      this.getList(this.page, this.searchItmes);
    },

    summaryMethod({ data }) {
      let sum = []
      if (data && data.length) {
        let transAmountSum = 0
        for(let i=0; i<data.length; i++) {
          if (data[i].inOut == '1') {
            transAmountSum += data[i].transAmount || 0 //收入
          } else {
            transAmountSum -= data[i].transAmount || 0 //支出
          }
        }
        sum = ['', '', '', transAmountSum.toFixed(3)]
      }
      return sum
    },
    handleExport() {
      if (this.page.total > 0) {
        this.listLoading = true;
        const { inOut } = this.searchItmes;
        const api = "/cap/accountFlow/exportAccountAllFlow";
        let queryParams = `current=0&size=${this.page.total}&accountId=${this.accountId}&name=${this.accountName}`;
        debugger;
        if (inOut) queryParams += `&inOut=${inOut}`;
        doExportExcel(`${api}?${queryParams}`)
          .then((res) => {
            // 获取headers中的filename文件名
            downExcel(res);
            this.listLoading = false;
          })
          .catch(() => {
            this.listLoading = false;
          });
      } else {
        this.$notify.error("暂无流水明细可导出。");
      }
    },
  },
};
</script>
