var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "corporate-box" }, [
            _c("div", { staticClass: "corporate-item" }, [
              _c("p", { staticClass: "mt15" }, [
                _vm._v("账户名称：" + _vm._s(_vm.accountFlowForm.accountName)),
              ]),
              _c("p", { staticClass: "mt15" }, [
                _vm._v(
                  "支付渠道：" + _vm._s(_vm.accountFlowForm.paymentChannelName)
                ),
              ]),
              _c("p", { staticClass: "mt15" }, [
                _vm._v("钱包账号：" + _vm._s(_vm.accountFlowForm.walletId)),
              ]),
            ]),
          ]),
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              option: _vm.option,
              page: _vm.page,
              "table-loading": _vm.listLoading,
              data: _vm.list,
              "summary-method": _vm.summaryMethod,
            },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "on-load": _vm.getList,
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentChange,
              "search-change": _vm.handleFilter,
              "search-reset": _vm.resetFilter,
            },
            scopedSlots: _vm._u([
              {
                key: "inOut",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          effect: "dark",
                          type: scope.row.inOut === "1" ? "success" : "danger",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.inOut === "1" ? "收入" : "支出")
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "transAmount",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      [_c("el-tag", [_vm._v(_vm._s(scope.row.transAmount))])],
                      1
                    ),
                  ]
                },
              },
              {
                key: "bakTotleAmount",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      [
                        _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v(_vm._s(scope.row.bakTotleAmount)),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "newTotleAmount",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      [
                        _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v(_vm._s(scope.row.newTotleAmount)),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }