var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "cForm",
          staticStyle: { padding: "0 20px" },
          attrs: {
            "label-position": "right",
            model: _vm.cForm,
            rules: _vm.cRules,
            "label-width": "150px",
            size: "small",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt10 inner-box" },
            [
              _vm.rowForm.currNode == "risk" && _vm.rowForm.type == 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "企业授信金额(元)：",
                            prop: "creditAmount",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.cForm.creditAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.cForm, "creditAmount", $$v)
                              },
                              expression: "cForm.creditAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "审核结果：", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请选择" },
                      on: {
                        change: function ($event) {
                          return _vm.clear()
                        },
                      },
                      model: {
                        value: _vm.cForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "status", $$v)
                        },
                        expression: "cForm.status",
                      },
                    },
                    _vm._l(_vm.AuditList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          index: index,
                          label: item.label,
                          value: item.value,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.cForm.status == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "拒绝原因：", prop: "rejectType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.cForm.rejectType,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "rejectType", $$v)
                            },
                            expression: "cForm.rejectType",
                          },
                        },
                        _vm._l(_vm.Reject, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              index: index,
                              label: item.label,
                              value: item.value,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rowForm.currNode == "ops" &&
              _vm.rowForm.type == 1 &&
              _vm.rowForm.productType == 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客户资金方：",
                            prop: "fundingPartnerId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.cForm.fundingPartnerId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "fundingPartnerId", $$v)
                                },
                                expression: "cForm.fundingPartnerId",
                              },
                            },
                            _vm._l(_vm.fundingPartner, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  index: index,
                                  label: item.name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "对客费率方案：", prop: "" } },
                        [
                          !_vm.isRate
                            ? _c(
                                "span",
                                {
                                  staticClass: "cursor",
                                  on: { click: _vm.getRate },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("未配置")]
                                  ),
                                  _c("el-icon", {
                                    staticClass: "el-icon-refresh",
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "el-button",
                                {
                                  staticClass: "ml15",
                                  attrs: { type: "primary" },
                                  on: { click: _vm.toConfigStandardPlan },
                                },
                                [_vm._v("查看")]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rowForm.currNode == "ops" &&
              _vm.rowForm.type == 0 &&
              _vm.rowForm.productType == 1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客户资金方：",
                            prop: "fundingPartnerId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.cForm.fundingPartnerId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "fundingPartnerId", $$v)
                                },
                                expression: "cForm.fundingPartnerId",
                              },
                            },
                            _vm._l(_vm.fundingPartner, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  index: index,
                                  label: item.name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "对客利率：", prop: "customerRate" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "240px" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.cForm.customerRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "customerRate", $$v)
                                },
                                expression: "cForm.customerRate",
                              },
                            },
                            [
                              _c("template", { slot: "prepend" }, [
                                _vm._v("年化"),
                              ]),
                              _c("template", { slot: "append" }, [_vm._v("%")]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "用款周期：", prop: "paymentDays" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "240px" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.cForm.paymentDays,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "paymentDays", $$v)
                                },
                                expression: "cForm.paymentDays",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("天"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rowForm.currNode == "ops" &&
              _vm.rowForm.type == 1 &&
              _vm.rowForm.productType == 1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客户资金方：",
                            prop: "fundingPartnerId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.cForm.fundingPartnerId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "fundingPartnerId", $$v)
                                },
                                expression: "cForm.fundingPartnerId",
                              },
                            },
                            _vm._l(_vm.fundingPartner, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  index: index,
                                  label: item.name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "对客利率：", prop: "customerRate" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "240px" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.cForm.customerRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "customerRate", $$v)
                                },
                                expression: "cForm.customerRate",
                              },
                            },
                            [
                              _c("template", { slot: "prepend" }, [
                                _vm._v("年化"),
                              ]),
                              _c("template", { slot: "append" }, [_vm._v("%")]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "用款周期：", prop: "paymentDays" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "240px" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.cForm.paymentDays,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "paymentDays", $$v)
                                },
                                expression: "cForm.paymentDays",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("天"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rowForm.currNode == "ops" &&
              _vm.rowForm.type == 2 &&
              _vm.rowForm.productType == 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "店铺账号状态", prop: "storeStatus" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.cForm.storeStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "storeStatus", $$v)
                                },
                                expression: "cForm.storeStatus",
                              },
                            },
                            _vm._l(_vm.shopStatus, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  index: index,
                                  label: item.label,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "店铺账户状态",
                            prop: "virtualAcctStatus",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.cForm.virtualAcctStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "virtualAcctStatus", $$v)
                                },
                                expression: "cForm.virtualAcctStatus",
                              },
                            },
                            _vm._l(_vm.shopStatus, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  index: index,
                                  label: item.label,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rowForm.currNode == "risk" &&
              _vm.rowForm.type == 1 &&
              _vm.rowForm.productType == 0
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "店铺授信上限金额",
                          prop: "creditLimitAmount",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "240px" },
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.cForm.creditLimitAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "creditLimitAmount", $$v)
                            },
                            expression: "cForm.creditLimitAmount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "所属企业授信上限金额",
                          prop: "creditAmount",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "240px" },
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.cForm.creditAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "creditAmount", $$v)
                            },
                            expression: "cForm.creditAmount",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.rowForm.currNode == "risk" &&
              _vm.rowForm.type == 1 &&
              _vm.rowForm.productType == 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属企业授信上限金额",
                        prop: "creditAmount",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "240px" },
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.cForm.creditAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.cForm, "creditAmount", $$v)
                          },
                          expression: "cForm.creditAmount",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rowForm.currNode == "ops" &&
              _vm.rowForm.type == 1 &&
              _vm.rowForm.productType == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "店铺绑定手机号", prop: "storeMobile" } },
                    [
                      _c("el-autocomplete", {
                        attrs: {
                          "fetch-suggestions": _vm.querySearch,
                          placeholder: "请输入手机号",
                        },
                        on: { select: _vm.handleSelect },
                        model: {
                          value: _vm.cForm.storeMobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.cForm, "storeMobile", $$v)
                          },
                          expression: "cForm.storeMobile",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rowForm.currNode == "ops" &&
              _vm.rowForm.type == 1 &&
              _vm.rowForm.productType == 1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "店铺账号状态", prop: "storeStatus" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.cForm.storeStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "storeStatus", $$v)
                                },
                                expression: "cForm.storeStatus",
                              },
                            },
                            _vm._l(_vm.shopStatus, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  index: index,
                                  label: item.label,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "店铺账户状态",
                            prop: "virtualAcctStatus",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.cForm.virtualAcctStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "virtualAcctStatus", $$v)
                                },
                                expression: "cForm.virtualAcctStatus",
                              },
                            },
                            _vm._l(_vm.shopStatus, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  index: index,
                                  label: item.label,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "店铺绑定手机号",
                            prop: "storeMobile",
                          },
                        },
                        [
                          _c("el-autocomplete", {
                            attrs: {
                              "fetch-suggestions": _vm.querySearch,
                              placeholder: "请输入手机号",
                            },
                            on: { select: _vm.handleSelect },
                            model: {
                              value: _vm.cForm.storeMobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.cForm, "storeMobile", $$v)
                              },
                              expression: "cForm.storeMobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.rowForm.type == 2 && _vm.rowForm.currNode == "financial"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "放款金额：", prop: "loanAmount" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "240px" },
                              model: {
                                value: _vm.cForm.loanAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.cForm, "loanAmount", $$v)
                                },
                                expression: "cForm.loanAmount",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.rowForm.productType == 1 &&
                  _vm.rowForm.type == 2 &&
                  _vm.rowForm.currNode == "risk"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "本次极速付款金额(元)：",
                            prop: "paymentAmount",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "240px" },
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.cForm.paymentAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.cForm, "paymentAmount", $$v)
                              },
                              expression: "cForm.paymentAmount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "审核意见：", prop: "comment" } },
                [
                  _c("el-input", {
                    attrs: { rows: 4, placeholder: "请输入", type: "textarea" },
                    model: {
                      value: _vm.cForm.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "comment", $$v)
                      },
                      expression: "cForm.comment",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "tc" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }