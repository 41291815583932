var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.Loading,
          expression: "Loading",
        },
      ],
      staticClass: "selection-box",
    },
    [
      _c("div", { staticStyle: { display: "flex" } }, [
        _c("div", { staticClass: "corporate-box" }, [
          _c("div", { staticClass: "corporate-item" }, [
            _c("p", { staticClass: "mt15" }, [
              _vm._v("极速付款支付金额（元）：" + _vm._s(_vm.form.applyAmount)),
            ]),
            _c("p", { staticClass: "mt15" }, [
              _vm._v("支付单号：" + _vm._s(_vm.form.applyNo)),
            ]),
            _c("p", { staticClass: "mt15" }, [
              _vm._v("支付时间：" + _vm._s(_vm.form.applyDateTime)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "corporate-box" }, [
          _c("div", { staticClass: "corporate-item" }, [
            _c("p", { staticClass: "mt15" }, [
              _vm._v("企业名称：" + _vm._s(_vm.form.enterpriseName)),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                },
              },
              [
                _c("p", { staticClass: "mt15" }, [
                  _vm._v(
                    "待还本金：" + _vm._s(_vm.form.pendingPrincipal) + "元"
                  ),
                ]),
                _c("p", { staticClass: "mt15" }, [
                  _vm._v(
                    "待还利息：" + _vm._s(_vm.form.pendingInterest) + "元"
                  ),
                ]),
                _c("p", { staticClass: "mt15" }, [
                  _vm._v(
                    "待还服务费：" + _vm._s(_vm.form.pendingServiceFee) + "元"
                  ),
                ]),
              ]
            ),
            _c("p", { staticClass: "mt15" }, [
              _vm._v("融资到期日：" + _vm._s(_vm.form.dueDate)),
            ]),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "acc-container" }, [
        _c(
          "div",
          { staticClass: "order" },
          [
            _c(
              "el-divider",
              {
                staticClass: "title-divider",
                attrs: { "content-position": "left" },
              },
              [_vm._v("支付单详情")]
            ),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "order_list" }, [
                _c("div", { staticClass: "order_list_title" }, [
                  _vm._v("订单编号"),
                ]),
                _c("p", { staticClass: "order_list_p" }, [
                  _vm._v(_vm._s(_vm.orderForm.orderNo)),
                ]),
              ]),
              _c("div", { staticClass: "order_list" }, [
                _c("div", { staticClass: "order_list_title" }, [
                  _vm._v("下单时间"),
                ]),
                _c("p", { staticClass: "order_list_p" }, [
                  _vm._v(_vm._s(_vm.orderForm.createdFormat)),
                ]),
              ]),
              _c("div", { staticClass: "order_list" }, [
                _c("div", { staticClass: "order_list_title" }, [
                  _vm._v("订单原价总金额（元）"),
                ]),
                _c("p", { staticClass: "order_list_p" }, [
                  _vm._v(_vm._s(_vm.orderForm.totalAmountCost) + "元"),
                ]),
              ]),
              _c("div", { staticClass: "order_list" }, [
                _c("div", { staticClass: "order_list_title" }, [
                  _vm._v("订单赊销价总金额（元）"),
                ]),
                _c("p", { staticClass: "order_list_p" }, [
                  _vm._v(_vm._s(_vm.orderForm.totalAmountCredit) + "元"),
                ]),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt40" },
          [
            _c(
              "el-divider",
              {
                staticClass: "title-divider",
                attrs: { "content-position": "left" },
              },
              [_vm._v("货品信息")]
            ),
            _c(
              "div",
              {
                staticClass: "order_Name ml25",
                staticStyle: { "font-size": "13px" },
              },
              [
                _vm._v(
                  "供货方：" +
                    _vm._s(_vm.orderForm.supplierComName) +
                    "，采购方：" +
                    _vm._s(_vm.orderForm.orderComName)
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "order_table mt20" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.dataList, border: true },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "goodsName",
                        label: "货品名称",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "goodsNo",
                        align: "center",
                        label: "货品编码",
                        width: "180",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "goodsImg",
                        align: "center",
                        label: "货品主图",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-image", {
                                staticClass: "goodsImg",
                                attrs: {
                                  src: scope.row.goodsImg,
                                  "preview-src-list": [scope.row.goodsImg],
                                  alt: "",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "skuName",
                        align: "center",
                        label: "购买规格",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "priceCost",
                        align: "center",
                        label: "原单价",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "priceCredit",
                        align: "center",
                        label: "赊销单价",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderNum",
                        align: "center",
                        label: "采购数量",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "totalAmountCost",
                        align: "center",
                        label: "原价总金额",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "totalAmountCredit",
                        align: "center",
                        label: "赊销价总金额",
                        width: "120",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "flex mt20 payment" }, [
                  _vm._v("支付方式：\n                "),
                  _c("div", { staticClass: "payment_list ml10" }, [
                    _vm._v(
                      _vm._s(
                        _vm.orderForm.payType == 1
                          ? "余额支付"
                          : _vm.orderForm.payType == 2
                          ? "极速付款"
                          : "组合支付"
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "jg-acc mt15" }, [
                  _c(
                    "div",
                    { staticClass: "card-box" },
                    _vm._l(_vm.order_info, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "card-item" },
                        [
                          _c("div", { staticClass: "item_label" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.name) +
                                ":\n                        "
                            ),
                          ]),
                          _c("div", [
                            item.value == "isCredit"
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.orderForm[item.value] ? "是" : "否"
                                      )
                                  ),
                                ])
                              : item.value == "laterAmount"
                              ? _c("span", [
                                  _vm.orderForm[item.value]
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.orderForm[item.value]) +
                                            "元"
                                        ),
                                        _vm.orderForm.laterPayAmountMax
                                          ? _c("span", [
                                              _vm._v(
                                                "(最高" +
                                                  _vm._s(
                                                    _vm.orderForm
                                                      .laterPayAmountMax
                                                  ) +
                                                  "元)"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  !_vm.orderForm[item.value]
                                    ? _c("span", [_vm._v("--元(最高--元)")])
                                    : _vm._e(),
                                ])
                              : _c("span", [
                                  _vm.orderForm[item.value]
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.orderForm[item.value])
                                        ),
                                      ])
                                    : _vm._e(),
                                  !_vm.orderForm[item.value]
                                    ? _c("span", [_vm._v("--")])
                                    : _vm._e(),
                                  item.unit
                                    ? _c("span", [_vm._v(_vm._s(item.unit))])
                                    : _vm._e(),
                                ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }