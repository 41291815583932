<template>
  <div>
    <el-form
      ref="cForm"
      :label-position="'right'"
      :model="cForm"
      :rules="cRules"
      label-width="150px"
      size="small"
      style="padding: 0 20px"
    >
      <!--      currNode:{{ rowForm.currNode }},type:{{ rowForm.type }},productType{{ rowForm.productType }}-->
      <div class="mt10 inner-box">
        <div v-if="rowForm.currNode == 'risk' && rowForm.type == 0">
          <el-form-item label="企业授信金额(元)：" prop="creditAmount">
            <el-input v-model="cForm.creditAmount" placeholder="请输入" style="width: 300px" />
          </el-form-item>
        </div>
        <el-form-item label="审核结果：" prop="status">
          <el-select v-model="cForm.status" placeholder="请选择" style="width: 300px" @change="clear()">
            <el-option
              v-for="(item, index) in AuditList"
              :key="index"
              :index="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="cForm.status == 2" label="拒绝原因：" prop="rejectType">
          <el-select v-model="cForm.rejectType" placeholder="请选择">
            <el-option
              v-for="(item, index) in Reject"
              :key="index"
              :index="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <div v-if="rowForm.currNode == 'ops' && rowForm.type == 1 && rowForm.productType == 0">
          <el-form-item label="客户资金方：" prop="fundingPartnerId">
            <el-select v-model="cForm.fundingPartnerId" placeholder="请选择" style="width: 300px">
              <el-option
                v-for="(item, index) in fundingPartner"
                :key="index"
                :index="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="对客费率方案：" prop="">
            <span v-if="!isRate" class="cursor" @click="getRate">
              <span style="color: red">未配置</span>
              <el-icon class="el-icon-refresh" />
            </span>
            <el-button v-else class="ml15" type="primary" @click="toConfigStandardPlan">查看</el-button>
          </el-form-item>
        </div>
        <div v-if="rowForm.currNode == 'ops' && rowForm.type == 0 && rowForm.productType == 1">

          <el-form-item label="客户资金方：" prop="fundingPartnerId">
            <el-select v-model="cForm.fundingPartnerId" placeholder="请选择" style="width: 300px">
              <el-option
                v-for="(item, index) in fundingPartner"
                :key="index"
                :index="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="对客利率：" prop="customerRate">
            <!-- <span v-if="cForm.customerRate != null && cForm.customerRate != undefined">年化 {{cForm.customerRate }} %</span>
            <span style="color: red;" v-else> 未配置</span> -->
            <el-input v-model="cForm.customerRate" placeholder="请输入" style="width: 240px">
              <template slot="prepend">年化</template>
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item label="用款周期：" prop="paymentDays">
            <el-input v-model="cForm.paymentDays" placeholder="请输入" style="width: 240px">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
        </div>
        <div v-if="rowForm.currNode == 'ops' && rowForm.type ==1 && rowForm.productType == 1">
          <el-form-item label="客户资金方：" prop="fundingPartnerId">
            <el-select v-model="cForm.fundingPartnerId" placeholder="请选择" style="width: 300px">
              <el-option
                v-for="(item, index) in fundingPartner"
                :key="index"
                :index="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="对客利率：" prop="customerRate">
            <!-- <span v-if="cForm.customerRate != null && cForm.customerRate != undefined">年化 {{cForm.customerRate }} %</span>
  <span style="color: red;" v-else> 未配置</span> -->
            <el-input v-model="cForm.customerRate" placeholder="请输入" style="width: 240px">
              <template slot="prepend">年化</template>
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item label="用款周期：" prop="paymentDays">
            <el-input v-model="cForm.paymentDays" placeholder="请输入" style="width: 240px">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
        </div>
        <div v-if="rowForm.currNode == 'ops' && rowForm.type == 2 && rowForm.productType == 0">
          <el-form-item label="店铺账号状态" prop="storeStatus">
            <el-select v-model="cForm.storeStatus" placeholder="请选择" style="width: 300px">
              <el-option
                v-for="(item, index) in shopStatus"
                :key="index"
                :index="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="店铺账户状态" prop="virtualAcctStatus">
            <el-select v-model="cForm.virtualAcctStatus" placeholder="请选择" style="width: 300px">
              <el-option
                v-for="(item, index) in shopStatus"
                :key="index"
                :index="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </div>
        <template v-if="rowForm.currNode == 'risk' && rowForm.type == 1 && rowForm.productType == 0">
          <el-form-item label="店铺授信上限金额" prop="creditLimitAmount">
            <el-input v-model="cForm.creditLimitAmount" placeholder="请输入" style="width: 240px" />
          </el-form-item>
          <el-form-item label="所属企业授信上限金额" prop="creditAmount">
            <el-input v-model="cForm.creditAmount" placeholder="请输入" style="width: 240px" />
          </el-form-item>
        </template>
        <el-form-item
          v-if="rowForm.currNode == 'risk' && rowForm.type == 1 && rowForm.productType == 1"
          label="所属企业授信上限金额"
          prop="creditAmount"
        >
          <el-input v-model="cForm.creditAmount" placeholder="请输入" style="width: 240px" />
        </el-form-item>
        <el-form-item
          v-if="rowForm.currNode == 'ops' && rowForm.type == 1 && rowForm.productType == 0"
          label="店铺绑定手机号"
          prop="storeMobile"
        >
          <el-autocomplete
            v-model="cForm.storeMobile"
            :fetch-suggestions="querySearch"
            placeholder="请输入手机号"
            @select="handleSelect"
          />
        </el-form-item>
        <div v-if="rowForm.currNode == 'ops' && rowForm.type == 1 && rowForm.productType == 1">
          <el-form-item label="店铺账号状态" prop="storeStatus">
            <el-select v-model="cForm.storeStatus" placeholder="请选择" style="width: 300px">
              <el-option
                v-for="(item, index) in shopStatus"
                :key="index"
                :index="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="店铺账户状态" prop="virtualAcctStatus">
            <el-select v-model="cForm.virtualAcctStatus" placeholder="请选择" style="width: 300px">
              <el-option
                v-for="(item, index) in shopStatus"
                :key="index"
                :index="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="店铺绑定手机号" prop="storeMobile">
            <el-autocomplete
              v-model="cForm.storeMobile"
              :fetch-suggestions="querySearch"
              placeholder="请输入手机号"
              @select="handleSelect"
            />
          </el-form-item>
        </div>
        <div v-else-if="rowForm.type == 2 && rowForm.currNode == 'financial'">
          <el-form-item label="放款金额：" prop="loanAmount">
            <el-input v-model="cForm.loanAmount" style="width: 240px">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </div>
        <div v-else-if="rowForm.productType == 1 && rowForm.type == 2 && rowForm.currNode == 'risk'">
          <el-form-item label="本次极速付款金额(元)：" prop="paymentAmount">
            <el-input v-model="cForm.paymentAmount" placeholder="请输入" style="width: 240px" />
          </el-form-item>
        </div>
        <el-form-item label="审核意见：" prop="comment">
          <el-input v-model="cForm.comment" :rows="4" placeholder="请输入" type="textarea" />
        </el-form-item>
      </div>
      <div class="tc">
        <el-button size="medium" type="primary" @click="submit">提交</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

// Component.registerHooks(['beforeRouteLeave']) //ts用法

export default defineComponent({
  name: 'CaseRecord',
  components: {},
  props: {
    rowForm: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      cForm: {
        status: '',
        customerRate: '',
        fundingPartnerId: '',
        firstTraceDays: '',
        multipleTraceDays: '',
        paymentAmount: '',
        creditAmount: '',
        loanAmount: '',
        creditLimitAmount: ''
      },
      isRate: false,
      dataForm: {},
      fundingPartner: [],
      AuditList: [
        {
          label: '通过',
          value: 1
        },
        {
          label: '拒绝',
          value: 2
        }
      ],
      Reject: [
        {
          label: '无法联系',
          value: 0
        },
        {
          label: '身份核实失败',
          value: 1
        },
        {
          label: '店铺信息伪造',
          value: 2
        },
        {
          label: '严重涉诉',
          value: 3
        },
        {
          label: '负债过高',
          value: 4
        },
        {
          label: '历史信用差',
          value: 5
        },
        {
          label: '其他',
          value: 6
        }
      ],
      shopStatus: [
        {
          label: '未管控',
          value: 'un_controlled'
        },
        {
          label: '已管控',
          value: 'controlled'
        }
      ],
      cRules: {
        status: [{ required: true, trigger: 'blur', message: '请选择审核结果' }],
        rejectType: [{ required: true, trigger: 'blur', message: '请选择拒绝原因' }],
        creditAmount: [{ required: true, trigger: 'blur', message: '请输入企业授信金额' }],
        creditLimitAmount: [{ required: true, trigger: 'blur', message: '请输入店铺授信上限金额' }],
        paymentAmount: [{ required: true, trigger: 'blur', message: '请输入极速付款金额' }],
        paymentDays: [{ required: true, trigger: 'blur', message: '请输入用款周期' }],
        customerRate: [{ required: true, trigger: 'blur', message: '请输入对客利率' }],
        loanAmount: [{ required: true, trigger: 'blur', message: '请输入放款金额' }],
        fundingPartnerId: [{ required: true, trigger: 'blur', message: '请选择客户资金方' }],
        // firstTraceDays: [{ required: true, trigger: 'blur', message: '请输入首笔提款回溯天数' }],
        // multipleTraceDays: [{ required: true, trigger: 'blur', message: '请输入多笔提款回溯天数' }],
        virtualAcctStatus: [{ required: true, trigger: 'blur', message: '请选择店铺账户状态' }],
        storeStatus: [{ required: true, trigger: 'blur', message: '请选择店铺账号状态' }]
      },
      mobileList: []
    };
  },
  created() {
    this.getPhoneList();
    if (this.rowForm.type == 0) {
      this.getData();
      this.getAll();
      this.getRate();
    }
    if (this.rowForm.type == 1) {
      this.getAll();
    }
    if (this.rowForm.amount) {
      this.cForm.loanAmount = this.rowForm.amount;
    }
    if (this.rowForm.currNode == 'ops' && this.rowForm.type == 0 && this.rowForm.productType == 1) {
      this.getCustRates();
    }
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.mobileList;
      const results = queryString
        ? restaurants.filter((item) => {
          return item.mobile.toLowerCase().includes(queryString.toLowerCase());
        })
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleSelect(item) {
      this.cForm.storeMobile = item.value;
    },
    getPhoneList() {
      this.doPost('bium-portal/controller/fin-ops/store/getCloudNameList').then(({ data }) => {
        data.forEach(item => {
          item.value = item.mobile;
        });
        this.mobileList = data;
      });
    },
    /** 获取渠道数据 */
    getCustRates() {
      this.doPost('bium-portal/controller/fin-ops/ratePlan/getRatePlanDetail', {
        storeId: this.rowForm.storeId
      }).then(({ data }) => {
        if (data && data.result) {
          if (data.result.custRateDetailDTOList != null) {
            // this.cForm.customerRate = (Number(data.result.custRateDetailDTOList[0].customerRate) * 100).toFixed(2)
            this.$set(
              this.cForm,
              'customerRate',
              (Number(data.result.custRateDetailDTOList[0].customerRate) * 100).toFixed(2),
            );
          } else {
            this.$set(this.cForm, 'customerRate', '');
          }
        }
      });
    },
    getdetail() {
      this.doPost('/bium-portal/controller/fin-ops/audit/getAuditDetail', {
        id: this.rowForm.id
      }).then(({ data }) => {
        this.getshopData(data.result.content.auditStoreEntity.id);
      });
    },

    toConfigStandardPlan() {
      const dataItem = this.rowForm;
      const comp = () => import('../../expense/components-plan/plan-view.vue');
      this.$modalDialog(comp, { dataItem });
    },
    getData() {
      console.log(this.rowForm);
      this.doPost('bium-portal/controller/fin-ops/ratePlan/getRatePlan', {
        storeId: this.rowForm.storeId
      }).then(({ data }) => {
        if (data.result) {
          this.cForm = data.result;
          // this.cForm.fundingPartnerId = data.result.funderId
          this.$set(this.cForm, 'fundingPartnerId', this.cForm.funderId);
        }
      });
    },
    getAll() {
      this.doPost('bium-portal/controller/fin-ops/fundingPartner/all', {
        companyId: this.rowForm.enterpriseId,
        productType: this.rowForm.productType
      }).then(({ data }) => {
        this.fundingPartner = data.result;
      });
    },
    getRate() {
      this.doPost('/bium-portal/controller/fin-ops/ratePlan/isCustRateDetailConfig', {
        storeId: this.rowForm.storeId
      }).then(({ data }) => {
        this.isRate = data.result;
      });
    },
    submit() {
      if (this.cForm.status == 1) {
        this.$refs['cForm'].validate((valid) => {
          if (valid) {
            this.submitForm();
          }
        });
      } else if (this.cForm.status == 2) {
        if (!this.cForm.rejectType && this.cForm.rejectType != 0 && this.cForm.rejectType == '') {
          return this.$message.error('请选择拒绝原因');
        }
        this.submitForm();
      } else {
        return this.$message.error('请选择审核结果');
      }
    },
    submitForm() {
      const form = {
        id: this.rowForm.id,
        status: this.cForm.status,
        rejectType: this.cForm.status == 2 ? this.cForm.rejectType : '',
        comment: this.cForm.comment,
        bussParams: {
          paymentAmount: this.cForm.paymentAmount,
          fundingPartnerId: this.cForm.fundingPartnerId,
          customerRate: this.cForm.customerRate ? Number(this.cForm.customerRate / 100).toFixed(4) : '',
          firstTraceDays: this.cForm.firstTraceDays,
          multipleTraceDays: this.cForm.multipleTraceDays,
          creditAmount: this.cForm.creditAmount,
          loanAmount: this.cForm.loanAmount,
          paymentDays: this.cForm.paymentDays,
          storeStatus: this.cForm.storeStatus,
          storeMobile: this.cForm.storeMobile,
          virtualAcctStatus: this.cForm.virtualAcctStatus,
          creditLimitAmount: this.cForm.creditLimitAmount
        }
      };
      this.doPost('bium-portal/controller/fin-ops/audit/operate', form).then(({ data }) => {
        if (data && data.result) {
          this.$parent.handleClose();
        }
      });
    },
    clear() {
      this.$refs['cForm'].clearValidate();
    }
  }
});
</script>

<style lang="scss" scoped></style>
