<template>
    <div class="selection-box" v-loading="Loading">
        <section class="acc-container">
            <ul class="card-infos">
                <li class="word-break">
                    <label>渠道名称：</label>{{ dataDetails.name }}
                </li>
                <li class="word-break">
                    <label>渠道注册时间：</label>{{ dataDetails.createdDesc }}
                </li>
                <li class="word-break">
                    <label>渠道等级：</label>{{ dataDetails.levelDesc }}
                </li>
              
            </ul>

        </section>
        <section class="acc-container">
            <div v-if="dataDetails.enterprise != null">
                <el-divider content-position="left" class="title-divider">企业营业信息
                </el-divider>
                <div class="jg-acc">
                    <!--开户成功，有数据-->
                   <div class="card-box">
                        <div class="card-item" v-for="(item, index) in companyInfo" :key="index">
                            <div class="item_label">
                                {{ item.name }}：
                            </div>
                            <div>
                                <span v-if="item.name == '营业执照有效期'">{{ dataDetails.enterprise.establishmentDate }}至{{ dataDetails.enterprise.validDate }}</span>
                                <span v-else>{{ dataDetails.enterprise[item.value] }}</span>
                            </div>
                        </div>

                    </div>
                </div>
                <el-divider content-position="left" class="title-divider">企业法人信息
                </el-divider>
                <div class="jg-acc">
                    <!--开户成功，有数据-->
                   <div class="card-box">
                        <div class="card-item" v-for="(item, index) in personInformation" :key="index">
                            <div class="item_label">
                                {{ item.name }}：
                            </div>
                            <div>
                                {{ dataDetails.enterprise.legal[item.value] }}
                            </div>
                        </div>

                    </div>

                </div>
                <div v-if="dataDetails.enterprise.authorization != null">
                    <el-divider content-position="left" class="title-divider">授权人信息
                    </el-divider>
                    <div class="jg-acc">
                        <!--开户成功，有数据-->
                        <div class="card-box">
                           <div class="card-item" v-for="(item, index) in AuthorizedInformation" :key="index">
                                <div class="item_label">
                                    {{ item.name }}：
                                </div>
                                <div>
                                    {{ dataDetails.enterprise.authorization[item.value] }}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div v-if="dataDetails.accountList != null">
                <div v-for=" (item, index) in dataDetails.accountList" :key="index">
                    <el-divider content-position="left" class="title-divider">
                        {{ item.paymentChannelDesc }}账户信息
                    </el-divider>
                    <div class="jg-acc">
                        <!--开户成功，有数据-->
                        <div class="card-box">
                            <div class="card-item" v-for="(item, index) in accountList" :key="index">
                                <div class="item_label">
                                    {{ item.name }}：
                                </div>
                                <div>
                                    {{ dataDetails.accountList[0][item.value] }}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
           <div v-if="dataDetails.enterprise.id != null">
            <imgdialog :code="dataDetails.enterprise.id" :fileArr="dataDetails.attachmentList"></imgdialog>
           </div>
        </section>
     
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import imgdialog from './components/imgdialog'
// Component.registerHooks(['beforeRouteLeave']) //ts用法

export default defineComponent({
    name: 'channelDetail',
    components: {
        imgdialog
    },
    data() {
        return {
            dataDetails: {
                attachmentList:[]
            },
            Loading: false,
            //企业营业信息
            companyInfo: [
                {
                    name: "企业名称",
                    value: "name"
                }, {
                    name: "社会信用代码",
                    value: "creditCode"
                }, {
                    name: "企业地址信息",
                    value: "address"
                }, {
                    name: "营业执照有效期",
                    value: "validDate"
                }
            ],
            //企业法人信息
            personInformation: [
                {
                    name: "法人姓名",
                    value: "name"
                }, {
                    name: "法人身份证",
                    value: "card"
                }, {
                    name: "身份证有效期",
                    value: "duration"
                }, {
                    name: "法人联系邮箱",
                    value: "mail"
                }, {
                    name: "法人手机号码",
                    value: "mobile"
                }, {
                    name: "民族",
                    value: "ethnic"
                }
            ],
            //授权人信息
            AuthorizedInformation: [
                {
                    name: "授权人姓名",
                    value: "name"
                }, {
                    name: "授权人身份证",
                    value: "card"
                }, {
                    name: "身份证有效期",
                    value: "duration"
                }, {
                    name: "授权人邮箱",
                    value: "mail"
                }, {
                    name: "授权人联系电话",
                    value: "mobile"
                }, {
                    name: "民族",
                    value: "ethnic"
                }
            ],
            //账户信息
            accountList: [
                {
                    name: "账户系统",
                    value: "paymentChannelDesc"
                }, {
                    name: "账户名称",
                    value: "accountName"
                }, {
                    name: "所属银行",
                    value: "bankName"
                }, {
                    name: "账号",
                    value: "accountNo"
                }, {
                    name: "账户类型",
                    value: "accountTypeDesc"
                }, {
                    name: "开户支行",
                    value: "bankBranchName"
                }, {
                    name: "开户行地址",
                    value: "bankBranchAddress"
                }
            ],
        }
    },
    created() {
        this.getData()
    },
    mounted() {

    },
    methods: {
        getData() {
            this.Loading = true
            this.doPost("/bium-portal/controller/fin-ops/enterprise/load", {
                id: this.$route.query.id
            }).then(({ data }) => {
			   this.dataDetails = data.result
            });
            this.Loading = false;
        },
     
    },
})
</script>

<style scoped lang="scss">
.acc-container {
    height: auto;
    min-height: 60px;

    .acc-tip {
        text-align: center;
    }

    .dis-act {
        color: #909399;
        background-color: #eee;
        border-color: #eee;
        cursor: not-allowed;
        margin: 0;

        i {
            font-style: normal;
            color: #f00;
        }
    }

    /deep/.el-loading-mask {
        top: -8px;
    }
}

/deep/ .inner-box {
    margin: 20px;
    width: auto;
}

/deep/ .el-button--small .iconfont {
    font-size: 12px !important;
}

/deep/ .account-list .account-list-item .tag {
    left: 0;
    right: auto;
    border-top-right-radius: 20px;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 0;
    font-weight: normal;
    font-size: 12px;
}

/deep/ .account-list .account-list-item {
    /*min-width: 120px;*/
    height: 75px;
    text-align: center;
    padding-top: 32px;
}

/deep/ .svg-waves {
    display: none;
}

/deep/ .account-list .account-list-item.actived {
    background-image: radial-gradient(circle at center, #4a98f9, #5bacfa);
}

/deep/ .account-list .account-list-item .success {
    background-color: #66cc66;
}

/deep/.account-list .account-list-item .warn {
    background-color: #ffcc33;
}
</style>