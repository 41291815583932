<template>
	<div class="product-financing-plan">
		<basic-container>
			<avue-crud ref="crud" :table-loading="isLoading" :search.sync="form" :page.sync="page" :option="option"
				:data="dataList" @on-load="getList" @size-change="sizeChange" @current-change="currentChange"
				@search-change="handleFilter" @search-reset="resetFilter" @refresh-change="handleRefreshChange">
				<!-- 列表左上角操作按钮 -->
				<template #menuLeft>
					<el-button icon="el-icon-download" type="primary" :loading="downLoading"
						@click="toConfigStandardPlan(form)">
						导出
					</el-button>
				</template>
			</avue-crud>
		</basic-container>
	</div>
</template>

<script>
	import {
		PAGE
	} from '@/const/global.js'
	import {
		doExportPostExcel,
		downExcel
	} from '@/router/axios'

	export default {
		name: 'ProductFinancingPlan',
		props: {},
		data() {
			return {
				form: {},
				isLoading: true,
				searchItmes: {},
				dataList: [],
				downLoading: false,
				option: {
					// 搜索栏配置
					searchMenuSpan: 8,
					searchMenuPosition: 'left',
					// 表格主体配置
					border: false,
					index: false,
					indexLabel: '序号',
					stripe: true,
					headeralign: 'left',
					align: 'left',
					// 表格操作列配置
					menu: false,
					menuWidth: 150,
					addBtn: false,
					editBtn: false,
					delBtn: false,
					searchShowBtn: false,
					column: [{
							label: '账户名称',
							prop: 'accountName',
							minWidth: 280,
							align: 'left',
							search: true,
							searchSpan: 6,
						},

						{
							label: '支付机构',
							prop: 'accountType',
							search: true,
							searchSpan: 6,
              type: 'select',
              hide: true,
              dicData: [
                { label: 'PING++', value: 'ping_pp' },
              ],
						},
						{
							label: '账号',
							prop: 'accountId',
							minWidth: 280,
							search: true,
							searchSpan: 6,
						},
						{
							label: '监管账户余额',
							prop: 'availableBalance',
							minWidth: 280,
						},
						{
							label: '交易类型',
							prop: 'transactionType',
							minWidth: 280,
						},
						{
							label: '交易金额',
							prop: 'amount',
							minWidth: 280,

						},
						{
							label: '交易日期',
							prop: 'postedAt',
							type: 'date',
							searchRange: true,
							valueFormat: 'yyyy-MM-dd',
							searchValue: [this.getLastMonth(), this.getNow()],
							search: true,
							searchSpan: 6,
							span: 12,
							width: 150,
						},
					],
				},
				page: PAGE,
				downLoad: {},
			}
		},
		watch: {},
		created() {},
		mounted() {},
		methods: {
			getNow() {
				const now = new Date();
				return this.formatDate(now);
			},
			getLastMonth() {
				const now = new Date();
				const lastMonth = new Date(now.setMonth(now.getMonth() - 1));
				return this.formatDate(lastMonth);
			},
			formatDate(date) {
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const day = date.getDate();
				return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
			},
			/** 获取列表数据 */
			getList(page, params) {
				if (params && params.postedAt) {
					params.startTime = params.postedAt[0]
					params.endTime = params.postedAt[1]
					delete params.postedAt
				}
				
				const api = '/bium-portal/controller/fin-ops/clearing/selectByAccountCharging'
				params = params ?
					params :
					{
						...this.searchItmes,
					}
				params.pageNum = page.currentPage
				params.pageSize = page.pageSize
				if(this.form.postedAt == undefined){
					params.startTime =this.getLastMonth()
					params.endTime = this.getNow()
				}else{
					params.startTime = this.form.postedAt[0]
					params.endTime = this.form.postedAt[1]
				}
				this.isLoading = true
				this.downLoad = params
				this.doPost(api, params).then(({
					data
				}) => {
					if (data.result.records) {
						this.dataList = data.result.records || []
						this.page.total = data.result.total || 0
					}
					this.isLoading = false
				})
			},

			/** 分页变化，存储 */
			sizeChange(pageSize) {
				this.page.pageSize = pageSize
			},
			/** 当前页码变化，存储 */
			currentChange(current) {
				this.page.currentPage = current
			},
			/** 点击搜索栏搜索 */
			handleFilter(param, done) {
				this.page.currentPage = 1
				this.searchItmes = param ? param : {}
				this.getList(this.page, param)
				done()
			},
			/** 重置条件搜索 */
			resetFilter() {
				this.page.currentPage = 1
				this.searchItmes = {}
				this.getList(this.page, this.searchItmes)
			},
			/** 手动刷新列表 */
			handleRefreshChange() {
				this.getList(this.page, this.searchItmes)
			},

			/** 导出 */
			toConfigStandardPlan(form) {
				if(this.page.total == 0) return this.$message.error('暂无可导出的数据') 
				let that = this
				const data = {
					accountName:form.accountName || null, //账户名称
					accountType: form.accountType || null, //支付机构
					accountId: form.accountId || null, //账号
					startTime:form.postedAt[0] || null, //起始日， 格式 yyyy-MM-dd
					endTime: form.postedAt[1] || null, //结束日， 格式 yyyy-MM-dd
					pageSize: 1000, //每页数量
					pageNum: 1, //当前页
				}
					this.listLoading = this.downLoading = true
					const api = '/bium-portal/controller/fin-ops/clearing/downloadAccountCharging'
					doExportPostExcel(api, data)
						.then((res) => {
							let reader = new FileReader()
							reader.onload = function(event) {
								if (event.target.result === '') {
									that.$message.error('超过最大下载量，最多可下载1000条')
									that.listLoading = that.downLoading = false
								} else {
									downExcel(res)
									that.listLoading = that.downLoading = false
								}
							}
							reader.readAsText(res.data)
						})
						.catch(() => {
							this.listLoading = this.downLoading = false
						})
			},
			/** 显示配置弹窗 */
			toConfigPlanModal(dataItem) {
				// const comp = () => import("./components-plan/plan-edit.vue");
				// this.$modalDialog(comp, {
				// 	dataItem
				// }).then(() => {
				// 	this.page.currentPage = 1;
				// 	this.handleRefreshChange();
				// });
			},
		},
	}
</script>

<style lang="scss" scoped>
::v-deep .el-col:nth-last-child(2) {
	.el-form-item__label {
		width: fit-content !important;
	}

	.el-form-item__content {
		margin-left: 100px !important;
	}
}
</style>
