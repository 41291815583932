<template>
  <div class="selection-box">
    <section class="acc-container">
      <el-divider content-position="left" class="title-divider">基本信息</el-divider>
      <div class="jg-acc">
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">客户名称:</div>
            <div>{{ form.enterpriseName }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业编号:</div>
            <div>{{ form.enterpriseCode }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">联系方式:</div>
            <div>{{ form.mobile }}</div>
          </div>
        </div>
      </div>
      <el-divider content-position="left" class="title-divider">店铺信息</el-divider>
      <div class="jg-acc">
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">店铺名称:</div>
            <div>{{ form.storeName }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">店铺所属平台:</div>
            <div v-if="form.livePlatform == 10">抖音</div>
            <div v-if="form.livePlatform == 11">快手</div>
            <div v-if="form.livePlatform == 12">拼多多</div>
            <div v-if="form.livePlatform == 13">腾讯视频号</div>
            <div v-if="form.livePlatform == 14">美团</div>
          </div>
          <div class="card-item">
            <div class="item_label">店铺ID:</div>
            <div>{{ form.storeCode }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">店铺账户ID:</div>
            <div>{{ form.storekeeperAcctId }}</div>
          </div>
        </div>
      </div>
      <el-divider content-position="left" class="title-divider">融资订单信息</el-divider>
      <div class="jg-acc">
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">订单号:</div>
            <div>{{ form.applyNo }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">日期:</div>
            <div>{{ form.paymentDate }}</div>
          </div>
          <div class="card-item">
            <div class="item_label" style="color: #00a2ff">放款金额:</div>
            <div style="color: #00a2ff">{{ form.paymentAmount }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">状态:</div>
            <div v-if="form.status == 'Repayment'" style="color: red">还款中</div>
            <div v-if="form.status == 'Overdue'" style="color: red">逾期未结清</div>
            <div v-if="form.status == 'Overdue_Clearing'" style="color: red">逾期清分中</div>
            <div v-if="form.status == 'PayOff'" style="color: green">正常结清</div>
            <div v-if="form.status == 'PayOff_Overdue'" style="color: red">逾期结清</div>
          </div>
          <div class="card-item">
            <div class="item_label">费率:</div>
            <!-- todo 新增费率  单笔  年化  后端处理  http://localhost:9501/#/operate/financingDetails?id=28-->
            <div>{{ (Number(form.customerRate) * 100).toFixed(4) }}% /{{ form.billMethod }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">息费总额:</div>
            <div>{{ form.interestAmount }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">折扣率:</div>
            <div v-if="form.discountRate">{{ (Number(form.discountRate) * 100).toFixed(2) }}%</div>
            <div v-else>无</div>
          </div>
          <div class="card-item">
            <div class="item_label">剩余应还本金:</div>
            <div>{{ form.pendingPrincipal }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">剩余应还利息:</div>
            <div>{{ form.pendingInterest }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">剩余应还服务费:</div>
            <div>{{ form.pendingServiceFee }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">罚息:</div>
            <div>{{ form.pendingOverdue }}</div>
          </div>
        </div>
      </div>
      <el-divider content-position="left" class="title-divider">费率信息</el-divider>
      <div class="jg-acc">
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">资金方:</div>
            <div>{{ form.funderName }}</div>
          </div>
          <!-- <div class="card-item">
            <div class="item_label">首笔提款回溯天数:</div>
            <div>T-{{ form.firstTraceDays }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">多笔提款回溯天数:</div>
            <div>T-{{ form.multipleTraceDays }}</div>
          </div> -->
          <div class="card-item">
            <div class="item_label">企业归属:</div>
            <div v-if="form.channelId">
              <span>{{ form.channelName }}</span>
              (
              <span v-if="form.channelLevel == 1">一级</span>
              <span v-if="form.channelLevel == 2">二级</span>
              <span v-if="form.channelLevel == 3">三级</span>
              )
            </div>
            <div v-else>直客</div>
          </div>
        </div>
      </div>
    </section>
    <el-divider content-position="left" class="title-divider">融资订单</el-divider>
    <div style="color: #00a2ff">
      <span>融资商品订单数：{{ orderNumberForm.orderCount }}笔</span>
      <span style="padding-left: 30px">已结算商品订单：{{ orderNumberForm.settledOrderCount }}笔</span>
    </div>
    <avue-crud
      ref="crud"
      :option="option"
      :page.sync="page"
      :table-loading="listLoading"
      :data="list"
      @on-load="getList"
      @size-change="sizeChange"
      @current-change="currentChange"
      @search-change="handleFilter"
      @search-reset="resetFilter"
      @refresh-change="handleRefreshChange">
      <template slot="statusDesc" slot-scope="scope">
        <el-tag
          effect="dark"
          :type="scope.row.statusDesc == '已还款' ? 'success' : scope.row.statusDesc == '已逾期' ? 'danger' : ''">
          <span>{{ scope.row.statusDesc }}</span>
        </el-tag>
      </template>
    </avue-crud>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { PAGE } from '@/const/global.js'
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchMenuPosition: 'center',
  searchIndex: 3,
  searchShowBtn: false,
  border: false,
  showSummary: false,
  printBtn: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  searchIndex: 3,
  searchIcon: false,
  searchShowBtn: false,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
  menu: false,
  searchShowBtn: false,
  column: [
    {
      label: '订单id',
      prop: 'orderId',
      align: 'left',
    },
    {
      label: '结算金额',
      prop: 'settledAmount',
      align: 'left',
    },
    {
      label: '状态',
      prop: 'procStatusDesc',
      align: 'left',
    },
    {
      label: '还款日期',
      prop: 'repayDate',
      align: 'left',
    },
  ],
}
export default defineComponent({
  name: 'clientDetail',
  components: {},
  data() {
    return {
      searchItmes: {},
      option: tableOption,
      page: PAGE,
      residueAmountType: true,
      isvisible: false,
      form: {},
      list: [{}],
      listLoading: false,
      orderNumberForm: {
        orderCount: 0,
        settledOrderCount: 0,
      },
    }
  },
  created() {
    this.getDetails()
    this.getStoreOrderRefStatistics()
  },
  mounted() {},

  methods: {
    getDetails() {
      this.doPost('bium-portal/controller/fin-ops/clearing/getClearingA1Detail', {
        id: this.$route.query.id,
      }).then((res) => {
        this.form = res.data.result
      })
    },
    getStoreOrderRefStatistics() {
      this.doPost('bium-portal/controller/fin-ops/clearing/getStoreOrderRefStatistics', {
        id: this.$route.query.id,
      }).then((res) => {
        this.orderNumberForm = res.data.result
      })
    },
    getList(page, params) {
      const api = 'bium-portal/controller/fin-ops/clearing/getStoreOrderRefPage'
      const { currentPage, pageSize } = page
      params = params
        ? params
        : {
            ...this.searchItmes,
          }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize
      params.id = this.$route.query.id
      this.listLoading = true
      this.doPost(api, params).then(({ data }) => {
        if (data.result.records) {
          this.list = data.result.records || []
          this.list.forEach((item) => {
            if (item.procStatus == 0) {
              item.procName = '未结算'
            }
            if (item.procStatus == 1) {
              item.procName = '已退货未还款'
            }
            if (item.procStatus == 2) {
              item.procName = '已结算'
            }
            if (item.procStatus == 3) {
              item.procName = '已还款'
            }
            if (item.procStatus == 4) {
              item.procName = '已退货已还款'
            }
          })
          this.page.total = data.result.total || 0
        }
        this.listLoading = false
      })
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },
  },
})
</script>

<style scoped lang="scss">
::v-deep .el-button {
  margin-bottom: 10px;
}

.acc-container {
  height: auto;
  min-height: 60px;

  .acc-tip {
    text-align: center;
  }

  .dis-act {
    color: #909399;
    background-color: #eee;
    border-color: #eee;
    cursor: not-allowed;
    margin: 0;

    i {
      font-style: normal;
      color: #f00;
    }
  }

  /deep/.el-loading-mask {
    top: -8px;
  }
}

/deep/ .inner-box {
  margin: 20px;
  width: auto;
}

/deep/ .el-button--small .iconfont {
  font-size: 12px !important;
}

/deep/ .account-list .account-list-item .tag {
  left: 0;
  right: auto;
  border-top-right-radius: 20px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
  font-weight: normal;
  font-size: 12px;
}

/deep/ .account-list .account-list-item {
  /*min-width: 120px;*/
  height: 75px;
  text-align: center;
  padding-top: 32px;
}

/deep/ .svg-waves {
  display: none;
}

/deep/ .account-list .account-list-item.actived {
  background-image: radial-gradient(circle at center, #4a98f9, #5bacfa);
}

/deep/ .account-list .account-list-item .success {
  background-color: #66cc66;
}

/deep/.account-list .account-list-item .warn {
  background-color: #ffcc33;
}
</style>
