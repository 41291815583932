var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "el-descriptions",
        { staticStyle: { margin: "10px" }, attrs: { border: "" } },
        [
          _c("el-descriptions-item", { attrs: { label: "还款流水号" } }, [
            _vm._v(_vm._s(_vm.$route.query.repayNo)),
          ]),
          _c("el-descriptions-item", { attrs: { label: "还款总金额" } }, [
            _vm._v(_vm._s(_vm.$route.query.repayAmount) + "元"),
          ]),
        ],
        1
      ),
      _c(
        "basic-container",
        { staticClass: "full-condition" },
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.option,
                page: _vm.page,
                "table-loading": _vm.listLoading,
                data: _vm.dataList,
                "cell-class-name": _vm.handleCellClassName,
              },
              on: {
                "update:page": function ($event) {
                  _vm.page = $event
                },
                "on-load": _vm.getList,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange,
                "search-change": _vm.handleFilter,
                "search-reset": _vm.resetFilter,
                "refresh-change": _vm.handleRefreshChange,
              },
            },
            [
              _c("template", { slot: "menuRight" }),
              _c("template", { slot: "menuLeft" }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }