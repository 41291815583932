var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selection-box" }, [
    _c(
      "section",
      { staticClass: "acc-container" },
      [
        _vm._l(_vm.pageDetail, function (item) {
          return [
            _c(
              "div",
              { key: item.id },
              [
                _c(
                  "el-divider",
                  {
                    staticClass: "title-divider",
                    attrs: { "content-position": "left" },
                  },
                  [_vm._v(_vm._s(item.title))]
                ),
                _c("div", { staticClass: "jg-acc" }, [
                  _c(
                    "div",
                    { staticClass: "card-box" },
                    _vm._l(item.data, function (index) {
                      return _c(
                        "div",
                        { key: index.value, staticClass: "card-item" },
                        [
                          _c("div", { staticClass: "item_label" }, [
                            _vm._v(_vm._s(index.label) + "："),
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.detailData[item.key][index.value])
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }