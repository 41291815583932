var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        { staticClass: "full-condition" },
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.option,
                page: _vm.page,
                "table-loading": _vm.listLoading,
                data: _vm.dataList,
                "cell-class-name": _vm.handleCellClassName,
              },
              on: {
                "update:page": function ($event) {
                  _vm.page = $event
                },
                "on-load": _vm.getList,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange,
                "search-change": _vm.handleFilter,
                "search-reset": _vm.resetFilter,
                "refresh-change": _vm.handleRefreshChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "menu",
                  fn: function (scope) {
                    return [
                      _c("el-button", { attrs: { type: "text" } }, [
                        _vm._v("查看详情"),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "menuRight" }),
              _c("template", { slot: "menuLeft" }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }