var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.Loading,
          expression: "Loading",
        },
      ],
      staticClass: "selection-box",
    },
    [
      _c("section", { staticClass: "acc-container" }, [
        _c("ul", { staticClass: "card-infos" }, [
          _c("li", { staticClass: "word-break" }, [
            _c("label", [_vm._v("渠道名称：")]),
            _vm._v(_vm._s(_vm.dataDetails.name) + "\n            "),
          ]),
          _c("li", { staticClass: "word-break" }, [
            _c("label", [_vm._v("渠道注册时间：")]),
            _vm._v(_vm._s(_vm.dataDetails.createdDesc) + "\n            "),
          ]),
          _c("li", { staticClass: "word-break" }, [
            _c("label", [_vm._v("渠道等级：")]),
            _vm._v(_vm._s(_vm.dataDetails.levelDesc) + "\n            "),
          ]),
        ]),
      ]),
      _c("section", { staticClass: "acc-container" }, [
        _vm.dataDetails.enterprise != null
          ? _c(
              "div",
              [
                _c(
                  "el-divider",
                  {
                    staticClass: "title-divider",
                    attrs: { "content-position": "left" },
                  },
                  [_vm._v("企业营业信息\n            ")]
                ),
                _c("div", { staticClass: "jg-acc" }, [
                  _c(
                    "div",
                    { staticClass: "card-box" },
                    _vm._l(_vm.companyInfo, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "card-item" },
                        [
                          _c("div", { staticClass: "item_label" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.name) +
                                "：\n                        "
                            ),
                          ]),
                          _c("div", [
                            item.name == "营业执照有效期"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dataDetails.enterprise
                                        .establishmentDate
                                    ) +
                                      "至" +
                                      _vm._s(
                                        _vm.dataDetails.enterprise.validDate
                                      )
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dataDetails.enterprise[item.value]
                                    )
                                  ),
                                ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "el-divider",
                  {
                    staticClass: "title-divider",
                    attrs: { "content-position": "left" },
                  },
                  [_vm._v("企业法人信息\n            ")]
                ),
                _c("div", { staticClass: "jg-acc" }, [
                  _c(
                    "div",
                    { staticClass: "card-box" },
                    _vm._l(_vm.personInformation, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "card-item" },
                        [
                          _c("div", { staticClass: "item_label" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.name) +
                                "：\n                        "
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.dataDetails.enterprise.legal[item.value]
                                ) +
                                "\n                        "
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm.dataDetails.enterprise.authorization != null
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-divider",
                          {
                            staticClass: "title-divider",
                            attrs: { "content-position": "left" },
                          },
                          [_vm._v("授权人信息\n                ")]
                        ),
                        _c("div", { staticClass: "jg-acc" }, [
                          _c(
                            "div",
                            { staticClass: "card-box" },
                            _vm._l(
                              _vm.AuthorizedInformation,
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "card-item" },
                                  [
                                    _c("div", { staticClass: "item_label" }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(item.name) +
                                          "：\n                            "
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.dataDetails.enterprise
                                              .authorization[item.value]
                                          ) +
                                          "\n                            "
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.dataDetails.accountList != null
          ? _c(
              "div",
              _vm._l(_vm.dataDetails.accountList, function (item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "el-divider",
                      {
                        staticClass: "title-divider",
                        attrs: { "content-position": "left" },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.paymentChannelDesc) +
                            "账户信息\n                "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "jg-acc" }, [
                      _c(
                        "div",
                        { staticClass: "card-box" },
                        _vm._l(_vm.accountList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "card-item" },
                            [
                              _c("div", { staticClass: "item_label" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.name) +
                                    "：\n                            "
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.dataDetails.accountList[0][item.value]
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm.dataDetails.enterprise.id != null
          ? _c(
              "div",
              [
                _c("imgdialog", {
                  attrs: {
                    code: _vm.dataDetails.enterprise.id,
                    fileArr: _vm.dataDetails.attachmentList,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }