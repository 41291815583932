<template>
  <div class="message-query-list">
    <basic-container>
      <avue-crud
        ref="crud"
        :table-loading="isLoading"
        :search.sync="form"
        :page.sync="page"
        :option="option"
        :data="dataList"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange">
        <template #menuLeft>
          <div style="font-weight: bold; font-size: 17px">号码列表</div>
        </template>
        <template slot="operate" slot-scope="scope">
          <el-button type="text" @click="function(){
            drawerOption.item=scope.row
            drawerOption.show=true
          }">查询短信</el-button>
          <el-button type="text" @click="bindMobileClick(scope.row)">绑定新手机号</el-button>
        </template>
      </avue-crud>
    </basic-container>
    <el-dialog :title="dialogOption.title" :visible.sync="dialogOption.show" :width="dialogOption.width">
      <el-form :rules="dialogOption.rules" ref="form2" :model="dialogOption.form" label-width="90px">
        <el-form-item label="绑定手机号" prop="name">
          <!-- <el-input v-model="form.name"></el-input> -->
          <el-autocomplete
            v-model="dialogOption.form.name"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            @select="handleSelect"></el-autocomplete>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="function(){
          dialogOption.show = false
          dialogOption.form.name=''
        }">取 消</el-button>
        <el-button type="primary" @click="bindMobile">确 定</el-button>
      </span>
    </el-dialog>
    <el-drawer
      :size="drawerOption.width"
      :title="drawerOption.title"
      v-if="drawerOption.show"
      :visible.sync="drawerOption.show"
      :direction="drawerOption.direction">
      <meaageMod :data="drawerOption.item"/>
    </el-drawer>
  </div>
</template>

<script>
import { PAGE } from '@/const/global.js'
import meaageMod from './components/meaageMod'
import { doGet, doPost } from '@/router/axios'
import { isMobile } from '@/utils/validate'

export default {
  name: 'phone-message',
  props: {},
  data() {
    return {
      drawerOption: {
        show: false,
        title: '查询短信',
        direction: 'rtl',
        width: '70%',
        item:{},
        option: {
          // 搜索栏配置
          searchMenuSpan: 12,
          searchMenuPosition: 'left',
          // 表格主体配置
          border: false,
          index: false,
          indexLabel: '序号',
          stripe: true,
          headeralign: 'left',
          align: 'left',
          // 表格操作列配置
          menu: false,
          addBtn: false,
          searchShowBtn: false,
          column: [
            {
              label: '接受手机号',
              prop: 'callTime',
            },
            {
              label: '接收短信',
              prop: 'smsContent',
            },
            {
              label: '接收时间',
              prop: 'smsContent',
            },
            {
              label: '短信内容',
              prop: 'sendMobile',
            },
            {
              label: '发送人',
              prop: 'createTime',
              align: 'enterpriseName',
            },
          ],
        },
      },
      dialogOption: {
        show: false,
        title: '绑定手机号',
        width: '500px',
        form: {
          name: '',
        },
        rules: {
          name: [{ required: true, trigger: 'select', message: '请输入手机号码' }],
        },
      },
      search: {
        associate_course_lesson_ids: null,
      },
      listLoading: false,
      form: {},
      page: PAGE, 
      page2: PAGE,
      searchItmes: {},
      isLoading: true,
      dataList: [],
      option: {
        // 搜索栏配置
        searchMenuSpan: 12,
        searchMenuPosition: 'left',
        // 表格主体配置
        border: false,
        index: false,
        indexLabel: '序号',
        stripe: true,
        headeralign: 'left',
        align: 'left',
        // 表格操作列配置
        menu: false,
        addBtn: false,
        searchShowBtn: false,
        column: [
          {
            label: '运营手机号',
            prop: 'bindingMobile',
            searchSpan: 4,
            width: 350,
            hide: true,
            search: true,
          },
          {
            label: '所属店铺',
            prop: 'name',
            search: true,
            searchSpan: 4,
            hide: true,
            width: 220,
            dicData: [],
          },
          {
            label: '所属店铺',
            prop: 'name',
          },
          {
            label: '店铺所属平台',
            prop: 'livePlatform',
          },
          {
            label: '手机号码',
            prop: 'bindingMobile',
          },
          {
            label: '号码所有人',
            prop: 'enterpriseName',
          },
          {
            label: '操作',
            prop: 'operate',
            align: 'left',
            width: 220,
          },
        ],
      },
    }
  },
  watch: {},
  components:{meaageMod},
  created() {
    // 获取店铺列表1
    this.getShopLost()
    this.getPhoneList()
  },
  mounted() {},
  methods: {
    handleSelect(item) {
      this.dialogOption.form.name = item.value
    },
    querySearch(queryString, cb) {
      var restaurants = this.mobileList
      const results = queryString
        ? restaurants.filter((item) => {
            return item.mobile.toLowerCase().includes(queryString.toLowerCase())
          })
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    getPhoneList() {
      this.doPost('bium-portal/controller/fin-ops/store/getCloudNameList').then(({ data }) => {
        data.forEach((item) => {
          item.value = item.mobile
        })
        this.mobileList = data
      })
    },
    bindMobileClick(item) {
      this.mobileForm = item
      this.dialogOption.form.name = ''
      this.dialogOption.show = true
    },
    bindMobile() {
      this.$refs.form2.validate((valid) => {
        if (valid) {
          const api = 'bium-portal/controller/fin-ops/store/changeCloud'
          this.doPost(api, {
            storeId: this.mobileForm.id,
            mobile: this.dialogOption.form.name,
          }).then(({ data }) => {
            if (data.success) {
              this.dialogOption.form.name = ''
              this.handleRefreshChange()
              this.dialogOption.show = false
            }
          })
        } 
      })
    },
    /** 获取列表数据 */
    getList(page, params) {
      this.isLoading = true
      const api = 'bium-portal/controller/fin-ops/store/pageStoreCloud'
      params = params
        ? {
            name: params ? this.form.name : '',
            bindingMobile: params ? this.form.bindingMobile : '',
          }
        : {
            name: this.form.name?this.form.name:'',
            bindingMobile: this.form.bindingMobile?this.form.bindingMobile:'',
          }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize

      // 调取接口
      this.doPost(api, params).then(({ data }) => {
        if (data.result.records) {
          this.dataList = data.result.records || []
          this.page.total = data.result.total || 0
        }
        this.isLoading = false
      })
    },
    getShopLost() {
      const api = 'bium-portal/controller/fin-ops/store/list_store_config'
      this.doGet(api).then(({ data }) => {
        if (data.result) {
          this.option.column[0].dicData = data.result || []
        }
      })
    },
    /** 分页变化，存储 */
    sizeChange(pageSize) {
      this.page.pageSize = pageSize
    },
    /** 当前页码变化，存储 */
    currentChange(current) {
      this.page.currentPage = current
    },
    /** 点击搜索栏搜索 */
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, {
        name:param.name?param.name:'',
        bindingMobile: param.bindingMobile?param.bindingMobile:'',
      })
      done()
    },
    /** 重置条件搜索 */
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, {
            name: '',
            bindingMobile: '',
          })
    },
    /** 手动刷新列表 */
    handleRefreshChange() {
      this.getList(this.page, {
            name: '',
            bindingMobile: '',
          })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-drawer__header {
  font-size: 17px;
  font-weight: bold;
}
::v-deep .el-drawer__body {
  padding: 20px;
}
::v-deep .el-autocomplete{
  width: 100%;
}
</style>
