<template>
  <div>
    <el-form
      ref="EForm"
      :label-position="'right'"
      :model="cForm"
      :rules="cRules"
      class="pl50 pr50"
      label-width="130px"
      size="small"
    >
      <div class="mt10 inner-box">
        <el-form-item label="客户资金方" prop="channelId">
          <el-select v-model="cForm.channelId" placeholder="请选择" style="width: 300px;">
            <el-option v-for="(item, index) in fundingPartner" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="首笔提款回溯天数：" prop="firstTraceDays">-->
        <!--          <el-input v-model="cForm.firstTraceDays" placeholder="请输入" style="width: 240px;">-->
        <!--            <template slot="prepend">T -</template>-->
        <!--            <template slot="append">天</template>-->
        <!--          </el-input>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="多笔提款回溯天数：" prop="multipleTraceDays">-->
        <!--          <el-input v-model="cForm.multipleTraceDays" placeholder="请输入" style="width: 240px;">-->
        <!--            <template slot="prepend">T -</template>-->
        <!--            <template slot="append">天</template>-->
        <!--          </el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="对客费率方案：" prop="">
          <span v-if="!isRate" class="cursor" @click="getRate">
            <span style="color: red;"> 未配置 </span>
            <el-icon class="el-icon-refresh" />
          </span>
          <el-button class="ml15" type="primary" @click="toConfigStandardPlan">
            查看
          </el-button>
        </el-form-item>
      </div>

    </el-form>
    <!-- <div class="tc" slot="footer">
      <el-button type="primary" size="medium" @click="submit">提交信息</el-button>
    </div> -->
  </div>
</template>
<script>
import { defineComponent } from 'vue';

// Component.registerHooks(['beforeRouteLeave']) //ts用法

export default defineComponent({
  name: 'CaseRecord',
  components: {},
  props: {
    rowForm: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      cForm: {
        channelId: '',
        firstTraceDays: '',
        multipleTraceDays: ''
      },
      isRate: false,
      fundingPartner: [],
      cRules: {
        channelId: [{ required: true, trigger: 'blur', message: '请选择客户资金方' }],
        // firstTraceDays: [{ required: true, trigger: 'blur', message: '请输入首笔提款回溯天数' }],
        multipleTraceDays: [{ required: true, trigger: 'blur', message: '请输入多笔提款回溯天数' }]
      }
    };
  },
  created() {
    this.getData();
    this.getAll();
    this.getRate();
  },
  methods: {

    toConfigStandardPlan() {
      const dataItem = this.rowForm;
      const comp = () => import('../../expense/components-plan/plan-edit.vue');
      this.$modalDialog(comp, { dataItem });
    },
    getData() {
      this.doPost('bium-portal/controller/fin-ops/ratePlan/getRatePlan', {
        storeId: this.rowForm.storeId
      }).then(({ data }) => {
        this.cForm = data.result;
      });
    },
    getAll() {
      this.doPost('bium-portal/controller/fin-ops/fundingPartner/all', {
        companyId: this.rowForm.enterpriseId
      }).then(({ data }) => {
        this.fundingPartner = data.result;
      });
    },
    getRate() {
      this.doPost('/bium-portal/controller/fin-ops/ratePlan/isCustRateDetailConfig', {
        storeId: this.rowForm.storeId
      }).then(({ data }) => {
        this.isRate = data.result;
      });
    },
    submit() {
      this.$refs['cForm'].validate((valid) => {
        if (valid) {
          this.doPost('bium-portal/controller/fin-ops/ratePlan/bind', {
            companyId: this.rowForm.id,
            channelId: this.cForm.channelId
          }).then(({ data }) => {
            if (data && data.result) {
              this.$parent.handleClose();
            }
          });
        }
      });
    }
  }
});
</script>

<style lang="scss" scoped></style>
