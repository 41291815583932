var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "cForm",
          staticStyle: { "padding-right": "20px" },
          attrs: {
            model: _vm.cForm,
            rules: _vm.vRules,
            "label-position": "right",
            "label-width": "120px",
            size: "small",
          },
        },
        [
          _c("el-alert", {
            staticClass: "mt10",
            attrs: {
              title: "您仅能添加您的次一级渠道",
              closable: false,
              type: "info",
            },
          }),
          _c(
            "div",
            { staticClass: "mt10 inner-box" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "渠道名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入渠道名称" },
                    model: {
                      value: _vm.cForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "name", $$v)
                      },
                      expression: "cForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "登录手机号码", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入登录手机号码" },
                    model: {
                      value: _vm.cForm.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "mobile", $$v)
                      },
                      expression: "cForm.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "verif-code",
                  attrs: { label: "手机验证码", prop: "smsCode" },
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { maxlength: "6", placeholder: "请输入验证码" },
                      model: {
                        value: _vm.cForm.smsCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "smsCode", $$v)
                        },
                        expression: "cForm.smsCode",
                      },
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isSentCode,
                                expression: "isSentCode",
                              },
                            ],
                            staticClass: "append-text",
                          },
                          [
                            _c("i", [_vm._v(_vm._s(_vm.sendTimerCount) + "s")]),
                            _vm._v(
                              "\n                            后重新发送\n                        "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isSentCode,
                                expression: "!isSentCode",
                              },
                            ],
                            staticClass: "append-text send-btn",
                            on: { click: _vm.toSendCode },
                          },
                          [_vm._v("发送验证码")]
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "登录密码", prop: "password" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "qc-log-input-text",
                      attrs: {
                        type: _vm.passwordType,
                        "auto-complete": "off",
                        maxlength: "20",
                        placeholder: "请输入登录密码",
                      },
                      model: {
                        value: _vm.cForm.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "password", $$v)
                        },
                        expression: "cForm.password",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-view el-input__icon",
                        attrs: { slot: "suffix" },
                        on: { click: _vm.showPassword },
                        slot: "suffix",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "登录密码", prop: "confirmPassword" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "qc-log-input-text",
                      attrs: {
                        type: _vm.passwordType,
                        "auto-complete": "off",
                        maxlength: "20",
                        placeholder: "请再次输入密码",
                      },
                      model: {
                        value: _vm.cForm.confirmPassword,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "confirmPassword", $$v)
                        },
                        expression: "cForm.confirmPassword",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-view el-input__icon",
                        attrs: { slot: "suffix" },
                        on: { click: _vm.showPassword },
                        slot: "suffix",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "渠道等级" } }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.cForm.level == 1 ? "二级" : "三级") +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tc" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "medium",
                    loading: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.saveCard()
                    },
                  },
                },
                [_vm._v("确认新增渠道")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            visible: _vm.outerVisible,
            "append-to-body": "",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.outerVisible = $event
            },
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "dialogTitle" }, [_vm._v("恭喜您！")]),
            _c("div", { staticClass: "dialogContent" }, [
              _vm._v(
                "\n                您的次级渠道已开通，请通知次级渠道自行登录账户\n            "
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.outerVisibleChange } }, [
                _vm._v("好的"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }