<template>
  <div class="user">
    <div class="statisticsWrap">
      <el-divider content-position="left" class="title-divider">数据统计
      </el-divider>
      <div class="dataWrap">
        <div class="dataList" v-for="(u, i) in dataList" :key="i">
          <span>{{ u.value }}</span>
          <span>{{ u.name }}</span>
        </div>
      </div>
    </div>
    <basic-container class="full-condition">
      <avue-crud ref="crud" :option="option" :page.sync="page" :table-loading="listLoading" :data="list"
        @on-load="getList" @size-change="sizeChange" @current-change="currentChange" @search-change="handleFilter"
        @search-reset="resetFilter" @refresh-change="handleRefreshChange">
        <template slot="statusDesc" slot-scope="scope">
          <el-tag effect="dark"
            :type="scope.row.statusDesc == '已还款' ? 'success' : scope.row.statusDesc == '已逾期' ? 'danger' : ''">
            <span>{{ scope.row.statusDesc }}</span>
          </el-tag>
        </template>
        <template slot="menu" slot-scope="scope">
          <el-button type="text" icon="el-icon-view" @click="handleDetails(scope.row, scope.index)">详情</el-button>
        </template>
      </avue-crud>
    </basic-container>

    <!--详情-->
    <el-dialog title="融资订单" :visible.sync="dialogVisible" width="60%" custom-class="pub_dialog">
      <page-details v-if="fileId" :modal-data="fileId" />
    </el-dialog>
  </div>
</template>

<script>
// done 运营管理-融资订单管理-添加计息方式字段
import { PAGE } from '@/const/global.js'
// import pageDetails from "./process/details";
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchMenuPosition: 'center',
  searchIndex: 3,
  searchShowBtn: false,
  border: false,
  showSummary: false,
  printBtn: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 120,
  searchIndex: 3,
  searchIcon: false,
  searchShowBtn: false,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
  column: [
    {
      label: '产品类型',
      prop: 'productName',
      align: 'left',
      width: 150,
      span: 24,
      type: 'select',
      search: true,
      dicData: [
        {
          label: '1A-极速收款',
          value: 0,
        },
        {
          label: '2A-极速付款',
          value: 1,
        },
      ],
    },
    {
      label: '所属资金方',
      prop: 'fundingName',
      search: true,
      align: 'left',
      searchSpan: 8,
      width: 250,
      type: 'select',
      dicData:[]
    },
    {
      label: '企业名称',
      prop: 'enterpriseName',
      search: true,
      align: 'left',
      searchSpan: 8,
      width: 250,
    },
    {
      label: '店铺名称',
      prop: 'storeName',
      search: true,
      align: 'left',
      width: 230,
    },
    {
      label: '融资单号',
      prop: 'applyNo',
      width: 240,
    },
    {
      label: '计息方式',
      prop: 'billingMethod',
    },
    {
      label: '费率',
      prop: 'customerRate',
    },
    {
      label: '融资金额',
      prop: 'financingAmount',
    },
    {
      label: '应收利息',
      prop: 'pendingInterest',
    },
    {
      label: '应收服务费',
      prop: 'pendingServiceFee',
      width: 120,
    },
    {
      label: '待还本金',
      prop: 'pendingPrincipal',
      width: 120,
    },
    {
      label: '放款日期',
      prop: 'paymentDate',
    },
    {
      label: '到期日',
      prop: 'expiredDate',
      width: 130,
    },
    {
      label: '状态',
      prop: 'statusDesc',
    },
    {
      label: '放款日期',
      prop: 'paymentDate',
      type: 'date',
      hide: true,
      format: 'yyyy-MM-dd',
      searchRange: true,
      valueFormat: 'yyyy-MM-dd',
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      width: 130,
      searchValue: new Date().toISOString().substr(0, 10),
      span: 12,
    },
  ],
}

export default {
  name: 'TableUser',
  // components: { pageDetails },
  data() {
    return {
      dataList: [{
        value: '0.00',
        name: '订单放款金额（元）'
      },
      {
        value: '1000.00',
        name: '在贷余额（元）'
      },
      {
        value: '1100.00',
        name: '剩余应收利息（元）'
      },
      {
        value: '110000.00',
        name: '剩余应收服务费（元）'
      }],
      searchItmes: {},
      option: tableOption,
      page: PAGE,
      list: [],
      listLoading: true,
      dialogVisible: false,
      fileId: '',
    }
  },
  computed: {},
  watch: {},
  created() { this.getfunBoxList()},
  methods: {
    getfunBoxList(params){
      const api = 'bium-portal/controller/fin-ops/fundingPartner/funBoxList'
      this.doPost(api).then(({ data }) => {
        let funBoxList = []
        if(data.result.length){
          data.result.forEach(item=>{
             funBoxList.push({
                label:item.name,
                value: item.id,
             })
          })
        }
        this.option.column[1].dicData = funBoxList
      })
    },
    getStatistics(params){
      const api = 'bium-portal/controller/fin-ops/clearing/page/stat'
      this.doPost(api, params).then(({ data }) => {
        this.dataList[0].value = data.result.loanAmount
        this.dataList[1].value = data.result.inLoanAmount
        this.dataList[2].value = data.result.residueInterest
        this.dataList[3].value = data.result.residueServiceAmount
      })
    },
    getList(page, params) {
      const api = 'bium-portal/controller/fin-ops/clearing/page'
      const { currentPage, pageSize } = page
      params = params
        ? params
        : {
          ...this.searchItmes,
        }
      if (params.productName == 1) {
        params.productType = 1
      }
      if (params.productName == 0) {
        params.productType = 0
      }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize
      if (params.paymentDate) {
        let arr = params.paymentDate.split(',')
        if (arr.length > 1) {
          params.paymentDateStart = arr[0]
          params.paymentDateEnd = arr[1]
        }
      }
      params.fundingName ? params.fundingId = params.fundingName : params.fundingId = null
      this.listLoading = true
      this.getStatistics(params)
      this.doPost(api, params).then(({ data }) => {
        if (data.result.records) {
          this.list = data.result.records || []
          this.page.total = data.result.total || 0
          this.list.forEach((item) => {
            item.productType == 1 ? (item.productName = '2A-极速付款') : (item.productName = '1A-极速收款')
          })
        }
        this.listLoading = false
      })
    },

    sizeChange(pageSize) {
      this.page.pageSize = pageSize
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },

    handleDetails(row, index) {
      if (row.productType == 0) {
        this.$router.push({
          path: `/operate/financingDetails?id=${row.id}`,
        })
      } else {
        this.$router.push({
          path: `/operate/financingDetails2A?id=${row.id}`,
        })
      }
    },
  },
}
</script>

<style lang="scss">
.statisticsWrap {
  background-color: white;
  padding: 20px 20px 1px 20px;
  margin: 10px 10px;
  border-radius: 5px;

  .dataWrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .dataList {
      width: 25%;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-right: 1px solid #f5f5f5;
    }

    .dataList span:nth-child(1) {
      font-size: 22px;
      font-weight: 600;
      padding-bottom: 8px;
    }

    .dataList span:nth-child(2) {
      font-size: 13px;
      font-size: 800;
    }
  }
}
</style>
