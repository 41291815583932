var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "document_containter" }, [
      _c("h1", { staticClass: "tc" }, [_vm._v("平台用户注册协议")]),
      _c("div", { staticClass: "f14 fb tc" }, [_vm._v("声明")]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "请贵司仔细阅读并充分理解本协议各条款, 特别是其中所涉及的免除及限制平台责任的条款、对平台用户权利进行限制的条款等。请贵司审慎阅读并选择接受或不接受本协议。如贵司对协议有任何疑问, 应向平台客服人员进行咨询。除非贵司接受本协议所有条款, 否则贵司无权使用平台于本协议项下所提供的服务。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "当贵司按照页面提示填写信息、阅读本协议并根据平台规则完成本协议的签署或贵司以其他平台允许的方式实际使用平台服务时, 即视为贵司已完全同意并接受本协议, 并愿受其约束, 本协议即成立并生效。贵司承诺接受并遵守本协议的约定, 届时贵司不应以未阅读本协议的内容或者未获得平台对贵司问询的解答等理由, 主张本协议无效, 或要求撤销本协议。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "除本协议条款外, 贵司同意遵守平台的其他各项规则及其说明和/或其所不时发布的平台规则。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "为规范双方业务行为，明确双方权利义务，甲方（即和信（天津）国际商业保理有限公司 2021，系作为“必米供应链金融”平台运营方）与乙方（即“必米供应链金融”平台用户），本着平等互利的原则，就“必米供应链金融”平台服务相关事宜达成本协议："
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第一章 术语及释义")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("第一条 如无特别说明，下列用语在本协议中的含义为："),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "1、必米供应链平台/平台：指由甲方运营的“必米供应链金融”平台（必米供应链平台包括网址【www.biumdigits.com】、已经开发或将来不时开发的实现平台网站部分或全部功能的互联网应用程序（即APP，如有）、微信公众号、微信小程序等）。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "2、平台服务：指甲方通过本平台为用户提供的账户管理、统计报表等服务，具体以平台展示的为准。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "3、平台用户：符合条件的申请人在必米供应链平台网站通过必要的注册程序后，即成为平台用户（即本协议之乙方）。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "4、有权代表：系指经乙方有效授权对乙方企业账户进行操作、管理的自然人。为避免异议，乙方未根据本协议第十七条约定向甲方发送通知的情况下，任何成功登录乙方企业账户的主体均视为乙方的有权代表。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "5、本协议：系指本《必米供应链平台用户注册协议》，以及对其不时的修改或补充。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "8、平台规则：系指甲方为平台制定的平台使用规则，以及对其不时的修改或补充。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "9、不可抗力：系指在本协议签署后发生的、本协议签署时不能预见的、其发生与后果无法避免或克服的、妨碍任何一方全部或部分履约的所有事件。上述事件包括地震、台风、水灾、火灾、战争、国际或国内运输中断、政府或公共机构的行为（包括重大法律变更或政策调整）、流行病、民乱、罢工，以及一般国际商业惯例认作不可抗力的其他事件。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "10、法律：系指中国有权机关颁布的法律、法规、规章或其他任何规范性文件。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "11、中国：系指中华人民共和国（为且仅为本协议之目的，不包括香港特别行政区、澳门特别行政区和台湾地区）。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第二章 协议的接受及确认")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第二条 用户确认：在注册或使用平台服务之前，已仔细阅读了本协议，并特别注意了字体加黑/加黑及下划线的条款，对本协议条款的含义及相应的法律后果已全部知晓并充分理解，愿意遵守其全部内容；甲方已应要求对相关条款进行了充分的提示和说明。用户知悉认可并同意，乙方在签署本协议并使用平台服务之前，甲方有权对乙方进行审核，乙方有义务向甲方提供审核所需文件和资料，乙方仅在通过甲方审核经甲方确认为符合所需条件的主体方能签署本协议。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第三条乙方确认同意（包括但不限于勾选注册页面下方的“阅读并同意”选项、点击“确认注册”按钮等方式）本协议后，其即注册成为平台用户，本协议即在甲方和乙方之间生效。乙方承诺接受并遵守本协议的约定以及本协议电子合同的效力。如果乙方不同意本协议的约定，应立即停止注册程序并停止使用平台服务。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第四条 本协议内容包括协议正文及所有甲方已经发布的或将来可能发布（或修改）的有关平台的各类规则（以下简称“规则”）。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。除甲方另行明确声明外，任何与甲方平台有关的一切服务、交易及非交易活动均受本协议约束，不论该活动是在甲方或者第三方平台页面上操作完成。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "上述规则包括但不限于甲方通过以下方式发布或告知用户的规则：通过平台或其他渠道发布公告、通知、声明；网页上出现的关于操作的提示说明；发送给用户的信息（通过向用户预留手机或预留电子邮箱发送信息或通过电话语音形式发送）等。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第五条 甲方有权依据国家有关规定及业务需要对本协议及各类规则等内容进行调整，涉及用户权利义务变更的，将正式对外公告一定时期后执行并适用于本协议；如果用户未在公告期申请变更或终止相关服务，或是继续使用服务的，视为用户同意相关调整，变更后的内容对用户产生法律约束力，若用户不执行变更后的内容，甲方有权选择终止本项服务。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第六条 与平台服务有关的乙方与甲方或服务第三方发生的争议，甲方有权依据争议任何一方的要求向相关单位提交系统记录作为证据。任何一方均确认同意该数据电文及电子合同的真实及有效性。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第三章 注册账户")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第七条 乙方确认，在完成注册程序或以其他甲方允许的方式实际使用服务时，乙方应当是具备完全民事权利能力和与所从事的民事行为相适应的民事行为能力的自然人、法人或其他组织。若乙方不具备前述主体资格及甲方申请人条件的，请勿使用服务，否则应承担因此而导致的一切后果，且甲方有权终止服务，注销乙方的账户。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第八条 乙方确认，乙方有权代表（含乙方平台账户管理员）在企业账户项下的任何行为活动，均视为已得到乙方合法授权，代表乙方的真实意思表示，并由乙方承担全部法律后果。乙方承诺，不会以乙方未授权或意思表示瑕疵等任何理由提出抗辩，而拒绝承担乙方企业账户项下任何活动所产生的法律责任及义务。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第九条 乙方有义务在注册、用户升级、服务申请以及信息发生变更时，提供、更新或补充完整、真实、准确、合法、合规、有效的信息、资料（或核对确认系统已录入信息、资料），保证该信息及资料内容的持续完整性、真实性、有效性、合法性及合规性且不存在虚假、遗漏或误导信息，并同意甲方通过必要方式对其身份和资质以及信用情况进行核实。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第十条 因乙方的信息或资料不完整、不真实、不准确、失效或违法违规而导致甲方或第三方无法提供服务或提供服务时发生任何错误，乙方不得将此作为取消交易、终止协议的理由，由此产生的全部法律后果由乙方承担，因此给甲方或其他第三方造成损失的，乙方应予以赔偿。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第十一条 甲方如有合理理由怀疑乙方提供的资料错误、不实、过时或不完整的，甲方有权向乙方发出询问及/或要求改正的通知，并有权要求乙方补充提供资料或直接做出拒绝乙方企业认证请求、服务申请请求或删除相应资料的处理，直至中止、终止对乙方提供部分或全部平台服务，甲方对此不承担任何责任。如因乙方提供的资料错误、不实、过时或不完整等原因导致甲方损失的，甲方保留追究乙方违约责任的权利。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第十二条 乙方对账户设置用户名的，不得侵犯或涉嫌侵犯他人合法权益（包括但不限于企业名称权、商标权、姓名权、肖像权等）。如乙方设置的用户名侵犯/涉嫌侵犯第三方合法权益，甲方有权终止向乙方提供服务，并注销账户。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第四章 账户安全")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第十三条 乙方通过平台注册成为企业用户以后，有权使用登录名称及密码登录平台。乙方应妥善保管登录名称、密码（包括账户设置的密码、预留手机号、电子邮箱接收到的邀请码、验证码等，统称密码），避免以不安全的方式或在不安全的环境下使用密码，切勿将密码以任何形式透露给任何他人，或以易被他人发现、获得、盗用的方式保管。因黑客行为或会员保管疏忽导致登录名称及密码被他人非法使用的，平台不承担任何责任。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第十四条 乙方应对使用乙方账户登录名称以及密码而从事的一切交易或非交易行为活动承担法律责任，包括但不限于：①在平台开通企业账户并设置密码；②登录企业账户完成身份及操作验证；③上传（或确认）乙方主体信息及相关业务信息；④发起业务申请；⑤点击确认（或签署）电子合同；⑥查看业务状态、签约进度及签约文本等。除本协议另有规定或甲方另行同意外，乙方向平台发出的任何指令均不可撤销。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第十五条 乙方应直接通过平台所在网站登录，而不应通过未经甲方认可的邮件或其他网站提供的链接登录。因黑客行为或会员疏忽导致乙方通过伪造或不可靠的链接登录他方网站的，平台不承担任何责任，乙方应当自行承担相关法律后果和责任。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第十六条 乙方不得以任何形式转让、出租或授权他人使用其注册信息和账户（包括但不限于登录名称及密码），违反该规定而引起的一切后果均由乙方自行承担责任，因此给甲方或其他第三方造成损失的，乙方应予以赔偿，且甲方有权停止为乙方提供服务。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第十七条 乙方的登录名、密码遭到未经授权的使用或发生其他任何安全问题时，乙方应立即通知甲方。甲方收到用户的有效请求并核实身份后，在合理期限内有权根据不同情况采取相应措施。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第十八条 为保障乙方账户的安全，乙方应配合平台完成身份的验证及绑定，包括但不限于提交主体信息及身份资料、输入正确的密码、进行乙方管理员（或法定代表人）的人脸识别操作等。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第十九条 乙方认可本平台对其企业账户身份及操作验证结果，只要验证结果为“通过”即视为乙方有权代表本人操作。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第二十条 乙方决定不再使用本平台服务的，应当向甲方申请注销其企业账户。经甲方审核同意并由乙方完全履行其届时应当履行的全部义务（如有）后可正式注销其企业账户。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第五章 服务及收费")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第二十一条 乙方可根据自身需要选择甲方提供的服务，乙方选择一经做出，即对其有约束力。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第二十二条 甲方服务的具体类型和功能以甲方实际提供为准，甲方有权根据业务需要随时调整。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第二十三条 乙方因交易或使用平台服务而发生的所有应纳税赋以及费用全部由乙方自行承担。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第二十四条 甲方有权向乙方收取相关服务费用。甲方拥有制订及调整服务费用的权利，具体服务费用标准及收取规则将以甲方网站公示的方式进行公告，不再单独通知乙方。自公告施行之日公告内容构成对甲方与乙方间协议约定的有效修改和补充。如果乙方不同意接受甲方有关公告的内容，应当立即停止使用该等服务费用相关的甲方服务。乙方继续使用的，即视为乙方接受甲方公告的服务费用标准及收取规则。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第六章 第三方服务")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第二十五条 乙方通过平台办理与甲方或第三方提供的具体金融服务时，需办理相关手续或签署专项协议。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第二十六条 乙方通过平台接受第三方提供的服务时，或乙方通过平台向第三方提供服务时，乙方与第三方的权利义务关系取决于乙方与第三方签署的协议/合同。若因第三方提供的服务或乙方向第三方提供的服务，乙方与第三方发生纠纷的，乙方与第三方自行解决，与甲方无关。若第三方服务过程中与平台服务有关的，乙方及第三方仍应受本协议约束。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第二十七条 尽管甲方对第三方进行了严格的甄选，但不能控制或保证第三方全部信息的真实性、合法性、准确性，亦不能控制或保证第三方所涉及产品或服务的质量、客户服务能力及持续性，以及第三方履行在相应服务协议项下的各项义务的能力。乙方应自行谨慎判断确定相关产品/服务或信息的真实性、合法性、完整性、合规性和有效性，并自行承担因此产生的责任与损失。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第七章 甲方权责")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第二十八条 甲方有权在现有条件下提高服务质量，在合理的范围内确保为乙方提供的服务顺利进行。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第二十九条 甲方或平台上第三方服务商（如有）有权各自依据其内部业务规定及商业判断独立决定是否同意乙方所申请事项，并有权在乙方风险异常时随时独立决定中断或终止有关已批准服务事项。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第三十条 甲方有权对乙方的账户资料及交易行为进行查阅，发现账户资料或交易行为违反本协议约定的，均有权向乙方发出询问的通知，并有权根据具体情况要求乙方改正或依据本协议规定采取相应措施。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第三十一条 乙方同意，甲方有权根据业务需要将本协议项下的全部或部分权利义务转让给其关联公司，发生此种情况时甲方仅需提前以网站公告的形式通知乙方即可。如果乙方不同意接受甲方有关公告的内容，应当立即停止使用甲方服务。乙方继续使用的甲方/关联公司服务的，即视为乙方接受甲方公告内容。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第八章 乙方权责")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第三十二条 乙方应对其在平台中的所有行为活动负全部责任。因乙方原因导致甲方或其他第三方任何直接或间接损失的，乙方应承担全部责任，并应赔偿甲方或第三方因此遭受的全部损失。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第三十三条 乙方承诺其在使用平台服务时的所有言行活动遵守国家法律、法规以及本协议的约定、甲方相关规则并符合社会公共利益或公共道德。乙方授权甲方将乙方的主体信息、业务申请信息等提交给平台资金方；乙方同意并授权甲方及平台资金方出于向乙方提供服务之目的向中国人民银行金融信用信息基础数据库查询、获取、保存和报送乙方主体信息和信用信息，以及向其他依法成立并合法留存乙方信息的机构查询、获取乙方相关信息，乙方同时授权依法成立并合法留存乙方信息的机构基于上述目的向甲方及平台资金方提供乙方的相关信息。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第三十四条 乙方及行为人在注册用户和接受平台服务中不得从事如下的行为活动："
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（1）冒用他人名义、盗用、伪造或变造他人资料注册或使用服务；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（2）用户真实身份与提供资料认证身份不符；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（3）虚构事实、隐瞒真相以误导、欺骗甲方或其他人，或以不正当手段通过身份验证；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（4）利用平台从事违法犯罪活动，如贷款诈骗、金融票据诈骗、合同诈骗、非法从事金融/支付结算业务等；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（5）侵害甲方知识产权及商业秘密或侵害第三人的合法权益；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（6）使用与平台正常业务无关的任何软硬件装置，用于干预或试图干预平台的正常运作或正在平台进行的任何活动。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（7）违反本协议约定或法律、行政法规禁止的其他行为。"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第三十五条 因乙方自身的原因导致的任何损失由乙方自行承担，原因包括但不限于：乙方不按照页面提示操作，乙方未及时进行页面操作，乙方发送的指令错误或不完整，账户密码未妥善保管、账户管理员滥用权利、未在安全环境中操作、未及时查阅甲方公告、乙方信息资料有误或未更新。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第三十六条 乙方应经常浏览平台发布的各类规则、通知、公告及声明，以了解平台各类政策、规则、事项的变化。甲方不再单独或个别地通知乙方前述信息。因乙方未及时浏览平台公告信息而导致权利丧失、服务中断或终止等不利后果由乙方承担。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第三十七条 经乙方有权代表或通过乙方企业账户上传或点击确认的管理员身份信息、企业主体信息、相关证件及文件的复印件或扫描件均与原件一致，且真实、准确、合法、合规、有效及完整。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第三十八条 乙方承诺其在本协议签署之日为在中国境内合法有效注册且合法合规经营的法人主体或其他组织，已经具备从事其所有业务的经营资质或许可，且在本协议有效期间和使用平台服务期间将严格遵守与其经营业务所适用的法律、法规，包括取得并完成法律规定所需的证照、备案等，如因乙方经营违法违规或存在瑕疵所产生的任何法律责任和后果应当由乙方自行承担，如因此对甲方造成任何直接或间接损失的，乙方应当进行赔偿。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第三十九条 乙方企业账户的暂停、中止或终止使用不代表乙方在本协议项下的义务和责任的终止，乙方仍应对适用平台服务期间的行为承担可能的违约或损害赔偿责任，同时甲方仍可以保有乙方的相关信息。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第九章 软件系统")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第四十条 为更好地提供平台服务，甲方或合作第三方有权根据市场情况和实际需求向乙方提供有关的软件供其使用，包括但不限于客户端软件等。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第四十一条 甲方授权乙方一项不可转让及非排他性的使用本协议第四十条项下的软件系统。乙方授权使用范围为：为非商业目的在终端设备上下载、安装、使用、登录、显示、运行软件。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第四十二条 甲方未明示授权的其他一切权利仍由甲方保留，乙方以任何形式使用其他权利时须另行取得甲方的书面许可。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第十章 知识产权")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第四十三条 除用户自有的信息资料外，平台服务中有关的系统、网站及软件应用程序中所有内容，包括但不限于著作、图片、资讯、资料、网站架构、网站画面的安排、网页设计及相关的商业运行模式、产品设计方案、技术方案、软件代码、客户信息、业务数据等，均由甲方及有关权利人依法拥有知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。甲方在平台服务中所涉及的图形、文字或其组成，以及其他平台标志及产品、服务名称（以下统称“平台标识”），其著作权或商标权归甲方所有。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第四十四条 尊重知识产权是乙方应尽的义务。非经甲方书面同意，任何人（包括乙方在内）不得擅自使用、修改、反向编译、复制、公开传播、改变、散布、发行或公开发表、或许可他人使用前述两条款有关内容，亦不得将平台标识以任何方式展示或使用或作其他处理，也不得向他人表明乙方或其他人有权展示、使用、或其他有权处理平台标识。如有违反，行为人应向甲方承担损害赔偿责任。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第四十五条 乙方对获悉的有关甲方及其关联公司的商业秘密及非公开信息（涉及客户信息、业务信息、业务系统有关的任何资料及其它各类数据、信息）负有保密义务，未经甲方事先书面同意，不得向第三方披露或许可其使用，法律法规及监管部门另有规定的除外。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第十一章 信息保密")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第四十六条 甲方采取各种安全保障措施依法保护乙方及其管理员的信息安全。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第四十七条 乙方同意甲方在乙方的计算机上设定或取用与平台服务有关信息。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第四十八条 为帮助平台改善服务并更好地向乙方提供服务，乙方同意甲方及其关联公司收集、存储、处理、使用、共享下列信息（包括但不限于文字、图片、视频、音频）："
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（1）平台服务项下的注册信息或用户身份信息，例如：企业基本信息（含法定代表人、管理员信息）、个人真实姓名、身份证号、手机号码、肖像、手机设备识别码、IP地址、企业收款银行账户等；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（2）用户自行向甲方披露、传输或发布的信息；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（3）平台合作的第三方向甲方披露、传输的信息；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（4）用户授权甲方向第三方机构查询、验证的信息；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（5）行为数据，指用户对平台服务的操作状态以及使用习惯等明确且客观反映在服务器端的基本行为信息。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("第四十九条 甲方及其关联公司可能将用户的信息用于下列用途："),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（1）向用户提供服务或履行义务，包括但不限于服务本身的需要或用于服务相关的身份验证、客户服务、安全防范、侵权监测、存档和备份用途等；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（2）帮助改善现有服务或设计新服务，包括但不限于让用户参与有关服务的调查、评估服务中的广告和其他促销及推广活动的效果等；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（3）使平台更加了解用户如何接入和使用服务，针对性地回应用户个性化需求；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（4）可能用于甲方其他的服务，例如可能将用户在使用某一项服务时所收集的信息，在其他服务中向用户提供特定内容，或向用户展示与乙方相关的信息；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（5）向用户提供更加相关的广告或其他商业资讯；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（6）软件认证或管理软件升级；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（7）其他与平台服务相关的事宜。"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第五十条 甲方及其关联公司未经乙方同意不向任何第三方公开、透露用户信息，并在以下情形时才可向有关第三方披露乙方信息："
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（1）依据本协议或其他相关协议、规则等规定可以提供的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（2）依据法律法规的规定可以提供的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（3）行政、司法等政府部门要求提供的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（4）乙方同意甲方向第三方提供或乙方自行提供的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（5）只有披露乙方的信息，甲方或第三方才能提供产品和服务；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（6）为解决举报事件、提起诉讼而需要提供的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（7）为防止严重违法行为或涉嫌犯罪行为发生而采取必要、合理行动所必须提供的；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（8）甲方及其关联公司发生合并、收购、资产转让或类似的交易而需要提供的；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（9）基于本协议约定用途，分享给甲方关联公司、合作伙伴（包括但不限于资金方）、第三方服务商（包括但不限于第三方数据源，如需）及代理人。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第五十一条 乙方及其代表同意授权：①甲方及关联公司以及合作的第三方收集、整理、加工、存储、使用本人（本企业）信息用于本协议有关的业务目的；②甲方及其关联公司可根据业务需要将收集的本人（本企业）信息披露给平台服务相关的第三方；③平方服务相关的第三方可根据业务需要将收集的本人（本企业）信息披露给甲方及其关联公司；④甲方及关联公司以及合作的第三方根据业务需要向第三方机构查询、验证本人（本企业）信息。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第十二章 责任限制")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第五十二条 甲方仅负责按当时现状和当时可得到的状态向乙方提供平台服务。除非甲方另行以书面方式明确无误的作出承诺，否则甲方对平台服务不作任何明示或暗示的保证，包括但不限于平台服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途；甲方也不对平台服务所涉及的技术及信息的有效性、准确性、可靠性、质量、稳定、完整和及时性作出任何承诺和保证。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第五十三条 如因不可抗力或甲方控制范围外的原因造成的服务中断或其他缺陷（包括但不限于自然灾害、社会事件、硬件故障以及因网站所具有的特殊性质而产生的包括黑客攻击、电信部门技术调整导致的影响、政府管制、政策法规变化而造成的暂时性关闭在内的任何影响网络正常运营的因素），甲方不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "任何由于甲方合理控制范围以外的原因而产生的交易或服务系统问题，造成资料信息传输或储存上的错误，导致资料泄露、丢失、被盗用或被篡改等，致使甲方平台响应延迟或未能履约的，甲方不承担对此可能造成的任何责任。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第五十四条 乙方自平台及平台工作人员取得的建议和资讯，无论其为书面或口头形式，均不构成甲方对服务的保证。对乙方因使用上述建议或资讯而产生的损失，甲方不承担任何责任。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第五十五条 因乙方自身原因造成信息泄露、操作不当、授权管理不当或乙方的手机、电脑软件、系统、硬件和通信线路出现故障、木马病毒或手机号码变更、停机、不能正常使用或乙方通过非甲方授权的方式使用相关服务以及其他非甲方过错、甲方无法控制或合理预见的情形造成乙方的损失或风险的，甲方不承担责任。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第五十六条 乙方通过平台获取由第三方提供的信息或第三方提供的服务的，乙方应自行判断其真实性、准确性及合法性，并独立承担相应风险。甲方不对该等信息或第三方服务对乙方造成的损害或损失承担责任。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第五十七条 乙方理解并确认，甲方需要定期或不定期地对平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，甲方无需为此承担任何责任，但甲方应事先进行通告。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第五十八条 根据实际需要，甲方或合作第三方可能会对乙方提交的信息资料、服务申请进行审查。乙方理解该审查需要合理期限且具有一定规范要求，因审查未批准使乙方未能享受服务，或审查通过后发现乙方风险异常又中止或终止服务的，甲方不承担责任。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第五十九条 甲方不对乙方通过平台对相关数据删除或储存的失败负责，不承诺对乙方的存储信息进行无限期保留。乙方应根据自身的需要及时自行备份服务中的相关数据。如因终止协议或乙方违约造成删除信息或注销账户的，甲方可以从服务器上永久地删除相关数据且没有义务向乙方恢复任何数据。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第六十条 在任何情况下，甲方均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害承担责任，包括因乙方使用平台服务而遭受的经济损失。同时，甲方对本协议所承担的违约赔偿责任总额不超过如下数额：甲方向乙方收取的服务费用总额。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第十三章 违约责任")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("第六十一条 乙方有如下情形的，构成违约："),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（1）未履行本协议“第三章注册账户”约定义务的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（2）未履行本协议“第八章乙方权责”约定义务的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "（3）未按时足额支付服务费用或未在甲方通知期限内足额赔偿甲方损失的；"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（4）未履行本协议“第十章知识产权”约定义务的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（5）其他违反本协议（含有关规则）约定情形的；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（6）违反国家法律法规禁止性规定或违背社会公序良俗；"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("（7）其他违反本协议或甲方认定为不当的行为。"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("其中，第（1）--（4）项和第（6）项构成根本违约。"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第六十二条 对于乙方在平台服务中的违法、违约或其它甲方认为不当的行为，甲方有权采取删除或屏蔽乙方信息、限制、中止或终止全部或部分服务、解除协议、列入黑名单、网站公示、警告、数据清空、注销账户等一项或多项处理措施，并保留追究其法律责任的权利，乙方有权提出异议。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第六十三条 乙方未按照平台公布的收费标准支付本协议项下服务费用的，乙方须向甲方支付违约金，每逾期一日应按乙方应付费用千分之一的标准计算（乙方给甲方造成的损失赔偿亦适用）。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第六十四条 乙方因违法或违约所引起的纠纷、责任等一概由乙方负责，甲方也无需向乙方退还已收取的任何费用，而由此给乙方带来的损失（包括但不限于通信中断、相关数据清空、本服务或第三方服务中断、终止等造成的后果），由乙方自行承担，造成甲方或他人损失的，乙方应赔偿。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第十四章 协议终止")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第六十五条 鉴于互联网服务的特殊性，甲方有权根据经营需求单方变更、终止服务，但应及时通知乙方。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第六十六条 如果本协议任一方履行其于本协议项下任何义务，可能导致该方违反任何国内外法律、法令或禁令，或任何国内外行政机构的条例、规则或指引，则该方可采取一切合理应对措施避免因履约而遭受损失（包括可能产生的声誉风险），前述措施包括但不限于该方可中止或终止其相应义务的履行而不视为该方违约。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第六十七条 本协议约定的中止或终止条件成就时，甲方有权随时中止、终止服务。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第六十八条 如因不可抗力等因素影响本协议履行或导致本协议无法履行的，双方可协商变更或终止服务。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第六十九条 协议变更或终止，不影响变更或终止前行为的效力和遗留义务的继续履行。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v("第七十条 因任何原因终止协议的，甲方有权注销乙方账户。"),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _c("strong", [_vm._v("第十五章 其他事项")]),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第七十一条 本协议适用中华人民共和国内地的法律。协议履行中如发生争议，应首先进行协商或调解；协商或调解不成的，任一方可向甲方所在地有管辖权人民法院提起诉讼。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第七十二条 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。本协议的终止或无效或部分条款无效，均不影响知识产权条款的效力。"
        ),
      ]),
      _c("p", { staticClass: "str_2" }, [
        _vm._v(
          "第七十三条  乙方可通过平台系统或页面提示向甲方提出投诉意见，包括信息保护、知识产权保护或其他与平台服务相关的投诉。甲方接受与乙方有关的投诉，并依照法律规定进行答复或处理。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }