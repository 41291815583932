<template>
  <div class="footer-wrap" :class="{ logined }">
    <div :class="{'main-wrapper': !logined}">
      <div class="footer-info-box">
        <div>
          <div class="list-box">
            <div
              v-for="item in list"
              :key="item.url"
              class="item-box"
            >
              <router-link :to="item.url" target="_blank">
                <el-link>{{ item.label }}</el-link>
              </router-link>
            </div>
            <div class="item-box">
              <span style="color: #999">人工咨询微信公众号</span>
            </div>
          </div>
          <div class="copy-right-box">
            版权所有 © 和信（天津）国际商业保理有限公司 2021 保留一切权利
            <el-link href="http://www.beian.gov.cn/portal/index.do" target="_blank">
              <!-- 浙ICP备2023015073号-1 -->
              <!-- 浙ICP备2024069340号 -->
              <!-- 浙ICP备2024069340号 -->
            </el-link>
          </div>
        </div>
        <div class="img-box" v-if="!logined">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Footer',
  props: {
    logined: Boolean
  },
  data() {
    return {
      list: [
        { label: '隐私与安全政策', url: '/protocol/privatePolicy' },
        { label: '网站使用条款', url: '/protocol/website' },
        { label: 'Cookie政策', url: '/protocol/cookie' },
        { label: '建议与意见', url: '/protocol/feedback' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ]),
    // logined() {
    //   return !!this.token
    // }
  }
}
</script>
<style lang="scss" scoped>
	.footer-wrap {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-bottom: 5px;
    margin-top: 20px;
    box-sizing: border-box;
		text-align: center;
    border-top: 1px solid #eee;
    background-color: #fff;
    /*background: linear-gradient(0deg, rgba(#409Eee, 1), rgba(#409EFF, 1));*/
    a {
      color: #999;
    }
    a:hover {
      color: #666;
    }
    &.logined {
      background: transparent;
      text-align: center;
      margin-top: 0;
      a {
        color: rgba(black, .45);
      }
      .copy-right-box {
        text-align: center;
        color: rgba(black, .45);
      }
      .list-box {
        justify-content: center;
        .item-box {
          color: rgba(black, .45);
        }
      }
      .footer-info-box {
        display: block;
      }
    }
	}
	.footer-info-box {
		padding: 15px 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.list-box {
		display: flex;
		align-items: center;
		.item-box {
			margin-right: 20px;
			font-size: 14px;
			color: #666;
    	font-weight: 500;
		}
	}
	.img-box {
		img {
			width: 60px;
			&:first-child {
				margin-right: 20px;
			}
		}
	}
	.copy-right-box {
		font-size: 12px;
		color: #666;
		font-weight: normal;
    text-align: center;
    padding-top: 15px;
		/deep/ .el-link {
			font-size: 12px;
			font-weight: normal;
      color: #666;
		}
	}
</style>
