<template>
    <div class="user">
        <basic-container>
            <avue-crud ref="crud" :option="option" :page.sync="page" :table-loading="listLoading" :data="list"
                @on-load="getList" @size-change="sizeChange" @current-change="currentChange">
                <template slot="menuLeft" />

            </avue-crud>
            
        </basic-container>
        <div class="tc" slot="footer">
      <el-button type="primary" size="medium" @click="goback">返回</el-button>
    </div>
    </div>
</template>
  
<script>

export default {
    name: 'TableUser',

    data() {
        return {

            option: {
                border: false,
                index: false,
                indexLabel: '序号',
                stripe: true,
                searchShow: false,
                header: false,
                menualign: 'left',
                menuWidth: '400',
                editBtn: false,
                delBtn: false,
                align: 'left',
                addBtn: false,
                menu: false,
               searchShowBtn:false,
 column: [
                    {
                        label: '商品',
                        prop: 'itemDes',
                        width: 500,
                        span: 12
                    },
                    {
                        label: '实付金额',
                        prop: 'amount',
                        span: 12,
                        width: 150
                    },
                    {
                        label: '可贷金额',
                        prop: 'availableFinAmount',
                        width: 150
                    },
                    {
                        label: '购买日期',
                        prop: 'purchaseDate',
                        minWidth: 200,
                        span: 12,
                    },

                ]
            },
            listLoading: false,
            page: {
                total: 0, // 总页数
                currentPage: 1, // 当前页数
                pageSize: 10, // 每页显示多少条
                pageSizes: [10, 20, 30, 50, 100],
                isAsc: false // 是否倒序
            },
            list: []
        }
    },

    methods: {
        goback(){
            this.$router.go(-1)
        },
        getList(page) {
            this.listLoading = true
            let params = {}
            params.pageNum = page.currentPage
            params.pageSize = page.pageSize
            params.storeQuotaId = this.$route.query.id
            this.doPost('/bium-portal/controller/fin-ops/apply/orderPage', params).then(({ data }) => {
                this.list = data.result.records
                this.page.total = data.result.total
                this.listLoading = false
            })
            this.listLoading = false
        },

        sizeChange(size) {
            this.page.pageSize = size
        },
        currentChange(current) {
            this.page.currentPage = current
        },

    }
}

</script>
  