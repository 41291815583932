var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-container" }, [
    _c("div", { staticClass: "login-weaper bounceInRight" }, [
      _c("div", { staticClass: "login-border", attrs: { id: "skinBox" } }, [
        _c(
          "div",
          { staticClass: "login-main" },
          [_vm.activeName === "user" ? _c("userLogin") : _vm._e()],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }