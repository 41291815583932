var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-login-root" }, [
    _c("div", { staticClass: "header-position" }),
    _c("div", { staticClass: "header-login-wrapper" }, [
      _c(
        "div",
        { staticClass: "header-login-container" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _c("img", {
              staticStyle: { height: "60px" },
              attrs: {
                src: require("@/assets/bg/hxlogo-report.png"),
                width: "160",
                alt: "",
              },
            }),
          ]),
          _c(
            "router-link",
            { attrs: { tag: "span", to: _vm.url } },
            [
              _vm._v(_vm._s(_vm.text1)),
              _c("el-link", { attrs: { type: "primary", underline: false } }, [
                _vm._v(_vm._s(_vm.text2)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }