<template>
  <div class="user">
    <basic-container>
      <avue-crud ref="crud" v-model="form" :before-open="handleOpenBefore" :data="list" :option="option"
        :page.sync="page" :table-loading="listLoading" @on-load="getList" @size-change="sizeChange"
        @current-change="currentChange" @search-change="handleFilter" @search-reset="resetFilter"
        @refresh-change="handleRefreshChange">
        <template #menuLeft>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane v-for="item in tabList" :key="item.value" :label="item.label" :name="item.value"></el-tab-pane>
          </el-tabs>
        </template>
        <template slot="enterpriseStatus" slot-scope="scope">
          <span>{{enterpriseStatus[scope.row.enterpriseStatus]}}</span>
        </template>
        <template slot="accountStatus" slot-scope="scope">
          <span>{{accountStatus[scope.row.accountStatus]}}</span>
        </template>
        <template slot="status" slot-scope="scope">
          <span v-if="scope.row.status == 0">待审核</span>
          <span v-if="scope.row.status == 1">审核通过</span>
          <span v-if="scope.row.status == 2">审核失败</span>
        </template>
        <template slot="currNode" slot-scope="scope">
          <span v-if="scope.row.currNode == 'ops'">运营</span>
          <span v-if="scope.row.currNode == 'risk'">风控</span>
          <span v-if="scope.row.currNode == 'financial'">财务</span>
        </template>
        <template slot="productType" slot-scope="scope">
          <span v-if="scope.row.productType == 0">1A-极速收款</span>
          <span v-if="scope.row.productType == 1">2A-极速付款</span>
        </template>
        <template slot="menu" slot-scope="scope">
          <el-button type="text" @click="handleApproval(scope.row)">详情</el-button>
          <el-button type="text" @click="handleApproval(scope.row)">案例记录</el-button>
        </template>
      </avue-crud>
    </basic-container>
    <el-dialog :before-close="handleClose" :visible.sync="Caseisvisible" class="dialog-standard channel-config-rate"
      title="新增案例" top="100px" width="550px">
      <addCase v-if="Caseisvisible" :rowForm="rowItem"></addCase>
    </el-dialog>
    <el-dialog :before-close="handleClose" :visible.sync="casedialog" class="dialog-standard channel-config-rate"
      title="案例记录" top="100px" width="800px">
      <CaseRecord v-if="casedialog" :rowForm="rowItem" />
    </el-dialog>
    <el-dialog :before-close="handleClose" :visible.sync="examinedialog" class="dialog-standard channel-config-rate"
      title="审核操作" top="100px" width="800px">
      <examine v-if="examinedialog" :rowForm="rowItem"></examine>
    </el-dialog>
    <el-dialog :before-close="handleClose" :visible.sync="Customerdialog" class="dialog-standard channel-config-rate"
      title="客户归属绑定" top="100px" width="700px">
      <customer v-if="Customerdialog" :rowForm="rowItem"></customer>
    </el-dialog>
    <el-dialog :before-close="handleClose" :visible.sync="Ratesvisible" class="dialog-standard channel-config-rate"
      title="费率方案" top="100px" width="600px">
      <RatePlan v-if="Ratesvisible" :rowForm="rowItem"></RatePlan>
    </el-dialog>
    <el-dialog :before-close="handleClose" :visible.sync="creditvisible" class="dialog-standard channel-config-rate"
      title="征信报告" top="100px" width="1000px">
      <creditReport v-if="creditvisible" :rowForm="rowItem"></creditReport>
    </el-dialog>
  </div>
</template>

<script>
import CaseRecord from '@/views/operate/components/CaseRecord.vue';
import addCase from '@/views/operate/components/addCase';
import examine from '../components/examine';
import customer from '../components/customer';
import RatePlan from '../components/RatePlan';
import creditReport from '../components/creditReport';

export default {
  name: 'TableUser',
  components: {
    CaseRecord,
    addCase,
    examine,
    customer,
    RatePlan,
    creditReport,
  },
  data() {
    return {
      enterpriseStatus:{
        7: '待认证',
        3: '认证失败',
        31: '企业四要素认证失败',
        32: '法人三要素认证失败',
        2: '认证成功'
      },
      accountStatus:{
     1:'可用',
     2:'待签约',
     3:'待开户',
     4:'待短信验证',
     5:'待打款验证',
     6:'签约失败',
     7:'开户失败',
     8:'销户',
     9:'处理中',
    10:'等待开场景账户'
      },
      activeName: '0',
      tabList: [
        { label: '待审核', value: '0' },
        { label: '已审核', value: '1' },
      ],
      Ratesvisible: false,
      creditvisible: false,
      casedialog: false,
      Caseisvisible: false,
      examinedialog: false,
      Customerdialog: false,
      searchItmes: {},
      option: {
        border: false,
        index: false,
        indexLabel: '序号',
        stripe: true,
        searchShow: true,
        header: true,
        menualign: 'left',
        menuWidth: '300',
        editBtn: false,
        delBtn: false,
        align: 'left',
        addBtn: false,
        searchShowBtn: false,
        column: [
          {
            label: '企业名称',
            prop: 'enterpriseName',
            align: 'left',
            width: 300,
            hide: true,
            search: true,
            searchSpan: 4
          },
          {
            label: '企业名称',
            prop: 'enterpriseName',
          },
          {
            label: '店铺数量',
            prop: 'storeCount',
          },
          {
            label: '手机号码',
            prop: 'mobile',
          },
          {
            label: '在贷余额',
            prop: 'loanAmount',
          },
          {
            label: '认证状态',
            prop: 'enterpriseStatus',
          },
          {
            label: '开户状态',
            prop: 'accountStatus',
          },
        ]
      },
      defaultProps: {
        label: 'name',
        value: 'id',
      },
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        pageSizes: [10, 20, 30, 50, 100],
        isAsc: false, // 是否倒序
      },
      list: [],
      loading: false,
      listLoading: true,
      form: {},
      rowItem: {},
    }
  },
  created() {
  },
  methods: {
    handleClick(item, index) {
      console.log(item, index);

      this.activeName = item.index
      this.getList(this.page, this.searchItmes)
    },
    /** 关闭弹窗 */
    handleClose() {
      this.Caseisvisible = false
      this.Ratesvisible = false
      this.creditvisible = false
      this.casedialog = false
      this.examinedialog = false
      this.Customerdialog = false
      this.getList(this.page, this.searchItmes)
    },
    openDIalog(title, row) {
      if (title === 'examinedialog' && row.type == 2 && row.currNode == 'financial') {
        this.doPost('bium-portal/controller/fin-ops/audit/getApplyAuditAmount', {
          id: row.id,
        }).then(({ data }) => {
          this.rowItem = row
          this[title] = true
          this.rowItem.amount = data.result
        })
      } else if (title === 'Ratesvisible') {
        this.doPost('bium-portal/controller/fin-ops/ratePlan/getRatePlan', {
          storeId: row.storeId,
        }).then(({ data }) => {
          if (data.result == null) {
            this.$message.error('请先绑定客户')
          } else {
            this[title] = true
            this.rowItem = row
          }
        })
      } else {
        this[title] = true
        this.rowItem = row
      }
    },

    getList(page, params) {
      console.log(this.activeName);

      params = params
        ? params
        : {
          ...this.searchItmes,
        }
      this.listLoading = true
      if (params.created) {
        params.startTime = params.created[0] + ' 00:00:00'
        params.endTime = params.created[1] + ' 23:59:59'
      }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize
      this.doPost('/bium-portal/controller/fin-ops/enterprise/merchantPage', params).then(({ data }) => {
        this.list = data.result.records || []
        this.page.total = data.result.total || 0
      })
      this.listLoading = false
    },
    handleApproval(row, index) {
      this.$router.push({ path: '/wfTaskInfo/Taskdetails?id=' + row.id })
    },
    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },
    /** 重置条件搜索 */
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    handleOpenBefore(show, type) {
      window.boxType = type
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {
      } else if (type === 'add') {
      }
      show()
    },
  },
}
</script>

<style scoped>
::v-deep .el-tabs__nav-wrap::after {
  display: none;
}
</style>
