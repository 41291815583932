var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        { staticClass: "full-condition" },
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              "cell-class-name": _vm.handleCellClassName,
              data: _vm.list,
              option: _vm.option,
              page: _vm.page,
              "table-loading": _vm.listLoading,
            },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "on-load": _vm.getList,
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentChange,
              "search-change": _vm.handleFilter,
              "search-reset": _vm.resetFilter,
              "refresh-change": _vm.handleRefreshChange,
            },
            scopedSlots: _vm._u([
              {
                key: "customerRate",
                fn: function (scope) {
                  return [
                    scope.row.customerRate != null
                      ? _c("span", [_vm._v(_vm._s(scope.row.customerRate))])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "productType",
                fn: function (scope) {
                  return [
                    scope.row.productType == 0
                      ? _c("span", [_vm._v("1A-极速收款")])
                      : _vm._e(),
                    scope.row.productType == 1
                      ? _c("span", [_vm._v("2A-极速付款")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "dataSource",
                fn: function (scope) {
                  return [
                    scope.row.dataSource == 0
                      ? _c("span", [_vm._v("手动获取")])
                      : _vm._e(),
                    scope.row.dataSource == 1
                      ? _c("span", [_vm._v("微风企")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "menu",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.detailsChange(scope.row, scope.index)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openDIalog("Caseisvisible", scope.row)
                          },
                        },
                      },
                      [_vm._v("新增案例")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openDIalog("casedialog", scope.row)
                          },
                        },
                      },
                      [_vm._v("查看案例")]
                    ),
                    ["10", "12"].includes(scope.row.livePlatform)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openManageData(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          数据管理\n        ")]
                        )
                      : _vm._e(),
                    ["10", "12"].includes(scope.row.livePlatform) &&
                    scope.row.dataSource == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openFetchData(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n          获取数据\n        ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            "before-close": _vm.handleClose,
            visible: _vm.Caseisvisible,
            title: "新增案例",
            top: "100px",
            width: "550px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.Caseisvisible = $event
            },
          },
        },
        [
          _vm.Caseisvisible
            ? _c("addCase", { attrs: { "row-form": _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            "before-close": _vm.handleClose,
            visible: _vm.casedialog,
            title: "案例记录",
            top: "100px",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.casedialog = $event
            },
          },
        },
        [
          _vm.casedialog
            ? _c("CaseRecord", { attrs: { "row-form": _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            "before-close": _vm.handleClose,
            visible: _vm.manageshow,
            title: "店铺数据管理",
            top: "100px",
            width: "460px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.manageshow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form_modal" },
            [
              _c("p", { staticClass: "tips" }, [
                _vm._v(
                  "\n        选择微风企进行数据获取前,请确保店铺已使用店铺云号完成主账号换绑,并且店铺名称无误,否则数据获取失败!\n      "
                ),
              ]),
              _c(
                "el-form",
                { attrs: { model: _vm.manageFrom } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "数据获取方式" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.manageFrom.dataSourceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.manageFrom, "dataSourceType", $$v)
                            },
                            expression: "manageFrom.dataSourceType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: Number(0), label: "手动" },
                          }),
                          _c("el-option", {
                            attrs: { value: Number(1), label: "微风企" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.manageFrom.livePlatform == "12"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "店铺登录密码(拼多多)" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.manageFrom.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.manageFrom, "password", $$v)
                              },
                              expression: "manageFrom.password",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.onCancelManage()
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmitManage()
                    },
                  },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            "before-close": _vm.handleClose,
            visible: _vm.fetchshow,
            title: "店铺数据获取",
            top: "100px",
            width: "460px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fetchshow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "form_modal" }, [
            _c("p", { staticClass: "tips" }, [
              _vm._v(
                "进行店铺数据主动获取前请确保店铺已使用店铺云号完成主账号换绑,并且店铺名称无误,否则数据获取失败!"
              ),
            ]),
            _c("p", { staticClass: "desc" }, [
              _vm._v("最后一次店铺数据获取状态:"),
              _c("span", [_vm._v(_vm._s(_vm.digital.statusDesc))]),
            ]),
            _c("p", { staticClass: "desc" }, [
              _vm._v("店铺数据最后更新时间:"),
              _c("span", [_vm._v(_vm._s(_vm.digital.updated))]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.digital.dataStatus == 1,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onFetchDigital()
                    },
                  },
                },
                [_vm._v("\n        手动发起店铺数据获取\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }