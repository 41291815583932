<template>
  <div v-loading="Loading" class="selection-box">
    <section class="acc-container">
      <el-divider class="title-divider" content-position="left">基本信息</el-divider>
      <!-- 客户信息 -->
      <div class="jg-acc">
        <div class="card-box">
          <div v-for="(item, index) in customerInfo" :key="index" class="card-item">
            <div class="item_label">{{ item.name }}：</div>
            <div>
              <span v-if="item.name == '审核类型'">
                <span v-if="dataDetails[item.value] == 0">企业审核</span>
                <span v-if="dataDetails[item.value] == 1">店铺审核</span>
                <span v-if="dataDetails[item.value] == 2">支用审核</span>
                <span v-if="dataDetails[item.value] == 3">提现审核</span>
              </span>
              <span v-else-if="item.name == '状态'">
                <span v-if="dataDetails[item.value] == 0">待审核</span>
                <span v-if="dataDetails[item.value] == 1">审核通过</span>
                <span v-if="dataDetails[item.value] == 2">审核失败</span>
              </span>
              <span v-else-if="item.value == 'productType'">
                <span v-if="dataDetails[item.value] == 0">1A-极速收款</span>
                <span v-if="dataDetails[item.value] == 1">2A-极速付款</span>
              </span>
              <span v-else>{{ dataDetails[item.value] }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 提现信息 -->
      <div v-if="dataDetails.content.auditWithdrawEntity">
        <el-divider class="title-divider" content-position="left">提现信息</el-divider>

        <div class="jg-acc">
          <div class="card-box">
            <div v-for="(item, index) in Withdraw_info" :key="index" class="card-item">
              <div class="item_label">{{ item.name }}:</div>

              <div>
                <span>
                  {{
                    dataDetails.content.auditWithdrawEntity[item.value] ||
                      dataDetails.content.auditWithdrawEntity[item.value] == 0
                      ? dataDetails.content.auditWithdrawEntity[item.value]
                      : '--'
                  }}
                  <span
                    v-if="
                      dataDetails.content.auditWithdrawEntity[item.value] ||
                        dataDetails.content.auditWithdrawEntity[item.value] == 0
                    "
                  >
                    {{ item.unit }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 订单信息 -->
      <div v-if="dataDetails.content.auditApplyOrderEntity">
        <el-divider class="title-divider" content-position="left">订单信息</el-divider>

        <div class="mb10">
          <el-button
            type="primary"
            @click="goPath('/wfTaskInfo/orderDetails', { orderNo: dataDetails.content.auditApplyOrderEntity.orderNo })"
          >
            查看订单详情
          </el-button>
        </div>
        <div class="jg-acc">
          <div class="card-box">
            <div v-for="(item, index) in order_info" :key="index" class="card-item">
              <div class="item_label">{{ item.name }}:</div>

              <div>
                <span v-if="item.value == 'creditRatio'">
                  {{
                    dataDetails.content.auditApplyOrderEntity[item.value]
                      ? dataDetails.content.auditApplyOrderEntity[item.value]
                      : '--'
                  }}
                  <span v-if="dataDetails.content.auditApplyOrderEntity[item.value]">%</span>
                </span>
                <span v-else-if="item.value == 'orderPaidRatio'">
                  {{ (Number(dataDetails.content.auditApplyOrderEntity[item.value]) * 100).toFixed(0) }}%
                </span>
                <span v-else>
                  {{
                    dataDetails.content.auditApplyOrderEntity[item.value]
                      ? dataDetails.content.auditApplyOrderEntity[item.value]
                      : '--'
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 额度信息  -->
      <div v-if="dataDetails.content.auditCreditAmountEntity">
        <el-divider class="title-divider" content-position="left">额度信息</el-divider>
        <div class="jg-acc">
          <div class="card-box">
            <div v-for="(item, index) in AuditCreditAmountEntity" :key="index" class="card-item">
              <div class="item_label">{{ item.name }}:</div>

              <div>
                <span v-if="dataDetails.content.auditCreditAmountEntity[item.value]">
                  {{ dataDetails.content.auditCreditAmountEntity[item.value] }}
                </span>
                <span v-else>--</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 店铺信息 -->
      <div v-if="dataDetails.content.auditStoreEntity">
        <el-divider class="title-divider" content-position="left">店铺信息</el-divider>

        <div class="mb10">
          <el-button
            type="primary"
            @click="
              openPath('/creditReport/index', {
                id: dataDetails.content.auditStoreEntity.id,
                livePlatform: dataDetails.content.auditStoreEntity.livePlatform,
                storeCode: dataDetails.content.auditStoreEntity.storeCode,
                storename: dataDetails.content.auditStoreEntity.name,
                entName: dataDetails.content.auditEnterpriseEntity.name,
              })
            "
          >
            查看风控报告
          </el-button>
        </div>
        <div class="jg-acc">
          <div class="card-box">
            <div v-for="(item, index) in shopformation" :key="index" class="card-item">
              <div class="item_label">{{ item.name }}:</div>

              <div>
                <span v-if="item.value == 'livePlatform'">
                  <span v-if="dataDetails.content.auditStoreEntity[item.value] == 10">抖音</span>
                  <span v-if="dataDetails.content.auditStoreEntity[item.value] == 11">快手</span>
                  <span v-if="dataDetails.content.auditStoreEntity[item.value] == 12">拼多多</span>
                  <span v-if="dataDetails.content.auditStoreEntity[item.value] == 13">腾讯视频号</span>
                  <span v-if="dataDetails.content.auditStoreEntity[item.value] == 14">美团</span>
                </span>
                <span v-else-if="item.value == 'storeStatusDesc'">
                  {{ storeStatusDesc }}
                </span>
                <span v-else-if="item.value == 'virtualAcctStatusDesc'">
                  {{ virtualAcctStatusDesc }}
                </span>
                <span v-else>{{ dataDetails.content.auditStoreEntity[item.value] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 费率信息 -->
      <div v-if="dataDetails.productType == 0 && dataDetails.content.auditRatePlanEntity">
        <el-divider class="title-divider" content-position="left">费率信息</el-divider>
        <div class="jg-acc">
          <div class="card-box">
            <div v-for="(item, index) in direct" :key="index" class="card-item">
              <div class="item_label">{{ item.name }}:</div>
              <div>
                <span v-if="item.value == 'firstTraceDays' || item.value == 'multipleTraceDays'">
                  T - {{ dataDetails.content.auditRatePlanEntity[item.value] }}天
                </span>
                <span v-else-if="item.value == 'channelName'">
                  {{
                    dataDetails.content.auditRatePlanEntity.channelName
                      ? dataDetails.content.auditRatePlanEntity.channelName
                      : '直客'
                  }}
                </span>
                <el-button v-else-if="item.value == 'btn'" type="primary" @click="toConfigStandardPlan(dataDetails)">
                  查看费率
                </el-button>
                <span v-else>{{ dataDetails.content.auditRatePlanEntity[item.value] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 费率信息2 -->
      <div v-if="dataDetails.productType == 1 && dataDetails.content.auditRatePlanEntity">
        <el-divider class="title-divider" content-position="left">费率信息</el-divider>
        <div class="jg-acc">
          <div class="card-box">
            <div v-for="(item, index) in directsType" :key="index" class="card-item">
              <div class="item_label">{{ item.name }}:</div>
              <div>
                <span v-if="item.value == 'paymentDays'">
                  {{ dataDetails.content.auditRatePlanEntity[item.value] }}天
                </span>
                <span v-else-if="item.value == 'customerRate'">
                  {{
                    (Number(dataDetails.content.auditRatePlanEntity.custRateDetailList[0].customerRate) * 100).toFixed(
                      4,
                    )
                  }}%
                </span>
                <span v-else-if="item.value == 'channelName'">
                  {{
                    dataDetails.content.auditRatePlanEntity.channelName
                      ? dataDetails.content.auditRatePlanEntity.channelName
                      : '直客'
                  }}
                </span>
                <span v-else>{{ dataDetails.content.auditRatePlanEntity[item.value] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 支用信息 -->
      <div v-if="dataDetails.content.auditApplyEntity">
        <el-divider class="title-divider" content-position="left">支用信息</el-divider>

        <div class="mb10">
          <el-button
            type="primary"
            @click="
              goPath('/wfTaskInfo/disbursement', {
                id: dataDetails.content.auditApplyEntity.storeQuotaId,
              })
            "
          >
            查看订单详情
          </el-button>
        </div>
        <div class="jg-acc">
          <div class="card-box">
            <div v-for="(item, index) in Disbursementformation" :key="index" class="card-item">
              <div class="item_label">{{ item.name }}:</div>
              <div>
                <span
                  v-if="
                    item.value == 'riskDiscountRate' ||
                      item.value == 'realityDiscountRate' ||
                      item.value == 'customerRate'
                  "
                >
                  {{ (Number(dataDetails.content.auditApplyEntity[item.value]) * 100).toFixed(4) }}{{ item.unit }}
                </span>
                <span v-else-if="dataDetails.content.auditApplyEntity[item.value]">
                  {{ dataDetails.content.auditApplyEntity[item.value] }}{{ item.unit }}
                </span>
                <span v-else>--</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 企业营业信息 -->
      <el-divider class="title-divider" content-position="left">企业营业信息</el-divider>

      <div class="jg-acc">
        <div class="card-box">
          <div v-for="(item, index) in companyInfo" :key="index" class="card-item">
            <div class="item_label">{{ item.name }}:</div>
            <div>
              <span v-if="item.name == '营业执照有效期'">
                {{ dataDetails.content.auditEnterpriseEntity.establishmentDate }}至{{
                  dataDetails.content.auditEnterpriseEntity.validDate
                }}
              </span>
              <span v-else>{{ dataDetails.content.auditEnterpriseEntity[item.value] }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 企业法人信息 -->
      <div v-if="dataDetails.type != 3">
        <el-divider class="title-divider" content-position="left">企业法人信息</el-divider>
        <div class="jg-acc">
          <div class="card-box">
            <div v-for="(item, index) in personInformation" :key="index" class="card-item">
              <div class="item_label">{{ item.name }}:</div>
              <div>
                {{ dataDetails.content.auditEnterpriseEntity[item.value] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 授权人信息 -->
      <div
        v-if="
          dataDetails.content.auditEnterpriseEntity.authorizationName &&
            dataDetails.content.auditEnterpriseEntity.authorizationName != null &&
            dataDetails.type != 3
        "
      >
        <el-divider class="title-divider" content-position="left">授权人信息</el-divider>
        <div class="jg-acc">
          <div class="card-box">
            <div v-for="(item, index) in AuthorizedInformation" :key="index" class="card-item">
              <div class="item_label">{{ item.name }}:</div>
              <div>
                {{ dataDetails.content.auditEnterpriseEntity[item.value] }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="dataDetails.content.auditAttachmentEntityList && dataDetails.content.auditAttachmentEntityList.length">
        <imgdialog
          :code="dataDetails.content.auditEnterpriseEntity.id"
          :file-arr="dataDetails.content.auditAttachmentEntityList"
        />
        <filedialog
          :code="dataDetails.content.auditEnterpriseEntity.id"
          :file-arr="dataDetails.content.auditAttachmentEntityList"
        />
      </div>
    </section>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import imgdialog from '@/views/operate/components/imgdialog.vue';
import filedialog from '@/views/operate/components/filedialog.vue';

export default defineComponent({
  name: 'shopDetail',
  components: {
    imgdialog,
    filedialog
  },
  data() {
    return {
      Loading: false,
      dataDetails: {},
      // 额度信息
      AuditCreditAmountEntity: [
        {
          name: '总授信额度',
          value: 'creditAmount'
        },
        {
          name: '剩余授信额度',
          value: 'residueAmount'
        }
      ],
      Withdraw_info: [
        {
          name: '申请金额',
          value: 'applyAmount',
          unit: '元'
        },
        {
          name: '账户可用资金余额',
          value: 'availableAmount',
          unit: '元'
        },
        {
          name: '账户冻结金额',
          value: 'reservedAmount',
          unit: '元'
        }
      ],
      // 订单信息
      order_info: [
        {
          name: '支付单号',
          value: 'orderNo'
        },
        {
          name: '订单总金额',
          value: 'totalAmount'
        },
        {
          name: '待支付总金额',
          value: 'totalToBePaid'
        },
        {
          name: '待支付定金金额',
          value: 'depositToBePaid'
        },
        {
          name: '待支付全款金额',
          value: 'normalToBePaid'
        },
        {
          name: '综合赊销率',
          value: 'creditRatio'
        },
        {
          name: '订单支付比例',
          value: 'orderPaidRatio'
        }
      ],
      // 企业营业信息
      companyInfo: [
        {
          name: '企业名称',
          value: 'name'
        },
        {
          name: '社会信用代码',
          value: 'creditCode'
        },
        {
          name: '营业执照有效期',
          value: 'validDate'
        },
        {
          name: '企业地址信息',
          value: 'address'
        }

      ],
      // 企业法人信息
      personInformation: [
        {
          name: '法人姓名',
          value: 'legalName'
        },
        {
          name: '法人身份证',
          value: 'legalCard'
        },
        {
          name: '身份证有效期',
          value: 'legalDuration'
        },
        {
          name: '法人联系邮箱',
          value: 'legalMail'
        },
        {
          name: '法人手机号码',
          value: 'legalMobile'
        },
        {
          name: '民族',
          value: 'legalEthnic'
        }
      ],
      // 授权人信息
      AuthorizedInformation: [
        {
          name: '授权人姓名',
          value: 'authorizationName'
        },
        {
          name: '授权人身份证',
          value: 'authorizationCard'
        },
        {
          name: '身份证有效期',
          value: 'authorizationDuration'
        },
        {
          name: '授权人邮箱',
          value: 'authorizationMail'
        },
        {
          name: '授权人联系电话',
          value: 'authorizationMobile'
        },
        {
          name: '民族',
          value: 'authorizationEthnic'
        }
      ],
      // 客户信息
      customerInfo: [
        {
          name: '客户名称',
          value: 'enterpriseName'
        },
        {
          name: '订单号',
          value: 'auditNo'
        },
        {
          name: '联系方式',
          value: 'mobile'
        },
        {
          name: '审核类型',
          value: 'type'
        },
        {
          name: '状态',
          value: 'status'
        }
      ],
      // 费率信息
      direct: [
        {
          name: '资金方',
          value: 'funderName'
        },
        // {
        //   name: '首笔提款回溯天数',
        //   value: 'firstTraceDays',
        // },
        // {
        //   name: '多笔提款回溯天数',
        //   value: 'multipleTraceDays',
        // },
        {
          name: '企业归属',
          value: 'channelName'
        },
        {
          name: '对客费率方案',
          value: 'btn'
        }
      ],
      // 费率信息
      directsType: [
        {
          name: '资金方',
          value: 'funderName'
        },
        {
          name: '每日还款比例',
          value: 'dailyRepaymentRatio'
        },
        {
          name: '对客费率（年化）',
          value: 'customerRate'
        },
        {
          name: '用款周期',
          value: 'paymentDays'
        },
        {
          name: '渠道归属',
          value: 'channelName'
        }
      ],
      // 店铺信息
      shopformation: [
        {
          name: '店铺名称',
          value: 'name'
        },
        {
          name: '店铺所属平台',
          value: 'livePlatform'
        },
        {
          name: '店铺ID',
          value: 'storeCode'
        }
        // {
        //   name: '店铺账户ID',
        //   value: 'storeAccountId'
        // },
        // {
        //   name: '店铺账号状态',
        //   value: 'storeStatusDesc'
        // },
        // {
        //   name: '店铺账户状态',
        //   value: 'virtualAcctStatusDesc'
        // },
        // {
        //   name: '店铺管控手机号',
        //   value: 'bindingMobile'
        // }
      ],
      // 支用信息
      Disbursementformation: [
        {
          name: '支用金额',
          value: 'applyAmount',
          unit: '元'
        },
        {
          name: '待结算金额',
          value: 'unsettledOrderAmount',
          unit: '元'
        },
        {
          name: '风控折扣率',
          value: 'riskDiscountRate',
          unit: '%'
        },
        {
          name: '实际折扣率',
          value: 'realityDiscountRate',
          unit: '%'
        },
        {
          name: '对客费率',
          value: 'customerRate',
          unit: '% /单笔'
        },
        {
          name: '订单回溯天数',
          value: 'orderTraceDays',
          unit: '天'
        },
        {
          name: '可支用商品订单笔数',
          value: 'orderCount',
          unit: '笔'
        }
      ],
      virtualAcctStatusDesc: '',
      storeStatusDesc: ''
    };
  },
  created() {
    this.getData();
  },

  methods: {
    getshopData(id) {
      this.doPost('/bium-portal/controller/fin-ops/store/approval/get', {
        storeId: id
      }).then(({ data }) => {
        this.storeStatusDesc = data.result.storeStatusDesc;
        this.virtualAcctStatusDesc = data.result.virtualAcctStatusDesc;
      });
    },
    toConfigStandardPlan(dataItem) {
      const comp = () => import('../../expense/components-plan/plan-view.vue');
      this.$modalDialog(comp, { dataItem });
    },
    getData() {
      this.Loading = true;
      this.doPost('/bium-portal/controller/fin-ops/audit/getAuditDetail', {
        id: this.$route.query.id
      }).then(({ data }) => {
        this.dataDetails = data.result;
        if (this.dataDetails.content.auditApplyEntity.isZCX == true) {
          this.Disbursementformation.push({
            name: '建行授信金额',
            value: 'ccbCreditAmount',
            unit: ''
          },
          {
            name: '建行实际用信申请金额',
            value: 'ccbApplyCreditAmount',
            unit: ''
          });
        }
        console.log('this.Disbursementformation[4]', this.dataDetails);
        if (this.dataDetails.content.auditApplyEntity.billingMethod == 'Annualized') {
          this.Disbursementformation[4].unit = '% /年化';
        } else {
          this.Disbursementformation[4].unit = '% /单笔';
        }
        this.Loading = false;
        if (data.result.content.auditStoreEntity && data.result.content.auditStoreEntity.id) {
          this.getshopData(data.result.content.auditStoreEntity.id);
        }
      });
      this.Loading = false;
    },
    goPath(path, query) {
      this.$router.push({
        path: path,
        query: query
      });
    },
    openPath(path, query) {
      const { href } = this.$router.resolve({
        path: path,
        query: query
      });
      window.open(href, '_blank');
    }
  }
});
</script>

<style lang="scss" scoped>
::v-deep .el-button {
  margin: 5px 0;
}

.acc-container {
  height: auto;
  min-height: 60px;

  .acc-tip {
    text-align: center;
  }

  .dis-act {
    color: #909399;
    background-color: #eee;
    border-color: #eee;
    cursor: not-allowed;
    margin: 0;

    i {
      font-style: normal;
      color: #f00;
    }
  }

  /deep/ .el-loading-mask {
    top: -8px;
  }
}
</style>
