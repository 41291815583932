<template>
	<div class="selection-box">
		<section class="acc-container">
			<el-divider content-position="left" class="title-divider">
				转账详情
			</el-divider>
			<div class="selection-box">
				<el-form ref="payForm" :model="payForm" :rules="vRules" :label-position="'right'" label-width="120px"
					size="small">
					<div class="mt10 inner-box">
						<el-row :gutter="24">
							<el-col :span="12">
								<el-form-item label="支付机构" prop="paymentType">
									<el-select :disabled="type == 'edit'" style="width: 350px;"
										v-model="payForm.paymentType" placeholder="请选择">
										<el-option v-for="item in options" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="24">
							<el-col :span="12">
								<el-form-item label="入账账户名称" prop="toEnterpriseName">
									<el-select :disabled="type == 'edit'" style="width: 350px;" @change="changeto"
										v-model="payForm.toEnterpriseName" filterable placeholder="请选择">
										<el-option v-for="(item, i) in accountList" :key="i"
											:label="item.enterpriseName" :value="item.enterpriseId">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="入账账户场景类型" prop="toAccountScene">
									<el-select :disabled="type == 'edit'" style="width: 350px;" @change="changetotype"
										v-model="payForm.toAccountScene" placeholder="请选择">
										<el-option v-for="(item, i) in tolist" :key="i" :label="item.accountScene"
											:value="item.accountScene">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="24">
							<el-col :span="12">
								<el-form-item label="入账账号" prop="toWalletId">
									<el-input disabled style="width: 350px;" v-model="payForm.toWalletId"
										maxlength="40" />
								</el-form-item>
							</el-col>

						</el-row>
						<el-row :gutter="24">
							<el-col :span="12">
								<el-form-item label="出账账户名称" prop="fromEnterpriseName">
									<el-select :disabled="type == 'edit'" style="width: 350px;" @change="changefrom"
										v-model="payForm.fromEnterpriseName" filterable placeholder="请选择">
										<el-option v-for="(item, i) in accountList" :key="i"
											:label="item.enterpriseName" :value="item.enterpriseId">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="出账账户场景类型" prop="fromAccountScene">
									<el-select :disabled="type == 'edit'" style="width: 350px;" @change="changefromtype"
										v-model="payForm.fromAccountScene" placeholder="请选择">
										<el-option v-for="(item, i) in fromlist" :key="i" :label="item.accountScene"
											:value="item.accountScene">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>

						</el-row>
						<el-row :gutter="24">
							<el-col :span="12">
								<el-form-item label="出账账号" prop="fromWalletId">
									<el-input disabled style="width: 350px;" v-model="payForm.fromWalletId"
										maxlength="40" />
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="24">
							<el-col :span="24">
								<el-form-item label="出账账号可用余额" prop="availableAmount">
									<el-input :disabled="true" style="width: 350px;" v-model="payForm.availableAmount"
										maxlength="40">
										<template slot="append">元</template></el-input>

								</el-form-item>
							</el-col>

						</el-row>
						<el-row :gutter="24">
							<el-col :span="24">
								<el-form-item label="出账金额" prop="amount">
									<el-input-number :precision="2" :disabled="type == 'edit'" style="width: 350px;"
										v-model="payForm.amount" :step="2"
										 ></el-input-number>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="24">
							<el-col :span="24">
								<el-form-item label="业务说明" prop="transferComment">
									<el-input type="textarea" :disabled="type == 'edit'" style="width: 70.2%;"
										v-model="payForm.transferComment" />
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="24">
							<el-col :span="24">
								<el-form-item label="银行交易附言" prop="transPost">
									<el-input   maxlength="255" show-word-limit type="textarea" :disabled="type == 'edit'" style="width: 70.2%;"
										v-model="payForm.transPost" />
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="24">
							<el-col :span="24">
								<el-form-item label="附件上传">
									<dict-upload-info v-model="payForm.attachId" type="file" :limit="1" type-code="ZZFJ"
										url="/bium-portal/controller/fin-ops/attach/upload" v-if="type == 'add'"
										@getOcrInfo="handleSetOcrInfo" @openUrl="openUrl" />
									<el-button size="small" type="primary" v-else-if="type != 'add' && payForm.attachId"
										@click="getFile(payForm.attachId, payForm.fileSuffix)">点击查看</el-button>
									<span v-else>无</span>
									<el-image ref="imageUrl" style="width: 0px; height: 0px" :src="fileUrl"
										:preview-src-list="[fileUrl]" />
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="24" v-if="type == 'edit'">
							<el-col :span="24">
								<el-form-item label="审批意见">
									<el-input :disabled="routerStatus == null || routerStatus == 0" type="textarea"
										style="width: 70.2%;" v-model="payForm.auditComment" />
								</el-form-item>
							</el-col>
						</el-row>
						<div v-if="routerStatus == null">
							<el-row :gutter="24"
								v-if="type == 'edit' && payForm.transferStatus == 2 && payForm.failureComment">
								<el-col :span="24" style="color: red;">
									转账失败原因：{{ payForm.failureComment }}
								</el-col>
							</el-row>
						</div>
					</div>
					<el-form-item>
						<el-button v-if="type == 'add'" type="primary" size="medium" :loading="loading"
							@click="submit()">提交复核</el-button>
					</el-form-item>
					<el-form-item v-if="routerStatus == 1">
						<el-button type="primary" size="medium" :loading="loading"
							@click="submitExamine(1)">通过</el-button>
						<el-button type="danger" size="medium" :loading="loading"
							@click="submitExamine(0)">拒绝</el-button>
					</el-form-item>
				</el-form>
			</div>
		</section>
	</div>
</template>
<script>
import DictUploadInfo from '@/views/components/DictUploadInfo'
import { doImg } from '../../router/axios'
import { renderAsync } from "docx-preview";
// 校验规则
const checkamount = (rule, value, callback) => {
	if (value <= 0) {
		callback(new Error('转账金额需要大于0'))
	} else {
		callback()
	}
}
export default ({
	name: 'Payment',
	components: { DictUploadInfo },
	data() {
		return {
			rejectTypeList: [
				{
					value: 0,
					label: '无法联系'
				},
				{
					value: 1,
					label: '身份核实失败'
				},
				{
					value: 2,
					label: '店铺信息伪造'
				},
				{
					value: 3,
					label: '严重涉诉'
				},
				{
					value: 4,
					label: '负债过高'
				},
				{
					value: 5,
					label: '历史信用差'
				},
				{
					value: 6,
					label: '其他'
				}
			],
			loading: false,
			type: 'edit',
			options: [{
				value: 1,
				label: 'Ping++'
			}],
			accountList: [],
			dataList: [],
			fromlist: [],
			tolist: [],
			fileUrl: '',
			typeUrl: '',
			payForm: {
				paymentType: 1,
				toEnterpriseName: '',
				toAccountScene: '',
				toWalletId: '',
				fromEnterpriseName: '',
				fromAccountScene: '',
				fromWalletId: '',
				availableAmount: '',
				amount: '',
				transferComment: '',
				transPost:'',
				toAccountId: '',
				fromAccountId: '',
				attachId: [],
				fileSuffix: ''
			},
			vRules: {
				paymentType: [{ required: true, trigger: 'blur', message: '请选择' }],
				toEnterpriseName: [{ required: true, trigger: 'blur', message: '请选择' }],
				toAccountScene: [{ required: true, trigger: 'blur', message: '请选择' }],
				toWalletId: [{ required: true, trigger: 'blur', message: '请选择或输入' }],
				fromEnterpriseName: [{ required: true, trigger: 'blur', message: '请选择' }],
				fromAccountScene: [{ required: true, trigger: 'blur', message: '请选择' }],
				fromWalletId: [{ required: true, trigger: 'blur', message: '请选择或输入' }],
				availableAmount: [{ required: true, trigger: 'blur', message: '请输入' }],
				amount: [{ required: true, trigger: 'blur', message: '请输入' },
				{ validator: checkamount, trigger: 'blur' }],
				transferComment: [{ required: true, trigger: 'blur', message: '请输入' }],
			},
			routerStatus: 0
		}
	},
	created() {
		this.type = this.$route.query.type
		this.$route.query.status ? this.routerStatus = this.$route.query.status : this.routerStatus = null
		this.getAccount()
		if (this.type == 'edit') {
			this.getDetail()
		}
	},
	mounted() { },

	methods: {
		handleSetOcrInfo(e, type) {
			this.payForm.fileSuffix = type
		},
		submitExamine(type) {
			let form = { id: this.payForm.id }
			form.auditComment = this.payForm.auditComment
			if (type == 0) {
				if (!this.payForm.auditComment) return this.$message.error('拒绝审核时，需备注原因')
				form.status = 2
				this.examineApi(form)
			} else {
				form.status = 1
				this.examineApi(form)
			}
		},
		examineApi(form) {
			this.doPost('/bium-portal/controller/fin-ops/audit/transfer/operate', form).then(res => {
				this.$message.success('操作成功')
				setTimeout(() => {
					this.$router.push('/operate/recheck')
				})
			})
		},
		openUrl() {
			this.getFile(this.payForm.attachId[0], this.payForm.fileSuffix)
		},
		getFile(id, type) {
			if(!type){
				return this.$message.error('请稍等~')
			}
			doImg("/bium-portal/controller/fin-ops/attach/viewById", {
				id: id
			}).then(({
				data
			}) => {
				if (data) {
					if (type == 'pdf') {
						const blob = new Blob([data], { type: "application/pdf" });
						window.open(window.URL.createObjectURL(blob), "_blank");
					} else if (type == 'docx') {
						const blob = new Blob([data], { type: "application/pdf" });
						const newWindow = window.open();
						newWindow.document.write(
							"<html><head><title>docx文件</title></head><body>"
						);
						newWindow.document.write('<div id="preview-container"></div>');
						newWindow.document.write("</body></html>");
						newWindow.document.close();
						const previewContainer = newWindow.document.getElementById("preview-container");
						renderAsync(blob, previewContainer);
					} else if (type == 'xlsx') {
						const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8F" });
						let url = window.URL.createObjectURL(blob);
						// 创建一个`<a>标签，通过该标签以下载形式打开链接
						let a = document.createElement('a');
						a.href = url;
						// 设置文件名
						a.download = '附件.xlsx'; document.body.appendChild(a); a.addEventListener('load', function () {
							document.body.removeChild(a);
						})
						a.click();// 执行下载操作
					} else {
						this.fileUrl = window.URL.createObjectURL(data)
						this.$refs.imageUrl.clickHandler()
					}
				}

			});
		},
		getDetail() {
			this.doPost('/bium-portal/controller/fin-ops/audit/transfer/detail', {
				id: this.$route.query.id
			}).then(res => {
				this.payForm = res.data.result

			})
		},
		changetotype(e) {
			let arr = this.tolist.filter(item => { return item.accountScene == e })
			this.$set(this.payForm, 'toWalletId', arr[0].walletId)
			this.$set(this.payForm, 'toAccountId', arr[0].accountId)
		},
		changefromtype(e) {
			let arr = this.fromlist.filter(item => { return item.accountScene == e })
			this.$set(this.payForm, 'fromWalletId', arr[0].walletId)
			this.$set(this.payForm, 'fromAccountId', arr[0].accountId)
			this.getAvailableAmount(arr[0].accountId)
		},
		changefrom(e) {
			this.payForm.fromAccountScene = ''
			this.payForm.fromWalletId = ''
			this.payForm.availableAmount = ''
			this.fromlist = this.dataList.filter(item => { return item.enterpriseId == e })
		},
		changeto(e) {
			this.payForm.toAccountScene = ''
			this.payForm.toWalletId = ''
			this.tolist = this.dataList.filter(item => { return item.enterpriseId == e })
		},
		getAvailableAmount(accountId) {
			this.doPost('/bium-portal/controller/fin-ops/va/getAvailableAmount', { accountId: accountId }).then(res => {
				this.payForm.availableAmount = res.data.result.result
			})
		},
		getAccount() {
			this.doPost('/bium-portal/controller/fin-ops/account/selectAccountEnterprseList').then(res => {
				this.dataList = res.data.result
				this.accountList = res.data.result
				this.findDuplicateElements(JSON.parse(JSON.stringify(res.data.result)))
			})
		},
		findDuplicateElements(arr) {
			let map = new Map()
			arr.forEach(item => {
				map.set(item.enterpriseId, item)
			})
			this.accountList = [...map.values()]
		},
		submit() {
			this.$refs.payForm.validate(valid => {
				if (valid) {
					if (this.payForm.toWalletId == this.payForm.fromWalletId) {
						return this.$message.error('出⼊账账⼾填写错误！')
					}
					if (this.payForm.availableAmount < this.payForm.amount) {
						return this.$message.error('账⼾余额不⾜！')
					}
					let form = {
						paymentType: this.payForm.paymentType,
						availableAmount: this.payForm.availableAmount,
						amount: this.payForm.amount,
						transferComment: this.payForm.transferComment,
						transPost: this.payForm.transPost,
						toAccountId: this.payForm.toAccountId,
						fromAccountId: this.payForm.fromAccountId,
						attachId: this.payForm.attachId && this.payForm.attachId.length > 0 ? this.payForm.attachId[0] : '',
						fileSuffix: this.payForm.fileSuffix,
					}
					this.loading = true;
					this.doPost('/bium-portal/controller/fin-ops/audit/transfer/save', form).then(res => {
						this.loading = false;
						if (res.data.errCode == 1000) {
							this.$message.success('提交成功！')
							this.$router.go(-1)
						}
					}).catch(e => {
						this.loading = false;
					});
				} else {
					return false;
				}
			});
		}
	},
})
</script>

<style scoped lang="scss">
.selection-box {
  overflow-x: scroll;
}
</style>
