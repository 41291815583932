<template>
  <div class="register-wrapper">
    <header-login />
    <div class="register-container">
      <h3>找回密码</h3>
      <el-form ref="registerForm" :model="registerForm" :rules="registerRuels" class="register-form">
        <el-form-item prop="mobile" label="手机号码">
          <el-input v-model="registerForm.mobile" maxlength="11" type="text" auto-complete="off" placeholder="请输入手机号码">
            <i slot="prefix" class="el-icon-user" style="font-size: 16px" />
          </el-input>
        </el-form-item>
        <el-form-item prop="captchaCode" label="验证码">
          <el-input v-model="registerForm.captchaCode" maxlength="6" type="text" auto-complete="off"
            placeholder="请输入验证码">
            <i slot="prefix" class="icon-yanzhengma" />
            <div slot="suffix" class="input-suffix img-vcode">
              <img :src="codeUrl" width="100%" height="100%" @click="getCode">
            </div>
          </el-input>
        </el-form-item>
        <el-form-item prop="smsCode" label="手机验证码">

          <el-input v-model="registerForm.smsCode" type="text" maxlength="6" auto-complete="off" placeholder="手机验证码">
            <i slot="prefix" class="icon-yanzhengma" />
            <div slot="suffix" class="input-suffix phone-code">
              <el-button type="primary" :disabled="pCode.disabled" size="medium"
                style="margin-top: 2px; margin-right:1px" @click="onPhoneCode">
                {{ pCode.text }}
              </el-button>
            </div>
          </el-input>

        </el-form-item>
        <el-form-item prop="password" label="设置新密码">
          <el-input v-model="registerForm.password" maxlength="20" type="password" auto-complete="off"
            placeholder="请设置登录密码">
            <i slot="prefix" class="icon-mima" />
          </el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword" label="确认新密码">
          <el-input v-model="registerForm.confirmPassword" maxlength="20" type="password" auto-complete="off"
            placeholder="请确认登录密码">
            <i slot="prefix" class="icon-mima" />
          </el-input>
        </el-form-item>
        <el-form-item style="margin-bottom: 0px; margin-top: 20px;">
          <el-button :loading="loading" style="width: 100%;" size="lager" type="primary"
            @click.native.prevent="handleRegister">
            <span v-if="!loading">确定</span>
            <span v-else>密码找回 中...</span>
          </el-button>
        </el-form-item>
        <p class="login-tip" />
      </el-form>
    </div>
    <Footer />
  </div>
</template>
<script>
  // import { md5 } from '@/utils/md5'
  import {
    doGet,
    doPost
  } from '@/router/axios';
  import {
    getCodeImg
  } from '@/api/login';
  import HeaderLogin from '@/views/components/HeaderLogin';
  import Footer from '@/views/components/Footer';
  import {
    getEncryCode
  } from '@/utils/jsencrypt.js'
  export default {
    name: 'Register',
    components: {
      HeaderLogin,
      Footer
    },
    data() {
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.registerForm.confirmPassword !== '') {
            this.$refs.registerForm.validateField('confirmPassword');
          }
          callback();
        }
      };
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.registerForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      const validateIsRead = (rule, value, callback) => {
        if (value) {
          callback();
        } else {
          callback(new Error('请阅读并同意遵守以下政策及协议'));
        }
      };
      return {
        codeUrl: '',
        registerForm: {
          mobile: '',
          smsCode: '',
          captchaCode: '',
          password: '',
          confirmPassword: ''
        },
        registerRuels: {
          mobile: [{
            required: true,
            trigger: 'blur',
            message: '手机号不能为空'
          }],
          smsCode: [{
            required: true,
            trigger: 'blur',
            message: '请输入手机验证码'
          }],
          captchaCode: [{
            required: true,
            trigger: 'blur',
            message: '验证码不能为空'
          }],
          password: [{
            required: true,
            trigger: 'blur',
            validator: validatePass
          }],
          confirmPassword: [{
            required: true,
            trigger: 'blur',
            validator: validatePass2
          }]
        },
        loading: false,
        pCode: {
          disabled: false,
          text: '获取验证码'
        }
      };
    },
    created() {
      this.getCode();
    },
    methods: {
      getCode() {
        this.doGet('bium-portal/controller/fin-ops/sys/user/generate_captcha').then(res => {
          this.codeUrl = 'data:image/gif;base64,' + res.data.result.image
          this.registerForm.captchaKey = res.data.result.key
        });
      },
      handleRegister() {
         const that = this
        this.$refs.registerForm.validate(valid => {
          if (valid) {
            this.loading = true;
            const {
              pass,
              reppass,
              ...form
            } = that.registerForm
            form.password = getEncryCode(this.registerForm.password.trim())
            form.confirmPassword = getEncryCode(this.registerForm.confirmPassword.trim())
            doPost('bium-portal/controller/fin-ops/sys/user/find_password', form).then(res => {
              if (res.data.errCode == 1000) {
                this.$notify({
                  title: '修改成功',
                  type: 'success',
                  duration: 2500
                });
                this.loading = false;
                this.$router.push({
                  path: '/login'
                });
                this.$refs['registerForm'].resetFields();
              } else {
                this.getCode();
                this.loading = false;
                this.$notify({
                  title: res.data.msg,
                  type: 'error',
                  duration: 2500
                });
              }
            }).catch(e => {
              this.loading = false;
            });
          } else {
            return false;
          }
        });
      },
      onPhoneCode() {
       const user = {
         mobile: this.registerForm.mobile,
         captchaCode: this.registerForm.captchaCode,
         captchaKey: this.registerForm.captchaKey,
         scene: '1',
       }
       if (user.mobile == null || user.mobile == '') {
         this.$notify.error('请填下手机号码')
         return false
       }
       if (user.captchaCode == null || user.captchaCode == '') {
         this.$notify.error('请填写图形验证码')
         return false
       }
        this.pCode.disabled = true;
        this.pCode.disabled = true
        doPost('bium-portal/controller/fin-ops/sys/user/send_sms_code', user)
          .then((res) => {
            this.loading = false
            if (res.data.errCode == 1000) {
               this.$notify.success('短信发送成功')
              this.setTimer()
            } else {
              this.getCode()
              this.pCode.disabled = false
            }
          })
          .catch((e) => {
            this.getCode()
            this.loading = false
            this.pCode.disabled = false
          })
      },
      setTimer() {
        this.timer && clearInterval(this.timer);
        let count = 119;
        this.timer = setInterval(() => {
          count--;
          this.pCode.text = count + 's';
          if (count === 0) {
            this.pCode.text = '获取验证码';
            this.pCode.disabled = false;
            clearInterval(this.timer);
          }
        }, 1000);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .register-wrapper {
    height: 100%;
    overflow: auto;
    padding-top: 30px;
    padding-bottom: 90px;
    // background:url("~@/assets/login_images/img-login_1.jpg") center no-repeat;
    /*background-size: cover;*/
    background: #f7f7fa;
  }

  .register-container {
    width: 500px;
    margin: 0 auto 30px;
    padding: 40px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, .07), 0 0 8px 0 rgba(0, 0, 0, .05);
    border-radius: 5px;

    /deep/ .el-form-item__label {
      // display: none;
    }

    /deep/ .el-form-item {
      margin-bottom: 10px;
    }

    /deep/ .el-input__icon {
      display: inline-block;
      width: 36px;
      height: 100%;

      &.el-icon-circle-close {
        display: none;
      }
    }

    /deep/ .el-input__inner {
      height: 44px;
      padding-left: 48px;

      &:focus {
        border-color: #f39700;
      }
    }

    .input-vcode {
      .el-input__inner {
        padding-right: 110px;
      }

      .el-input__suffix .el-input__icon {
        display: none;
      }
    }

    .text-box {
      text-align: center;

      .line {
        margin: 0 5px;
        color: #999;
      }
    }

    .checkbox-item {
      /deep/ .el-form-item__content {
        margin: 15px 0 8px;
        line-height: 20px;
      }
    }

    .input-suffix {
      padding-top: 2px;
      margin-right: -3px;

      &.img-vcode {
        padding-top: 4px;
      }
    }

    /deep/ .el-input__prefix {
      left: 10px;
      padding-top: 8px
    }
  }
</style>
