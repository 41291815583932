<template>
  <div class="product-financing-plan">
    <basic-container>
      <avue-crud ref="crud" :table-loading="isLoading" :search.sync="form" :page.sync="page" :option="option"
        :data="dataList"  @size-change="sizeChange" @current-change="currentChange"
        @search-change="handleFilter" @search-reset="resetFilter" @refresh-change="handleRefreshChange"
        >

        <!-- 列表左上角操作按钮 -->
        <template #menuLeft>
          <el-button icon="el-icon-download" type="primary" :loading="downLoading" @click="toConfigStandardPlan(form)">
            下载银行对账单
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import { PAGE } from '@/const/global.js'
import { doExportPostExcel, downExcel } from '@/router/axios'
import {exportExcel} from '@/utils/util.js'
export default {
  name: 'ProductFinancingPlan',
  props: {},
  data() {
    return {
      form: {},
      isLoading: false,
      searchItmes: {},
      dataList: [],
      downLoading: false,
      option: {
        // 搜索栏配置
        searchMenuSpan: 8,
        searchMenuPosition: 'left',
        // 表格主体配置
        border: false,
        index: false,
        indexLabel: '序号',
        stripe: true,
        headeralign: 'left',
        align: 'right',
        // 表格操作列配置
        menu: false,
        menuWidth: 150,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        searchShowBtn: false,
        column: [
          {
            label: '银行户名',
            prop: 'accountName',
            width: 280,
            align: 'left',
            hide: true,
            search: true,
            searchSpan:6,
          },
          {
            label: '银行账号',
            prop: 'accountId',
            width: 280,
            align: 'left',
            hide: true,
            search: true,
            searchSpan:6,
          },
          {
            label: '交易日期',
            prop: 'paymentDate',
            type: 'date',
            hide: true,
            searchRange: true,
            valueFormat: 'yyyy-MM-dd',
            searchValue: [],
            search: true,
            searchSpan: 6,
            span: 12,
            width: 150,
          },

          {
            label: '交易日期',
            prop: 'createdAt',
            align: 'left',
          },
          {
            label: '交易类型',
            prop: 'transType',
            align: 'left',
          },
          {
            label: '交易金额',
            prop: 'amount',
            align: 'left',
          },
          {
            label: '收支方向',
            prop: 'direct',
            align: 'left',
          },
          {
            label: '交易后余额',
            prop: 'availableBalance',
            align: 'left',
          },
          {
            label: '银行流水号',
            prop: 'source',
            align: 'left',
          },
          {
            label: '对方账户',
            prop: 'counterpartyAcctId',
            align: 'left',
          },
          {
            label: '对方户名',
            prop: 'counterpartyAcctName',
            align: 'left',
          },
          {
            label: '备注',
            prop: 'remark',
            align: 'left',
          },
        ],
      },
      page: PAGE,
      downLoad: {},
      newData:{},
      transType:{
        PAYMENT:'转账',
        WITHDRAW:'提现',
        DEPOSIT:'充值',
      }
    }
  },
  watch: {},
  mounted() {},
  methods: {
    getNow() {
				const now = new Date();
				return this.formatDate(now);
			},
			getLastMonth() {
				const now = new Date();
				const lastMonth = new Date(now.setMonth(now.getMonth() - 1));
				return this.formatDate(lastMonth);
			},
			formatDate(date) {
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const day = date.getDate();
				return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
			},
    /** 获取列表数据 */
    getList(page, params) {
      if(!params.accountName&&!params.accountId) return this.$message.error('请输入银行户名或银行账号')
      if (params && params.paymentDate) {
        params.startTime = `${params.paymentDate[0]} 00:00:00`
        params.endTime = `${params.paymentDate[1]} 23:59:59`
        delete params.paymentDate
      }
      const api = '/bium-portal/controller/fin-ops/va/detail/listPage'
      params = params
        ? params
        : {
            ...this.searchItmes,
          }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize

      this.isLoading = true
      this.downLoad =this.newData = params
      this.doPost(api, params).then(({ data }) => {
        if (data.result.records) {
          const list = data.result.records
          list.forEach(item => {
            item.direct = item.direct==1?'收入':'支出'
          })
          this.dataList = data.result.records || []
          this.page.total = data.result.total || 0
        }
        this.isLoading = false
      })
    },

    /** 分页变化，存储 */
    sizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList(this.page,this.newData)
    },
    /** 当前页码变化，存储 */
    currentChange(current) {
      this.page.currentPage = current
      this.getList(this.page,this.newData)
    },
    /** 点击搜索栏搜索 */
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {},
      this.getList(this.page, param)
      done()
    },
    /** 重置条件搜索 */
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    /** 手动刷新列表 */
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },
    /** 导出 */
    toConfigStandardPlan( params) {
      if(this.page.total == 0) return this.$message.error('暂无可导出的数据')
      if (params && params.paymentDate && params.paymentDate.length) {
        params.startTime = `${params.paymentDate[0]} 00:00:00`
        params.endTime = `${params.paymentDate[1]} 23:59:59`
      }else{
        params.startTime = ''
        params.endTime = ''
      }
      const api = '/bium-portal/controller/fin-ops/va/detail/exportList'
      this.doPost(api, params).then(({ data }) => {
        if (data.result) {
          const list = data.result
          list.forEach(item => {
            item.direct = item.direct==1?'收入':'支出'
            item.transType =this.transType[item.transType]
          })
          const reg = new RegExp("-","g");
          const fields =  ["accountName", "requestId", "source", "accountId", "direct", "amount", "availableBalance", "transType", "counterpartyAcctId", "counterpartyAcctName", "postedAt", "remark"];
          const headers = ["账户名称", "平台流水编号", "机构流水编号", "账号", "收支方向", "交易金额", "交易后金额", "交易类型", "交易对手账号", "交易对手户名", "交易完成时间", "备注"];
          exportExcel(list, fields, headers, `${params.paymentDate[0]?params.paymentDate[0].replace(reg,''):''}${params.paymentDate[0]?'-':''}${params.paymentDate[1]?params.paymentDate[1].replace(reg,''):''}银行对账单${params.accountId?'-'+params.accountId:''}.xlsx`);
        }
      })

    },
    removeEmptyValues(obj) {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
      return obj;
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-col:nth-last-child(2) {
  .el-form-item__label {
    width: fit-content !important;
  }

  .el-form-item__content {
    margin-left: 100px !important;
  }
}
</style>
