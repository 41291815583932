<template>
  <div class="product-financing-plan">
    <basic-container>
      <avue-crud ref="crud" :table-loading="isLoading" :search.sync="form" :page.sync="page" :option="option"
        :data="dataList" @on-load="getList" @size-change="sizeChange" @current-change="currentChange"
        @search-change="handleFilter" @search-reset="resetFilter" @refresh-change="handleRefreshChange"
        >

        <!-- 列表左上角操作按钮 -->
        <template #menuLeft>
          <el-button icon="el-icon-download" type="primary" :loading="downLoading" @click="toConfigStandardPlan(form)">
            导出
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import { PAGE } from '@/const/global.js'
import { doExportPostExcel, downExcel } from '@/router/axios'

export default {
  name: 'ProductFinancingPlan',
  props: {},
  data() {
    return {
      form: {},
      isLoading: true,
      searchItmes: {},
      dataList: [],
      downLoading: false,
      option: {
        // 搜索栏配置
        searchMenuSpan: 8,
        searchMenuPosition: 'left',
        // 表格主体配置
        border: false,
        index: false,
        indexLabel: '序号',
        stripe: true,
        headeralign: 'left',
        align: 'right',
        // 表格操作列配置
        menu: false,
        menuWidth: 150,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        searchShowBtn: false,
        column: [
          {
            label: '产品类型',
            prop: 'productType',
            type: 'select',
            search: true,
            hide: true,
            dicData: [
              { label: '2A-极速付款', value: '1' },
              { label: '1A-极速收款', value: '0' },
            ],
          },
          {
            label: '企业名称',
            prop: 'enterpriseName',
            width: 280,
            align: 'left',
            hide: true,
            search: true,
            searchSpan:6,
          },
          {
            label: '店铺名称',
            prop: 'storeName',
            align: 'left',
            hide: true,
            minWidth: 280,
            search: true,
            searchSpan: 6,
          },
          {
            label: '融资单号',
            prop: 'applyNo',
            align: 'left',
            hide: true,
            minWidth: 280,
            search: true,
            searchSpan: 6,
          },
          {
            label: '放款日期',
            prop: 'paymentDate',
            type: 'date',
            hide: true,
            searchRange: true,
            valueFormat: 'yyyy-MM-dd',
            searchValue: [this.getLastMonth(), this.getNow()],
            search: true,
            searchSpan: 6,
            span: 12,
            width: 150,
          },
          {
            label: '结清日期',
            prop: 'settlementDate',
            type: 'date',
            hide: true,
            searchRange: true,
            valueFormat: 'yyyy-MM-dd',
            searchValue: [],
            search: true,
            searchSpan: 6,
            span: 12,
            width: 150,
          },
          {
            label: '资方账号',
            prop: 'accountNo',
            align: 'left',
            minWidth: 280,
            hide: true,
            search: true,
            searchSpan: 6,
          },
          {
            label: '资金方方案名称',
            prop: 'programme',
            align: 'left',
            minWidth: 280,
            hide: true,
            search: true,
            searchSpan: 6,
          },

          {
            label: '产品类型',
            prop: 'productTypeDesc',
            align: 'left',
            width: 120,
          },
          {
            label: '企业名称',
            prop: 'enterpriseName',
            align: 'left',
            width: 250,
          },
          {
            label: '店铺名称',
            prop: 'storeName',
            align: 'left',
            width: 150,
          },
          {
            label: '融资单号',
            prop: 'applyNo',
            align: 'left',
            width: 250,
          },
          {
            label: '计息方式',
            prop: 'billingMethod',
            align: 'left',
            width: 120,
          },
          {
            label: '费率',
            prop: 'customerRate',
            align: 'left',
            width: 120,
          },
          {
            label: '融资金额',
            prop: 'financingAmount',
            align: 'left',
            width: 120,
          },
          {
            label: '应收利息',
            prop: 'interestReceivable',
            align: 'left',
            width: 120,
          },
          {
            label: '应收服务费',
            prop: 'serviceChargesReceivable',
            align: 'left',
            width: 120,
          },
          {
            label: '待还本金',
            prop: 'pendingPrincipal',
            align: 'left',
            width: 120,
          },
          {
            label: '待还利息',
            prop: 'pendingInterest',
            align: 'left',
            width: 120,
          },
          {
            label: '待还服务费',
            prop: 'pendingServiceFee',
            align: 'left',
            width: 120,
          },
          {
            label: '已结算渠道费',
            prop: 'fee',
            align: 'left',
            width: 120,
          },
          {
            label: '放款日期',
            prop: 'paymentDate',
            align: 'left',
            width: 160,
          },
          {
            label: '结清日期',
            prop: 'settlementDate',
            align: 'left',
            width: 160,
          },
          {
            label: '到期日期',
            prop: 'expiredDate',
            align: 'left',
            width: 120,
          },
          {
            label: '融资状态',
            prop: 'statusDesc',
            align: 'left',
            width: 120,
          },
          {
            label: '资金方方案名称',
            prop: 'accountName',
            align: 'left',
            width: 120,
          },
          {
            label: '资方账号',
            prop: 'accountNo',
            align: 'left',
            width: 200,
          },
        ],
      },
      page: PAGE,
      downLoad: {},
    }
  },
  watch: {},
  mounted() {},
  methods: {
    getNow() {
				const now = new Date();
				return this.formatDate(now);
			},
			getLastMonth() {
				const now = new Date();
				const lastMonth = new Date(now.setMonth(now.getMonth() - 1));
				return this.formatDate(lastMonth);
			},
			formatDate(date) {
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const day = date.getDate();
				return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
			},
    /** 获取列表数据 */
    getList(page, params) {
      if (params && params.paymentDate) {
        params.paymentDateStart = params.paymentDate[0]
        params.paymentDateEnd = params.paymentDate[1]
        delete params.paymentDate
      }
      if (params && params.settlementDate) {
        params.settlementDateStart = params.settlementDate[0]
        params.settlementDateEnd = params.settlementDate[1]
        delete params.settlementDate
      }
      const api = '/bium-portal/controller/fin-ops/clearing/selectByFinancingBill'
      params = params
        ? params
        : {
            ...this.searchItmes,
          }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize
      if(this.form.paymentDate == undefined){
					params.paymentDateStart =this.getLastMonth()
					params.paymentDateEnd = this.getNow()
			}else{
          params.paymentDateStart = this.form.paymentDate[0]
          params.paymentDateEnd = this.form.paymentDate[1]
      }
      this.isLoading = true
      this.downLoad = params
      this.doPost(api, params).then(({ data }) => {
        if (data.result.records) {
          this.dataList = data.result.records || []
          this.page.total = data.result.total || 0
        }
        this.isLoading = false
      })
    },

    /** 分页变化，存储 */
    sizeChange(pageSize) {
      this.page.pageSize = pageSize
    },
    /** 当前页码变化，存储 */
    currentChange(current) {
      this.page.currentPage = current
    },
    /** 点击搜索栏搜索 */
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {},
      this.getList(this.page, param)
      done()
    },
    /** 重置条件搜索 */
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    /** 手动刷新列表 */
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },
    /** 导出 */
    toConfigStandardPlan( form) {
      if(this.page.total == 0) return this.$message.error('暂无可导出的数据')
      let that = this
      const data = {
        productType: form.productType || null, //产品类型
        enterpriseName: form.enterpriseName || null, //企业名称
        storeName: form.storeName || null, //店铺名称
        applyNo: form.applyNo || null, //融资单号
        accountNo: form.accountNo || null, //帐号
        paymentDateStart:form.paymentDate[0] || null, //放款起始日， 格式 yyyy-MM-dd
        paymentDateEnd: form.paymentDate[1] || null, //放款结束日， 格式 yyyy-MM-dd
        settlementDateStart: form.settlementDate[0] || null,
        settlementDateEnd: form.settlementDate[1] || null,
        programme: form.programme || null, //资金方方案名称
        pageSize: 1000, //每页数量
        pageNum: 1, //当前页
      }
      this.listLoading = this.downLoading = true
        const api = '/bium-portal/controller/fin-ops/clearing/downloadFinancingBill'
        let newForm = this.removeEmptyValues(data);
        doExportPostExcel(api, newForm)
          .then((res) => {
            let reader = new FileReader()
            reader.onload = function (event) {
              if (event.target.result === '') {
                that.$message.error('导出数据为空或超过最大1000条限制')
                that.listLoading = that.downLoading = false
              } else {
                downExcel(res)
                that.listLoading = that.downLoading = false
              }
            }
            reader.readAsText(res.data)
          })
          .catch(() => {
            this.listLoading = this.downLoading = false
          })
    },
    removeEmptyValues(obj) {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
      return obj;
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-col:nth-last-child(2) {
  .el-form-item__label {
    width: fit-content !important;
  }

  .el-form-item__content {
    margin-left: 100px !important;
  }
}
</style>
