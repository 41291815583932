<template>
  <div class="user">
    <basic-container class="full-condition">
      <avue-crud
        ref="crud"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange">
        <template slot="menuRight">
          <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="handleSave()">添加新渠道</el-button>
        </template>
        <template slot="status" slot-scope="scope">
          <el-select
            v-if="scope.row.isEnterpriseAuth"
            v-model="scope.row.status"
            @change="submitStatus($event, scope.row)">
            <el-option v-for="item in statusList" :key="item.value" :value="item.value" :label="item.label" />
          </el-select>
          <el-tag v-else effect="dark" :type="scope.row.status == 1 ? 'success' : 'info'">
            <span v-if="scope.row.status == 1">启用</span>
            <span v-else-if="scope.row.status == 0">停用</span>
          </el-tag>
        </template>
        <template slot="menu" slot-scope="scope">
          <el-button type="text" v-if="scope.row.isEnterpriseAuth" @click="chanIncome(scope.row, scope.index)">
            详情
          </el-button>
          <el-button v-if="scope.row.isEnterpriseAuth" @click="showModal(scope.row, 'edit')" type="text">
            渠道费率
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
    <el-dialog
      :visible.sync="dialogVisible"
      title="收益流水明细"
      width="98%"
      custom-class="pub_dialog"
      @close="closeDialog">
      <channel-income v-if="chanId" :chan-id="chanId" />
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible2"
      title="资金流水明细"
      width="98%"
      custom-class="pub_dialog"
      @close="closeDialog">
      <account-flow v-if="accountId" :account-id="accountId" />
    </el-dialog>
    <el-dialog
      title="新增渠道"
      class="dialog-standard channel-config-rate"
      width="1000px"
      top="100px"
      :visible.sync="isvisible"
      :before-close="refreshTable">
      <channelAdd v-if="isvisible" @refreshTable="refreshTable" />
    </el-dialog>
  </div>
</template>

<script>
import channelIncome from './channelIncome'
import channelAdd from './components/channelAdd'
import accountFlow from '@/views/cap/accountFlow.vue'
// import ChannelConfigRate from './components/config-rate.vue'
import Qrcode from '@/components/qr-code/index.vue'
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchMenuPosition: 'center',
  searchIndex: 3,
  searchIcon: false,
  searchShowBtn: false,
  border: false,
  sumColumnList: [],
  printBtn: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 150,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
  searchShowBtn: false,
  column: [
    {
      fixed: true,
      label: 'id',
      prop: 'id',
      span: 24,
      hide: true,
    },
    {
      label: '渠道名称',
      prop: 'name',
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      minWidth: 150,
      align: 'left',
      span: 12,
    },
    {
      label: '渠道等级',
      prop: 'level',
      type: 'select',
      dicData: [
        {
          label: '一级',
          value: 1,
        },
        {
          label: '二级',
          value: 2,
        },
        {
          label: '三级',
          value: 3,
        },
      ],
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 8,
      span: 12,
    },
    {
      label: '企业数',
      prop: 'enterpriseNum',
      minWidth: 150,
      span: 12,
    },
    {
      label: '今日放款额',
      prop: 'todayLoanAmount',
      minWidth: 120,
      span: 12,
    },
    {
      label: '近30日放款额',
      prop: 'thirtyLoanAmount',
      minWidth: 120,
      span: 12,
    },
    {
      label: '逾期金额',
      prop: 'overdueLoanAmount',
      span: 12,
    },
    {
      label: '渠道状态',
      prop: 'status',
      width: 100,
    },
    {
      label: '注册时间',
      prop: 'created',
      minWidth: 200,
    },
  ],
}

export default {
  name: 'TableUser',
  components: {
    accountFlow,
    channelIncome,
    Qrcode,
    // ChannelConfigRate,
    channelAdd,
  },
  data() {
    return {
      statusList: [
        {
          label: '启用',
          value: 1,
        },
        {
          label: '停用',
          value: 0,
        },
      ],
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1,
        pageSize: 10,
      },
      isvisible: false,
      list: [],
      listLoading: true,
      chanId: '',
      accountId: '',
      dialogVisible: false,
      dialogVisible2: false,
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    //提交状态修改
    submitStatus(status, row) {
      let form = {
        channelId: row.id,
        status: status,
      }
      this.doPost('bium-portal/controller/fin-ops/channel/changeStatus', form)
        .then(({ data }) => {
          if (data) {
            this.$notify.success('配置成功。')
            this.getList(this.page)
          }
        })
        .catch(() => {
          this.getList(this.page)
        })
    },
    refreshTable(data) {
      this.isvisible = false
      this.page.currentPage = 1
      this.getList(this.page)
    },
    /** 显示配置弹窗 */
    showModal(item, type) {
      const comp = () => import('./components/config-rate.vue')
      this.$modalDialog(comp, {
        modalType: type,
        modalData: item,
      }).then(() => {
        this.getList(this.page)
      })
    },
    handleSave() {
      this.isvisible = true
    },
    getList(page, params) {
      params = params
        ? params
        : {
            ...this.searchItmes,
          }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize
      params.nameLike = params.name
      this.listLoading = true
      this.doPost('/bium-portal/controller/fin-ops/channel/page', params).then(({ data }) => {
        if (data) {
          this.list = data.result.records || []
          this.page.total = data.result.total || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },

    chanIncome(row) {
      // this.chanId = row.id;
      // this.dialogVisible = true;
      this.$router.push('/operate/channelDetail?id=' + row.id)
    },
    chanFlow(row) {
      this.accountId = row.accId
      this.dialogVisible2 = true
    },
    approveEnter(row, index) {
      this.$router.push({
        path: `/chan/approve?channelId=${row.id}`,
      })
    },
    closeDialog() {
      this.chanId = ''
      this.accountId = ''
      this.dialogVisible = false
      this.dialogVisible2 = false
    },
  },
}
</script>

<style lang="scss" scoped>
.qr-code {
  width: 130px;
  padding-top: 5px;
  text-align: center;
}
</style>
<style>
.el-popover.qrcode {
  min-width: 130px;
}
</style>
