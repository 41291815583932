<template>
  <div class="product-financing-plan">
    <basic-container>
      <avue-crud
        ref="crud"
        :table-loading="isLoading"
        :search.sync="form"
        :page.sync="page"
        :option="option"
        :data="dataList"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange">
        <template slot="qrCode" slot-scope="scope">
          <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.qrCode"
            :preview-src-list="[scope.row.qrCode]">
            <div slot="error" class="image-slot">
      </div>
    </el-image>
        </template>
        <template slot="applicant" slot-scope="scope">
          <el-input
            :disabled="scope.row.disabled"
            style="width: 150px; margin-right: 10px"
            size="mini"
            v-model="scope.row.applicant"
            placeholder="请输入内容"></el-input>
          <el-button
            v-if="scope.row.disabled"
            size="mini"
            @click="btnChange(scope.row)"
            type="primary"
            icon="el-icon-edit"
            circle></el-button>
          <el-button
            v-if="!scope.row.disabled"
            size="mini"
            @click="btnSend(scope.row)"
            type="primary"
            icon="el-icon-check"
            circle></el-button>
          <el-button v-if="!scope.row.disabled" @click="btnClose(scope.row)" icon="el-icon-close" circle></el-button>
        </template>
        <!-- 列表左上角操作按钮 -->
        <template #menuLeft>
          <el-button type="primary" :loading="downLoading" @click="toConfigStandardPlan(form)">点击获取云号</el-button>
        </template>
        <template slot="menuRight">
					<div style="margin-right: 10px;font-weight: bold;display: inline-block;">当前未使用号码：{{ dataList.length? dataList[0].totalcount : 0 }}个</div>
				</template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import { PAGE } from '@/const/global.js'
import { doExportPostExcel, downExcel } from '@/router/axios'
import { exportExcel } from '@/utils/util.js'
export default {
  name: 'ProductFinancingPlan',
  props: {},
  data() {
    return {
      form: {},
      isLoading: true,
      searchItmes: {},
      dataList: [],
      downLoading: false,
      option: {
        // 搜索栏配置
        searchMenuSpan: 8,
        searchMenuPosition: 'left',
        // 表格主体配置
        border: false,
        index: false,
        indexLabel: '序号',
        stripe: true,
        headeralign: 'left',
        align: 'right',
        // 表格操作列配置
        menu: false,
        menuWidth: 150,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        searchShowBtn: false,
        column: [
          {
            label: '门店名称',
            prop: 'storeName',
            width: 280,
            align: 'left',
            hide: true,
            search: true,
            searchSpan: 6,
          },
          {
            label: '手机号',
            prop: 'mobile',
            width: 280,
            align: 'left',
            hide: true,
            search: true,
            searchSpan: 6,
          },

          {
            label: '号码',
            prop: 'mobile',
            align: 'left',
          },
          {
            label: '创建时间',
            prop: 'created',
            align: 'left',
          },
          {
            label: '审核状态',
            prop: 'status',
            align: 'left',
          },
          {
            label: '二维码',
            prop: 'qrCode',
            align: 'left',
          },
          {
            label: '绑定店铺id/所属平台',
            prop: 'storePlat',
            align: 'left',
          },
          {
            label: '号码申请人',
            prop: 'applicant',
            align: 'left',
            width: 260,
          },
        ],
      },
      page: PAGE,
      downLoad: {},
    }
  },
  watch: {},
  mounted() {},
  methods: {
    btnChange(e) {
      this.dataList[e.$index].title = e.applicant
      this.dataList[e.$index].disabled = false
    },
    btnClose(e) {
      this.dataList[e.$index].applicant = e.title
      this.dataList[e.$index].disabled = true
    },
    btnSend(e) {
      const api = '/bium-portal/controller/fin-ops/store/cloud/saveApplicant'
      this.doPost(api, {
        isBound: e.isBound,
        mobile: e.mobile,
        applicant: e.applicant
      }).then(({ data }) => {
        if(data.success){
          this.handleRefreshChange()
        }else{
          this.dataList[e.$index].applicant = e.title
          this.dataList[e.$index].disabled = true
        }
      }).catch(()=>{
        this.dataList[e.$index].applicant = e.title
      this.dataList[e.$index].disabled = true
      })
      
    },
    getNow() {
      const now = new Date()
      return this.formatDate(now)
    },
    getLastMonth() {
      const now = new Date()
      const lastMonth = new Date(now.setMonth(now.getMonth() - 1))
      return this.formatDate(lastMonth)
    },
    formatDate(date) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    },
    /** 获取列表数据 */
    getList(page, params) {
      const api = '/bium-portal/controller/fin-ops/store/cloud/page'
      params = params
        ? params
        : {
            ...this.searchItmes,
          }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize

      this.isLoading = true
      this.downLoad = params
      this.doPost(api, params).then(({ data }) => {
        if (data.result.records) {
          const list = data.result.records
          list.forEach((item) => {
            item.disabled = true
            item.status =
              item.status == 0
                ? '待激活'
                : item.status == 1
                ? '正常使用'
                : item.status == 2
                ? '欠费停机'
                : item.status == 3
                ? '销户离网'
                : item.status == 5
                ? '待审核'
                : ''
          })
          this.dataList = data.result.records || []
          this.page.total = data.result.total || 0
        }
        this.isLoading = false
      })
    },

    /** 分页变化，存储 */
    sizeChange(pageSize) {
      this.page.pageSize = pageSize
    },
    /** 当前页码变化，存储 */
    currentChange(current) {
      this.page.currentPage = current
    },
    /** 点击搜索栏搜索 */
    handleFilter(param, done) {
      this.page.currentPage = 1
      ;(this.searchItmes = param ? param : {}), this.getList(this.page, param)
      done()
    },
    /** 重置条件搜索 */
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    /** 手动刷新列表 */
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },
    /** 导出 */
    toConfigStandardPlan(params) {
      const api = '/bium-portal/controller/fin-ops/store/cloud/create'
      this.doGet(api).then(({ data }) => {
        if (data.success) {
         this.handleRefreshChange()
        }
      })
    },
    removeEmptyValues(obj) {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
          delete obj[key]
        } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
          delete obj[key]
        }
      }
      return obj
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-col:nth-last-child(2) {
  .el-form-item__label {
    width: fit-content !important;
  }

  .el-form-item__content {
    margin-left: 100px !important;
  }
}
</style>
