var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "record-box" }, [
    _c(
      "section",
      { staticClass: "acc-container" },
      [
        _vm._l(_vm.dataList, function (item, index) {
          return _c("div", { key: index, staticClass: "record-card mb20" }, [
            _c("div", { staticClass: "record-header flex-c-s" }, [
              _c("div", [_vm._v("案例记录时间：" + _vm._s(item.created))]),
              _c("div", [_vm._v("操作人：" + _vm._s(item.userName))]),
            ]),
            _c("div", { staticClass: "record-content" }, [
              _vm._v(
                "\n                " +
                  _vm._s(item.comment) +
                  "\n                "
              ),
            ]),
          ])
        }),
        _vm.dataList.length == 0
          ? _c("div", { staticClass: "dataValue" }, [
              _vm._v("\n            暂无案例记录\n        "),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }