import dayjs from 'dayjs';
export const calcDate = (date1, date2) => {
  var date3 = date2 - date1

  var days = Math.floor(date3 / (24 * 3600 * 1000))

  var leave1 = date3 % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000))

  var leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000))

  var leave3 = leave2 % (60 * 1000) // 计算分钟数后剩余的毫秒数
  var seconds = Math.round(date3 / 1000)
  return {
    leave1,
    leave2,
    leave3,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }
}

/**
 * 日期格式化
 */
export function dateFormat(date) {
  let format = 'yyyy-MM-dd HH:mm:ss';
  if (date != 'Invalid Date') {
    var o = {
      "M+": date.getMonth() + 1, //month
      "d+": date.getDate(), //day
      "h+": date.getHours(), //hour
      "m+": date.getMinutes(), //minute
      "s+": date.getSeconds(), //second
      "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
      "S": date.getMilliseconds() //millisecond
    }
    if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
      if (new RegExp("(" + k + ")").test(format))
        format = format.replace(RegExp.$1,
          RegExp.$1.length == 1 ? o[k] :
            ("00" + o[k]).substr(("" + o[k]).length));
    return format;
  }
  return '';

}

/**
 * 比较日期字符串，返回最大值，或最小值
 * */
export const compareDateStr = (dateStrArr, method = 'min') => {
  if(method === 'min') {
    dateStrArr.sort()
    return dateStrArr[0]
  }
  if(method === 'max') {
    dateStrArr.sort()
    return dateStrArr[dateStrArr.length-1]
  }
}

/**
 * 获取当前日期加上指定天数之后的日期
 * @param {String} time     日期格式为2021-04-10
 * @param {Number} days     指定天数
 * @result {String}         日期格式为2021-04-10
 */
export const getNewData = (time, days=0) => {

  let date = new Date(time);
  date.setDate(date.getDate() + days*1);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  return date.getFullYear() + '-' + ('0'+ month).slice(-2) + '-' + ('0'+ day).slice(-2);
}

// 返回前一天日期
export function getYesterday(val = -1) {
  return dayjs().add(val, 'day').format('YYYY-MM-DD');
}

/**
 * 从yyyy年MM月dd日 转换为 yyyy-MM-dd
 * @param val
 */
export function setDateFormat(val) {
  var reg = /(\d{4})\年(\d{2})\月(\d{2})\日/;
  var date = val.replace(reg, '$1-$2-$3');
  return date;
}
