<template>
    <div>
        <el-form ref="EForm" :inline="true" :label-position="'right'" class="pl30 pr30" label-width="160px"
            size="small">
            <div class="mt20 inner-box">
                <el-divider content-position="left" class="title-divider">基础信息
                </el-divider>
                <div class="flex">
                    <el-form-item label="法人婚姻状态：" prop="legalMarryStatus">

                        <el-select placeholder="请选择" v-model="basicInfo.legalMarryStatus" style="width: 140px;">
                            <el-option v-for="(item, index) in Marital" :key="index" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="房产状态：" prop="estateStatus">
                        <el-select placeholder="请选择" v-model="basicInfo.estateStatus" style="width: 140px;">
                            <el-option v-for="(item, index) in Property" :key="index" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="近3月查询次数：" prop="searchCnt3m">
                        <el-input placeholder="请输入" v-model="basicInfo.searchCnt3m" style="width: 140px;">

                        </el-input>
                    </el-form-item>
                </div>
                <el-divider content-position="left" class="title-divider">贷记卡信息
                </el-divider>
                <div class="flex">
                    <el-form-item label="贷记卡逾期账户数：" prop="debitCardDueAccountCnt">
                        <el-input placeholder="请输入" v-model="debitCardInfo.debitCardDueAccountCnt"
                            style="width: 140px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="贷记卡当前逾期最长期数：" prop="debitCardDueMaxPeriod">
                        <el-input placeholder="请输入" v-model="debitCardInfo.debitCardDueMaxPeriod" style="width: 140px;">

                        </el-input>
                    </el-form-item>
                    <el-form-item label="贷记卡当前逾期总金额：" prop="debitCardDueAmountTotal">
                        <el-input placeholder="请输入" v-model="debitCardInfo.debitCardDueAmountTotal"
                            style="width: 140px;">

                        </el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="贷记卡近6月逾期数：" prop="debitCardDueCnt6m">
                        <el-input placeholder="请输入" v-model="debitCardInfo.debitCardDueCnt6m" style="width: 140px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="贷记卡近12月最高逾期数：" prop="debitCardDueTopCnt12m">
                        <el-input placeholder="请输入" v-model="debitCardInfo.debitCardDueTopCnt12m" style="width: 140px;">

                        </el-input>
                    </el-form-item>
                    <el-form-item label="贷记卡呆账笔数：" prop="debitCardDueBadCnt">
                        <el-input placeholder="请输入" v-model="debitCardInfo.debitCardDueBadCnt" style="width: 140px;">

                        </el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="贷记卡五级分类：" prop="debitCardCate5l">
                        <el-select placeholder="请选择" v-model="debitCardInfo.debitCardCate5l" style="width: 140px;">
                            <el-option v-for="(item, index) in credit" :key="index" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="贷记卡最长逾期期数次数：" prop="debitCardDueLongCnt">
                        <el-input placeholder="请输入" v-model="debitCardInfo.debitCardDueLongCnt" style="width: 140px;">

                        </el-input>
                    </el-form-item>
                    <el-form-item label="贷记卡特殊状态：" prop="debitCardSpecStatus">
                        <el-select placeholder="请选择" v-model="debitCardInfo.debitCardSpecStatus" style="width: 140px;">
                            <el-option v-for="(item, index) in creditSpecial" :key="index" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <el-divider content-position="left" class="title-divider">贷款信息
                </el-divider>
                <div class="flex">
                    <el-form-item label="贷款五级分类：" prop="debitCardSpecStatus">
                        <el-select placeholder="请选择" v-model="loanInfo.loanCate5l" style="width: 140px;">
                            <el-option v-for="(item, index) in credit" :key="index" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="贷款当前逾期账户数：" prop="loanDueAccountCnt">
                        <el-input placeholder="请输入" v-model="loanInfo.loanDueAccountCnt" style="width: 140px;">

                        </el-input>
                    </el-form-item>
                    <el-form-item label="贷款当前最长逾期期数：" prop="loanDueMaxPeriod">
                        <el-input placeholder="请输入" v-model="loanInfo.loanDueMaxPeriod" style="width: 140px;">

                        </el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="贷款当前逾期总金额：" prop="loanDueAmountTotal">
                        <el-input placeholder="请输入" v-model="loanInfo.loanDueAmountTotal" style="width: 140px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="贷款近6月逾期次数：" prop="loanDueCnt6m">
                        <el-input placeholder="请输入" v-model="loanInfo.loanDueCnt6m" style="width: 140px;">

                        </el-input>
                    </el-form-item>
                    <el-form-item label="贷款近12月逾期次数：" prop="loanDueCnt12m">
                        <el-input placeholder="请输入" v-model="loanInfo.loanDueCnt12m" style="width: 140px;">

                        </el-input>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="贷款呆账笔数：" prop="loanDueBadCnt">
                        <el-input placeholder="请输入" v-model="loanInfo.loanDueBadCnt" style="width: 140px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="贷款最长逾期期数次数：" prop="loanDueLongCnt">
                        <el-input placeholder="请输入" v-model="loanInfo.loanDueLongCnt" style="width: 140px;">

                        </el-input>
                    </el-form-item>

                </div>
                <el-divider content-position="left" class="title-divider">其他信息
                </el-divider>
                <div class="flex">
                    <el-form-item label="资产处置笔数：" prop="assetHandlerCnt">
                        <el-input placeholder="请输入" v-model="otherInfo.assetHandlerCnt" style="width: 140px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="资产处置总金额：" prop="assetHandlerAmount">
                        <el-input placeholder="请输入" v-model="otherInfo.assetHandlerAmount" style="width: 140px;">

                        </el-input>
                    </el-form-item>
                    <el-form-item label="对外担保贷款五级分类：" prop="outAssureLoanCate5l">
                        <el-select placeholder="请选择" v-model="otherInfo.outAssureLoanCate5l" style="width: 140px;">
                            <el-option v-for="(item, index) in credit" :key="index" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="保证人代偿总金额：" prop="guaCompAmountTotal">
                        <el-input placeholder="请输入" v-model="otherInfo.guaCompAmountTotal" style="width: 140px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="保证人代偿笔数：" prop="guaCompCnt">
                        <el-input placeholder="请输入" v-model="otherInfo.guaCompCnt" style="width: 140px;">

                        </el-input>
                    </el-form-item>
                    <el-form-item label="借新还旧/贷款展期：" prop="loanReturnStatus">
                        <el-select placeholder="请选择" v-model="otherInfo.loanReturnStatus" style="width: 140px;">
                            <el-option v-for="(item, index) in loanExtension" :key="index" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div class="tc pt20">
                <el-button type="primary" size="medium" @click="submit">保存信息</el-button>
            </div>
        </el-form>

    </div>
</template>
<script>
import { defineComponent } from 'vue'

// Component.registerHooks(['beforeRouteLeave']) //ts用法

export default defineComponent({
    name: 'CaseRecord',
    components: {},
    props: {
        rowForm: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            basicInfo: {
                legalMarryStatus: '',
                estateStatus: '',
                searchCnt3m: '',
            },
            debitCardInfo: {
                debitCardCate5l: "",
                debitCardDueAccountCnt: "",
                debitCardDueAmountTotal: "",
                debitCardDueBadCnt: "",
                debitCardDueCnt6m: "",
                debitCardDueLongCnt: "",
                debitCardDueMaxPeriod: "",
                debitCardDueTopCnt12m: "",
            },
            loanInfo: {
                loanCate5l: "",
                loanDueAccountCnt: "",
                loanDueAmountTotal: "",
                loanDueBadCnt: "",
                loanDueCnt6m: "",
                loanDueCnt12m: "",
                loanDueLongCnt: "",
                loanDueMaxPeriod: ""
            },
            otherInfo: {
                assetHandlerAmount: "",
                assetHandlerCnt: "",
                guaCompAmountTotal: "",
                guaCompCnt: "",
                loanReturnStatus: "",
                outAssureLoanCate5l: ""
            },
            Marital: [
                {
                    label: '已婚',
                    value: '0'
                }, {
                    label: '未婚',
                    value: '1'
                }, {
                    label: '离异',
                    value: '2'
                },
            ],
            Property: [
                {
                    label: '有房无抵',
                    value: '0'
                }, {
                    label: '有房有抵',
                    value: '1'
                }, {
                    label: '无房产',
                    value: '2'
                },
            ],
            creditSpecial: [
                {
                    label: '冻结',
                    value: '0'
                }, {
                    label: '止付',
                    value: '1'
                }, {
                    label: '无',
                    value: '2'
                }

            ],
            credit: [
                {
                    label: '正常',
                    value: '0'
                }, {
                    label: '关注',
                    value: '1'
                }, {
                    label: '次级',
                    value: '2'
                }, {
                    label: '可疑',
                    value: '3'
                }, {
                    label: '损失',
                    value: '4'
                },

            ],

            loanExtension: [
                {
                    label: '是',
                    value: '0'
                }, {
                    label: '否',
                    value: '1'
                }

            ]
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.doPost('bium-portal/controller/fin-ops/audit/getCreditInfo', {
                auditId: this.rowForm.id,
            }).then(({ data }) => {
                if (data && data.result) {
                    // this.basicInfo = data.result.basicInfo
                    // this.debitCardInfo = data.result.debitCardInfo
                    // this.loanInfo = data.result.loanInfo
                    // this.otherInfo = data.result.otherInfo
                    let form = {
                        basicInfo: data.result.basicInfo,
                        debitCardInfo: data.result.debitCardInfo,
                        loanInfo: data.result.loanInfo,
                        otherInfo: data.result.otherInfo,
                    }
                    for (const key in form) {
                        for (const value in form[key]) {
                            this[key][value] =form[key][value]
                        }
                    }
                }
            })
        },
        submit() {
            let form = {
                basicInfo: this.basicInfo,
                debitCardInfo: this.debitCardInfo,
                loanInfo: this.loanInfo,
                otherInfo: this.otherInfo,
            }
            for (const key in form) {
                for (const value in form[key]) {
                    form[key][value] = form[key][value].replace(/^\s*/, "")
                    if (form[key][value] == '') {
                        return this.$message.error('请检查征信报告是否填写完整')
                    }
                }
            }
            this.doPost('bium-portal/controller/fin-ops/audit/saveCreditInfo', {
                auditId: this.rowForm.id,
                ...form
            }).then(({ data }) => {
                if (data && data.result) {
                    this.$notify({
                        title: '提交成功',
                        type: "success",
                    })
                    this.$parent.handleClose()
                }
            })
        },
    },
})
</script>

<style scoped lang="scss"></style>