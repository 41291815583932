import request, { doPostMulti, doUrl } from '@/router/axios';

export function upload(url, data) {
  const param = new FormData();
  Object.keys(data).forEach(key => {
    param.append(key, data[key]);
  });
  return doPostMulti(url, param);
}

export function getImageUrl(fileId) {
  return doUrl(`/common/viewImg?fileId=${fileId}`);
}

export function deleteFile(fileId) {
  return request({
    url: 'common/delete/' + fileId,
    method: 'get'
  });
}
