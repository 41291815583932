var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        { staticClass: "full-condition" },
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              option: _vm.option,
              page: _vm.page,
              "table-loading": _vm.listLoading,
              data: _vm.list,
            },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "on-load": _vm.getList,
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentChange,
              "search-change": _vm.handleFilter,
              "search-reset": _vm.resetFilter,
              "refresh-change": _vm.handleRefreshChange,
            },
            scopedSlots: _vm._u([
              {
                key: "auditStatus",
                fn: function (scope) {
                  return [
                    scope.row.auditStatus == 0
                      ? _c("span", [_vm._v("待审核")])
                      : _vm._e(),
                    scope.row.auditStatus == 1
                      ? _c("span", [_vm._v("审核通过")])
                      : _vm._e(),
                    scope.row.auditStatus == 2
                      ? _c("span", [_vm._v("审核失败")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "transferStatus",
                fn: function (scope) {
                  return [
                    scope.row.transferStatus == 0
                      ? _c("span", [_vm._v("转账中")])
                      : _vm._e(),
                    scope.row.transferStatus == 1
                      ? _c("span", [_vm._v("转账成功")])
                      : _vm._e(),
                    scope.row.transferStatus == 2
                      ? _c("span", [_vm._v("转账失败")])
                      : _vm._e(),
                    scope.row.transferStatus == 3
                      ? _c("span", [_vm._v("未知异常")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "menu",
                fn: function (scope) {
                  return [
                    scope.row.auditStatus == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSave("edit", scope.row.id, 1)
                              },
                            },
                          },
                          [_vm._v("\n          审核\n        ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSave("edit", scope.row.id, 0)
                          },
                        },
                      },
                      [_vm._v("\n          查看\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }