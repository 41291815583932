<template>
  <div class="user">
    <basic-container class="full-condition">
      <avue-crud
        ref="crud"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :data="dataList"
        :cell-class-name="handleCellClassName"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange">
        <template slot="menuRight">
          <!-- <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="handleSave()">添加新渠道</el-button> -->
        </template>
        <template slot="menuLeft">
          <!-- <el-button
            class="filter-item"
            type="primary"
            icon="icon-YUAN"
            @click="handleWidthdrawal"
            >提现
          </el-button>
          <el-button
            class="filter-item"
            type="primary"
            icon="icon-YUAN"
            @click="checkFlow"
            >流水核查
          </el-button> -->
        </template>

        <template slot="menu" slot-scope="scope">
          <el-button type="text" icon="el-icon-view" @click="accountFlow(scope.row, scope.index)">流水</el-button>
          <!-- <el-button type="text" icon="el-icon-refresh"
						@click="queryBook(scope.row, scope.index)">账户同步</el-button> -->
          <!-- <el-button
            v-if="scope.row.status == 1"
            type="text"
            icon="el-icon-view"
            @click="frozenBook(scope.row, scope.index)"
            >冻结</el-button
          >
          <el-button
            v-if="scope.row.status == 2"
            type="text"
            icon="el-icon-view"
            @click="frozenBook(scope.row, scope.index)"
            >解冻</el-button
          > -->
        </template>
      </avue-crud>
    </basic-container>

    <el-dialog
      :visible.sync="dialogVisible"
      title="账户明细"
      width="98%"
      top="50px"
      custom-class="pub_dialog"
      @close="handleCloseDialog">
      <account-flow v-if="dialogVisible" :accountFlowForm="accountFlowForm" @change="handleCloseDialog" />
    </el-dialog>
    <el-dialog
      title="新增渠道"
      class="dialog-standard channel-config-rate"
      width="1000px"
      top="100px"
      :visible.sync="isvisible">
      <channelAdd />
    </el-dialog>
  </div>
</template>
<script>
import channelAdd from '@/views/channel/components/channelAdd'
import { mapGetters } from 'vuex'
import accountFlow from './accountFlow'
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchMenuPosition: 'center',
  searchIndex: 3,
  searchShowBtn: false,
  border: false,
  showSummary: false,
  printBtn: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 200,
  searchIndex: 3,
  searchIcon: false,
  searchShowBtn: false,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
  column: [
    {
      fixed: true,
      label: 'id',
      prop: 'id',
      span: 24,
      hide: true,
      editDisplay: false,
      addDisplay: false,
    },
    {
      label: '账户名称',
      prop: 'accountNameDesc',
      width: 250,
      align: 'left',
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchSpan: 6,
      span: 24,
      type: 'select',
      searchFilterable: true,
      props: {
        label: 'name',
        value: 'id',
      },
      dicData: [],
    },
    {
      label: '店铺名称',
      prop: 'storeName',
      width: 150,
      editDisplay: false,
      addDisplay: false,
      span: 24,
    },
    {
      label: '账户类型',
      prop: 'accountType',
      width: 180,
      type: 'select',
      hide: true,
      editDisplay: true,
      addDisplay: false,
      search: true,
      searchSpan: 6,
      searchLabelWidth: 90,
      span: 24,
      dicData: [
        {
          label: '商户监管账户',
          value: 1,
        },
        {
          label: '平台监管户',
          value: 2,
        },
        {
          label: ' 资金方监管账户',
          value: 3,
        },
        {
          label: '通道费账户',
          value: 4,
        },
        {
          label: '渠道账户',
          value: 5,
        },
        {
          label: '商户提现账户',
          value: 11,
        },
        {
          label: '平台提现账户',
          value: 12,
        },
        {
          label: '资金方提现账户',
          value: 13,
        },
        {
          label: '通道提现账户',
          value: 14,
        },
        {
          label: '渠道提现账户',
          value: 15,
        },
      ],
    },
    {
      label: '支付渠道',
      prop: 'paymentChannelName',
      width: 150,
      editDisplay: false,
      addDisplay: false,
      span: 24,
    },
    {
      label: '钱包ID',
      prop: 'walletId',
      width: 180,
      align: 'left',
      search: true,
      searchSpan: 6,
      editDisplay: false,
      addDisplay: false,
      span: 24,
    },{
      label: '账户场景ID',
      prop: 'acctScene',
      width: 180,
      align: 'left',
      search: true,
      searchSpan: 6,
      editDisplay: false,
      addDisplay: false,
      span: 24,
    },
    {
      label: '绑定银行卡号',
      prop: 'accountNo',
      width: 180,
      align: 'left',
      editDisplay: false,
      addDisplay: false,
      search: true,
      searchSpan: 6,
      searchLabelWidth: 90,
      span: 24,
    },
    {
      label: '银行卡所属银行',
      prop: 'bankName',
      align: 'left',
      width: 180,
      editDisplay: false,
      addDisplay: false,

      span: 24,
    },
    {
      label: '总余额',
      prop: 'totalBalance',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '可用余额',
      prop: 'availableAmount',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      label: '冻结金额',
      prop: 'frozenAmount',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
  ],
}

export default {
  name: 'TableUser',
  components: {
    accountFlow,
    channelAdd,
  },
  data() {
    return {
      accountFlowForm: {},
      option: tableOption,
      searchItmes: {},
      dialogVisible: false,
      page: {
        total: 0, // 总页数
        currentPage: 1,
        pageSize: 10,
      },
      isvisible: false,
      dataList: [],
      listLoading: true,
      accountId: '',
      accountName: '',
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    syncPermissions() {
      return this.permissions['biz_account_sync']
    },
  },
  watch: {},
  created() {
    this.getNamelist()
  },
  methods: {
    getNamelist() {
      const api = 'bium-portal/controller/fin-ops/account/getEnterpriseNameList'
      this.doPost(api).then(({ data }) => {
        if (data) {
          this.option.column[1].dicData = data || []
        }
      })
    },
    handleSave() {
      this.isvisible = true
    },
    getList(page, params) {
      const api = 'bium-portal/controller/fin-ops/account/selectPage'
      const { currentPage, pageSize } = page
      params = params
        ? params
        : {
            ...this.searchItmes,
          }
      if (params.accountNameDesc) {
        params.companyId = params.accountNameDesc
      }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize
      this.listLoading = true
      this.doPost(api, params).then(({ data }) => {
        if (data.result.records) {
          this.dataList = data.result.records || []
          this.page.total = data.result.total || 0
          this.dataList.forEach((item) => {
            if (item.paymentChannel == 1) {
              item.paymentChannelName = 'PING++'
            }
            if (item.paymentChannel == 2) {
              item.paymentChannelName = '富友支付'
            }
          })
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },

    handleCloseDialog() {
      this.dialogVisible = false
    },
    handleCellClassName({ row, column, rowIndex, columnIndex }) {
      if ([7].includes(columnIndex)) {
        return 'yellow-cell'
      }
      return ''
    },

    accountFlow(row, index) {
      // this.$router.push({ path: '/cap/flowAll?accountId=' + row.id });
      this.accountFlowForm = {
        accountName: row.accountName,
        paymentChannelName: row.paymentChannelName,
        id: row.id,
        walletId: row.walletId,
        accountNo: row.walletId,
      }
      this.dialogVisible = true
    },
    queryBook(row, index) {
      this.listLoading = true
      doGet('/upyzt/balacc/queryBook/' + row.id).then((res) => {
        this.listLoading = false
        this.handleRefreshChange()
      })
    },
    checkFlow() {
      doGet('/cap/account/checkFlow').then((res) => {
        this.handleRefreshChange()
      })
    },
    frozenBook(row, index) {
      this.listLoading = true
      doGet('/upyzt/balacc/frozenBook/' + row.id).then((res) => {
        this.listLoading = false
        this.handleRefreshChange()
      })
    },
    handleWidthdrawal() {
      this.$router.push({
        path: '/account/withdraw',
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
