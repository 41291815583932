<template>
  <div class="product-financing-plan">
    <basic-container>
      <avue-crud
        ref="crud"
        :data="dataList"
        :option="option"
        :page.sync="page"
        :table-loading="isLoading"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange"
      >
        <!-- 列表左上角操作按钮 -->
        <template #menuLeft>
          <el-button icon="el-icon-setting" type="primary" @click="toConfigStandardPlan">直客方案管理</el-button>
        </template>
        <template slot="channelName" slot-scope="scope">
          {{ scope.row.channelName == null ? '直客' : scope.row.channelName }}
        </template>
        <!-- 自定义按钮 -->
        <template #menu="{ size, row }">
          <el-button
            :size="size"
            icon="el-icon-edit"
            text
            type="text"
            @click="toConfigPlanModal(row)"
          >配置融资方案
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
import { PAGE } from '@/const/global.js';

export default {
  name: 'ProductFinancingPlan',
  props: {},
  data() {
    return {
      isLoading: true,
      searchItmes: {},
      dataList: [],
      option: {
        // 搜索栏配置
        searchMenuSpan: 8,
        searchMenuPosition: 'left',
        // 表格主体配置
        border: false,
        index: false,
        indexLabel: '序号',
        stripe: true,
        headeralign: 'left',
        align: 'right',
        // 表格操作列配置
        menu: true,
        menuWidth: 150,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        searchShowBtn: false,
        column: [{
          label: '企业名称',
          prop: 'enterpriseName',
          align: 'left',
          fixed: true,
          search: true,
          searchSpan: 8
        },
        {
          label: '店铺名称',
          prop: 'storeName',
          align: 'left',
          fixed: true,
          search: true,
          searchSpan: 8
        },
          // {
          // 	label: "企业编号",
          // 	prop: "enterpriseCode",
          // 	width: 280,
          // 	align: "left",
          // 	search: true,
          // 	searchSpan: 8,
          // },

        {
          label: '产品类型',
          prop: 'productName',
          align: 'center'
        },

        {
          label: '资金方',
          prop: 'fundingPartnerName',
          align: 'left'
        },
        {
          label: '所属渠道',
          prop: 'channelName',
          align: 'left'
        }
        ]
      },
      page: PAGE
    };
  },
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    /** 获取列表数据 */
    getList(page, params) {
      const api = '/bium-portal/controller/fin-ops/enterprise/ratePlanEnterprisePage';
      params = params || {
        ...this.searchItmes
      };
      params.pageNum = page.currentPage;
      params.pageSize = page.pageSize;
      params.code = params.enterpriseCode;
      this.isLoading = true;
      this.doPost(api, params).then(({
        data
      }) => {
        if (data.result.records) {
          this.dataList = data.result.records || [];
          this.page.total = data.result.total || 0;
          this.dataList.forEach(item => {
            if (item.productType == 0) {
              item.productName = '1A-极速收款';
            } else {
              item.productName = '2A-极速付款';
            }
          });
        }
        this.isLoading = false;
      });
    },

    /** 分页变化，存储 */
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    /** 当前页码变化，存储 */
    currentChange(current) {
      this.page.currentPage = current;
    },
    /** 点击搜索栏搜索 */
    handleFilter(param, done) {
      this.page.currentPage = 1;
      this.searchItmes = param || {};
      this.getList(this.page, param);
      done();
    },
    /** 重置条件搜索 */
    resetFilter() {
      this.page.currentPage = 1;
      this.searchItmes = {};
      this.getList(this.page, this.searchItmes);
    },
    /** 手动刷新列表 */
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes);
    },

    /** 显示直客方案管理配置弹窗 */
    toConfigStandardPlan() {
      const comp = () => import('./components-plan/standard-plan-edit.vue');
      this.$modalDialog(comp);
    },
    /** 显示配置弹窗 */
    toConfigPlanModal(dataItem) {
      console.log('dataItem:', dataItem);
      const comp = () => import('./components-plan/plan-edit.vue');
      this.$modalDialog(comp, {
        dataItem
      }).then(() => {
        this.page.currentPage = 1;
        this.handleRefreshChange();
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
