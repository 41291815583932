<template>
	<div class="selection-box">
		<section class="acc-container">
			<el-divider content-position="left" class="title-divider">
				三方支付流水下载
			</el-divider>
			<div class="selection-box">
				<el-form ref="payForm" :model="payForm" :rules="payRules" :label-position="'right'" label-width="80px"
					size="small">
					<div class="mt10 inner-box">
						<el-form-item label="支付服务商" prop="liveStreamPlat">
							<el-select style="width: 350px;" v-model="payForm.value" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="账户账号" prop="account">
							<el-input style="width: 350px;" v-model="payForm.account" maxlength="40"
								placeholder="请输入账户账号" />
						</el-form-item>
						<el-form-item label="日期" prop="time">
							<el-date-picker v-model="payForm.expireDateAttr" value-format="yyyy-MM-dd" type="daterange"
								range-separator="至" start-placeholder="起始日期" end-placeholder="结束日期" />
						</el-form-item>
					</div>
					<el-form-item>
						<el-button type="primary" size="medium" :loading="loading" :disabled="!payForm.btnStatus"
							@click="saveExcel()">下载</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-divider content-position="left" class="title-divider">
				资金凭证下载
			</el-divider>
			<div class="selection-box">
				<el-form ref="payForm" :model="payForm" :rules="payRules" :label-position="'right'" label-width="80px"
					size="small">
					<div class="mt10 inner-box">
						<el-form-item label="支付服务商" prop="liveStreamPlat">
							<el-select style="width: 350px;" v-model="payForm.value" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="账户账号" prop="account">
							<el-input style="width: 350px;" v-model="payForm.account" maxlength="40"
								placeholder="请输入账户账号" />
						</el-form-item>
						<el-form-item label="凭证流水号" prop="account">
							<el-input style="width: 350px;" v-model="payForm.account" maxlength="40"
								placeholder="请输入凭证流水号" />
						</el-form-item>
						<el-form-item label="日期" prop="time">
							<el-date-picker v-model="payForm.expireDateAttr" value-format="yyyy-MM-dd" type="daterange"
								range-separator="至" start-placeholder="起始日期" end-placeholder="结束日期" />
						</el-form-item>
					</div>
					<el-form-item>
						<el-button type="primary" size="medium" :loading="loading" :disabled="!payForm.btnStatus"
							@click="saveExcel()">下载</el-button>
					</el-form-item>
				</el-form>
			</div>
		</section>
	</div>
</template>
<script>
	export default ({
		name: 'Payment',
		data() {
			return {
				loading: false,
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤鸭'
				}],
				payForm: {
					value: null,
					time: null,
					account: null,
					btnStatus: true
				},
				payRules: {}

			}
		},
		created() {},
		mounted() {},

		methods: {
			saveExcel() {
				this.$refs.cForm.validate(valid => {
					if (valid) {
						this.loading = true;
						// doPost('/cap/account/bindCard', data).then(res => {
						// 	this.loading = false;
						// 	if (res.data.code == 200) {
						// 		this.$notify({
						// 			title: '下载成功',
						// 			type: 'success',
						// 			duration: 2500
						// 		});
						// 	} else {}
						// }).catch(e => {
						// 	this.loading = false;
						// });
					} else {
						return false;
					}
				});
			}
		},
	})
</script>

<style scoped lang="scss">

</style>