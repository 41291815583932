var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        { staticClass: "full-condition" },
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.option,
                page: _vm.page,
                "table-loading": _vm.listLoading,
                data: _vm.dataList,
                "cell-class-name": _vm.handleCellClassName,
              },
              on: {
                "update:page": function ($event) {
                  _vm.page = $event
                },
                "on-load": _vm.getList,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange,
                "search-change": _vm.handleFilter,
                "search-reset": _vm.resetFilter,
                "refresh-change": _vm.handleRefreshChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "menu",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-view" },
                          on: {
                            click: function ($event) {
                              return _vm.accountFlow(scope.row, scope.index)
                            },
                          },
                        },
                        [_vm._v("流水")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "menuRight" }),
              _c("template", { slot: "menuLeft" }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "账户明细",
            width: "98%",
            top: "50px",
            "custom-class": "pub_dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleCloseDialog,
          },
        },
        [
          _vm.dialogVisible
            ? _c("account-flow", {
                attrs: { accountFlowForm: _vm.accountFlowForm },
                on: { change: _vm.handleCloseDialog },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            title: "新增渠道",
            width: "1000px",
            top: "100px",
            visible: _vm.isvisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isvisible = $event
            },
          },
        },
        [_c("channelAdd")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }