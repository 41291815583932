var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "selection-box" }, [
    _c(
      "section",
      { staticClass: "acc-container" },
      [
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("\n\t\t\t转账详情\n\t\t")]
        ),
        _c(
          "div",
          { staticClass: "selection-box" },
          [
            _c(
              "el-form",
              {
                ref: "payForm",
                attrs: {
                  model: _vm.payForm,
                  rules: _vm.vRules,
                  "label-position": "right",
                  "label-width": "120px",
                  size: "small",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "mt10 inner-box" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "支付机构",
                                  prop: "paymentType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      disabled: _vm.type == "edit",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.payForm.paymentType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payForm,
                                          "paymentType",
                                          $$v
                                        )
                                      },
                                      expression: "payForm.paymentType",
                                    },
                                  },
                                  _vm._l(_vm.options, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "入账账户名称",
                                  prop: "toEnterpriseName",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      disabled: _vm.type == "edit",
                                      filterable: "",
                                      placeholder: "请选择",
                                    },
                                    on: { change: _vm.changeto },
                                    model: {
                                      value: _vm.payForm.toEnterpriseName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payForm,
                                          "toEnterpriseName",
                                          $$v
                                        )
                                      },
                                      expression: "payForm.toEnterpriseName",
                                    },
                                  },
                                  _vm._l(_vm.accountList, function (item, i) {
                                    return _c("el-option", {
                                      key: i,
                                      attrs: {
                                        label: item.enterpriseName,
                                        value: item.enterpriseId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "入账账户场景类型",
                                  prop: "toAccountScene",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      disabled: _vm.type == "edit",
                                      placeholder: "请选择",
                                    },
                                    on: { change: _vm.changetotype },
                                    model: {
                                      value: _vm.payForm.toAccountScene,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payForm,
                                          "toAccountScene",
                                          $$v
                                        )
                                      },
                                      expression: "payForm.toAccountScene",
                                    },
                                  },
                                  _vm._l(_vm.tolist, function (item, i) {
                                    return _c("el-option", {
                                      key: i,
                                      attrs: {
                                        label: item.accountScene,
                                        value: item.accountScene,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "入账账号",
                                  prop: "toWalletId",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "350px" },
                                  attrs: { disabled: "", maxlength: "40" },
                                  model: {
                                    value: _vm.payForm.toWalletId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.payForm, "toWalletId", $$v)
                                    },
                                    expression: "payForm.toWalletId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "出账账户名称",
                                  prop: "fromEnterpriseName",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      disabled: _vm.type == "edit",
                                      filterable: "",
                                      placeholder: "请选择",
                                    },
                                    on: { change: _vm.changefrom },
                                    model: {
                                      value: _vm.payForm.fromEnterpriseName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payForm,
                                          "fromEnterpriseName",
                                          $$v
                                        )
                                      },
                                      expression: "payForm.fromEnterpriseName",
                                    },
                                  },
                                  _vm._l(_vm.accountList, function (item, i) {
                                    return _c("el-option", {
                                      key: i,
                                      attrs: {
                                        label: item.enterpriseName,
                                        value: item.enterpriseId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "出账账户场景类型",
                                  prop: "fromAccountScene",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      disabled: _vm.type == "edit",
                                      placeholder: "请选择",
                                    },
                                    on: { change: _vm.changefromtype },
                                    model: {
                                      value: _vm.payForm.fromAccountScene,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payForm,
                                          "fromAccountScene",
                                          $$v
                                        )
                                      },
                                      expression: "payForm.fromAccountScene",
                                    },
                                  },
                                  _vm._l(_vm.fromlist, function (item, i) {
                                    return _c("el-option", {
                                      key: i,
                                      attrs: {
                                        label: item.accountScene,
                                        value: item.accountScene,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "出账账号",
                                  prop: "fromWalletId",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "350px" },
                                  attrs: { disabled: "", maxlength: "40" },
                                  model: {
                                    value: _vm.payForm.fromWalletId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.payForm, "fromWalletId", $$v)
                                    },
                                    expression: "payForm.fromWalletId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "出账账号可用余额",
                                  prop: "availableAmount",
                                },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticStyle: { width: "350px" },
                                    attrs: { disabled: true, maxlength: "40" },
                                    model: {
                                      value: _vm.payForm.availableAmount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payForm,
                                          "availableAmount",
                                          $$v
                                        )
                                      },
                                      expression: "payForm.availableAmount",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "append" }, [
                                      _vm._v("元"),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "出账金额", prop: "amount" } },
                              [
                                _c("el-input-number", {
                                  staticStyle: { width: "350px" },
                                  attrs: {
                                    precision: 2,
                                    disabled: _vm.type == "edit",
                                    step: 2,
                                  },
                                  model: {
                                    value: _vm.payForm.amount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.payForm, "amount", $$v)
                                    },
                                    expression: "payForm.amount",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "业务说明",
                                  prop: "transferComment",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "70.2%" },
                                  attrs: {
                                    type: "textarea",
                                    disabled: _vm.type == "edit",
                                  },
                                  model: {
                                    value: _vm.payForm.transferComment,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.payForm,
                                        "transferComment",
                                        $$v
                                      )
                                    },
                                    expression: "payForm.transferComment",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "银行交易附言",
                                  prop: "transPost",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "70.2%" },
                                  attrs: {
                                    maxlength: "255",
                                    "show-word-limit": "",
                                    type: "textarea",
                                    disabled: _vm.type == "edit",
                                  },
                                  model: {
                                    value: _vm.payForm.transPost,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.payForm, "transPost", $$v)
                                    },
                                    expression: "payForm.transPost",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "附件上传" } },
                              [
                                _vm.type == "add"
                                  ? _c("dict-upload-info", {
                                      attrs: {
                                        type: "file",
                                        limit: 1,
                                        "type-code": "ZZFJ",
                                        url: "/bium-portal/controller/fin-ops/attach/upload",
                                      },
                                      on: {
                                        getOcrInfo: _vm.handleSetOcrInfo,
                                        openUrl: _vm.openUrl,
                                      },
                                      model: {
                                        value: _vm.payForm.attachId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.payForm, "attachId", $$v)
                                        },
                                        expression: "payForm.attachId",
                                      },
                                    })
                                  : _vm.type != "add" && _vm.payForm.attachId
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getFile(
                                              _vm.payForm.attachId,
                                              _vm.payForm.fileSuffix
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("点击查看")]
                                    )
                                  : _c("span", [_vm._v("无")]),
                                _c("el-image", {
                                  ref: "imageUrl",
                                  staticStyle: { width: "0px", height: "0px" },
                                  attrs: {
                                    src: _vm.fileUrl,
                                    "preview-src-list": [_vm.fileUrl],
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.type == "edit"
                      ? _c(
                          "el-row",
                          { attrs: { gutter: 24 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "审批意见" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "70.2%" },
                                      attrs: {
                                        disabled:
                                          _vm.routerStatus == null ||
                                          _vm.routerStatus == 0,
                                        type: "textarea",
                                      },
                                      model: {
                                        value: _vm.payForm.auditComment,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.payForm,
                                            "auditComment",
                                            $$v
                                          )
                                        },
                                        expression: "payForm.auditComment",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.routerStatus == null
                      ? _c(
                          "div",
                          [
                            _vm.type == "edit" &&
                            _vm.payForm.transferStatus == 2 &&
                            _vm.payForm.failureComment
                              ? _c(
                                  "el-row",
                                  { attrs: { gutter: 24 } },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        staticStyle: { color: "red" },
                                        attrs: { span: 24 },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t转账失败原因：" +
                                            _vm._s(_vm.payForm.failureComment) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _vm.type == "add"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "medium",
                              loading: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submit()
                              },
                            },
                          },
                          [_vm._v("提交复核")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.routerStatus == 1
                  ? _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "medium",
                              loading: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitExamine(1)
                              },
                            },
                          },
                          [_vm._v("通过")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              size: "medium",
                              loading: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitExamine(0)
                              },
                            },
                          },
                          [_vm._v("拒绝")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }