<template>
  <div class="unfree-funds">
    <basic-container v-loading="searchLoading">
      <div class="input-with-select">
        <el-input placeholder="请输入店铺全称" v-model="enterpriseName" clearable>
          <el-button
            slot="append"
            class="search-btn"
            icon="el-icon-search"
            @click="searchShop()"
            >查询店铺可解冻金额</el-button
          >
        </el-input>
      </div>
      <div class="search-result">
        <template v-if="currShop">
          <p class="note">注：实际解冻金额将小于填写解冻金额</p>
          <div class="curr-shop">
            <p>{{ currShop.enterpriseName | dealNull }}</p>
            <ul>
              <li class="cash-item">
                <label>可解冻金额上限：</label>
                <span>{{ currShop.overAmt | numberToCurrency(0) }}元</span>
              </li>
              <li class="cash-item">
                <label>本次解冻金额：</label>
                <el-input-number
                  v-model="unfreeFund"
                  placeholder="请输入解冻金额"
                  :precision="0"
                ></el-input-number>
              </li>
              <li style="text-align: center">
                <el-button
                  type="primary"
                  icon="el-icon-unlock"
                  v-loading="isSubmiting"
                  @click="toUnfreeFund()"
                  >解冻</el-button
                >
              </li>
            </ul>
          </div>
        </template>
        <el-empty :description="emptyDesc" v-else></el-empty>
      </div>
    </basic-container>
  </div>
</template>

<script>
export default {
  name: "unfreeFunds",
  props: {},
  data() {
    return {
      emptyDesc: "请输入店铺名称后，查看店铺可冻结金额",
      searchLoading: false,
      isSubmiting: false,
      enterpriseName: "",
      currShop: null,
      unfreeFund: undefined,
    };
  },
  watch: {},
  created() {
    // this.doPost("bium-portal/controller/fin-ops/va/store-reserved/release",)
    //   .then(({ data }) => {
    //     if (data.code == 200) {
    //       this.$notify.success("解冻成功。");
    //       this.currShop = null;
    //       this.unfreeFund = undefined;
    //       this.emptyDesc = "请输入店铺名称后，查看店铺可冻结金额";
    //     }
    //   })
    //   .finally(() => {
    //     setTimeout(() => {
    //       this.isSubmiting = false;
    //     }, 500);
    //   });
  },
  mounted() {},
  methods: {
    /** 搜索店铺 */
    searchShop() {
      const { enterpriseName } = this;
      if (enterpriseName) {
        this.searchLoading = true;
        this.emptyDesc = "暂无数据";
        this.doPost("bium-portal/controller/fin-ops/va/store-reserved/query", { enterpriseName })
          .then(({ data }) => {
            this.searchLoading = false;
            this.unfreeFund = undefined;
            this.currShop = data.data;
          })
          .catch(() => {
            this.searchLoading = false;
          });
      } else {
        this.$notify.error("请输入店铺名称。");
      }
    },
    /** 解冻 */
    toUnfreeFund() {
      if (!this.isSubmiting) {
        if (!this.unfreeFund || this.unfreeFund == 0) {
          this.$notify.error("请填写解冻金额，且金额不能为0。");
          return;
        }
        const overAmt = this.currShop.overAmt || 0;
        if (this.unfreeFund > overAmt) {
          this.$notify.error("填写的解冻金额不能超过上限。");
          return;
        }
        const msgtips = `本次实际解冻金额为：${this.unfreeFund}元，是否解冻？`;
        this.$confirm(msgtips, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          const sendData = {
            id: this.currShop.id,
            overAmt: this.unfreeFund,
          };
          this.isSubmiting = true;
          this.doPost("bium-portal/controller/fin-ops/va/store-reserved/release", sendData)
            .then(({ data }) => {
              if (data.code == 200) {
                this.$notify.success("解冻成功。");
                this.currShop = null;
                this.unfreeFund = undefined;
                this.emptyDesc = "请输入店铺名称后，查看店铺可冻结金额";
              }
            })
            .finally(() => {
              setTimeout(() => {
                this.isSubmiting = false;
              }, 500);
            });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.unfree-funds {
  .input-with-select {
    text-align: center;
    /deep/.el-input-group {
      width: 60%;
    }
  }
  .search-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #409eff;
    border-color: #409eff;
    color: #fff;
  }
  .search-result {
    height: calc(100vh - 200px);
    box-sizing: border-box;
    padding: 30px 0;
  }
  .note {
    margin-bottom: 8px;
    font-size: 14px;
    color: #f00;
  }
  .curr-shop {
    padding: 10px 20px;
    border: 1px solid #eee;
    border-radius: 5px;
    p {
      padding-left: 16px;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 8px;
      position: relative;
      &::before {
        display: inline-block;
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: #409eff;
        position: absolute;
        top: 12px;
        left: 0;
      }
    }
    .cash-item {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-bottom: 12px;
      & > label {
        width: 136px;
        color: #666;
        text-align: right;
      }
      /deep/.el-input-number--small {
        width: calc(100% - 200px);
        .el-input__inner {
          text-align: left;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
