<template>
  <div class="header-login-root">
    <div class="header-position"></div>
    <div class="header-login-wrapper">
      <div class="header-login-container">
        <router-link to="/">
			<!-- //和信 -->
          <img src="@/assets/bg/hxlogo-report.png" width="160" style="height: 60px;" alt="">
        </router-link>
        <router-link tag="span" :to="url">{{ text1 }}<el-link type="primary" :underline="false">{{ text2 }}</el-link></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderLogin',
  data() {
    return {
    }
  },
  computed: {
    isRegisterPage() {
      // return this.$router.currentRoute.name === 'register'
      return true
    },
    text1() {
      return this.isRegisterPage ? '已有账号？' : '没有账号？'
    },
    text2() {
      return this.isRegisterPage ? '去登录' : '去注册'
    },
    url() {
      return this.isRegisterPage ? '/' : '/register'
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .header-position {
    height: 60px;
  }
  .header-login-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    border-bottom: 1px solid #e4e8ef;
    z-index: 100;
    background: white;
  }
  .header-login-container {
    margin: 0 auto;
    width: 1096px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    /deep/ .el-link {
      display: inline-block;
      vertical-align: top;
    }
  }
</style>
