var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.option,
                page: _vm.page,
                "table-loading": _vm.listLoading,
                "before-open": _vm.handleOpenBefore,
                data: _vm.list,
              },
              on: {
                "update:page": function ($event) {
                  _vm.page = $event
                },
                "on-load": _vm.getList,
                "size-change": _vm.sizeChange,
                "current-change": _vm.currentChange,
                "search-change": _vm.handleFilter,
                "search-reset": _vm.resetFilter,
                "refresh-change": _vm.handleRefreshChange,
              },
              scopedSlots: _vm._u([
                {
                  key: "type",
                  fn: function (scope) {
                    return [
                      scope.row.type == 0
                        ? _c("span", [_vm._v("企业审核")])
                        : _vm._e(),
                      scope.row.type == 1
                        ? _c("span", [_vm._v("店铺审核")])
                        : _vm._e(),
                      scope.row.type == 2
                        ? _c("span", [_vm._v("支用审核")])
                        : _vm._e(),
                      scope.row.type == 3
                        ? _c("span", [_vm._v("提现审核")])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "status",
                  fn: function (scope) {
                    return [
                      scope.row.status == 0
                        ? _c("span", [_vm._v("待审核")])
                        : _vm._e(),
                      scope.row.status == 1
                        ? _c("span", [_vm._v("审核通过")])
                        : _vm._e(),
                      scope.row.status == 2
                        ? _c("span", [_vm._v("审核失败")])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "currNode",
                  fn: function (scope) {
                    return [
                      scope.row.currNode == "ops"
                        ? _c("span", [_vm._v("运营")])
                        : _vm._e(),
                      scope.row.currNode == "risk"
                        ? _c("span", [_vm._v("风控")])
                        : _vm._e(),
                      scope.row.currNode == "financial"
                        ? _c("span", [_vm._v("财务")])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "productType",
                  fn: function (scope) {
                    return [
                      scope.row.productType == 0
                        ? _c("span", [_vm._v("1A-极速收款")])
                        : _vm._e(),
                      scope.row.productType == 1
                        ? _c("span", [_vm._v("2A-极速付款")])
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "menu",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.handleApproval(scope.row)
                            },
                          },
                        },
                        [_vm._v("详情")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.openDIalog("Caseisvisible", scope.row)
                            },
                          },
                        },
                        [_vm._v("新增案例")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.openDIalog("casedialog", scope.row)
                            },
                          },
                        },
                        [_vm._v("案例记录")]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.form,
                callback: function ($$v) {
                  _vm.form = $$v
                },
                expression: "form",
              },
            },
            [_c("template", { slot: "menuLeft" })],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            title: "新增案例",
            width: "550px",
            top: "100px",
            visible: _vm.Caseisvisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.Caseisvisible = $event
            },
          },
        },
        [
          _vm.Caseisvisible
            ? _c("addCase", { attrs: { rowForm: _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            title: "案例记录",
            width: "800px",
            top: "100px",
            visible: _vm.casedialog,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.casedialog = $event
            },
          },
        },
        [
          _vm.casedialog
            ? _c("CaseRecord", { attrs: { rowForm: _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            title: "审核操作",
            width: "800px",
            top: "100px",
            visible: _vm.examinedialog,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.examinedialog = $event
            },
          },
        },
        [
          _vm.examinedialog
            ? _c("examine", { attrs: { rowForm: _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            title: "客户归属绑定",
            width: "700px",
            top: "100px",
            visible: _vm.Customerdialog,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.Customerdialog = $event
            },
          },
        },
        [
          _vm.Customerdialog
            ? _c("customer", { attrs: { rowForm: _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            title: "费率方案",
            width: "600px",
            top: "100px",
            visible: _vm.Ratesvisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.Ratesvisible = $event
            },
          },
        },
        [
          _vm.Ratesvisible
            ? _c("RatePlan", { attrs: { rowForm: _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            title: "征信报告",
            width: "1000px",
            top: "100px",
            visible: _vm.creditvisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.creditvisible = $event
            },
          },
        },
        [
          _vm.creditvisible
            ? _c("creditReport", { attrs: { rowForm: _vm.rowItem } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }