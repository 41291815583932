<template>
    <div class="selection-box" v-loading="Loading">
        <section class="acc-container">
            <div class="order">
                <el-divider content-position="left" class="title-divider">支付单详情</el-divider>
                <div class="flex">
                    <div class="order_list">
                        <div class="order_list_title">订单编号</div>
                        <p>{{ orderForm.orderNo }}</p>
                    </div>
                    <div class="order_list">
                        <div class="order_list_title">下单时间</div>
                        <p>{{ orderForm.createdFormat }}</p>
                    </div>
                    <div class="order_list">
                        <div class="order_list_title">订单原价总金额（元）</div>
                        <p>{{ orderForm.totalAmountCost }}元</p>
                    </div>
                    <div class="order_list">
                        <div class="order_list_title">订单赊销价总金额（元）</div>
                        <p>{{ orderForm.totalAmountCredit }}元</p>
                    </div>
                </div>
            </div>
            <div class="mt40">
                <el-divider content-position="left" class="title-divider">货品信息</el-divider>
                <div class="order_Name ml25" style="font-size: 13px;">
                    供货方：{{ orderForm.supplierComName }}，采购方：{{ orderForm.orderComName }}</div>
                <div class="order_table mt20">
                    <el-table :data="dataList" :border="true" style="width: 100%">
                        <el-table-column prop="goodsName" label="货品名称" width="120">
                        </el-table-column>
                        <el-table-column prop="goodsNo" :align="'center'" label="货品编码" width="180">
                        </el-table-column>
                        <el-table-column prop="goodsImg" :align="'center'" label="货品主图" width="120">
                            <template slot-scope="scope">
                                <el-image :src="scope.row.goodsImg" :preview-src-list="[scope.row.goodsImg]" alt=""
                                    class="goodsImg" />
                            </template>
                        </el-table-column>

                        <el-table-column prop="skuName" :align="'center'" label="购买规格">
                        </el-table-column>
                        <el-table-column prop="priceCost" :align="'center'" label="原单价">
                        </el-table-column>
                        <el-table-column prop="priceCredit" :align="'center'" label="赊销单价">
                        </el-table-column>
                        <el-table-column prop="orderNum" :align="'center'" label="采购数量">
                        </el-table-column>
                        <el-table-column prop="totalAmountCost" :align="'center'" label="原价总金额" width="120">
                        </el-table-column>
                        <el-table-column prop="totalAmountCredit" :align="'center'" label="赊销价总金额" width="120">
                        </el-table-column>

                    </el-table>
                    <!-- <div class="order_Name mt15 ">实际支付: <span style="color: red;" class="ml10">￥555元</span></div> -->
                    <div class="flex mt20 payment">支付方式：
                        <div class="payment_list ml10">{{ orderForm.payType == 1 ? '余额支付' : orderForm.payType == 2 ? '极速付款' :
                            '组合支付' }}</div>

                    </div>
                    <!-- 订单信息 -->
                    <div class="jg-acc mt15">
                        <div class="card-box">
                            <div class="card-item" v-for="(item, index) in order_info" :key="index">
                                <div class="item_label">
                                    {{ item.name }}:
                                </div>
                                <div>
                                <span v-if="item.value == 'isCredit'"> {{ orderForm[item.value] ? '是' : '否'
                                    }}</span>
                                <span v-else-if="item.value == 'laterAmount'">
                                    <span v-if="orderForm[item.value]"> {{ orderForm[item.value] }}元<span v-if="orderForm.laterPayAmountMax">(最高{{orderForm.laterPayAmountMax }}元)</span></span>
                                    <span v-if="!orderForm[item.value]">--</span>
                                </span>
                                <span v-else-if="item.value == 'creditDayNum'">
                                    {{ orderForm[item.value] }} {{ item.unit }}
                                </span>

                                <span v-else>
                                    <span v-if="orderForm[item.value] && orderForm.isCredit">{{ orderForm[item.value] }}</span>
                                    <span v-if="!orderForm[item.value] || !orderForm.isCredit">--</span>
                                    <span v-if="item.unit && orderForm.isCredit">{{ item.unit }}</span>
                                    </span>
                            </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
    name: 'orderDetails',

    data() {
        return {
            Loading: false,
            orderForm: {},
            dataList: [],
            //订单信息
            order_info: [
                {
                    name: "是否赊销",
                    value: "isCredit"
                }, {
                    name: "最大赊销天数",
                    value: "creditDayNum",
                    unit: '天'
                }, {
                    name: "首付款比例",
                    value: "creditFirstpayPercent",
                    unit: '%'
                }, {
                    name: "首付款金额",
                    value: "payAmountFirstpay",
                    unit: '元'
                }, {
                    name: "尾款金额",
                    value: "laterAmount",
                    unit: '元'
                }, {
                    name: "尾款到期日",
                    value: "creditLastPaytimeFormat"
                }
            ],
        }
    },
    created() {
        this.getData()
    },

    methods: {
        getData() {
            this.Loading = true
            this.doPost("/bium-portal/controller/fin-ops/audit/getZctOrderDetail", {
                orderNo: this.$route.query.orderNo
            }).then(({ data }) => {
                this.orderForm = data.result.orderDTO
                this.dataList = data.result.orderGoodsDTOs
                this.Loading = false;
            });
            this.Loading = false;
        },
    },
})
</script>

<style scoped lang="scss">
::v-deep .el-button {
    margin: 5px 0;
}

.acc-container {
    height: auto;
    min-height: 60px;

}

.goodsImg {
    width: 50px;
    height: 50px;
}

.order_list_title {
    background: #00a2ff !important;
}

.order_list p {
    font-size: 13px !important;
}

.payment_list {
    background: #00a2ff !important;
}

.payment {
    font-weight: 500;
    color: #303133 !important;
    font-size: 14px;
}</style>