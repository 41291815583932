<template>
  <div class="user">
    <basic-container class="full-condition">
      <avue-crud
        ref="crud"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange">
        <template slot="menuRight">
          <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="handleSave('add',0)">新增</el-button>
        </template>
        <template slot="auditStatus" slot-scope="scope">
          <span v-if="scope.row.auditStatus == 0">待审核</span>
          <span v-if="scope.row.auditStatus == 1">审核通过</span>
          <span v-if="scope.row.auditStatus == 2">审核失败</span>
        </template>
        <template slot="transferStatus" slot-scope="scope">
          <span v-if="scope.row.transferStatus == 0">转账中</span>
          <span v-if="scope.row.transferStatus == 1">转账成功</span>
          <span v-if="scope.row.transferStatus == 2">转账失败</span>
          <span v-if="scope.row.transferStatus == 3">未知异常</span>
          
        </template>
        <template slot="menu" slot-scope="scope">
          <el-button type="text" @click="handleSave('edit',scope.row.id)">
            查看
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 100,
  searchMenuPosition: 'center',
  searchIndex: 3,
  searchIcon: false,
  searchShowBtn: false,
  border: false,
  sumColumnList: [],
  printBtn: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menualign: 'left',
  menuWidth: 150,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
  searchShowBtn: false,
  column: [
    {
      fixed: true,
      label: 'id',
      prop: 'id',
      span: 24,
      hide: true,
    },
    {
      label: '入账账户名称',
      prop: 'toEnterpriseName',
      editDisplay: true,
      addDisplay: true,
      search: true,
      searchSpan: 6,
      minWidth: 150,
      align: 'left',
    },
	{
	  label: '出账账户名称',
	  prop: 'fromEnterpriseName',
	  editDisplay: true,
	  addDisplay: true,
	  search: true,
	  searchSpan: 6,
	  minWidth: 150,
	  align: 'left',
	  span: 12,
	},
	{
	  label: '转账金额（元）',
	  prop: 'amount',
	  minWidth: 150,
	  span: 12,
	},
	{
	  label: '操作人',
	  prop: 'applyUserName',
	  minWidth: 120,
	  span: 12,
	},
	{
	  label: '操作时间',
	  prop: 'applyTime',
	  minWidth: 120,
	  span: 12,
	},
    {
      label: '审核状态',
      prop: 'auditStatus',

	  },
	  {
	    label: '转账状态',
	    prop: 'transferStatus',
      editDisplay: true,
      addDisplay: true,
    },
    {
      label: '转账成功时间',
      prop: 'transferSuccTime',
      minWidth: 200,
    },
  ],
}

export default {
  name: 'TableUser',
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      page: {
        total: 0, // 总页数
        currentPage: 1,
        pageSize: 10,
      },
      list: [],
      listLoading: true,
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
	submitStatus(status, row) {
	 
      this.doPost('bium-portal/controller/fin-ops/audit/transfer/save', form)
        .then(({ data }) => {
          if (data) {
            this.$notify.success('配置成功。')
            this.getList(this.page)
          }
        })
        .catch(() => {
          this.getList(this.page)
        })
    },
    /** 显示配置弹窗 */
    showModal(item, type) {
      const comp = () => import('./components/config-rate.vue')
      this.$modalDialog(comp, {
        modalType: type,
        modalData: item,
      }).then(() => {
        this.getList(this.page)
      })
    },
    handleSave(type,id) {
     // this.chanId = row.id;
     // this.dialogVisible = true;
     this.$router.push({
      path:'/operate/transferadetails',
      query:{
        type:type,
        id:id
      }
     })
    },
    getList(page, params) {
      params = params
        ? params
        : {
            ...this.searchItmes,
          }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize
      params.nameLike = params.name
      this.listLoading = true
      this.doPost('bium-portal/controller/fin-ops/audit/transfer/zzpage', params).then(({ data }) => {
        if (data) {
          this.list = data.result.records || []
          this.page.total = data.result.total || 0
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },
    chanIncome(row) {
      
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
