//加密
import JSEncrypt from 'jsencrypt'
//str 未加密字符串
export function getEncryCode(str){
    //实例化JSEncrypt
    let jse = new JSEncrypt();
    //加密公钥（由服务端生成）
    let pubKey = process.env.VUE_APP_AES
    jse.setPublicKey(pubKey);
    let  data = jse.encrypt(str.toString());  // 进行加密
    return data;
}
//解密
//params 待解密字符串
export function deciphering(params){
    //实例化JSEncrypt
    let jse = new JSEncrypt();
    //使用前端私钥解密（私钥由服务端生成）
    let privKey = 'MIIEogIBAAKCAQEAjTix+X4lPGxn3FKtX91TY54wsjxyhr8QHbLPhS74054flxjcUkwNwx6yq7HSM62nSfFvoZBsfZiDPvL0wDjjjNX9Xmp3Bc+Ag3IwUVcPRZGvOs2qp/ywi2mkwR28KBESx1Ms8mqq2XGlxhSHbY2FpWJU3cVrm6uf5NP0+d3N8o4/Q1gEQdRnhgh/lgdjUfb8HU94F+XFQ42ReiroYWIqQ0nwxhgkNni+ASZHlzzd96H0J9/CSbqFHg+YU284JjvfLpse1fbhwI/uQJel1pSNcmZCUcx5CQ7GDTbInU7e9MGEPXCPMRfFvARHRMVk6K1l1O8UGFhCoDvMnUa4GPd0DwIDAQABAoIBACseRmjw9RmP195TeBAvGYjvAdtJzYYKzDblI6t8fgjO3MJbmtmd0w3iT5dQwed5e/x9tSHVYOqwKFKOB+Cw/flAVQ4A37pscsavlMUhGc6Z/rCrnxGPe6/1X5J/K7XoEJ2/bZfOrb+ajYz/M6JldYrbjSHxZzuumTFa7g9kg78s7fbxA7YLmTd+FvPsdISgJiv2+wVGyYrGvzBX/AmO0En0ilQrp24EMmCpo0mKlScwqGdWggus7IFyhRnkD0CS4mW46ljkf1g0uh6rcDXd7nVHhPQnP/iEaMvx801xVO4dBkFehbbIxyVPvP3uNa+Dbts9V3c7fuf5cN0S3Zm3uUECgYEA3ZgWbZJpt6WyA4WBRrkOjKOI598b7eeE7FibeYOPu+hcPzHKslBJgLXX+fZPTZivmIXLkts6t+Zw0yBM7pCAO8AMrDqEz7lHgJ1pOk/Eg8HmGWQAKWMVf3LUQQDiaKs3vuWOVNSyONB2+wbLL5mxGJTjZ4Qq/IIx5hdpoeglAv8CgYEAoyX1vWKWUpjUmSgzGeWVkumaQmHbvefjxjob5ublAmSXACYFmeVr8+y1s6fC7UFezT2I+SIb6Cflgw0y541dPBpblFVnEJy2F0NCsw0++tVYs5CHtdIu/ftm6i5DPH4r8Bgv7CZTGl10cuINgYLCftrsJES3XcRGOXHH9/b6XvECgYANW/DmNDB5aNzdh3vutpNg+S70w6ecZRvHX+QnpD3TF0QQb0EIFP0JbG0T3byUMgNMD6zhMqRMl/DvWYAp1b5VW9OnwnNlToc4AsVoRYzUYKktmLDFzfpqz5JqRNJhewkGUjKNfLEt6atyoMs5GGvrznYTNI2e4X5+n6S3pRYuIwKBgHGVjQfEKnno0yKqHSGreD4nCvGqZa1KU4b/j8tq9wd6drJwxjeFxWm2ZC5m3swFVfY4IDOq7pjrpdtehhE1lzKv+xBDgRTMte1uM3KrByzk+fNR7VnFsle6/sSNXtwvz3FBtaOIbxUM+XS+Mr73BWgCDKOXf731VxrJP3pAZcFRAoGAC0RlrnwC6bZQJbxcvUTxa/ELwfSCvSGF5ipz5ONZRdAgPmXzpQkoIEywKKGAUttJxcwSUXjxFn4bDWbWzEbCBKhRC6LF7wnHi65QzhLFCXvsKzsKq/9ENl4u2jnc/7fusCtWfDYxAjNuWnuSEyKqZ2tGjkq4iOfPM+yBpCvUOFU='

    jse.setPrivateKey(privKey);
    let data = jse.decrypt(params); //解密

    return data;
}
