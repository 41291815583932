/**
 * 全局通用配置
*/

// 页码
export const PAGE = {
  total: 0, // 总页数
  pageNum: 1,
  pageSize: 10, // 每页显示多少条
}
