var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "EForm",
          staticClass: "pl50 pr50",
          attrs: {
            "label-position": "right",
            model: _vm.cForm,
            rules: _vm.cRules,
            "label-width": "130px",
            size: "small",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt10 inner-box" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户资金方", prop: "channelId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.cForm.channelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "channelId", $$v)
                        },
                        expression: "cForm.channelId",
                      },
                    },
                    _vm._l(_vm.fundingPartner, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "对客费率方案：", prop: "" } },
                [
                  !_vm.isRate
                    ? _c(
                        "span",
                        { staticClass: "cursor", on: { click: _vm.getRate } },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(" 未配置 "),
                          ]),
                          _c("el-icon", { staticClass: "el-icon-refresh" }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml15",
                      attrs: { type: "primary" },
                      on: { click: _vm.toConfigStandardPlan },
                    },
                    [_vm._v("\n          查看\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }