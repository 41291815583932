var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "basic-container",
        { staticClass: "full-condition" },
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              "cell-class-name": _vm.handleCellClassName,
              data: _vm.dataList,
              option: _vm.option,
              page: _vm.page,
              "table-loading": _vm.listLoading,
            },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "on-load": _vm.getList,
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentChange,
              "search-change": _vm.handleFilter,
              "search-reset": _vm.resetFilter,
              "refresh-change": _vm.handleRefreshChange,
            },
            scopedSlots: _vm._u([
              {
                key: "menu",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.detailsChange(scope.row, scope.index)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "custom-class": "pub_dialog",
            title: "账户明细",
            top: "50px",
            width: "98%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleCloseDialog,
          },
        },
        [
          _vm.accountId
            ? _c("account-flow", {
                attrs: {
                  "account-id": _vm.accountId,
                  "account-name": _vm.accountName,
                },
                on: { change: _vm.handleCloseDialog },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            visible: _vm.isvisible,
            title: "新增渠道",
            top: "100px",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isvisible = $event
            },
          },
        },
        [_c("channelAdd")],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            visible: _vm.Caseisvisible,
            title: "新增案例",
            top: "100px",
            width: "550px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.Caseisvisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "cForm",
              staticStyle: { "padding-right": "20px" },
              attrs: {
                "label-position": "right",
                model: _vm.cForm,
                rules: _vm.vRules,
                "label-width": "120px",
                size: "small",
              },
            },
            [
              _c(
                "div",
                { staticClass: "mt10 inner-box" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "案例详情", prop: "calue" } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 4,
                          placeholder: "请输入案例详情",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.cForm.calue,
                          callback: function ($$v) {
                            _vm.$set(_vm.cForm, "calue", $$v)
                          },
                          expression: "cForm.calue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tc" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.loading,
                        size: "medium",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.Caseisvisible = false
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-standard channel-config-rate",
          attrs: {
            visible: _vm.casedialog,
            title: "案例记录",
            top: "100px",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.casedialog = $event
            },
          },
        },
        [_c("CaseRecord")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }