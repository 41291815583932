var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("div", { staticClass: "stl_02" }, [
        _c("div", { staticClass: "view" }, [
          _c("div", { staticClass: "stl_05 stl_06" }, [
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "9.1687em", left: "22.1375em" },
              },
              [
                _c("span", { staticClass: "stl_07 stl_08 stl_09" }, [
                  _vm._v("服"),
                ]),
                _c("span", { staticClass: "stl_10 stl_08 stl_09" }, [
                  _vm._v("务协议  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "12.8958em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("本协议由"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("${companyName}  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "14.5625em", left: "8.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("以下简称"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("“"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("甲方"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("”"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "）与【深圳前海环融联易信息科技服务有限公司】（以下  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "16.2291em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("简称"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("“"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("乙方"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("”"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("）、【  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "17.8958em", left: "8.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("（以下简称"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("“"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("丙方"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("”"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("）共同缔结。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "14.5625em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("（"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "17.8958em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("】"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "20.5191em", left: "21.8042em" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "stl_13 stl_14 stl_09",
                    staticStyle: { "font-weight": "bold" },
                  },
                  [_vm._v("【")]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "20.5191em", left: "22.8042em" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "stl_13 stl_14 stl_09",
                    staticStyle: { "font-weight": "bold" },
                  },
                  [_vm._v("签约声明】  ")]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "22.5834em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "一、甲方确认，在签署本协议前，已经充分阅读、理解并接受本协议的全部内  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "24.5334em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "容（特别是权利限制、争议管辖、费用计算条款等粗体加下划线部分内容），  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "26.4834em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("甲方在线确认"),
                ]),
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "同意本协议或实际已行使本协议项下的权利或履行义务的，即表  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "28.4334em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "示其同意遵循本协议之所有约定，本协议即在各方之间订立并生效。  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "30.3834em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "二、本协议内容包括签约声明、协议正文及本协议签订时在乙方及"
                  ),
                ]),
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("或其关联公  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "32.3334em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "司网站上已经发布的相关各类规则（如有）等。所有这些规则都应视为本协议  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "34.2834em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("不可分割的组成部分，与本协议具有同等法律效力。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "36.2334em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "三、甲方同意：乙方可根据运营需要对本协议内容及相应规则进行补充及"
                  ),
                ]),
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("或变  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "38.1834em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("更，并以书面（包括但不限于在乙方及"),
                ]),
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("或其关联公司网站公告、推送系统消息  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "40.1334em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("、"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "40.1334em", left: "8.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "发送短信通知、发送邮件通知等）的方式通知甲方。若甲方在收到变更本协  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "42.0834em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "议内容的书面通知后未及时提出书面异议，或者继续使用本协议项下服务（包  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "44.0334em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "括但不限于接受向资金方申请保理融资服务等），即表示甲方已充分阅读、理  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "45.9834em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "解并同意接受修改后的协议内容，也将遵循修改后的协议内容；若甲方不同意  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "47.9334em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "修改后的协议内容，应立即停止使用相应的信息技术服务，并向乙方提交终止  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "49.8834em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("协议的申请。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "51.4844em", left: "21.8042em" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "stl_13 stl_14 stl_09",
                    staticStyle: { "font-weight": "bold" },
                  },
                  [_vm._v("【")]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "51.4844em", left: "22.8042em" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "stl_13 stl_14 stl_09",
                    staticStyle: { "font-weight": "bold" },
                  },
                  [_vm._v("协议正文】  ")]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "53.5487em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("一、定义  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "55.4987em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("1"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "55.4987em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".1"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("线上平台：是指【  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "57.4487em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("】"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "57.4487em", left: "8.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("平台（具体名称以平台实际展示为准），包括网址【"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("https:// -  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "59.3987em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("aicfo.llschain.com"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "】）及已经开发或将来不时开发的实现线上平台全部或部  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "61.3487em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "分功能的互联网应用程序（如有）、微信小程序（如有）等。  "
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "stl_02" }, [
        _c("div", { staticClass: "view" }, [
          _c("div", { staticClass: "stl_05 stl_06" }, [
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "6.4077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("1"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "6.4077em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".2"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "乙方的关联公司：是指乙方之母公司、子公司、分公司、控股公司及与乙方  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "8.3577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("处于同一母公司直接或间接控制下的公司。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "10.4484em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("1"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "10.4484em", left: "7.9833em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".3  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "12.2577em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "资金监管账户：指甲方与第三方服务方确定的用于收取应收账款回款的银行监  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "14.2077em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("管账户。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "16.1577em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("1"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "18.2484em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("1"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "16.1577em", left: "7.9833em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".4"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "资金方：保理融资服务的提供方，包括但不限于商业保理公司。  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "18.2484em", left: "7.9833em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".6  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "20.0577em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("第三方服务方：资金方及"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("或与甲方达成寄售货品合作并为甲方提供寄售货品相  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "22.0077em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("关服务的主体。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "24.0984em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("1"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "24.0984em", left: "7.9833em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".7  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "25.9077em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "寄售协议：甲方与第三方服务方就达成寄售货品合作而签署的相关协议，具体  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "27.8577em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "名称以甲方、第三方服务方以及其他主体（如有）实际签署的为准。  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "29.9151em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_17 stl_08 stl_09" }, [
                  _vm._v("1"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "29.8077em", left: "7.9208em" },
              },
              [
                _c("span", { staticClass: "stl_17 stl_08 stl_09" }, [
                  _vm._v(".8"),
                ]),
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("【"),
                ]),
                _c("span", { staticClass: "stl_17 stl_08 stl_09" }, [
                  _vm._v("RPA"),
                ]),
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("工具】是指用于采集企业客户授权数据的技术工具，包括"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("电商"),
                ]),
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("模"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("块"),
                ]),
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("下的"),
                ]),
                _c("span", { staticClass: "stl_17 stl_08 stl_09" }, [
                  _vm._v("RPA"),
                ]),
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("工具  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "31.8192em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("等。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "35.6577em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("二、服务内容  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "37.6077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("2"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "39.5577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("2"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "37.6077em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".1"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "丙方负责线上平台的客户拓展，甲方为丙方拓展的客户。  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "39.5577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".2"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "甲方可通过线上平台使用乙方提供的电商模块、资金监管模块、账户中心模  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "41.5077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "块等功能服务，甲方还可通过线上平台向第三方服务方申请保理融资业务或寄  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "43.4577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("售货品相关服务。"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "特别地，在甲方与第三方服务方签署保理合同（具体合同名  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "45.4077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "称以双方实际签署的为准）后，甲方授权委托乙方于每个自然日或每个工作日  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "47.3577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("（"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "47.3577em", left: "8.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "具体根据第三方服务商的规定确定）就前一日或前一工作日甲方在相关电商  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "49.3077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("平台店铺项下新增的已发货订单项下应收账款"),
                ]),
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("未来应收账款，自动向第三方服  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "51.2577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("务方发起单笔保理融资申请（以下称"),
                ]),
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("“"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("自动融资授权"),
                ]),
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("”"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("）"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("。前述相关电商平台  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "53.2077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "店铺以甲方或丙方于线上平台维护的甲方店铺信息为准。  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "55.1577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("2"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "55.1577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v(".3"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "甲方了解并确认，乙方为甲方提供的服务自第三方服务方向甲方发放保理融  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "57.1077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "资款或第三方服务方向甲方首次支付寄售协议中的首期金额时即视为完成。  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "61.0077em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("3"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "61.0077em", left: "7.9833em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("、服务规则  "),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "stl_02" }, [
        _c("div", { staticClass: "view" }, [
          _c("div", { staticClass: "stl_05 stl_06" }, [
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "6.5484em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("3"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "6.5484em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".1"),
                ]),
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("甲方知悉并同意"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "对于平台展示的融资产品信息，乙方负责按照第三方服务方提供的信  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "8.4192em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "息进行展示，甲方根据自身的情况决定是否向第三方服务方发起融资申请，第三方服务方  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "10.3692em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("根据其自身的审核规则决定是否向甲方提供融资服务"),
                ]),
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "12.2577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("3"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "12.2577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".2"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("甲方使用线上平台提供的"),
                ]),
                _c("span", { staticClass: "stl_20 stl_08 stl_09" }, [
                  _vm._v("RPA"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("工具采集其在直播电商平台相关数据"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("的"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("，甲方需按"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("线上  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "14.2692em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "平台提示提供其在直播电商平台相关账户信息等，该工具可协助甲方收集、传送其在相关  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "16.1577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("直播电商平台的订单数据、账单数据等（以下简称"),
                ]),
                _c("span", { staticClass: "stl_20 stl_08 stl_09" }, [
                  _vm._v("“"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("电商"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("平台账户数据"),
                ]),
                _c("span", { staticClass: "stl_20 stl_08 stl_09" }, [
                  _vm._v("”"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("）"),
                ]),
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("；在使用该功  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "18.1077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("能模块前甲方需按照《电商中心用户须知》（附件"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("一"),
                ]),
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("）的指引完成硬件准备与相应操作。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "20.0577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("3"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "20.0577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".3"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "甲方使用线上平台提供的资金监管服务的，甲方需在线上平台添加相应的监  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "22.0077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("管账户信息，  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "23.9577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "该服务可同时向甲方展示监管账户的实时账户余额、账户流水等信息（以下简  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "25.9077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("称"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("“"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("银行账户信息"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("”"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("）。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "29.834em", left: "7.4833em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("4"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "29.834em", left: "7.9833em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("、"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("权利义务  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "31.7577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("4"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "31.7577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v(".1"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("甲方通过"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("线上"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("平台接受第三方服务"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("方"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("提供的服务时，甲方应当评估该服务是否符合甲  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "33.7077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("方要求，甲方与第三方服务"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("方"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("的权利义务关系取决于甲方与第三方服务"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("方"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("签署的协议"),
                ]),
                _c("span", { staticClass: "stl_20 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("合  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "35.6577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("同。若因第三方服务"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("方"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("提供的服务，甲方与第三方服务"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("方"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("发生纠纷的，甲方与第三方服务  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "37.6077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("方"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("自行协商解决。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "39.5577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("4"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "39.5577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v(".2"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "甲方应确保其指令的真实性、合法性、有效性和完整性，妥善使用"
                  ),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("线上"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("平台，并自行  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "41.5692em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "解决与其交易对手、开户行等其他第三方之间各种争议、纠纷。  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "43.4577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("4"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "45.4077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("4"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "43.4577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".3"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("甲方应按本协议的约定向乙方支付服务费。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "45.4077em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".4"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("甲方确认并同意，"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "一经乙方根据自动融资授权向第三方服务方发起单笔保理  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "47.3577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "融资申请，该申请即对甲方发生相应的法律约束力，甲方不得以任何理由对该  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "49.3077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("等申请的有效性提出任何抗辩。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "51.2577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("4"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "51.2577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".5"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("甲方确认，"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("如甲方拟撤销自动融资授权，甲方需提前【  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "53.2077em", left: "8.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "个工作日告知乙方，且如甲方撤销自动融资授权，不影响乙方根据原有授权  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "55.1577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("所发出的单笔保理融资服务申请的效力。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "53.2077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("】"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "57.1077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("4"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "59.0577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("4"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "57.1077em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".6 "),
                ]),
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("乙方应当按照本协议的约定向甲方提供"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("相应的"),
                ]),
                _c("span", { staticClass: "stl_18 stl_08 stl_09" }, [
                  _vm._v("平台功能服务。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "59.0577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".7"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("乙方仅对"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("线上"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("平台本身的运行、维护和升级，以及通过"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("线上"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("平台准确执行甲方指令承  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "61.0692em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "担责任，本协议另有约定的除外。对于甲方本身指令的准确性、合法性、有效性，以及甲  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "63.0192em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "方与其交易对手、开户行等其他第三方之间各种争议、纠纷，乙方不承担任何责任。  "
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "stl_02" }, [
        _c("div", { staticClass: "view" }, [
          _c("div", { staticClass: "stl_05 stl_06" }, [
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "6.4077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("4"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "6.4077em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v(".8"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("线上"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "平台提供的分析、核算等功能服务，仅依据甲方提供的数据进行分析、核算，服  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "8.4192em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "务结果仅供甲方参考，乙方对此不承担任何明示或默示的保证或担保责任。甲方基于乙方  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "10.3692em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "提供的服务所进行的任何判断、做出的决定、所从事的各项活动等，应由甲方自行承担后  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "12.3192em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "果。特别地，对于来源于第三方（包括但不限于第三方服务商）的数据，乙方无义务对该  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "14.2692em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("数据本身的真实性、完整性、准确性进行分辨或核验。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "18.1077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("五、数据授权  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "20.1984em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("5"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "20.1984em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".1"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "甲方委托乙方不时地从对应的直播电商平台获取其平台账户数据。为实现前述目的，  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "22.0692em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "甲方授权同意乙方保存并使用其提供的直播电商平台账号及密码。  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "23.9577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("5"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "23.9577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v(".2"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("甲方使用线上平台提供的资金监管服务的，"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("甲方委托乙方不时地从直联银行端口  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "25.9077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("获取相应的银行账户数据"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "27.8577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v(".3"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("对于乙方按照"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("与甲方的"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "约定获取的甲方相关信息，包括但不限于甲方主体信息、银行  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "27.8577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("5"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "29.8077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("账户数据、"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("电商"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("平台账户数据等，甲方授权乙方及"),
                ]),
                _c("span", { staticClass: "stl_20 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("或其关联公司为向甲方提供服务的需  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "31.7577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("要保存、整理及使用前述信息。为使用第三方服务"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("方"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("所提供的服务，甲方授权并同意乙方  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "33.7077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("将"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("前述"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("甲方信息提供给第三方服务"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("方"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "35.6577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("5"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "35.6577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v(".4"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("甲方通过"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("线上"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("平台向第三方服务"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("方"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("申请服务的，甲方在此授权同意第三方服务"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("方"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("将甲  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "37.6077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "方办理业务所产生的相关数据信息（包括但不限于融资金额等"
                  ),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("）"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("提供给乙方及"),
                ]),
                _c("span", { staticClass: "stl_20 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("或乙方关  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "39.6192em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("联公司进行使用、处理、分析等。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "41.5077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("5"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "41.5077em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v(".5"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("甲方授权同意乙方或乙方关联公司将"),
                ]),
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("5.3"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("条和"),
                ]),
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("5.4"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("条项下的数据信息提供给丙  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "43.4577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("方。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "47.3577em", left: "7.65em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("六、服务费的收取  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "49.3077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("6"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "49.3077em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".1"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "就每一笔保理融资业务，甲方应向乙方支付平台服务费，服务费"
                  ),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("="),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("【该笔保  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "51.2577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("理融资本金金额】"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("*"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("费率。"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("如甲方与第三方服务方签署的保理合同（具体合同名  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "53.2077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "称以双方实际签署的为准）中约定第三方服务方委托乙方代为收取保理融资利  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "55.1577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "息及服务费的，则本条约定的平台服务费已包括代收的保理融资利息及服务费  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "57.1077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("。"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "57.1077em", left: "8.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "乙方收取平台服务费之后，再根据与第三方服务方的约定对代收的保理融资  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "59.0577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("利息及服务费进行结算。  "),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "stl_02" }, [
        _c("div", { staticClass: "view" }, [
          _c("div", { staticClass: "stl_05 stl_06" }, [
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "6.5484em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("6"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "6.5484em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".2  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "8.3577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "就每一笔寄售货品相关服务订单，甲方应向乙方支付平台服务费，服务费"
                  ),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("="),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("【首  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "10.3077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("期金额】"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("*"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "费率。其中，【首期金额】指的是第三方服务方根据寄售协议向甲方  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "12.2577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "支付的相关金额，具体金额以线上平台留存相关业务数据为准。  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "14.2077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("6"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "14.2077em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".3"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("本协议项下"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("“"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("费率"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("”"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("均以乙方通过线上平台向甲方展示的为准，甲方可登录  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "16.1577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("线上平台进行具体查看。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "18.2484em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("6"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "18.2484em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".4  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "20.0577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("甲方应在收到线上平台关于缴纳服务费的推送"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("待办"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("通知或乙方的书面通知后  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "22.0077em", left: "8.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("按前述相关推送"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("通知"),
                ]),
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("/"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("待办要求的日期向乙方如下指定银行账户支付足额服  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "23.9577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("务费。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "25.9077em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".5"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "甲方若逾期支付本协议项下服务费，甲方应以应付未付金额为基数按照日千  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "27.8577em", left: "7.5em" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "stl_11 stl_08 stl_09",
                    staticStyle: { "word-spacing": "0.25em" },
                  },
                  [
                    _vm._v(
                      "分之一的标准向乙方按日计付违约金，直至付清为准。逾期超过【 "
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "stl_12 stl_08 stl_09",
                    staticStyle: { "word-spacing": "0.25em" },
                  },
                  [_vm._v("5  ")]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "29.8077em", left: "8.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("天的，乙方有权终止为甲方提供本协议项下服务。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "31.7577em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".6"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "若甲方与第三方服务方确定使用资金监管账户作为收取应收账款回款的银行  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "22.0077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("，"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "25.9077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("6"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "29.8077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("】"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "31.7577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("6"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "33.7077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v(
                    "账户，基于乙方负责与监管银行进行对接，根据甲方的授权，资金监管账户的  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "35.6577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("资金监管事项指令由乙方向监管银行发送。"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("甲方确认并同意，就本协议项下的  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "37.6077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "平台服务费，乙方有权直接发送监管资金划转指令，将与甲方应支付的平台服  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "39.5577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("务费等额的款项划转至乙方账户"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("。  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "41.5077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v("6"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "41.5077em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_12 stl_08 stl_09" }, [
                  _vm._v(".7"),
                ]),
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("甲方向乙方指定的以下银行账户支付相应的服务费：  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "43.4577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("账户名：深圳前海环融联易信息科技服务有限公司  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "45.4077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_11 stl_08 stl_09" }, [
                  _vm._v("开户行：平安银行股份有限公司深圳新城支行  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "47.3577em", left: "7.5em" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "stl_11 stl_08 stl_09",
                    staticStyle: { "word-spacing": "0.75em" },
                  },
                  [_vm._v("账 号：")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "stl_12 stl_08 stl_09",
                    staticStyle: { "word-spacing": "0.75em" },
                  },
                  [_vm._v("15567403540066  ")]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "49.3077em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("6"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "49.3077em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v(".8"),
                ]),
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "甲方逾期向乙方支付服务费的，乙方有权自行或委托合作的第三方以合法的  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "51.2577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v(
                    "手段向甲方催收，必要时乙方将通过司法手段维护自身的权益。  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "55.1577em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_15 stl_08 stl_09" }, [
                  _vm._v("七、责任限制  "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "57.2484em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("7"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "57.2484em", left: "8em" },
              },
              [
                _c("span", { staticClass: "stl_16 stl_08 stl_09" }, [
                  _vm._v("."),
                ]),
                _c("span", { staticClass: "stl_20 stl_08 stl_09" }, [
                  _vm._v("1"),
                ]),
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "鉴于网络之特殊属性，发生包括但不限于下列情形导致乙方不能履行本协议下义务或  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "59.1192em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v(
                    "平台不能正常运行的，在乙方已根据现有通用技术进行应急处理与维护的前提下，乙方可  "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "stl_01",
                staticStyle: { top: "61.0692em", left: "7.5em" },
              },
              [
                _c("span", { staticClass: "stl_19 stl_08 stl_09" }, [
                  _vm._v("以免责：  "),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }