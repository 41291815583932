var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "cForm",
          staticStyle: { "padding-right": "20px" },
          attrs: {
            model: _vm.cForm,
            rules: _vm.cRules,
            "label-position": "right",
            "label-width": "120px",
            size: "small",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt10 inner-box" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "案例详情：", prop: "comment" } },
                [
                  _c("el-input", {
                    attrs: {
                      rows: 4,
                      type: "textarea",
                      placeholder: "请输入案例详情",
                    },
                    model: {
                      value: _vm.cForm.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.cForm, "comment", $$v)
                      },
                      expression: "cForm.comment",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tc" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }