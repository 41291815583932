var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "footer-wrap", class: { logined: _vm.logined } },
    [
      _c("div", { class: { "main-wrapper": !_vm.logined } }, [
        _c("div", { staticClass: "footer-info-box" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "list-box" },
              [
                _vm._l(_vm.list, function (item) {
                  return _c(
                    "div",
                    { key: item.url, staticClass: "item-box" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: item.url, target: "_blank" } },
                        [_c("el-link", [_vm._v(_vm._s(item.label))])],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _vm._m(0),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "copy-right-box" },
              [
                _vm._v(
                  "\n          版权所有 © 和信（天津）国际商业保理有限公司 2021 保留一切权利\n          "
                ),
                _c("el-link", {
                  attrs: {
                    href: "http://www.beian.gov.cn/portal/index.do",
                    target: "_blank",
                  },
                }),
              ],
              1
            ),
          ]),
          !_vm.logined ? _c("div", { staticClass: "img-box" }) : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item-box" }, [
      _c("span", { staticStyle: { color: "#999" } }, [
        _vm._v("人工咨询微信公众号"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }