<template>
  <div class="selection-box">
    <section class="acc-container">
      <el-divider content-position="left" class="title-divider">
        资金方结算明细导出
      </el-divider>
      <div class="selection-box">
        <el-form ref="payForm" :model="payForm" :label-position="'right'" label-width="120px" size="small">
          <div class="mt10 inner-box">
            <el-form-item label="日期" prop="billDate" >
              <el-date-picker valueFormat='yyyy-MM-dd' style="width: 350px;" v-model="payForm.billDate" type="daterange"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="企业名称">
              <el-input style="width: 350px;" v-model="payForm.enterpriseName" placeholder="请输入">
              </el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button type="primary" size="medium" :loading="loading" :disabled="!payForm.billDate"
              @click="saveExcel()">下载</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-divider content-position="left" class="title-divider">
        下载记录
      </el-divider>
      <avue-crud ref="crud" :table-loading="isLoading" :page.sync="page" :option="option" :data="dataList"
        @on-load="getList" @size-change="sizeChange" @current-change="currentChange" @search-change="handleFilter"
        @search-reset="resetFilter" @refresh-change="handleRefreshChange">
      </avue-crud>
    </section>
  </div>
</template>
<script>
  import {
    PAGE
  } from '@/const/global.js'
  import {
    doExportPostExcel,
    downExcel
  } from '@/router/axios'
  export default ({
    name: 'order',
    data() {
      return {
        loading: false,
        isLoading: false,
        options: [{
          value: 'Ping++',
          label: 'Ping++'
        }, ],
        payForm: {
          value: 'Ping++',
          billDate: null,
        },
        dataList: [],
        downLoading: false,
        option: {
          // 搜索栏配置
          searchMenuSpan: 8,
          searchMenuPosition: 'left',
          // 表格主体配置
          border: false,
          index: false,
          indexLabel: '序号',
          stripe: true,
          headeralign: 'left',
          align: 'right',
          // 表格操作列配置
          menu: false,
          menuWidth: 150,
          addBtn: false,
          editBtn: false,
          delBtn: false,
          searchShowBtn: false,
          column: [{
              label: '操作人',
              prop: 'userName',
              align: 'left',
            },
            {
              label: '操作时间',
              prop: 'created',
              align: 'left',
            },
            {
              label: '累积下载次数',
              prop: 'totalDownload',
              align: 'left',
            },

          ],
        },
        page: PAGE,

      }
    },
    created() {},
    mounted() {},

    methods: {
      saveExcel() {
        let that = this
        const data = {
          enterpriseName: this.payForm.enterpriseName || null, //企业名称
          postedAtStart: this.payForm.billDate[0] || null, //起始日， 格式 yyyy-MM-dd
          postedAtEnd: this.payForm.billDate[1] || null, //结束日， 格式 yyyy-MM-dd
          pageSize: 1000, //每页数量
          pageNum: 1, //当前页
        }
        this.listLoading = this.downLoading = true
        const api = '/bium-portal/controller/fin-ops/clearing/downloadFundsSettlement'
        doExportPostExcel(api, data)
          .then((res) => {
            let reader = new FileReader()
            reader.onload = function(event) {
              if (event.target.result === '') {
                that.$message.error('导出数据为空或超过最大1000条限制')
                that.listLoading = that.downLoading = false
              } else {
                downExcel(res)
                that.listLoading = that.downLoading = false
              }
            }
            reader.readAsText(res.data)
          })
          .catch(() => {
            this.listLoading = this.downLoading = false
          })

      },
      /** 获取列表数据 */
      getList(page, params) {
        const api = '/bium-portal/controller/fin-ops/clearing/selectByTotalDownload'
        this.doPost(api, {
          moduleName: "资金方结算明细"
        }).then(({
          data
        }) => {
          if (data.result) {
            this.dataList = [data.result] || []
            console.log(this.dataList, 1212)
          }
          this.isLoading = false
        })
      },

      /** 分页变化，存储 */
      sizeChange(pageSize) {
        this.page.pageSize = pageSize
      },
      /** 当前页码变化，存储 */
      currentChange(current) {
        this.page.currentPage = current
      },
      /** 点击搜索栏搜索 */
      handleFilter(param, done) {
        this.page.currentPage = 1
        this.searchItmes = param ? param : {}
        this.getList(this.page, param)
        done()
      },
      /** 重置条件搜索 */
      resetFilter() {
        this.page.currentPage = 1
        this.searchItmes = {}
        this.getList(this.page, this.searchItmes)
      },
      /** 手动刷新列表 */
      handleRefreshChange() {
        this.getList(this.page, this.searchItmes)
      },
    },
  })
</script>

<style scoped lang="scss"></style>
