<template>
  <div class="selection-box">
    <section class="acc-container">
      <el-divider class="title-divider" content-position="left">基本信息</el-divider>
      <div class="jg-acc">
        <div class="card-box">
          <div class="card-item fullWidth">
            <div class="item_label">客户名称：</div>
            <div>{{ form.name }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">联系方式：</div>
            <div>{{ form.mobile }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">店铺数量：</div>
            <div>{{ form.storeCount }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业角色：</div>
            <div>{{ cateTypeText[form.cateType] }}</div>
          </div>
          <!-- <div class="card-item">
            <div class="item_label">产品号:</div>
            <div>1A-极速收款</div>
          </div>
          <div class="card-item">
            <div class="item_label">所属渠道：</div>
            <div v-if=" form.channelId">{{ form.channelName }}</div>
            <div v-else>直客</div>
          </div> -->
        </div>
      </div>
      <el-divider class="title-divider" content-position="left">极速收款</el-divider>
      <div class="jg-acc">
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">开通状态:</div>
            <div>{{ form.openProductTypeA1 ? '已开通' : '未开通' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业已用额度:</div>
            <div>{{ form.usedAmountA1|| '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业授信上限金额:</div>
            <div>{{ form.creditAmountA1|| '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业开通店铺数:</div>
            <div>{{ form.storeCountA1 }}家</div>
          </div>
        </div>
      </div>
      <el-divider class="title-divider" content-position="left">极速付款</el-divider>
      <div class="jg-acc">
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">角色:</div>
            <div>{{ form.zctRoleType ? zctRoleTypeText[form.zctRoleType] : '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">开通状态:</div>
            <div>{{ form.openProductTypeA2 ? '已开通' : '未开通' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业已用额度:</div>
            <div>{{ form.usedAmountA2 || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业授信上限金额:</div>
            <div>{{ form.creditAmountA2 || '--' }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业开通店铺数:</div>
            <div>{{ form.storeCountA2 }}家</div>
          </div>
        </div>
      </div>
      <!-- <el-divider v-if="shopList.length" class="title-divider" content-position="left">店铺信息</el-divider>
      <div v-if="shopList.length" class="jg-acc">
        <div v-for="(u,index) in shopList" :key="index" class="card-box">
          <div class="card-item">
            <div class="item_label">店铺名:</div>
            <div>{{ u.name }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">所属平台:</div>
            <div v-if="u.livePlatform == 10">抖音</div>
            <div v-if="u.livePlatform == 11">快手</div>
            <div v-if="u.livePlatform == 12">拼多多</div>
            <div v-if="u.livePlatform == 13">腾讯视频号</div>
            <div v-if="u.livePlatform == 14">美团</div>
          </div>
          <div class="card-item">
            <div class="item_label">在贷融资:</div>
            <div>{{ u.inLoanCount }}笔</div>
          </div>
          <div class="card-item">
            <div class="item_label">平均回款周期:</div>
            <div v-if="u.avgTurnoverDays">{{ u.avgTurnoverDays }}天</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">折扣率:</div>
            <div v-if="u.financingRatio">{{ (Number(u.financingRatio) * 100).toFixed(2) }}%</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">剩余应还总额:</div>
            <div>{{ u.residueAmount }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">状态:</div>
            <div v-if="u.overdue" style="color:red">逾期</div>
            <div v-if="!u.overdue" style="color:green">正常</div>
          </div>
          <div class="card-item">
            <el-button type="primary" @click="goShop(u)">查看详情</el-button>
          </div>
        </div>
      </div> -->
      <!-- <el-divider class="title-divider" content-position="left">监管账户信息</el-divider>
      <div class="jg-acc">
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">账户号:</div>
            <div v-if="accountFrom.accountNo">{{ accountFrom.accountNo }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">所属银行:</div>
            <div v-if="accountFrom.bankName">{{ accountFrom.bankName }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">开户支行号:</div>
            <div v-if="accountFrom.bankBranchNo">{{ accountFrom.bankBranchNo }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">账户系统:</div>
            <div v-if="accountFrom.paymentChannel == 1">Ping++</div>
            <div v-else-if="accountFrom.paymentChannel == 2">富友支付</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">开户行地址:</div>
            <div v-if="accountFrom.bankBranchAddress">{{ accountFrom.bankBranchAddress }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">账户余额:</div>
            <div>{{ accountFrom.totalBalance }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">可用额度:</div>
            <div>{{ accountFrom.availableAmount }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">冻结额度:</div>
            <div>{{ accountFrom.frozenAmount }}</div>
          </div>
        </div>
      </div> -->
      <el-divider class="title-divider" content-position="left">企业营业信息</el-divider>
      <div class="jg-acc">
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item fullWidth">
            <div class="item_label">企业名称:</div>
            <div>{{ form.name }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">社会信用代码:</div>
            <div>{{ form.creditCode }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">营业执照有效期:</div>
            <div>{{ form.establishmentDate }}至{{ form.validDate }}</div>
          </div>
          <div class="card-item">
            <div class="item_label">企业地址信息:</div>
            <div>{{ form.address }}</div>
          </div>
        </div>
      </div>
      <el-divider class="title-divider" content-position="left">企业法人信息</el-divider>
      <div class="jg-acc">
        <!--开户成功，有数据-->
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">法人姓名:</div>
            <div v-if="form.legalName">{{ form.legalName }}</div>
            <div v-else>--</div>
          </div>
          <!-- <div class="card-item">
            <div class="item_label">法人性别:</div>
            <div v-if="form.legalGender == 1">男</div>
            <div v-if="form.legalGender == 2">女</div>
            <div v-if="!form.legalGender ">--</div>
          </div> -->
          <div class="card-item">
            <div class="item_label">法人身份证:</div>
            <div v-if="form.legalCard">{{ form.legalCard }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">身份证有效期:</div>
            <div v-if="form.legalDuration">{{ form.legalDuration }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">法人联系邮箱:</div>
            <div v-if="form.legalMail">{{ form.legalMail }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">法人手机号码:</div>
            <div v-if="form.legalMobile">{{ form.legalMobile }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">民族:</div>
            <div v-if="form.legalEthnic">{{ form.legalEthnic }}</div>
            <div v-else>--</div>
          </div>
          <!-- <div class="card-item">
            <div class="item_label">身份证住址:</div>
            <div v-if="form.legalAddress">{{ form.legalAddress }}</div>
            <div v-else>--</div>
          </div> -->
        </div>
      </div>
      <el-divider class="title-divider" content-position="left">授权人信息</el-divider>
      <div class="jg-acc">
        <div class="card-box">
          <div class="card-item">
            <div class="item_label">授权人姓名:</div>
            <div v-if="form.authName">{{ form.authName }}</div>
            <div v-else>--</div>
          </div>
          <!-- <div class="card-item">
            <div class="item_label">授权人性别:</div>
            <div v-if="form.authorizationGender == 1">男</div>
            <div v-if="form.authorizationGender == 2">女</div>
            <div v-if="!form.authorizationGender">--</div>
          </div> -->
          <div class="card-item">
            <div class="item_label">授权人身份证:</div>
            <div v-if="form.authCard">{{ form.authCard || '--' }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">身份证有效期:</div>
            <div v-if="form.authDuration">{{ form.authDuration || '--' }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">授权人联系邮箱:</div>
            <div v-if="form.authMail">{{ form.authMail || '--' }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">授权人手机号码:</div>
            <div v-if="form.authMobile">{{ form.authMobile || '--' }}</div>
            <div v-else>--</div>
          </div>
          <div class="card-item">
            <div class="item_label">民族:</div>
            <div v-if="form.authEthnic">{{ form.authEthnic || '--' }}</div>
            <div v-else>--</div>
          </div>
          <!-- <div class="card-item">
            <div class="item_label">身份证住址:</div>
            <div v-if="form.authAddress">{{ form.authAddress || '--' }}</div>
            <div v-else>--</div>
          </div> -->
        </div>
      </div>
      <!-- <el-divider class="title-divider" content-position="left">文件信息</el-divider>
      <div class="jg-acc">
        <div class="attachList">
          <div
            class="attachList_item"
            v-for="item in attachList"
            :key="item.id"
          >
            <p class="attachList_item_name">{{ item.fileName.split('.')[0] }}</p>
            <div class="attachList_item_img">
              <img
                :src="getFile(item.id)"
              />
            </div>
          </div>
        </div>
      </div> -->
    </section>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { doImg } from '../../router/axios'

export default defineComponent({
  name: 'clientDetail',
  components: {},
  data() {
    return {
      residueAmountType: true,
      isvisible: false,
      page: {
        total: 1, // 总页数
        currentPage: 1,
        pageSize: 10
      },
      form: {},
      list: [{}],
      listLoading: false,
      accountFrom: {},
      shopList: [],
      cateTypeText: {
        0: '普通商户',
        4: '渠道',
        5: '资金方'
      },
      zctRoleTypeText: {
        1: '供应商',
        2: '集采商',
        3: '经销商'
      },
      attachList: [],
    };
  },
  created() {
    this.queryAttachList();
    this.getDetails();
  },
  mounted() {
  },

  methods: {
    goShop(row) {
      this.$router.push(`/operate/shopDetail?id=${row.enterpriseId}&shopid=${row.id}&storeCode=${row.storeCode}`);
    },
    editResidueAmoun() {
      if (!this.residueAmountType) {
        if (!this.form.creditAmount) return this.$message.error('请输入要修改的额度');
        this.doPost('bium-portal/controller/fin-ops/enterpriseProduct/modifyCreditAmount', {
          enterpriseId: this.form.enterpriseId,
          creditAmount: this.form.creditAmount
        }).then(res => {
          this.$message.success('修改成功');
          this.residueAmountType = true;
        });
      } else {
        this.residueAmountType = false;
        this.$forceUpdate();
      }
    },
    getRatePlanDetail() {
      return;
      this.doPost('bium-portal/controller/fin-ops/ratePlan/getRatePlanDetail', {
        storeId: this.form.id,
        accountType: 1
      }).then(res => {
        this.form.customerRate1 = (Number(res.data.result.custRateDetailDTOList[0].customerRate) * 100).toFixed(2);
        this.$forceUpdate();
      });
    },
    getselectAccount() {
      this.doPost('bium-portal/controller/fin-ops/account/selectAccount', {
        companyCode: this.form.enterpriseCode,
        accountType: 1
      }).then(res => {
        console.log(res.data);
        if (res.data.result) {
          this.accountFrom = res.data.result[0];
        }
      });
    },
    getlistStoreClearByEnterprise() {
      this.doPost('bium-portal/controller/fin-ops/store/listStoreClearByEnterpriseId', {
        enterpriseId: this.form.id,
        pageSize: 100,
        pageNum: 1
      }).then(res => {
        this.shopList = res.data.result.records;
      });
    },

    queryAttachList() {
      let self = this;
      this.doPost('bium-portal/controller/fin-ops/attach/list', {
        enterpriseId: this.$route.query.id
      }).then(res => {
        let result = res.data.result;
        self.attachList = result;
      });
    },
    getFile(id) {
      doImg("/bium-portal/controller/fin-ops/attach/viewById", {id: id}).then(({
        data
      }) => {
        if (data) {
          return window.URL.createObjectURL(data);
        }
      });
		},
    getDetails() {
      this.doPost('bium-portal/controller/fin-ops/enterprise/getEnterpriseDetailById', {
        id: this.$route.query.id
      }).then(res => {
        this.form = res.data.result;
        this.getselectAccount();
        this.getlistStoreClearByEnterprise();
        this.getRatePlanDetail();
        const data = res.data.result;
        if (this.form.dailyRepaymentRatio) {
          this.form.dailyRepaymentRatio = this.form.dailyRepaymentRatio.match(/(\S*)%/)[1];
          this.form.dailyRepaymentRatio = `${(Number(this.form.dailyRepaymentRatio) * 100).toFixed(2)}%`;
        } else {
          this.form.dailyRepaymentRatio = '0.00%';
        }
      });
    },
    toConfigStandardPlan() {
      const comp = () => import('../expense/components-plan/plan-view.vue');
      this.$modalDialog(comp);
    },
    viewImg() {
      this.isvisible = true;
    }
  }
});
</script>

<style lang="scss" scoped>
::v-deep .el-button {
  margin-bottom: 10px;
}

.acc-container {
  height: auto;
  min-height: 60px;

  .acc-tip {
    text-align: center;
  }

  .dis-act {
    color: #909399;
    background-color: #eee;
    border-color: #eee;
    cursor: not-allowed;
    margin: 0;

    i {
      font-style: normal;
      color: #f00;
    }
  }

  /deep/ .el-loading-mask {
    top: -8px;
  }
}

/deep/ .inner-box {
  margin: 20px;
  width: auto;
}

/deep/ .el-button--small .iconfont {
  font-size: 12px !important;
}

/deep/ .account-list .account-list-item .tag {
  left: 0;
  right: auto;
  border-top-right-radius: 20px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
  font-weight: normal;
  font-size: 12px;
}

/deep/ .account-list .account-list-item {
  /*min-width: 120px;*/
  height: 75px;
  text-align: center;
  padding-top: 32px;
}

/deep/ .svg-waves {
  display: none;
}

/deep/ .account-list .account-list-item.actived {
  background-image: radial-gradient(circle at center, #4a98f9, #5bacfa);
}

/deep/ .account-list .account-list-item .success {
  background-color: #66cc66;
}

/deep/ .account-list .account-list-item .warn {
  background-color: #ffcc33;
}
.card-box {
}
.card-item {
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 14px;
}
.item_label {
  height: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 14px;
  margin-bottom: 8px;
}
.fullWidth {
  width: 100%;
}
.attachList {
  display: flex;
  flex-wrap: wrap;
}
.attachList_item {
  width: 48%;
  margin-bottom: 10px;
  position: relative;
  height: 205px;
}
.attachList_item_name {
  height: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 14px;
  margin-bottom: 8px;
}
.attachList_item:nth-child(2n) {
  margin-left: 2%;
}
</style>
