<template>
  <div class="user">
    <!-- <el-descriptions style="margin: 10px;" border >
      <el-descriptions-item label="结清批次号">{{ $route.query.repayNo }}</el-descriptions-item>
    </el-descriptions> -->
    <basic-container class="full-condition">
      <avue-crud ref="crud" :option="option" :page.sync="page" :table-loading="listLoading" :data="dataList"
        :cell-class-name="handleCellClassName" @on-load="getList" @size-change="sizeChange"
        @current-change="currentChange" @search-change="handleFilter" @search-reset="resetFilter"
        @refresh-change="handleRefreshChange">
        <template slot="menuRight">
          <!-- <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="handleSave()">添加新渠道</el-button> -->
        </template>
        <template slot="menuLeft">
          <!-- <el-button
                class="filter-item"
                type="primary"
                icon="icon-YUAN"
                @click="handleWidthdrawal"
                >提现
              </el-button>
              <el-button
                class="filter-item"
                type="primary"
                icon="icon-YUAN"
                @click="checkFlow"
                >流水核查
              </el-button> -->
        </template>

      </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import channelAdd from '@/views/channel/components/channelAdd'
import { mapGetters } from 'vuex'
const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchMenuPosition: 'center',
  searchIndex: 3,
  searchShowBtn: false,
  border: false,
  showSummary: false,
  printBtn: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menu: false,
  searchIndex: 3,
  searchIcon: false,
  searchShowBtn: false,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
  column: [
    // {
    //   label: '支用金额',
    //   width: 'auto',
    //   prop: 'amount',
    //   editDisplay: true,
    //   addDisplay: true,
    //   span: 12,
    // }, {
    //   label: '放款日期',
    //   width: 'auto',
    //   prop: 'date',
    //   editDisplay: true,
    //   addDisplay: true,
    //   span: 12,
    // }, 
    {
      label: '商品订单号',
      width: 'auto',
      prop: 'orderId',
      editDisplay: true,
      addDisplay: true,
      span: 12,
    },
    {
      label: '融资单号',
      prop: 'applyNo',
      width: 'auto',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      label: '结算时间',
      prop: 'settledDate',
      editDisplay: false,
      width: 'auto',
      addDisplay: false,
      span: 12,
    },
    {
      label: '商品订单结算金额',
      width: 'auto',
      prop: 'estimatedAmount',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      width: 'auto',
      label: '融资金额',
      prop: 'applyAmount',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      width: 'auto',
      label: '还款本金',
      prop: 'repayablePrincipal',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      width: 'auto',
      label: '还款息费',
      prop: 'interestFee',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
    {
      width: 'auto',
      label: '清分时间',
      prop: 'updated',
      editDisplay: false,
      addDisplay: false,
      span: 12,
    },
  
  ],
}

export default {
  name: 'TableUser',
  components: {
    channelAdd,
  },
  data() {
    return {
      option: tableOption,
      searchItmes: {},
      dialogVisible: false,
      page: {
        total: 0, // 总页数
        currentPage: 1,
        pageSize: 10,
      },
      isvisible: false,
      dataList: [],
      listLoading: true,
      accountId: '',
      accountName: '',
      billingMethod: {
        Charge_Per_transaction: '单笔',
        Annualized: '年化',
      },
      clearStatus: {
        Repayment: '还款中',
        Overdue: '逾期未结清',
        Clearing_Overdue: '逾期清分中',
        Clearing_Apply: '申请结清中',
        PayOff: '正常结清',
        PayOff_Overdue: '逾期结清',
      },
      livePlatform: {
        10: '抖音',
        11: '快手',
        12: '拼多多',
        13: '腾讯视频号',
        14: '美团',
      },
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    syncPermissions() {
      return this.permissions['biz_account_sync']
    },
  },
  watch: {},
  created() {
    this.getNamelist()
  },
  methods: {
    getNamelist() {
      const api = 'bium-portal/controller/fin-ops/account/getEnterpriseNameList'
      this.doPost(api).then(({ data }) => {
        if (data) {
          this.option.column[1].dicData = data || []
        }
      })
    },
    handleSave() {
      this.isvisible = true
    },
    getList(page, params) {
      const api = 'bium-portal/controller/fin-ops/bill/deposit-clearing/detail'
      const { currentPage, pageSize } = page
      params = params
        ? params
        : {
          ...this.searchItmes,
        }
      if (params.accountNameDesc) {
        params.companyId = params.accountNameDesc
      }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize
      // params.storeQuotaId = 32
      // params.batchNo = 'cc6555f192de497c8e694a511117f286_20240901'
      params.storeQuotaId = this.$route.query.storeQuotaId
      params.batchNo = this.$route.query.batchNo
      this.listLoading = true
      this.doPost(api, params).then(({ data }) => {
        if (data.result.records) {
          this.dataList = data.result.records || []
          this.page.total = data.result.total || 0
          this.dataList.forEach((item) => {
            item.mayApplyAmount = this.$route.query.customerRate*100 + '%'
            item.amount = this.$route.query.applyAmount
            item.applyNo = this.$route.query.applyNo
            item.date = this.$route.query.paymentDate
            item.status = this.$route.query.status
            item.repayDate = this.$route.query.repayDate
            item.transferStatus = this.$route.query.transferStatus
            item.procStatus =
              item.procStatus == 0
                ? '未结算'
                : item.procStatus == 1
                  ? '已退货未还款'
                  : item.procStatus == 2
                    ? '已结算'
                    : item.procStatus == 3
                      ? '已还款'
                      : item.procStatus == 4
                        ? '已退货已还款'
                        : ''
          })
        }
        this.listLoading = false
      })
    },

    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },

    handleCloseDialog() {
      this.dialogVisible = false
    },
    handleCellClassName({ row, column, rowIndex, columnIndex }) {
      if ([7].includes(columnIndex)) {
        return 'yellow-cell'
      }
      return ''
    },

    queryBook(row, index) {
      this.listLoading = true
      doGet('/upyzt/balacc/queryBook/' + row.id).then((res) => {
        this.listLoading = false
        this.handleRefreshChange()
      })
    },
    checkFlow() {
      doGet('/cap/account/checkFlow').then((res) => {
        this.handleRefreshChange()
      })
    },
    frozenBook(row, index) {
      this.listLoading = true
      doGet('/upyzt/balacc/frozenBook/' + row.id).then((res) => {
        this.listLoading = false
        this.handleRefreshChange()
      })
    },
    handleWidthdrawal() {
      this.$router.push({
        path: '/account/withdraw',
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>