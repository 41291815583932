<template>
  <div class="user">
    <basic-container class="full-condition">
      <avue-crud
        ref="crud"
        :cell-class-name="handleCellClassName"
        :data="list"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange"
      >

        <template slot="customerRate" slot-scope="scope">
          <span v-if="scope.row.customerRate != null">{{ scope.row.customerRate }}</span>
        </template>
        <template slot="productType" slot-scope="scope">
          <span v-if="scope.row.productType == 0">1A-极速收款</span>
          <span v-if="scope.row.productType == 1">2A-极速付款</span>
        </template>
        <template slot="dataSource" slot-scope="scope">
          <span v-if="scope.row.dataSource == 0">手动获取</span>
          <span v-if="scope.row.dataSource == 1">微风企</span>
        </template>
        <template slot="menu" slot-scope="scope">
          <el-button type="text" @click="detailsChange(scope.row, scope.index)">详情</el-button>
          <el-button type="text" @click="openDIalog('Caseisvisible', scope.row)">新增案例</el-button>
          <el-button type="text" @click="openDIalog('casedialog', scope.row)">查看案例</el-button>
          <el-button
            v-if="['10', '12'].includes(scope.row.livePlatform)"
            type="text"
            @click="openManageData(scope.row)"
          >
            数据管理
          </el-button>
          <el-button
            v-if="['10', '12'].includes(scope.row.livePlatform) && scope.row.dataSource == 1"
            type="text"
            @click="openFetchData(scope.row)"
          >
            获取数据
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="Caseisvisible"
      class="dialog-standard channel-config-rate"
      title="新增案例"
      top="100px"
      width="550px"
    >
      <addCase v-if="Caseisvisible" :row-form="rowItem" />
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="casedialog"
      class="dialog-standard channel-config-rate"
      title="案例记录"
      top="100px"
      width="800px"
    >
      <CaseRecord v-if="casedialog" :row-form="rowItem" />
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="manageshow"
      class="dialog-standard channel-config-rate"
      title="店铺数据管理"
      top="100px"
      width="460px"
    >
      <div class="form_modal">
        <p class="tips">
          选择微风企进行数据获取前,请确保店铺已使用店铺云号完成主账号换绑,并且店铺名称无误,否则数据获取失败!
        </p>
        <el-form
          :model="manageFrom"
        >
          <el-form-item label="数据获取方式">
            <el-select
              v-model="manageFrom.dataSourceType"
              placeholder="请选择"
            >
              <el-option
                :value="Number(0)"
                label="手动"
              />
              <el-option
                :value="Number(1)"
                label="微风企"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="店铺登录密码(拼多多)" v-if="manageFrom.livePlatform == '12'">
            <el-input
              placeholder="请输入"
              v-model="manageFrom.password"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="flex">
        <el-button @click="onCancelManage()">取消</el-button>
        <el-button type="primary" @click="onSubmitManage()">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="fetchshow"
      class="dialog-standard channel-config-rate"
      title="店铺数据获取"
      top="100px"
      width="460px"
    >
      <div class="form_modal">
        <p class="tips">进行店铺数据主动获取前请确保店铺已使用店铺云号完成主账号换绑,并且店铺名称无误,否则数据获取失败!</p>
        <p class="desc">最后一次店铺数据获取状态:<span>{{digital.statusDesc}}</span></p>
        <p class="desc">店铺数据最后更新时间:<span>{{digital.updated}}</span></p>
      </div>
      <div class="flex">
        <el-button
          type="primary"
          @click="onFetchDigital()"
          :disabled="digital.dataStatus == 1"
        >
          手动发起店铺数据获取
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import CaseRecord from './components/CaseRecord';
import addCase from './components/addCase';
import { doGet } from '@/router/axios';

const tableOption = {
  searchMenuSpan: 24,
  searchLabelWidth: 80,
  searchMenuPosition: 'center',
  border: false,
  showSummary: false,
  printBtn: false,
  index: false,
  indexLabel: '序号',
  stripe: true,
  searchIndex: 3,
  searchIcon: false,
  searchShowBtn: false,
  labelWidth: 100,
  editBtn: false,
  delBtn: false,
  align: 'left',
  addBtn: false,
  menu: true,
  column: [
    {
      fixed: true,
      label: 'id',
      prop: 'id',
      span: 24,
      hide: true
    },
    {
      label: '店铺名称',
      prop: 'storeName',
      width: 250,
      search: true,
      searchSpan: 6,
      span: 24
    },
    {
      label: '所属企业',
      prop: 'enterpriseName',
      width: 250,
      search: true,
      searchSpan: 6,
      span: 24
    },
    {
      label: '产品号',
      prop: 'productType',
      type: 'select',
      dicData: [
        { label: ' 1A-极速收款', value: '0' }, { label: '2A-极速付款', value: '1' }],
      search: true,
      width: 150,
      span: 12
    },
    {
      label: '所属平台',
      prop: 'livePlatformDesc',
      width: 140,
      span: 24
    },
    {
      label: '数据获取方式',
      prop: 'dataSource',
      width: 140,
      span: 24
    },
    {
      label: '在贷余额',
      prop: 'pendingPrincipal',
      width: 150,

      span: 24
    }, {
      label: '平均回款天数',
      prop: 'avgTurnoverDays',
      width: 140,

      span: 24
    }, {
      label: '对客费率',
      prop: 'customerRate',
      span: 24
    }
  ]
};

export default {
  name: 'Shop',
  components: {
    CaseRecord,
    addCase
  },
  data() {
    return {
      cForm: {
        calue: ''
      },
      loading: false,
      casedialog: false,
      manageshow: false,
      fetchshow: false,
      manageFrom: {
        id: null,
        dataSourceType: '',
        password: '',
        livePlatform: '',
      },
      vRules: {
        calue: [{ required: true, trigger: 'blur', message: '请输入案例详情' }]
      },
      option: tableOption,
      searchItmes: {},
      dialogVisible: false,
      page: {
        total: 0, // 总页数
        currentPage: 1,
        pageSize: 10
      },
      Caseisvisible: false,
      list: [],
      listLoading: true,
      accountId: '',
      accountName: '',
      digital: {},
      digitalId: null,
    };
  },
  computed: {
    ...mapGetters(['permissions']),
    syncPermissions() {
      return this.permissions['biz_account_sync'];
    }
  },
  watch: {},
  created() {
  },
  methods: {
    /** 关闭弹窗 */
    handleClose() {
      this.Caseisvisible = false;
      this.casedialog = false;
      this.manageshow = false;
      this.fetchshow = false;
      this.manageFrom = {
        id: null,
        password: '',
        dataSourceType: '',
        livePlatform: null
      }
      this.getList(this.page, this.searchItmes);
    },
    openDIalog(title, row) {
      this[title] = true;
      this.rowItem = row;
      this.rowItem.id = '';
    },
    openManageData(row) {
      let self = this;
      self.manageFrom.id = row.id;
      self.manageFrom.dataSourceType = Number(row.dataSource);
      self.manageFrom.livePlatform = row.livePlatform;
      if (row.livePlatform == '12') {
        self.manageFrom.password = row.password;
      }
      console.log('self.manageFrom', self.manageFrom);
      self.manageshow = true;
    },
    onCancelManage() {
      this.manageFrom = {
        id: null,
        password: '',
        dataSourceType: '',
        livePlatform: null
      }
      this.manageshow = false;
    },
    onSubmitManage() {
      let params = {
        id: this.manageFrom.id,
        dataSourceType: this.manageFrom.dataSourceType,
        password: this.manageFrom.password,
      }
      if (this.manageFrom.dataSourceType === '') {
        this.$message.error('请选择数据获取方式');
        return;
      }
      console.log('this.manageFrom', this.manageFrom)
      if (this.manageFrom.livePlatform == '12' && !this.manageFrom.password) {
        this.$message.error('请输入密码');
        return;
      }
      let self = this;
      self.doPost('/bium-portal/controller/fin-ops/store/updateDataSource', params)
        .then(res=>{
          self.onCancelManage();
          self.getList(this.page, this.searchItmes);
        })
    },
    openFetchData(obj) {
      let self = this;
      let params = {
        id: obj.id
      }
      this.digitalId = obj.id;
      self.doPost('/bium-portal/controller/fin-ops/store/selectDataStatus', params)
        .then(res=>{
          self.digital = res.data.result;
          self.fetchshow = true;
        })
    },
    onFetchDigital() {
      let self = this;
      let params = {
        id: self.digitalId
      }
      self.doPost('/bium-portal/controller/fin-ops/store/invokeDataSource', params)
        .then(res=>{
          self.fetchshow = false;
          self.getList(this.page, this.searchItmes);
        })
    },
    getList(page, params) {
      const { currentPage, pageSize } = page;
      params = params || { ...this.searchItmes };
      params.pageNum = currentPage;
      params.pageSize = pageSize;
      this.listLoading = true;
      this.doPost('/bium-portal/controller/fin-ops/store/listPage', params).then(({ data }) => {
        if (data && data.result) {
          this.list = data.result.records || [];
          this.page.total = data.result.total || 0;
        }
        this.listLoading = false;
      });
    },

    sizeChange(size) {
      this.page.pageSize = size;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    handleFilter(param, done) {
      this.page.currentPage = 1;
      this.searchItmes = param || {};
      this.getList(this.page, param);
      done();
    },
    resetFilter() {
      this.page.currentPage = 1;
      this.searchItmes = {};
      this.getList(this.page, this.searchItmes);
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes);
    },

    handleCloseDialog() {
      this.dialogVisible = false;
      this.widthdrawDialogVisible = false;
      this.accountId = '';
      this.accountName = '';
      this.handleRefreshChange();
    },
    handleCellClassName({ row, column, rowIndex, columnIndex }) {
      if ([7].includes(columnIndex)) {
        return 'yellow-cell';
      }
      return '';
    },

    detailsChange(row, index) {
      this.$router.push({ path: `/operate/shopDetail?id=${row.enterpriseId}&shopid=${row.id}&storeCode=${row.storeCode}` });
    },
    queryBook(row, index) {
      this.listLoading = true;
      doGet('/upyzt/balacc/queryBook/' + row.id).then((res) => {
        this.listLoading = false;
        this.handleRefreshChange();
      });
    },
    checkFlow() {
      doGet('/cap/account/checkFlow').then((res) => {
        this.handleRefreshChange();
      });
    },
    frozenBook(row, index) {
      this.listLoading = true;
      doGet('/upyzt/balacc/frozenBook/' + row.id).then((res) => {
        this.listLoading = false;
        this.handleRefreshChange();
      });
    },
    handleWidthdrawal() {
      this.$router.push({
        path: '/account/withdraw'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.flex {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form_modal {
  padding: 0 20px;
}
.tips {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  line-height: 17px;
  margin-bottom: 16px;
}
.desc {
  height: 17px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>

