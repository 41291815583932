<template>
  <div class="user">
    <basic-container>
      <avue-crud
        ref="crud"
        v-model="form"
        :option="option"
        :page.sync="page"
        :table-loading="listLoading"
        :before-open="handleOpenBefore"
        :data="list"
        @on-load="getList"
        @size-change="sizeChange"
        @current-change="currentChange"
        @search-change="handleFilter"
        @search-reset="resetFilter"
        @refresh-change="handleRefreshChange">
        <template slot="menuLeft" />
        <template slot="type" slot-scope="scope">
          <span v-if="scope.row.type == 0">企业审核</span>
          <span v-if="scope.row.type == 1">店铺审核</span>
          <span v-if="scope.row.type == 2">支用审核</span>
          <span v-if="scope.row.type == 3">提现审核</span>
        </template>
        <template slot="status" slot-scope="scope">
          <span v-if="scope.row.status == 0">待审核</span>
          <span v-if="scope.row.status == 1">审核通过</span>
          <span v-if="scope.row.status == 2">审核失败</span>
        </template>
        <template slot="currNode" slot-scope="scope">
          <span v-if="scope.row.currNode == 'ops'">运营</span>
          <span v-if="scope.row.currNode == 'risk'">风控</span>
          <span v-if="scope.row.currNode == 'financial'">财务</span>
        </template>
        <template slot="productType" slot-scope="scope">
          <span v-if="scope.row.productType == 0">1A-极速收款</span>
          <span v-if="scope.row.productType == 1">2A-极速付款</span>
        </template>
        <template slot="menu" slot-scope="scope">
          <el-button type="text" @click="handleApproval(scope.row)">详情</el-button>
          <el-button type="text" v-if="scope.row.status == 0" @click="openDIalog('examinedialog', scope.row)">
            审批
          </el-button>
          <el-button type="text" v-if="scope.row.isBind" @click="openDIalog('Customerdialog', scope.row)">
            客户绑定
          </el-button>
          <!-- <el-button v-if="scope.row.isRatePlan" type="text" @click="openDIalog('Ratesvisible', scope.row)">费率方案
          </el-button> -->
          <el-button type="text" @click="openDIalog('Caseisvisible', scope.row)">新增案例</el-button>
          <el-button type="text" @click="openDIalog('casedialog', scope.row)">案例记录</el-button>
          <el-button v-if="scope.row.isCreditInfo" type="text" @click="openDIalog('creditvisible', scope.row)">
            征信信息
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
    <el-dialog
      title="新增案例"
      class="dialog-standard channel-config-rate"
      width="550px"
      top="100px"
      :visible.sync="Caseisvisible"
      :before-close="handleClose">
      <addCase v-if="Caseisvisible" :rowForm="rowItem"></addCase>
    </el-dialog>
    <el-dialog
      title="案例记录"
      class="dialog-standard channel-config-rate"
      width="800px"
      top="100px"
      :visible.sync="casedialog"
      :before-close="handleClose">
      <CaseRecord v-if="casedialog" :rowForm="rowItem" />
    </el-dialog>
    <el-dialog
      title="审核操作"
      class="dialog-standard channel-config-rate"
      width="800px"
      top="100px"
      :visible.sync="examinedialog"
      :before-close="handleClose">
      <examine v-if="examinedialog" :rowForm="rowItem"></examine>
    </el-dialog>
    <el-dialog
      title="客户归属绑定"
      class="dialog-standard channel-config-rate"
      width="700px"
      top="100px"
      :visible.sync="Customerdialog"
      :before-close="handleClose">
      <customer v-if="Customerdialog" :rowForm="rowItem"></customer>
    </el-dialog>
    <el-dialog
      title="费率方案"
      class="dialog-standard channel-config-rate"
      width="600px"
      top="100px"
      :visible.sync="Ratesvisible"
      :before-close="handleClose">
      <RatePlan v-if="Ratesvisible" :rowForm="rowItem"></RatePlan>
    </el-dialog>
    <el-dialog
      title="征信报告"
      class="dialog-standard channel-config-rate"
      width="1000px"
      top="100px"
      :visible.sync="creditvisible"
      :before-close="handleClose">
      <creditReport v-if="creditvisible" :rowForm="rowItem"></creditReport>
    </el-dialog>
  </div>
</template>

<script>
import CaseRecord from '@/views/operate/components/CaseRecord.vue'
import addCase from '@/views/operate/components/addCase'
import examine from '../components/examine'
import customer from '../components/customer'
import RatePlan from '../components/RatePlan'
import creditReport from '../components/creditReport'
export default {
  name: 'TableUser',
  components: {
    CaseRecord,
    addCase,
    examine,
    customer,
    RatePlan,
    creditReport,
  },
  data() {
    return {
      Ratesvisible: false,
      creditvisible: false,
      casedialog: false,
      Caseisvisible: false,
      examinedialog: false,
      Customerdialog: false,
      searchItmes: {},
      option: {
        border: false,
        index: false,
        indexLabel: '序号',
        stripe: true,
        searchShow: true,
        header: true,
        menualign: 'left',
        menuWidth: '400',
        editBtn: false,
        delBtn: false,
        align: 'left',
        addBtn: false,
        searchShowBtn: false,
        column: [
          {
            label: '企业名称',
            prop: 'enterpriseName',
            align: 'left',
            width: 300,
            search: true,
            span: 12,
          },
          {
            label: '订单号',
            prop: 'auditNo',
            width: 300,
          },
          {
            label: '审核类型',
            prop: 'type',
            type: 'select',
            dicData: [
              { label: ' 企业审核', value: '0' },
              { label: '店铺审核', value: '1' },
              { label: '支用审核', value: '2' },
              { label: '提现审核', value: '3' },
            ],
            search: true,
            span: 12,
          },
          {
            label: '产品类型',
            prop: 'productType',
            type: 'select',
            dicData: [
              { label: ' 1A-极速收款', value: '0' },
              { label: '2A-极速付款', value: '1' },
            ],
            search: true,
            width: 150,
            span: 12,
          },
          {
            label: '金额',
            prop: 'amount',
            span: 12,
            width: 150,
          },
          {
            label: '状态',
            prop: 'status',
            width: 150,
          },
          {
            label: '提交时间',
            prop: 'created',
            type: 'date',
            searchRange: true,
            valueFormat: 'yyyy-MM-dd',
            search: true,
            searchSpan: 8,
            span: 12,
            width: 150,
          },
          {
            label: '审核角色',
            prop: 'currNode',
            span: 12,
            width: 150,
          },
          {
            label: '操作时间',
            prop: 'updated',
            span: 12,
            width: 150,
          },
        ],
      },
      defaultProps: {
        label: 'name',
        value: 'id',
      },
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
        pageSizes: [10, 20, 30, 50, 100],
        isAsc: false, // 是否倒序
      },
      list: [],
      loading: false,
      listLoading: true,
      form: {},
      rowItem: {},
    }
  },
  created() {},
  methods: {
    /** 关闭弹窗 */
    handleClose() {
      this.Caseisvisible = false
      this.Ratesvisible = false
      this.creditvisible = false
      this.casedialog = false
      this.examinedialog = false
      this.Customerdialog = false
      this.getList(this.page, this.searchItmes)
    },
    openDIalog(title, row) {
      console.log("🚀 ~ openDIalog ~ title, row:", title, row)
      if(title === 'examinedialog'&& row.type == 2 && row.currNode == 'financial'){
        this.doPost('bium-portal/controller/fin-ops/audit/getApplyAuditAmount', {
            id: row.id,
          }).then(({ data }) => {
            this.rowItem = row
            this[title] = true
            this.rowItem.amount = data.result
          })
      }else if (title === 'Ratesvisible') {
        this.doPost('bium-portal/controller/fin-ops/ratePlan/getRatePlan', {
          storeId: row.storeId,
        }).then(({ data }) => {
          if (data.result == null) {
            this.$message.error('请先绑定客户')
          } else {
            this[title] = true
            this.rowItem = row
          }
        })
      } else {
        this[title] = true
        this.rowItem = row
      }
    },

    getList(page, params) {
      params = params
        ? params
        : {
            ...this.searchItmes,
          }
      this.listLoading = true
      if (params.created) {
        params.startTime = params.created[0] + ' 00:00:00'
        params.endTime = params.created[1] + ' 23:59:59'
      }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize
      params.isAudit = true
      this.doPost('bium-portal/controller/fin-ops/audit/listAuditPage', params).then(({ data }) => {
        this.list = data.result.records || []
        this.page.total = data.result.total || 0
        console.log(this.page.total, data.result.total)
      })
      console.log(this.page.total)
      this.listLoading = false
    },
    handleApproval(row, index) {
      this.$router.push({ path: '/wfTaskInfo/Taskdetails?id=' + row.id })
    },
    sizeChange(size) {
      this.page.pageSize = size
    },
    currentChange(current) {
      this.page.currentPage = current
    },
    handleFilter(param, done) {
      this.page.currentPage = 1
      this.searchItmes = param ? param : {}
      this.getList(this.page, param)
      done()
    },
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },
    /** 重置条件搜索 */
    resetFilter() {
      this.page.currentPage = 1
      this.searchItmes = {}
      this.getList(this.page, this.searchItmes)
    },
    handleOpenBefore(show, type) {
      window.boxType = type
      // 若是编辑、查看回显名称
      if (['edit', 'views'].includes(type)) {
      } else if (type === 'add') {
      }
      show()
    },
  },
}
</script>
