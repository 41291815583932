var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c(
        "div",
        { staticClass: "statisticsWrap" },
        [
          _c(
            "el-divider",
            {
              staticClass: "title-divider",
              attrs: { "content-position": "left" },
            },
            [_vm._v("数据统计\n    ")]
          ),
          _c(
            "div",
            { staticClass: "dataWrap" },
            _vm._l(_vm.dataList, function (u, i) {
              return _c("div", { key: i, staticClass: "dataList" }, [
                _c("span", [_vm._v(_vm._s(u.value))]),
                _c("span", [_vm._v(_vm._s(u.name))]),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "basic-container",
        { staticClass: "full-condition" },
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              option: _vm.option,
              page: _vm.page,
              "table-loading": _vm.listLoading,
              data: _vm.list,
            },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "on-load": _vm.getList,
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentChange,
              "search-change": _vm.handleFilter,
              "search-reset": _vm.resetFilter,
              "refresh-change": _vm.handleRefreshChange,
            },
            scopedSlots: _vm._u([
              {
                key: "statusDesc",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          effect: "dark",
                          type:
                            scope.row.statusDesc == "已还款"
                              ? "success"
                              : scope.row.statusDesc == "已逾期"
                              ? "danger"
                              : "",
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(scope.row.statusDesc))])]
                    ),
                  ]
                },
              },
              {
                key: "menu",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", icon: "el-icon-view" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDetails(scope.row, scope.index)
                          },
                        },
                      },
                      [_vm._v("详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "融资订单",
            visible: _vm.dialogVisible,
            width: "60%",
            "custom-class": "pub_dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.fileId
            ? _c("page-details", { attrs: { "modal-data": _vm.fileId } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }