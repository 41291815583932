<template>
  <div>
    <div class="stl_02">
      <div class="view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="top:9.1687em; left:22.1375em;"><span class="stl_07 stl_08 stl_09">服</span><span class="stl_10 stl_08 stl_09">务协议 &nbsp;</span></div>
          <div class="stl_01" style="top:12.8958em; left:7.5em;"><span class="stl_11 stl_08 stl_09">本协议由</span><span class="stl_12 stl_08 stl_09">${companyName} &nbsp;</span></div>
          <div class="stl_01" style="top:14.5625em; left:8.5em;"><span class="stl_11 stl_08 stl_09">以下简称</span><span class="stl_12 stl_08 stl_09">“</span><span class="stl_11 stl_08 stl_09">甲方</span><span class="stl_12 stl_08 stl_09">”</span><span class="stl_11 stl_08 stl_09">）与【深圳前海环融联易信息科技服务有限公司】（以下 &nbsp;</span></div>
          <div class="stl_01" style="top:16.2291em; left:7.5em;"><span class="stl_11 stl_08 stl_09">简称</span><span class="stl_12 stl_08 stl_09">“</span><span class="stl_11 stl_08 stl_09">乙方</span><span class="stl_12 stl_08 stl_09">”</span><span class="stl_11 stl_08 stl_09">）、【 &nbsp;</span></div>
          <div class="stl_01" style="top:17.8958em; left:8.5em;"><span class="stl_11 stl_08 stl_09">（以下简称</span><span class="stl_12 stl_08 stl_09">“</span><span class="stl_11 stl_08 stl_09">丙方</span><span class="stl_12 stl_08 stl_09">”</span><span class="stl_11 stl_08 stl_09">）共同缔结。 &nbsp;</span></div>
          <div class="stl_01" style="top:14.5625em; left:7.5em;"><span class="stl_11 stl_08 stl_09">（</span></div>
          <div class="stl_01" style="top:17.8958em; left:7.5em;"><span class="stl_11 stl_08 stl_09">】</span></div>
          <div class="stl_01" style="top:20.5191em; left:21.8042em;"><span class="stl_13 stl_14 stl_09" style="font-weight:bold;">【</span></div>
          <div class="stl_01" style="top:20.5191em; left:22.8042em;"><span class="stl_13 stl_14 stl_09" style="font-weight:bold;">签约声明】 &nbsp;</span></div>
          <div class="stl_01" style="top:22.5834em; left:7.5em;"><span class="stl_15 stl_08 stl_09">一、甲方确认，在签署本协议前，已经充分阅读、理解并接受本协议的全部内 &nbsp;</span></div>
          <div class="stl_01" style="top:24.5334em; left:7.5em;"><span class="stl_15 stl_08 stl_09">容（特别是权利限制、争议管辖、费用计算条款等粗体加下划线部分内容）， &nbsp;</span></div>
          <div class="stl_01" style="top:26.4834em; left:7.5em;"><span class="stl_15 stl_08 stl_09">甲方在线确认</span><span class="stl_16 stl_08 stl_09">/</span><span class="stl_15 stl_08 stl_09">同意本协议或实际已行使本协议项下的权利或履行义务的，即表 &nbsp;</span></div>
          <div class="stl_01" style="top:28.4334em; left:7.5em;"><span class="stl_15 stl_08 stl_09">示其同意遵循本协议之所有约定，本协议即在各方之间订立并生效。 &nbsp;</span></div>
          <div class="stl_01" style="top:30.3834em; left:7.5em;"><span class="stl_15 stl_08 stl_09">二、本协议内容包括签约声明、协议正文及本协议签订时在乙方及</span><span class="stl_16 stl_08 stl_09">/</span><span class="stl_15 stl_08 stl_09">或其关联公 &nbsp;</span></div>
          <div class="stl_01" style="top:32.3334em; left:7.5em;"><span class="stl_15 stl_08 stl_09">司网站上已经发布的相关各类规则（如有）等。所有这些规则都应视为本协议 &nbsp;</span></div>
          <div class="stl_01" style="top:34.2834em; left:7.5em;"><span class="stl_15 stl_08 stl_09">不可分割的组成部分，与本协议具有同等法律效力。 &nbsp;</span></div>
          <div class="stl_01" style="top:36.2334em; left:7.5em;"><span class="stl_15 stl_08 stl_09">三、甲方同意：乙方可根据运营需要对本协议内容及相应规则进行补充及</span><span class="stl_16 stl_08 stl_09">/</span><span class="stl_15 stl_08 stl_09">或变 &nbsp;</span></div>
          <div class="stl_01" style="top:38.1834em; left:7.5em;"><span class="stl_15 stl_08 stl_09">更，并以书面（包括但不限于在乙方及</span><span class="stl_16 stl_08 stl_09">/</span><span class="stl_15 stl_08 stl_09">或其关联公司网站公告、推送系统消息 &nbsp;</span></div>
          <div class="stl_01" style="top:40.1334em; left:7.5em;"><span class="stl_15 stl_08 stl_09">、</span></div>
          <div class="stl_01" style="top:40.1334em; left:8.5em;"><span class="stl_15 stl_08 stl_09">发送短信通知、发送邮件通知等）的方式通知甲方。若甲方在收到变更本协 &nbsp;</span></div>
          <div class="stl_01" style="top:42.0834em; left:7.5em;"><span class="stl_15 stl_08 stl_09">议内容的书面通知后未及时提出书面异议，或者继续使用本协议项下服务（包 &nbsp;</span></div>
          <div class="stl_01" style="top:44.0334em; left:7.5em;"><span class="stl_15 stl_08 stl_09">括但不限于接受向资金方申请保理融资服务等），即表示甲方已充分阅读、理 &nbsp;</span></div>
          <div class="stl_01" style="top:45.9834em; left:7.5em;"><span class="stl_15 stl_08 stl_09">解并同意接受修改后的协议内容，也将遵循修改后的协议内容；若甲方不同意 &nbsp;</span></div>
          <div class="stl_01" style="top:47.9334em; left:7.5em;"><span class="stl_15 stl_08 stl_09">修改后的协议内容，应立即停止使用相应的信息技术服务，并向乙方提交终止 &nbsp;</span></div>
          <div class="stl_01" style="top:49.8834em; left:7.5em;"><span class="stl_15 stl_08 stl_09">协议的申请。 &nbsp;</span></div>
          <div class="stl_01" style="top:51.4844em; left:21.8042em;"><span class="stl_13 stl_14 stl_09" style="font-weight:bold;">【</span></div>
          <div class="stl_01" style="top:51.4844em; left:22.8042em;"><span class="stl_13 stl_14 stl_09" style="font-weight:bold;">协议正文】 &nbsp;</span></div>
          <div class="stl_01" style="top:53.5487em; left:7.5em;"><span class="stl_15 stl_08 stl_09">一、定义 &nbsp;</span></div>
          <div class="stl_01" style="top:55.4987em; left:7.5em;"><span class="stl_12 stl_08 stl_09">1</span></div>
          <div class="stl_01" style="top:55.4987em; left:8em;"><span class="stl_12 stl_08 stl_09">.1</span><span class="stl_11 stl_08 stl_09">线上平台：是指【 &nbsp;</span></div>
          <div class="stl_01" style="top:57.4487em; left:7.5em;"><span class="stl_11 stl_08 stl_09">】</span></div>
          <div class="stl_01" style="top:57.4487em; left:8.5em;"><span class="stl_11 stl_08 stl_09">平台（具体名称以平台实际展示为准），包括网址【</span><span class="stl_12 stl_08 stl_09">https:// - &nbsp;</span></div>
          <div class="stl_01" style="top:59.3987em; left:7.5em;"><span class="stl_12 stl_08 stl_09">aicfo.llschain.com</span><span class="stl_11 stl_08 stl_09">】）及已经开发或将来不时开发的实现线上平台全部或部 &nbsp;</span></div>
          <div class="stl_01" style="top:61.3487em; left:7.5em;"><span class="stl_11 stl_08 stl_09">分功能的互联网应用程序（如有）、微信小程序（如有）等。 &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="top:6.4077em; left:7.5em;"><span class="stl_12 stl_08 stl_09">1</span></div>
          <div class="stl_01" style="top:6.4077em; left:8em;"><span class="stl_12 stl_08 stl_09">.2</span><span class="stl_11 stl_08 stl_09">乙方的关联公司：是指乙方之母公司、子公司、分公司、控股公司及与乙方 &nbsp;</span></div>
          <div class="stl_01" style="top:8.3577em; left:7.5em;"><span class="stl_11 stl_08 stl_09">处于同一母公司直接或间接控制下的公司。 &nbsp;</span></div>
          <div class="stl_01" style="top:10.4484em; left:7.4833em;"><span class="stl_12 stl_08 stl_09">1</span></div>
          <div class="stl_01" style="top:10.4484em; left:7.9833em;"><span class="stl_12 stl_08 stl_09">.3 &nbsp;</span></div>
          <div class="stl_01" style="top:12.2577em; left:7.4833em;"><span class="stl_11 stl_08 stl_09">资金监管账户：指甲方与第三方服务方确定的用于收取应收账款回款的银行监 &nbsp;</span></div>
          <div class="stl_01" style="top:14.2077em; left:7.4833em;"><span class="stl_11 stl_08 stl_09">管账户。 &nbsp;</span></div>
          <div class="stl_01" style="top:16.1577em; left:7.4833em;"><span class="stl_12 stl_08 stl_09">1</span></div>
          <div class="stl_01" style="top:18.2484em; left:7.4833em;"><span class="stl_12 stl_08 stl_09">1</span></div>
          <div class="stl_01" style="top:16.1577em; left:7.9833em;"><span class="stl_12 stl_08 stl_09">.4</span><span class="stl_11 stl_08 stl_09">资金方：保理融资服务的提供方，包括但不限于商业保理公司。 &nbsp;</span></div>
          <div class="stl_01" style="top:18.2484em; left:7.9833em;"><span class="stl_12 stl_08 stl_09">.6 &nbsp;</span></div>
          <div class="stl_01" style="top:20.0577em; left:7.4833em;"><span class="stl_11 stl_08 stl_09">第三方服务方：资金方及</span><span class="stl_12 stl_08 stl_09">/</span><span class="stl_11 stl_08 stl_09">或与甲方达成寄售货品合作并为甲方提供寄售货品相 &nbsp;</span></div>
          <div class="stl_01" style="top:22.0077em; left:7.4833em;"><span class="stl_11 stl_08 stl_09">关服务的主体。 &nbsp;</span></div>
          <div class="stl_01" style="top:24.0984em; left:7.4833em;"><span class="stl_12 stl_08 stl_09">1</span></div>
          <div class="stl_01" style="top:24.0984em; left:7.9833em;"><span class="stl_12 stl_08 stl_09">.7 &nbsp;</span></div>
          <div class="stl_01" style="top:25.9077em; left:7.4833em;"><span class="stl_11 stl_08 stl_09">寄售协议：甲方与第三方服务方就达成寄售货品合作而签署的相关协议，具体 &nbsp;</span></div>
          <div class="stl_01" style="top:27.8577em; left:7.4833em;"><span class="stl_11 stl_08 stl_09">名称以甲方、第三方服务方以及其他主体（如有）实际签署的为准。 &nbsp;</span></div>
          <div class="stl_01" style="top:29.9151em; left:7.4833em;"><span class="stl_17 stl_08 stl_09">1</span></div>
          <div class="stl_01" style="top:29.8077em; left:7.9208em;"><span class="stl_17 stl_08 stl_09">.8</span><span class="stl_18 stl_08 stl_09">【</span><span class="stl_17 stl_08 stl_09">RPA</span><span class="stl_18 stl_08 stl_09">工具】是指用于采集企业客户授权数据的技术工具，包括</span><span class="stl_11 stl_08 stl_09">电商</span><span class="stl_18 stl_08 stl_09">模</span><span class="stl_11 stl_08 stl_09">块</span><span class="stl_18 stl_08 stl_09">下的</span><span class="stl_17 stl_08 stl_09">RPA</span><span class="stl_18 stl_08 stl_09">工具 &nbsp;</span></div>
          <div class="stl_01" style="top:31.8192em; left:7.4833em;"><span class="stl_18 stl_08 stl_09">等。 &nbsp;</span></div>
          <div class="stl_01" style="top:35.6577em; left:7.4833em;"><span class="stl_15 stl_08 stl_09">二、服务内容 &nbsp;</span></div>
          <div class="stl_01" style="top:37.6077em; left:7.5em;"><span class="stl_12 stl_08 stl_09">2</span></div>
          <div class="stl_01" style="top:39.5577em; left:7.5em;"><span class="stl_12 stl_08 stl_09">2</span></div>
          <div class="stl_01" style="top:37.6077em; left:8em;"><span class="stl_12 stl_08 stl_09">.1</span><span class="stl_11 stl_08 stl_09">丙方负责线上平台的客户拓展，甲方为丙方拓展的客户。 &nbsp;</span></div>
          <div class="stl_01" style="top:39.5577em; left:8em;"><span class="stl_12 stl_08 stl_09">.2</span><span class="stl_11 stl_08 stl_09">甲方可通过线上平台使用乙方提供的电商模块、资金监管模块、账户中心模 &nbsp;</span></div>
          <div class="stl_01" style="top:41.5077em; left:7.5em;"><span class="stl_11 stl_08 stl_09">块等功能服务，甲方还可通过线上平台向第三方服务方申请保理融资业务或寄 &nbsp;</span></div>
          <div class="stl_01" style="top:43.4577em; left:7.5em;"><span class="stl_11 stl_08 stl_09">售货品相关服务。</span><span class="stl_15 stl_08 stl_09">特别地，在甲方与第三方服务方签署保理合同（具体合同名 &nbsp;</span></div>
          <div class="stl_01" style="top:45.4077em; left:7.5em;"><span class="stl_15 stl_08 stl_09">称以双方实际签署的为准）后，甲方授权委托乙方于每个自然日或每个工作日 &nbsp;</span></div>
          <div class="stl_01" style="top:47.3577em; left:7.5em;"><span class="stl_15 stl_08 stl_09">（</span></div>
          <div class="stl_01" style="top:47.3577em; left:8.5em;"><span class="stl_15 stl_08 stl_09">具体根据第三方服务商的规定确定）就前一日或前一工作日甲方在相关电商 &nbsp;</span></div>
          <div class="stl_01" style="top:49.3077em; left:7.5em;"><span class="stl_15 stl_08 stl_09">平台店铺项下新增的已发货订单项下应收账款</span><span class="stl_16 stl_08 stl_09">/</span><span class="stl_15 stl_08 stl_09">未来应收账款，自动向第三方服 &nbsp;</span></div>
          <div class="stl_01" style="top:51.2577em; left:7.5em;"><span class="stl_15 stl_08 stl_09">务方发起单笔保理融资申请（以下称</span><span class="stl_16 stl_08 stl_09">“</span><span class="stl_15 stl_08 stl_09">自动融资授权</span><span class="stl_16 stl_08 stl_09">”</span><span class="stl_15 stl_08 stl_09">）</span><span class="stl_11 stl_08 stl_09">。前述相关电商平台 &nbsp;</span></div>
          <div class="stl_01" style="top:53.2077em; left:7.5em;"><span class="stl_11 stl_08 stl_09">店铺以甲方或丙方于线上平台维护的甲方店铺信息为准。 &nbsp;</span></div>
          <div class="stl_01" style="top:55.1577em; left:7.5em;"><span class="stl_16 stl_08 stl_09">2</span></div>
          <div class="stl_01" style="top:55.1577em; left:8em;"><span class="stl_16 stl_08 stl_09">.3</span><span class="stl_15 stl_08 stl_09">甲方了解并确认，乙方为甲方提供的服务自第三方服务方向甲方发放保理融 &nbsp;</span></div>
          <div class="stl_01" style="top:57.1077em; left:7.5em;"><span class="stl_15 stl_08 stl_09">资款或第三方服务方向甲方首次支付寄售协议中的首期金额时即视为完成。 &nbsp;</span></div>
          <div class="stl_01" style="top:61.0077em; left:7.4833em;"><span class="stl_16 stl_08 stl_09">3</span></div>
          <div class="stl_01" style="top:61.0077em; left:7.9833em;"><span class="stl_15 stl_08 stl_09">、服务规则 &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="top:6.5484em; left:7.5em;"><span class="stl_12 stl_08 stl_09">3</span></div>
          <div class="stl_01" style="top:6.5484em; left:8em;"><span class="stl_12 stl_08 stl_09">.1</span><span class="stl_18 stl_08 stl_09">甲方知悉并同意</span><span class="stl_19 stl_08 stl_09">对于平台展示的融资产品信息，乙方负责按照第三方服务方提供的信 &nbsp;</span></div>
          <div class="stl_01" style="top:8.4192em; left:7.5em;"><span class="stl_19 stl_08 stl_09">息进行展示，甲方根据自身的情况决定是否向第三方服务方发起融资申请，第三方服务方 &nbsp;</span></div>
          <div class="stl_01" style="top:10.3692em; left:7.5em;"><span class="stl_19 stl_08 stl_09">根据其自身的审核规则决定是否向甲方提供融资服务</span><span class="stl_18 stl_08 stl_09">。 &nbsp;</span></div>
          <div class="stl_01" style="top:12.2577em; left:7.5em;"><span class="stl_12 stl_08 stl_09">3</span></div>
          <div class="stl_01" style="top:12.2577em; left:8em;"><span class="stl_12 stl_08 stl_09">.2</span><span class="stl_19 stl_08 stl_09">甲方使用线上平台提供的</span><span class="stl_20 stl_08 stl_09">RPA</span><span class="stl_19 stl_08 stl_09">工具采集其在直播电商平台相关数据</span><span class="stl_15 stl_08 stl_09">的</span><span class="stl_19 stl_08 stl_09">，甲方需按</span><span class="stl_15 stl_08 stl_09">线上 &nbsp;</span></div>
          <div class="stl_01" style="top:14.2692em; left:7.5em;"><span class="stl_19 stl_08 stl_09">平台提示提供其在直播电商平台相关账户信息等，该工具可协助甲方收集、传送其在相关 &nbsp;</span></div>
          <div class="stl_01" style="top:16.1577em; left:7.5em;"><span class="stl_19 stl_08 stl_09">直播电商平台的订单数据、账单数据等（以下简称</span><span class="stl_20 stl_08 stl_09">“</span><span class="stl_15 stl_08 stl_09">电商</span><span class="stl_19 stl_08 stl_09">平台账户数据</span><span class="stl_20 stl_08 stl_09">”</span><span class="stl_19 stl_08 stl_09">）</span><span class="stl_18 stl_08 stl_09">；在使用该功 &nbsp;</span></div>
          <div class="stl_01" style="top:18.1077em; left:7.5em;"><span class="stl_18 stl_08 stl_09">能模块前甲方需按照《电商中心用户须知》（附件</span><span class="stl_11 stl_08 stl_09">一</span><span class="stl_18 stl_08 stl_09">）的指引完成硬件准备与相应操作。 &nbsp;</span></div>
          <div class="stl_01" style="top:20.0577em; left:7.5em;"><span class="stl_12 stl_08 stl_09">3</span></div>
          <div class="stl_01" style="top:20.0577em; left:8em;"><span class="stl_12 stl_08 stl_09">.3</span><span class="stl_11 stl_08 stl_09">甲方使用线上平台提供的资金监管服务的，甲方需在线上平台添加相应的监 &nbsp;</span></div>
          <div class="stl_01" style="top:22.0077em; left:7.5em;"><span class="stl_11 stl_08 stl_09">管账户信息， &nbsp;</span></div>
          <div class="stl_01" style="top:23.9577em; left:7.5em;"><span class="stl_11 stl_08 stl_09">该服务可同时向甲方展示监管账户的实时账户余额、账户流水等信息（以下简 &nbsp;</span></div>
          <div class="stl_01" style="top:25.9077em; left:7.5em;"><span class="stl_11 stl_08 stl_09">称</span><span class="stl_12 stl_08 stl_09">“</span><span class="stl_11 stl_08 stl_09">银行账户信息</span><span class="stl_12 stl_08 stl_09">”</span><span class="stl_11 stl_08 stl_09">）。 &nbsp;</span></div>
          <div class="stl_01" style="top:29.834em; left:7.4833em;"><span class="stl_16 stl_08 stl_09">4</span></div>
          <div class="stl_01" style="top:29.834em; left:7.9833em;"><span class="stl_15 stl_08 stl_09">、</span><span class="stl_19 stl_08 stl_09">权利义务 &nbsp;</span></div>
          <div class="stl_01" style="top:31.7577em; left:7.5em;"><span class="stl_16 stl_08 stl_09">4</span></div>
          <div class="stl_01" style="top:31.7577em; left:8em;"><span class="stl_16 stl_08 stl_09">.1</span><span class="stl_19 stl_08 stl_09">甲方通过</span><span class="stl_15 stl_08 stl_09">线上</span><span class="stl_19 stl_08 stl_09">平台接受第三方服务</span><span class="stl_15 stl_08 stl_09">方</span><span class="stl_19 stl_08 stl_09">提供的服务时，甲方应当评估该服务是否符合甲 &nbsp;</span></div>
          <div class="stl_01" style="top:33.7077em; left:7.5em;"><span class="stl_19 stl_08 stl_09">方要求，甲方与第三方服务</span><span class="stl_15 stl_08 stl_09">方</span><span class="stl_19 stl_08 stl_09">的权利义务关系取决于甲方与第三方服务</span><span class="stl_15 stl_08 stl_09">方</span><span class="stl_19 stl_08 stl_09">签署的协议</span><span class="stl_20 stl_08 stl_09">/</span><span class="stl_19 stl_08 stl_09">合 &nbsp;</span></div>
          <div class="stl_01" style="top:35.6577em; left:7.5em;"><span class="stl_19 stl_08 stl_09">同。若因第三方服务</span><span class="stl_15 stl_08 stl_09">方</span><span class="stl_19 stl_08 stl_09">提供的服务，甲方与第三方服务</span><span class="stl_15 stl_08 stl_09">方</span><span class="stl_19 stl_08 stl_09">发生纠纷的，甲方与第三方服务 &nbsp;</span></div>
          <div class="stl_01" style="top:37.6077em; left:7.5em;"><span class="stl_15 stl_08 stl_09">方</span><span class="stl_19 stl_08 stl_09">自行协商解决。 &nbsp;</span></div>
          <div class="stl_01" style="top:39.5577em; left:7.5em;"><span class="stl_16 stl_08 stl_09">4</span></div>
          <div class="stl_01" style="top:39.5577em; left:8em;"><span class="stl_16 stl_08 stl_09">.2</span><span class="stl_19 stl_08 stl_09">甲方应确保其指令的真实性、合法性、有效性和完整性，妥善使用</span><span class="stl_15 stl_08 stl_09">线上</span><span class="stl_19 stl_08 stl_09">平台，并自行 &nbsp;</span></div>
          <div class="stl_01" style="top:41.5692em; left:7.5em;"><span class="stl_19 stl_08 stl_09">解决与其交易对手、开户行等其他第三方之间各种争议、纠纷。 &nbsp;</span></div>
          <div class="stl_01" style="top:43.4577em; left:7.5em;"><span class="stl_12 stl_08 stl_09">4</span></div>
          <div class="stl_01" style="top:45.4077em; left:7.5em;"><span class="stl_12 stl_08 stl_09">4</span></div>
          <div class="stl_01" style="top:43.4577em; left:8em;"><span class="stl_12 stl_08 stl_09">.3</span><span class="stl_11 stl_08 stl_09">甲方应按本协议的约定向乙方支付服务费。 &nbsp;</span></div>
          <div class="stl_01" style="top:45.4077em; left:8em;"><span class="stl_12 stl_08 stl_09">.4</span><span class="stl_11 stl_08 stl_09">甲方确认并同意，</span><span class="stl_15 stl_08 stl_09">一经乙方根据自动融资授权向第三方服务方发起单笔保理 &nbsp;</span></div>
          <div class="stl_01" style="top:47.3577em; left:7.5em;"><span class="stl_15 stl_08 stl_09">融资申请，该申请即对甲方发生相应的法律约束力，甲方不得以任何理由对该 &nbsp;</span></div>
          <div class="stl_01" style="top:49.3077em; left:7.5em;"><span class="stl_15 stl_08 stl_09">等申请的有效性提出任何抗辩。 &nbsp;</span></div>
          <div class="stl_01" style="top:51.2577em; left:7.5em;"><span class="stl_12 stl_08 stl_09">4</span></div>
          <div class="stl_01" style="top:51.2577em; left:8em;"><span class="stl_12 stl_08 stl_09">.5</span><span class="stl_11 stl_08 stl_09">甲方确认，</span><span class="stl_15 stl_08 stl_09">如甲方拟撤销自动融资授权，甲方需提前【 &nbsp;</span></div>
          <div class="stl_01" style="top:53.2077em; left:8.5em;"><span class="stl_15 stl_08 stl_09">个工作日告知乙方，且如甲方撤销自动融资授权，不影响乙方根据原有授权 &nbsp;</span></div>
          <div class="stl_01" style="top:55.1577em; left:7.5em;"><span class="stl_15 stl_08 stl_09">所发出的单笔保理融资服务申请的效力。 &nbsp;</span></div>
          <div class="stl_01" style="top:53.2077em; left:7.5em;"><span class="stl_15 stl_08 stl_09">】</span></div>
          <div class="stl_01" style="top:57.1077em; left:7.5em;"><span class="stl_12 stl_08 stl_09">4</span></div>
          <div class="stl_01" style="top:59.0577em; left:7.5em;"><span class="stl_12 stl_08 stl_09">4</span></div>
          <div class="stl_01" style="top:57.1077em; left:8em;"><span class="stl_12 stl_08 stl_09">.6 </span><span class="stl_18 stl_08 stl_09">乙方应当按照本协议的约定向甲方提供</span><span class="stl_11 stl_08 stl_09">相应的</span><span class="stl_18 stl_08 stl_09">平台功能服务。 &nbsp;</span></div>
          <div class="stl_01" style="top:59.0577em; left:8em;"><span class="stl_12 stl_08 stl_09">.7</span><span class="stl_19 stl_08 stl_09">乙方仅对</span><span class="stl_15 stl_08 stl_09">线上</span><span class="stl_19 stl_08 stl_09">平台本身的运行、维护和升级，以及通过</span><span class="stl_15 stl_08 stl_09">线上</span><span class="stl_19 stl_08 stl_09">平台准确执行甲方指令承 &nbsp;</span></div>
          <div class="stl_01" style="top:61.0692em; left:7.5em;"><span class="stl_19 stl_08 stl_09">担责任，本协议另有约定的除外。对于甲方本身指令的准确性、合法性、有效性，以及甲 &nbsp;</span></div>
          <div class="stl_01" style="top:63.0192em; left:7.5em;"><span class="stl_19 stl_08 stl_09">方与其交易对手、开户行等其他第三方之间各种争议、纠纷，乙方不承担任何责任。 &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="top:6.4077em; left:7.5em;"><span class="stl_16 stl_08 stl_09">4</span></div>
          <div class="stl_01" style="top:6.4077em; left:8em;"><span class="stl_16 stl_08 stl_09">.8</span><span class="stl_15 stl_08 stl_09">线上</span><span class="stl_19 stl_08 stl_09">平台提供的分析、核算等功能服务，仅依据甲方提供的数据进行分析、核算，服 &nbsp;</span></div>
          <div class="stl_01" style="top:8.4192em; left:7.5em;"><span class="stl_19 stl_08 stl_09">务结果仅供甲方参考，乙方对此不承担任何明示或默示的保证或担保责任。甲方基于乙方 &nbsp;</span></div>
          <div class="stl_01" style="top:10.3692em; left:7.5em;"><span class="stl_19 stl_08 stl_09">提供的服务所进行的任何判断、做出的决定、所从事的各项活动等，应由甲方自行承担后 &nbsp;</span></div>
          <div class="stl_01" style="top:12.3192em; left:7.5em;"><span class="stl_19 stl_08 stl_09">果。特别地，对于来源于第三方（包括但不限于第三方服务商）的数据，乙方无义务对该 &nbsp;</span></div>
          <div class="stl_01" style="top:14.2692em; left:7.5em;"><span class="stl_19 stl_08 stl_09">数据本身的真实性、完整性、准确性进行分辨或核验。 &nbsp;</span></div>
          <div class="stl_01" style="top:18.1077em; left:7.5em;"><span class="stl_15 stl_08 stl_09">五、数据授权 &nbsp;</span></div>
          <div class="stl_01" style="top:20.1984em; left:7.5em;"><span class="stl_12 stl_08 stl_09">5</span></div>
          <div class="stl_01" style="top:20.1984em; left:8em;"><span class="stl_12 stl_08 stl_09">.1</span><span class="stl_19 stl_08 stl_09">甲方委托乙方不时地从对应的直播电商平台获取其平台账户数据。为实现前述目的， &nbsp;</span></div>
          <div class="stl_01" style="top:22.0692em; left:7.5em;"><span class="stl_19 stl_08 stl_09">甲方授权同意乙方保存并使用其提供的直播电商平台账号及密码。 &nbsp;</span></div>
          <div class="stl_01" style="top:23.9577em; left:7.5em;"><span class="stl_16 stl_08 stl_09">5</span></div>
          <div class="stl_01" style="top:23.9577em; left:8em;"><span class="stl_16 stl_08 stl_09">.2</span><span class="stl_11 stl_08 stl_09">甲方使用线上平台提供的资金监管服务的，</span><span class="stl_19 stl_08 stl_09">甲方委托乙方不时地从直联银行端口 &nbsp;</span></div>
          <div class="stl_01" style="top:25.9077em; left:7.5em;"><span class="stl_19 stl_08 stl_09">获取相应的银行账户数据</span><span class="stl_15 stl_08 stl_09">。 &nbsp;</span></div>
          <div class="stl_01" style="top:27.8577em; left:8em;"><span class="stl_16 stl_08 stl_09">.3</span><span class="stl_19 stl_08 stl_09">对于乙方按照</span><span class="stl_15 stl_08 stl_09">与甲方的</span><span class="stl_19 stl_08 stl_09">约定获取的甲方相关信息，包括但不限于甲方主体信息、银行 &nbsp;</span></div>
          <div class="stl_01" style="top:27.8577em; left:7.5em;"><span class="stl_16 stl_08 stl_09">5</span></div>
          <div class="stl_01" style="top:29.8077em; left:7.5em;"><span class="stl_19 stl_08 stl_09">账户数据、</span><span class="stl_15 stl_08 stl_09">电商</span><span class="stl_19 stl_08 stl_09">平台账户数据等，甲方授权乙方及</span><span class="stl_20 stl_08 stl_09">/</span><span class="stl_19 stl_08 stl_09">或其关联公司为向甲方提供服务的需 &nbsp;</span></div>
          <div class="stl_01" style="top:31.7577em; left:7.5em;"><span class="stl_19 stl_08 stl_09">要保存、整理及使用前述信息。为使用第三方服务</span><span class="stl_15 stl_08 stl_09">方</span><span class="stl_19 stl_08 stl_09">所提供的服务，甲方授权并同意乙方 &nbsp;</span></div>
          <div class="stl_01" style="top:33.7077em; left:7.5em;"><span class="stl_19 stl_08 stl_09">将</span><span class="stl_15 stl_08 stl_09">前述</span><span class="stl_19 stl_08 stl_09">甲方信息提供给第三方服务</span><span class="stl_15 stl_08 stl_09">方</span><span class="stl_19 stl_08 stl_09">。 &nbsp;</span></div>
          <div class="stl_01" style="top:35.6577em; left:7.5em;"><span class="stl_16 stl_08 stl_09">5</span></div>
          <div class="stl_01" style="top:35.6577em; left:8em;"><span class="stl_16 stl_08 stl_09">.4</span><span class="stl_19 stl_08 stl_09">甲方通过</span><span class="stl_15 stl_08 stl_09">线上</span><span class="stl_19 stl_08 stl_09">平台向第三方服务</span><span class="stl_15 stl_08 stl_09">方</span><span class="stl_19 stl_08 stl_09">申请服务的，甲方在此授权同意第三方服务</span><span class="stl_15 stl_08 stl_09">方</span><span class="stl_19 stl_08 stl_09">将甲 &nbsp;</span></div>
          <div class="stl_01" style="top:37.6077em; left:7.5em;"><span class="stl_19 stl_08 stl_09">方办理业务所产生的相关数据信息（包括但不限于融资金额等</span><span class="stl_15 stl_08 stl_09">）</span><span class="stl_19 stl_08 stl_09">提供给乙方及</span><span class="stl_20 stl_08 stl_09">/</span><span class="stl_19 stl_08 stl_09">或乙方关 &nbsp;</span></div>
          <div class="stl_01" style="top:39.6192em; left:7.5em;"><span class="stl_19 stl_08 stl_09">联公司进行使用、处理、分析等。 &nbsp;</span></div>
          <div class="stl_01" style="top:41.5077em; left:7.5em;"><span class="stl_16 stl_08 stl_09">5</span></div>
          <div class="stl_01" style="top:41.5077em; left:8em;"><span class="stl_16 stl_08 stl_09">.5</span><span class="stl_15 stl_08 stl_09">甲方授权同意乙方或乙方关联公司将</span><span class="stl_16 stl_08 stl_09">5.3</span><span class="stl_15 stl_08 stl_09">条和</span><span class="stl_16 stl_08 stl_09">5.4</span><span class="stl_15 stl_08 stl_09">条项下的数据信息提供给丙 &nbsp;</span></div>
          <div class="stl_01" style="top:43.4577em; left:7.5em;"><span class="stl_15 stl_08 stl_09">方。 &nbsp;</span></div>
          <div class="stl_01" style="top:47.3577em; left:7.65em;"><span class="stl_15 stl_08 stl_09">六、服务费的收取 &nbsp;</span></div>
          <div class="stl_01" style="top:49.3077em; left:7.5em;"><span class="stl_12 stl_08 stl_09">6</span></div>
          <div class="stl_01" style="top:49.3077em; left:8em;"><span class="stl_12 stl_08 stl_09">.1</span><span class="stl_11 stl_08 stl_09">就每一笔保理融资业务，甲方应向乙方支付平台服务费，服务费</span><span class="stl_12 stl_08 stl_09">=</span><span class="stl_11 stl_08 stl_09">【该笔保 &nbsp;</span></div>
          <div class="stl_01" style="top:51.2577em; left:7.5em;"><span class="stl_11 stl_08 stl_09">理融资本金金额】</span><span class="stl_12 stl_08 stl_09">*</span><span class="stl_11 stl_08 stl_09">费率。</span><span class="stl_15 stl_08 stl_09">如甲方与第三方服务方签署的保理合同（具体合同名 &nbsp;</span></div>
          <div class="stl_01" style="top:53.2077em; left:7.5em;"><span class="stl_15 stl_08 stl_09">称以双方实际签署的为准）中约定第三方服务方委托乙方代为收取保理融资利 &nbsp;</span></div>
          <div class="stl_01" style="top:55.1577em; left:7.5em;"><span class="stl_15 stl_08 stl_09">息及服务费的，则本条约定的平台服务费已包括代收的保理融资利息及服务费 &nbsp;</span></div>
          <div class="stl_01" style="top:57.1077em; left:7.5em;"><span class="stl_11 stl_08 stl_09">。</span></div>
          <div class="stl_01" style="top:57.1077em; left:8.5em;"><span class="stl_11 stl_08 stl_09">乙方收取平台服务费之后，再根据与第三方服务方的约定对代收的保理融资 &nbsp;</span></div>
          <div class="stl_01" style="top:59.0577em; left:7.5em;"><span class="stl_11 stl_08 stl_09">利息及服务费进行结算。 &nbsp;</span></div>
        </div>
      </div>
    </div>
    <div class="stl_02">
      <div class="view">
        <div class="stl_05 stl_06">
          <div class="stl_01" style="top:6.5484em; left:7.5em;"><span class="stl_12 stl_08 stl_09">6</span></div>
          <div class="stl_01" style="top:6.5484em; left:8em;"><span class="stl_12 stl_08 stl_09">.2 &nbsp;</span></div>
          <div class="stl_01" style="top:8.3577em; left:7.5em;"><span class="stl_11 stl_08 stl_09">就每一笔寄售货品相关服务订单，甲方应向乙方支付平台服务费，服务费</span><span class="stl_12 stl_08 stl_09">=</span><span class="stl_11 stl_08 stl_09">【首 &nbsp;</span></div>
          <div class="stl_01" style="top:10.3077em; left:7.5em;"><span class="stl_11 stl_08 stl_09">期金额】</span><span class="stl_12 stl_08 stl_09">*</span><span class="stl_11 stl_08 stl_09">费率。其中，【首期金额】指的是第三方服务方根据寄售协议向甲方 &nbsp;</span></div>
          <div class="stl_01" style="top:12.2577em; left:7.5em;"><span class="stl_11 stl_08 stl_09">支付的相关金额，具体金额以线上平台留存相关业务数据为准。 &nbsp;</span></div>
          <div class="stl_01" style="top:14.2077em; left:7.5em;"><span class="stl_12 stl_08 stl_09">6</span></div>
          <div class="stl_01" style="top:14.2077em; left:8em;"><span class="stl_12 stl_08 stl_09">.3</span><span class="stl_11 stl_08 stl_09">本协议项下</span><span class="stl_12 stl_08 stl_09">“</span><span class="stl_11 stl_08 stl_09">费率</span><span class="stl_12 stl_08 stl_09">”</span><span class="stl_11 stl_08 stl_09">均以乙方通过线上平台向甲方展示的为准，甲方可登录 &nbsp;</span></div>
          <div class="stl_01" style="top:16.1577em; left:7.5em;"><span class="stl_11 stl_08 stl_09">线上平台进行具体查看。 &nbsp;</span></div>
          <div class="stl_01" style="top:18.2484em; left:7.5em;"><span class="stl_12 stl_08 stl_09">6</span></div>
          <div class="stl_01" style="top:18.2484em; left:8em;"><span class="stl_12 stl_08 stl_09">.4 &nbsp;</span></div>
          <div class="stl_01" style="top:20.0577em; left:7.5em;"><span class="stl_11 stl_08 stl_09">甲方应在收到线上平台关于缴纳服务费的推送</span><span class="stl_12 stl_08 stl_09">/</span><span class="stl_11 stl_08 stl_09">待办</span><span class="stl_12 stl_08 stl_09">/</span><span class="stl_11 stl_08 stl_09">通知或乙方的书面通知后 &nbsp;</span></div>
          <div class="stl_01" style="top:22.0077em; left:8.5em;"><span class="stl_11 stl_08 stl_09">按前述相关推送</span><span class="stl_12 stl_08 stl_09">/</span><span class="stl_11 stl_08 stl_09">通知</span><span class="stl_12 stl_08 stl_09">/</span><span class="stl_11 stl_08 stl_09">待办要求的日期向乙方如下指定银行账户支付足额服 &nbsp;</span></div>
          <div class="stl_01" style="top:23.9577em; left:7.5em;"><span class="stl_11 stl_08 stl_09">务费。 &nbsp;</span></div>
          <div class="stl_01" style="top:25.9077em; left:8em;"><span class="stl_12 stl_08 stl_09">.5</span><span class="stl_11 stl_08 stl_09">甲方若逾期支付本协议项下服务费，甲方应以应付未付金额为基数按照日千 &nbsp;</span></div>
          <div class="stl_01" style="top:27.8577em; left:7.5em;"><span class="stl_11 stl_08 stl_09" style="word-spacing:0.25em;">分之一的标准向乙方按日计付违约金，直至付清为准。逾期超过【 </span><span class="stl_12 stl_08 stl_09" style="word-spacing:0.25em;">5 &nbsp;</span></div>
          <div class="stl_01" style="top:29.8077em; left:8.5em;"><span class="stl_11 stl_08 stl_09">天的，乙方有权终止为甲方提供本协议项下服务。 &nbsp;</span></div>
          <div class="stl_01" style="top:31.7577em; left:8em;"><span class="stl_12 stl_08 stl_09">.6</span><span class="stl_11 stl_08 stl_09">若甲方与第三方服务方确定使用资金监管账户作为收取应收账款回款的银行 &nbsp;</span></div>
          <div class="stl_01" style="top:22.0077em; left:7.5em;"><span class="stl_11 stl_08 stl_09">，</span></div>
          <div class="stl_01" style="top:25.9077em; left:7.5em;"><span class="stl_12 stl_08 stl_09">6</span></div>
          <div class="stl_01" style="top:29.8077em; left:7.5em;"><span class="stl_11 stl_08 stl_09">】</span></div>
          <div class="stl_01" style="top:31.7577em; left:7.5em;"><span class="stl_12 stl_08 stl_09">6</span></div>
          <div class="stl_01" style="top:33.7077em; left:7.5em;"><span class="stl_11 stl_08 stl_09">账户，基于乙方负责与监管银行进行对接，根据甲方的授权，资金监管账户的 &nbsp;</span></div>
          <div class="stl_01" style="top:35.6577em; left:7.5em;"><span class="stl_11 stl_08 stl_09">资金监管事项指令由乙方向监管银行发送。</span><span class="stl_15 stl_08 stl_09">甲方确认并同意，就本协议项下的 &nbsp;</span></div>
          <div class="stl_01" style="top:37.6077em; left:7.5em;"><span class="stl_15 stl_08 stl_09">平台服务费，乙方有权直接发送监管资金划转指令，将与甲方应支付的平台服 &nbsp;</span></div>
          <div class="stl_01" style="top:39.5577em; left:7.5em;"><span class="stl_15 stl_08 stl_09">务费等额的款项划转至乙方账户</span><span class="stl_11 stl_08 stl_09">。 &nbsp;</span></div>
          <div class="stl_01" style="top:41.5077em; left:7.5em;"><span class="stl_12 stl_08 stl_09">6</span></div>
          <div class="stl_01" style="top:41.5077em; left:8em;"><span class="stl_12 stl_08 stl_09">.7</span><span class="stl_11 stl_08 stl_09">甲方向乙方指定的以下银行账户支付相应的服务费： &nbsp;</span></div>
          <div class="stl_01" style="top:43.4577em; left:7.5em;"><span class="stl_11 stl_08 stl_09">账户名：深圳前海环融联易信息科技服务有限公司 &nbsp;</span></div>
          <div class="stl_01" style="top:45.4077em; left:7.5em;"><span class="stl_11 stl_08 stl_09">开户行：平安银行股份有限公司深圳新城支行 &nbsp;</span></div>
          <div class="stl_01" style="top:47.3577em; left:7.5em;"><span class="stl_11 stl_08 stl_09" style="word-spacing:0.75em;">账 号：</span><span class="stl_12 stl_08 stl_09" style="word-spacing:0.75em;">15567403540066 &nbsp;</span></div>
          <div class="stl_01" style="top:49.3077em; left:7.5em;"><span class="stl_16 stl_08 stl_09">6</span></div>
          <div class="stl_01" style="top:49.3077em; left:8em;"><span class="stl_16 stl_08 stl_09">.8</span><span class="stl_15 stl_08 stl_09">甲方逾期向乙方支付服务费的，乙方有权自行或委托合作的第三方以合法的 &nbsp;</span></div>
          <div class="stl_01" style="top:51.2577em; left:7.5em;"><span class="stl_15 stl_08 stl_09">手段向甲方催收，必要时乙方将通过司法手段维护自身的权益。 &nbsp;</span></div>
          <div class="stl_01" style="top:55.1577em; left:7.5em;"><span class="stl_15 stl_08 stl_09">七、责任限制 &nbsp;</span></div>
          <div class="stl_01" style="top:57.2484em; left:7.5em;"><span class="stl_16 stl_08 stl_09">7</span></div>
          <div class="stl_01" style="top:57.2484em; left:8em;"><span class="stl_16 stl_08 stl_09">.</span><span class="stl_20 stl_08 stl_09">1</span><span class="stl_19 stl_08 stl_09">鉴于网络之特殊属性，发生包括但不限于下列情形导致乙方不能履行本协议下义务或 &nbsp;</span></div>
          <div class="stl_01" style="top:59.1192em; left:7.5em;"><span class="stl_19 stl_08 stl_09">平台不能正常运行的，在乙方已根据现有通用技术进行应急处理与维护的前提下，乙方可 &nbsp;</span></div>
          <div class="stl_01" style="top:61.0692em; left:7.5em;"><span class="stl_19 stl_08 stl_09">以免责： &nbsp;</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'document301'
});
</script>

<style scoped lang="scss">
sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
sub {
  vertical-align: baseline;
  position: relative;
  top: 0.4em;
}
a:link {text-decoration:none;}
a:visited {text-decoration:none;}

.layer { }.ie { font-size: 1pt; }
.ie body { font-size: 12em; }
.stl_01 {
  position: absolute;
  white-space: nowrap;
}
.stl_02 {
  border-style: none;
  height: 70.08334em;
  width: 100%;
  margin: 0;
  line-height: 0.0em;
  font-size: 1em;
  display: block;
}
.stl_03 {
  position: relative;
}
.stl_04 {
  position: absolute;
  left: 0;
  top: 0;
}
.stl_05 {
  position: relative;
  width: 1024px;
  margin: 0 auto;
}
.stl_06 {
  height: 7.008333em;
}
.ie .stl_06 {
  height: 70.08334em;
}

.stl_07 {
  color: #000000;
  font-size: 1.333333em;
  font-family: "Microsoft YaHei", "Times New Roman",serif;
}
.stl_08 {
  line-height: 1em;
}
.stl_09 {
  letter-spacing: 0;
}

.ie .stl_09 {
  letter-spacing: 0;
}
.stl_10 {
  color: #000000;
  font-size: 1.333333em;
  font-family: "Microsoft YaHei", "Times New Roman",serif;
}

.stl_11 {
  color: #000000;
  font-size: 1em;
  font-family: "Microsoft YaHei", "Times New Roman",serif;
}

.stl_12 {
  color: #000000;
  font-size: 1em;
  font-family: "Microsoft YaHei",serif;
}
.stl_13 {
  color: #000000;
  font-size: 1em;
  font-family: "Microsoft YaHei", "Times New Roman",serif;
}
.stl_14 {
  line-height: 1.319824em;
}
.stl_15 {
  color: #000000;
  font-size: 1em;
  font-family: "Microsoft YaHei", "Times New Roman",serif;
}
.stl_16 {
  color: #000000;
  font-size: 1em;
  font-family: "Microsoft YaHei", "Times New Roman",serif;
}
.stl_17 {
  color: #000000;
  font-size: 0.875em;
  font-family: "Microsoft YaHei", "Times New Roman",serif;
}
.stl_18 {
  color: #000000;
  font-size: 0.875em;
  font-family: "Microsoft YaHei", "Times New Roman",serif;
}
.stl_19 {
  color: #000000;
  font-size: 0.875em;
  font-family: "Microsoft YaHei", "Times New Roman",serif;
}
.stl_20 {
  color: #000000;
  font-size: 0.875em;
  font-family: "Microsoft YaHei", "Times New Roman",serif;
}

</style>
