<template>
  <div class="product-financing-plan">
    <basic-container>
      <avue-crud ref="crud" :table-loading="isLoading" :search.sync="form" :page.sync="page" :option="option"
        :data="dataList" @on-load="getList" @size-change="sizeChange" @current-change="currentChange"
        @search-change="handleFilter" @search-reset="resetFilter" @refresh-change="handleRefreshChange">
        <template slot="paymentDate" slot-scope="scope">
          {{ scope.row.startTime }} - {{ scope.row.endTime }}
        </template>
        <!-- 列表左上角操作按钮 -->
        <template #menuLeft>
          <el-button icon="el-icon-download" type="primary" :loading="downLoading" @click="toConfigStandardPlan(form)">
            导出
          </el-button>
        </template>
      </avue-crud>
    </basic-container>
  </div>
</template>

<script>
  import { PAGE } from '@/const/global.js'
  import { doExportPostExcel, downExcel } from '@/router/axios'

  export default {
    name: 'ProductFinancingPlan',
    props: {},
    data() {
      return {
        form: {},
        isLoading: true,
        searchItmes: {},
        dataList: [],
        downLoading: false,
        option: {
          // 搜索栏配置
          searchMenuSpan: 8,
          searchMenuPosition: 'left',
          // 表格主体配置
          border: false,
          index: false,
          indexLabel: '序号',
          stripe: true,
          headeralign: 'left',
          align: 'right',
          // 表格操作列配置
          menu: false,
          menuWidth: 150,
          addBtn: false,
          editBtn: false,
          delBtn: false,
          searchShowBtn: false,
          column: [
            {
              label: '产品类型',
              prop: 'productType',
              type: 'select',
              search: true,
              hide: true,
              dicData: [
              { label: '2A-极速付款', value: '1' },
              { label: '1A-极速收款', value: '0' },
              ],
            },
            {
              label: '企业名称',
              prop: 'enterpriseName',
              width: 280,
              align: 'left',
              hide: true,
              search: true,
              searchSpan: 6,
            },
            {
              label: '店铺名称',
              prop: 'storeName',
              align: 'left',
              hide: true,
              minWidth: 280,
              search: true,
              searchSpan: 6,
            },
            {
              label: '融资单号',
              prop: 'applyNo',
              align: 'left',
              hide: true,
              minWidth: 280,
              search: true,
              searchSpan:6,
            },

            {
              label: '产品类型',
              prop: 'productTypeDesc',
              align: 'left',
              width: 120,
            },
            {
              label: '企业名称',
              prop: 'enterpriseName',
              align: 'left',
              width: 250,
            },
            {
              label: '店铺名称',
              prop: 'storeName',
              align: 'left',
              width: 150,
            },
            {
              label: '融资单号',
              prop: 'applyNo',
              align: 'left',
              width: 250,
            },
            {
              label: '计息方式',
              prop: 'billingMethod',
              align: 'left',
              width: 120,
            },
            {
              label: '费率',
              prop: 'customerRateStr',
              align: 'left',
              width: 120,
            },
            {
              label: '融资金额',
              prop: 'paymentAmount',
              align: 'left',
              width: 120,
            },
            // {
            //   label: '本次还款金额',
            //   prop: 'totalAmount',
            //   align: 'left',
            //   width: 120,
            // },
            {
              label: '本次还款本金',
              prop: 'principal',
              align: 'left',
              width: 120,
            },
            {
              label: '本次还款利息',
              prop: 'interest',
              align: 'left',
              width: 120,
            },

            {
              label: '本次还款服务费',
              prop: 'serviceFee',
              align: 'left',
              width: 120,
            },
            {
              label: '本次还款渠道费',
              prop: 'channelFee',
              align: 'left',
              width: 120,
            },
            {
              label: '还款日期',
              prop: 'repayDate',
              type: 'date',
              searchRange: true,
              valueFormat: 'yyyy-MM-dd',
              searchValue: [this.getLastMonth(), this.getNow()],
              search: true,
              searchSpan: 6,
              span: 12,
              width: 150,
            },
            {
              label: '还款账号',
              prop: 'accountNo',
              align: 'left',
              search: true,
              width: 170,
            },
            {
              label: '还款户名',
              prop: 'accountName',
              search: true,
              width: 200,
              align: 'left',
            },
          ],
        },
        page: PAGE,
        downLoad: {},
      }
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
      getNow() {
				const now = new Date();
				return this.formatDate(now);
			},
			getLastMonth() {
				const now = new Date();
				const lastMonth = new Date(now.setMonth(now.getMonth() - 1));
				return this.formatDate(lastMonth);
			},
			formatDate(date) {
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const day = date.getDate();
				return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
			},
      /** 获取列表数据 */
      getList(page, params) {
        if (params && params.repayDate) {
          params.startTime = params.repayDate[0]
          params.endTime = params.repayDate[1]
          delete params.repayDate
        }
        const api = '/bium-portal/controller/fin-ops/clearing/selectByCollectionBill'
        params = params
          ? params
          : {
              ...this.searchItmes,
            }
        params.pageNum = page.currentPage
        params.pageSize = page.pageSize
        if(this.form.repayDate == undefined){
					params.startTime =this.getLastMonth()
					params.endTime = this.getNow()
			}else{
          params.startTime = this.form.repayDate[0]
          params.endTime = this.form.repayDate[1]
      }
        this.isLoading = true
        this.downLoad = params
        this.doPost(api, params).then(({ data }) => {
          if (data.result.records) {
            this.dataList = data.result.records || []
            this.page.total = data.result.total || 0
          }
          this.isLoading = false
        })
      },

      /** 分页变化，存储 */
      sizeChange(pageSize) {
        this.page.pageSize = pageSize
      },
      /** 当前页码变化，存储 */
      currentChange(current) {
        this.page.currentPage = current
      },
      /** 点击搜索栏搜索 */
      handleFilter(param, done) {
        this.page.currentPage = 1
        this.searchItmes = param ? param : {}
        this.getList(this.page, param)
        done()
      },
      /** 重置条件搜索 */
      resetFilter() {
        this.page.currentPage = 1
        this.searchItmes = {}
        this.getList(this.page, this.searchItmes)
      },
      /** 手动刷新列表 */
      handleRefreshChange() {
        this.getList(this.page, this.searchItmes)
      },

      /** 导出 */
      toConfigStandardPlan(form) {
        if(this.page.total == 0) return this.$message.error('暂无可导出的数据') 
        let that = this
        const data = {
          productType: form.productType || null, //产品类型
          enterpriseName: form.enterpriseName || null, //企业名称
          storeName: form.storeName || null, //店铺名称
          applyNo: form.applyNo || null, //融资单号
          startTime: form.repayDate[0] || null, //放款起始日， 格式 yyyy-MM-dd
          endTime: form.repayDate[1] || null, //放款结束日， 格式 yyyy-MM-dd
          accountNo: form.accountNo || null, //还款账号
          accountName: form.accountName || null, //还款户名
          pageSize: 1000, //每页数量
          pageNum: 1, //当前页
        }
          this.listLoading = this.downLoading = true
          const api = '/bium-portal/controller/fin-ops/clearing/downloadCollectionBill'
          doExportPostExcel(api, data)
            .then((res) => {
              let reader = new FileReader()
              reader.onload = function (event) {
                if (event.target.result === '') {
                  that.$message.error('导出数据为空或超过最大1000条限制')
                  that.listLoading = that.downLoading = false
                } else {
                  downExcel(res)
                  that.listLoading = that.downLoading = false
                }
              }
              reader.readAsText(res.data)
            })
            .catch(() => {
              this.listLoading = this.downLoading = false
            })
       
      },
      /** 显示配置弹窗 */
      toConfigPlanModal(dataItem) {
        // const comp = () => import("./components-plan/plan-edit.vue");
        // this.$modalDialog(comp, {
        // 	dataItem
        // }).then(() => {
        // 	this.page.currentPage = 1;
        // 	this.handleRefreshChange();
        // });
      },
    },
  }
  </script>

<style lang="scss" scoped>
::v-deep .el-col:nth-last-child(2) {
  .el-form-item__label {
    width: fit-content !important;
  }

  .el-form-item__content {
    margin-left: 100px !important;
  }
}
</style>
