var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "selection-box" }, [
    _c(
      "section",
      { staticClass: "acc-container" },
      [
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("基本信息")]
        ),
        _c("div", { staticClass: "jg-acc" }, [
          _c("div", { staticClass: "card-box" }, [
            _c("div", { staticClass: "card-item fullWidth" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("客户名称：")]),
              _c("div", [_vm._v(_vm._s(_vm.form.name))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("联系方式：")]),
              _c("div", [_vm._v(_vm._s(_vm.form.mobile))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("店铺数量：")]),
              _c("div", [_vm._v(_vm._s(_vm.form.storeCount))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("企业角色：")]),
              _c("div", [_vm._v(_vm._s(_vm.cateTypeText[_vm.form.cateType]))]),
            ]),
          ]),
        ]),
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("极速收款")]
        ),
        _c("div", { staticClass: "jg-acc" }, [
          _c("div", { staticClass: "card-box" }, [
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("开通状态:")]),
              _c("div", [
                _vm._v(
                  _vm._s(_vm.form.openProductTypeA1 ? "已开通" : "未开通")
                ),
              ]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("企业已用额度:"),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.form.usedAmountA1 || "--"))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("企业授信上限金额:"),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.form.creditAmountA1 || "--"))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("企业开通店铺数:"),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.form.storeCountA1) + "家")]),
            ]),
          ]),
        ]),
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("极速付款")]
        ),
        _c("div", { staticClass: "jg-acc" }, [
          _c("div", { staticClass: "card-box" }, [
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("角色:")]),
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm.form.zctRoleType
                      ? _vm.zctRoleTypeText[_vm.form.zctRoleType]
                      : "--"
                  )
                ),
              ]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("开通状态:")]),
              _c("div", [
                _vm._v(
                  _vm._s(_vm.form.openProductTypeA2 ? "已开通" : "未开通")
                ),
              ]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("企业已用额度:"),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.form.usedAmountA2 || "--"))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("企业授信上限金额:"),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.form.creditAmountA2 || "--"))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("企业开通店铺数:"),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.form.storeCountA2) + "家")]),
            ]),
          ]),
        ]),
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("企业营业信息")]
        ),
        _c("div", { staticClass: "jg-acc" }, [
          _c("div", { staticClass: "card-box" }, [
            _c("div", { staticClass: "card-item fullWidth" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("企业名称:")]),
              _c("div", [_vm._v(_vm._s(_vm.form.name))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("社会信用代码:"),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.form.creditCode))]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("营业执照有效期:"),
              ]),
              _c("div", [
                _vm._v(
                  _vm._s(_vm.form.establishmentDate) +
                    "至" +
                    _vm._s(_vm.form.validDate)
                ),
              ]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("企业地址信息:"),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.form.address))]),
            ]),
          ]),
        ]),
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("企业法人信息")]
        ),
        _c("div", { staticClass: "jg-acc" }, [
          _c("div", { staticClass: "card-box" }, [
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("法人姓名:")]),
              _vm.form.legalName
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalName))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("法人身份证:")]),
              _vm.form.legalCard
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalCard))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("身份证有效期:"),
              ]),
              _vm.form.legalDuration
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalDuration))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("法人联系邮箱:"),
              ]),
              _vm.form.legalMail
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalMail))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("法人手机号码:"),
              ]),
              _vm.form.legalMobile
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalMobile))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("民族:")]),
              _vm.form.legalEthnic
                ? _c("div", [_vm._v(_vm._s(_vm.form.legalEthnic))])
                : _c("div", [_vm._v("--")]),
            ]),
          ]),
        ]),
        _c(
          "el-divider",
          {
            staticClass: "title-divider",
            attrs: { "content-position": "left" },
          },
          [_vm._v("授权人信息")]
        ),
        _c("div", { staticClass: "jg-acc" }, [
          _c("div", { staticClass: "card-box" }, [
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("授权人姓名:")]),
              _vm.form.authName
                ? _c("div", [_vm._v(_vm._s(_vm.form.authName))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("授权人身份证:"),
              ]),
              _vm.form.authCard
                ? _c("div", [_vm._v(_vm._s(_vm.form.authCard || "--"))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("身份证有效期:"),
              ]),
              _vm.form.authDuration
                ? _c("div", [_vm._v(_vm._s(_vm.form.authDuration || "--"))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("授权人联系邮箱:"),
              ]),
              _vm.form.authMail
                ? _c("div", [_vm._v(_vm._s(_vm.form.authMail || "--"))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [
                _vm._v("授权人手机号码:"),
              ]),
              _vm.form.authMobile
                ? _c("div", [_vm._v(_vm._s(_vm.form.authMobile || "--"))])
                : _c("div", [_vm._v("--")]),
            ]),
            _c("div", { staticClass: "card-item" }, [
              _c("div", { staticClass: "item_label" }, [_vm._v("民族:")]),
              _vm.form.authEthnic
                ? _c("div", [_vm._v(_vm._s(_vm.form.authEthnic || "--"))])
                : _c("div", [_vm._v("--")]),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }