<template>
    <div>
        <div>
            <el-divider content-position="left" class="title-divider">文件信息
            </el-divider>
            <div class="jg-acc">
                <div class="card-box">
                    <div class="file-item" v-for="(item, index) in fileList" :key="index">
                        <div class="file-item-line">
                            <div>
                                <div class="fc_333 fb">{{ item.title }}</div>
                            </div>
                            <div>
                                <div class="fc_blue fc_333 fb chand" @click="openFile(item)">查看</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <el-dialog title="查看图片" class="dialog-standard channel-config-rate" width="600px" top="100px"
            :visible.sync="isvisible">
            <div class="flex-c">
                <img style="width: 500px;height: 400px;" :src="fileUrl" alt="">
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {doImg} from '../../../router/axios'
export default {
    name: "ChannelConfigRate",
    props: {
        code: {
            type: String,
            default: false,
        },
        fileArr: {
            type: Array,
            default: false,
        },
    },
    data() {
        return {
            isvisible: false,
            fileUrl: '',
            fileList: []
        }
    },
    created() {
        let flie = [{
            title: '营业执照照片',
            value: 'QYYYZZ'
        }, {
            title: '法人身份证正面',
            value: 'FRSFZZM'
        }, {
            title: '法人身份证反面',
            value: 'FRSFZFM'
        }, {
            title: '法人手持身份证照片',
            value: 'FRSCSFZ'
        },{
            title: '授权人身份证正面',
            value: 'SQRSFZZM'
        }, {
            title: '授权人身份证反面',
            value: 'SQRSFZFM'
        },]
        this.fileList = []
        this.fileArr.forEach((item) => {
            flie.forEach((itn) => {
            if(itn.value == item.typeCode || itn.value == item.attachmentType){
                this.fileList.push(itn)
            }
        })
        })
    },
    methods: {
        openFile(item) {
            const form = {
                enterpriseId: this.code,
                typeCode: item.value
            }
            doImg("/bium-portal/controller/fin-ops/attach/viewByCode", form).then(({
                data
            }) => {
                if (data) {
                    this.fileUrl = window.URL.createObjectURL(data)
                    this.isvisible = true;
                }

            });
        }
    },
};
</script>

<style lang="scss" scoped></style>
