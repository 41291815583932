<template>
    <div class="user">
      <basic-container class="full-condition">
        <avue-crud
          ref="crud"
          :option="option"
          :page.sync="page"
          :table-loading="listLoading"
          :data="dataList"
          :cell-class-name="handleCellClassName"
          @on-load="getList"
          @size-change="sizeChange"
          @current-change="currentChange"
          @search-change="handleFilter"
          @search-reset="resetFilter"
          @refresh-change="handleRefreshChange">
          <template slot="menuRight">
            <!-- <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="handleSave()">添加新渠道</el-button> -->
          </template>
          <template slot="menuLeft">
            <!-- <el-button
              class="filter-item"
              type="primary"
              icon="icon-YUAN"
              @click="handleWidthdrawal"
              >提现
            </el-button>
            <el-button
              class="filter-item"
              type="primary"
              icon="icon-YUAN"
              @click="checkFlow"
              >流水核查
            </el-button> -->
          </template>

          <template slot="menu" slot-scope="scope">
            <el-button type="text" >查看详情</el-button>
            <!-- <el-button type="text" icon="el-icon-refresh"
                          @click="queryBook(scope.row, scope.index)">账户同步</el-button> -->
            <!-- <el-button
              v-if="scope.row.status == 1"
              type="text"
              icon="el-icon-view"
              @click="frozenBook(scope.row, scope.index)"
              >冻结</el-button
            >
            <el-button
              v-if="scope.row.status == 2"
              type="text"
              icon="el-icon-view"
              @click="frozenBook(scope.row, scope.index)"
              >解冻</el-button
            > -->
          </template>
        </avue-crud>
      </basic-container>

    </div>
  </template>
  <script>
  import channelAdd from '@/views/channel/components/channelAdd'
  import { mapGetters } from 'vuex'
  const tableOption = {
    searchMenuSpan: 24,
    searchLabelWidth: 80,
    searchMenuPosition: 'center',
    searchIndex: 3,
    searchShowBtn: false,
    border: false,
    showSummary: false,
    printBtn: false,
    index: false,
    indexLabel: '序号',
    stripe: true,
    menu:false,
    menualign: 'left',
    menuWidth: 200,
    searchIndex: 3,
    searchIcon: false,
    searchShowBtn: false,
    labelWidth: 100,
    editBtn: false,
    delBtn: false,
    align: 'left',
    addBtn: false,
    column: [
      {
        fixed: true,
        label: 'id',
        prop: 'id',
        span: 24,
        hide: true,
        editDisplay: false,
        addDisplay: false,
      },
      {
        label: '店铺名称',
        prop: 'storeName',
        width: 180,
        type: 'input',
        search: true,
        hide: false,
        editDisplay: true,
        addDisplay: false,
        searchSpan: 6,
        searchLabelWidth: 90,
        span: 24,
      },
    //   {
    //     label: '回款批次号',
    //     prop: 'accountType',
    //     width: 180,
    //     type: 'input',
    //     hide: true,
    //     editDisplay: true,
    //     addDisplay: false,
    //     search: true,
    //     searchSpan: 6,
    //     searchLabelWidth: 90,
    //     span: 24,
    //   },
      {
        label: '融资单号',
        prop: 'applyNo',
        width: 180,
        type: 'input',
        hide: true,
        editDisplay: true,
        addDisplay: false,
        search: true,
        searchSpan: 6,
        searchLabelWidth: 90,
        span: 24,
      },
      {
        label: '释放日期',
        prop: 'repaymentDate',
        editDisplay: true,
        addDisplay: true,
        span: 12,
      },
      {
        label: '企业名称',
        prop: 'enterpriseName',
        type: 'input',
        search: true,
        hide: false,
        editDisplay: true,
        addDisplay: true,
        span: 12,
      },
      {
        label: '店铺名称/所属平台',
        prop: 'idLivePlatform',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
      {
        label: '融资单号',
        prop: 'applyNo',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
      {
        label: '尾款释放总金额',
        prop: 'releaseReservedAmount',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
      // {
      //   label: '流水号',
      //   prop: 'serialNo',
      //   editDisplay: false,
      //   addDisplay: false,
      //   span: 12,
      // },
      {
        label: '释放账号',
        prop: 'accountId',
        editDisplay: false,
        addDisplay: false,
        span: 12,
      },
    ],
  }

  export default {
    name: 'TableUser',
    components: {
      channelAdd,
    },
    data() {
      return {
        option: tableOption,
        searchItmes: {},
        dialogVisible: false,
        page: {
          total: 0, // 总页数
          currentPage: 1,
          pageSize: 10,
        },
        isvisible: false,
        dataList: [],
        listLoading: true,
        accountId: '',
        accountName: '',
        livePlatform: {
        10: '抖音',
        11: '快手',
        12: '拼多多',
        13: '腾讯视频号',
        14: '美团',
      }
      }
    },
    computed: {
      ...mapGetters(['permissions']),
      syncPermissions() {
        return this.permissions['biz_account_sync']
      },
    },
    watch: {},
    created() {
      this.getNamelist()
    },
    methods: {
      getNamelist() {
        const api = 'bium-portal/controller/fin-ops/account/getEnterpriseNameList'
        this.doPost(api).then(({ data }) => {
          if (data) {
            this.option.column[1].dicData = data || []
          }
        })
      },
      handleSave() {
        this.isvisible = true
      },
      getList(page, params) {
        const api = 'bium-portal/controller/fin-ops/bill/release-reserved/page'
        const { currentPage, pageSize } = page
        params = params
          ? params
          : {
              ...this.searchItmes,
            }
        if (params.accountNameDesc) {
          params.companyId = params.accountNameDesc
        }
        params.pageNum = page.currentPage
        params.pageSize = page.pageSize
        this.listLoading = true
        this.doPost(api, params).then(({ data }) => {
          if (data.result.records) {
            this.dataList = data.result.records || []
            this.page.total = data.result.total || 0
            this.dataList.forEach((item) => {
              item.idLivePlatform=item.storeName+'/'+this.livePlatform[item.livePlatform]
            })
          }
          this.listLoading = false
        })
      },

      sizeChange(size) {
        this.page.pageSize = size
      },
      currentChange(current) {
        this.page.currentPage = current
      },
      handleFilter(param, done) {
        this.page.currentPage = 1
        this.searchItmes = param ? param : {}
        this.getList(this.page, param)
        done()
      },
      resetFilter() {
        this.page.currentPage = 1
        this.searchItmes = {}
        this.getList(this.page, this.searchItmes)
      },
      handleRefreshChange() {
        this.getList(this.page, this.searchItmes)
      },

      handleCloseDialog() {
        this.dialogVisible = false
      },
      handleCellClassName({ row, column, rowIndex, columnIndex }) {
        if ([7].includes(columnIndex)) {
          return 'yellow-cell'
        }
        return ''
      },

      queryBook(row, index) {
        this.listLoading = true
        doGet('/upyzt/balacc/queryBook/' + row.id).then((res) => {
          this.listLoading = false
          this.handleRefreshChange()
        })
      },
      checkFlow() {
        doGet('/cap/account/checkFlow').then((res) => {
          this.handleRefreshChange()
        })
      },
      frozenBook(row, index) {
        this.listLoading = true
        doGet('/upyzt/balacc/frozenBook/' + row.id).then((res) => {
          this.listLoading = false
          this.handleRefreshChange()
        })
      },
      handleWidthdrawal() {
        this.$router.push({
          path: '/account/withdraw',
        })
      },
    },
  }
  </script>

  <style lang="scss" scoped></style>
