var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "selection-box" },
    [
      _c(
        "section",
        { staticClass: "acc-container" },
        [
          _c(
            "el-divider",
            {
              staticClass: "title-divider",
              attrs: { "content-position": "left" },
            },
            [_vm._v("基本信息")]
          ),
          _c("div", { staticClass: "jg-acc" }, [
            _c("div", { staticClass: "card-box" }, [
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("客户名称:")]),
                _c("div", [_vm._v(_vm._s(_vm.form.enterpriseName))]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("企业编号:")]),
                _c("div", [_vm._v(_vm._s(_vm.form.enterpriseCode))]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("联系方式:")]),
                _c("div", [_vm._v(_vm._s(_vm.form.mobile))]),
              ]),
            ]),
          ]),
          _c(
            "el-divider",
            {
              staticClass: "title-divider",
              attrs: { "content-position": "left" },
            },
            [_vm._v("店铺信息")]
          ),
          _c("div", { staticClass: "jg-acc" }, [
            _c("div", { staticClass: "card-box" }, [
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("店铺名称:")]),
                _c("div", [_vm._v(_vm._s(_vm.form.storeName))]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [
                  _vm._v("店铺所属平台:"),
                ]),
                _vm.form.livePlatform == 10
                  ? _c("div", [_vm._v("抖音")])
                  : _vm._e(),
                _vm.form.livePlatform == 11
                  ? _c("div", [_vm._v("快手")])
                  : _vm._e(),
                _vm.form.livePlatform == 12
                  ? _c("div", [_vm._v("拼多多")])
                  : _vm._e(),
                _vm.form.livePlatform == 13
                  ? _c("div", [_vm._v("腾讯视频号")])
                  : _vm._e(),
                _vm.form.livePlatform == 14
                  ? _c("div", [_vm._v("美团")])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("店铺ID:")]),
                _c("div", [_vm._v(_vm._s(_vm.form.storeCode))]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [
                  _vm._v("店铺账户ID:"),
                ]),
                _c("div", [_vm._v(_vm._s(_vm.form.storekeeperAcctId))]),
              ]),
            ]),
          ]),
          _c(
            "el-divider",
            {
              staticClass: "title-divider",
              attrs: { "content-position": "left" },
            },
            [_vm._v("融资订单信息")]
          ),
          _c("div", { staticClass: "jg-acc" }, [
            _c("div", { staticClass: "card-box" }, [
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("订单号:")]),
                _c("div", [_vm._v(_vm._s(_vm.form.applyNo))]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("日期:")]),
                _c("div", [_vm._v(_vm._s(_vm.form.paymentDate))]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c(
                  "div",
                  {
                    staticClass: "item_label",
                    staticStyle: { color: "#00a2ff" },
                  },
                  [_vm._v("放款金额:")]
                ),
                _c("div", { staticStyle: { color: "#00a2ff" } }, [
                  _vm._v(_vm._s(_vm.form.paymentAmount)),
                ]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("状态:")]),
                _vm.form.status == "Repayment"
                  ? _c("div", { staticStyle: { color: "red" } }, [
                      _vm._v("还款中"),
                    ])
                  : _vm._e(),
                _vm.form.status == "Overdue"
                  ? _c("div", { staticStyle: { color: "red" } }, [
                      _vm._v("逾期未结清"),
                    ])
                  : _vm._e(),
                _vm.form.status == "Overdue_Clearing"
                  ? _c("div", { staticStyle: { color: "red" } }, [
                      _vm._v("逾期清分中"),
                    ])
                  : _vm._e(),
                _vm.form.status == "PayOff"
                  ? _c("div", { staticStyle: { color: "green" } }, [
                      _vm._v("正常结清"),
                    ])
                  : _vm._e(),
                _vm.form.status == "PayOff_Overdue"
                  ? _c("div", { staticStyle: { color: "red" } }, [
                      _vm._v("逾期结清"),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("费率:")]),
                _c("div", [
                  _vm._v(
                    _vm._s((Number(_vm.form.customerRate) * 100).toFixed(4)) +
                      "% /" +
                      _vm._s(_vm.form.billMethod)
                  ),
                ]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("息费总额:")]),
                _c("div", [_vm._v(_vm._s(_vm.form.interestAmount))]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("折扣率:")]),
                _vm.form.discountRate
                  ? _c("div", [
                      _vm._v(
                        _vm._s(
                          (Number(_vm.form.discountRate) * 100).toFixed(2)
                        ) + "%"
                      ),
                    ])
                  : _c("div", [_vm._v("无")]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [
                  _vm._v("剩余应还本金:"),
                ]),
                _c("div", [_vm._v(_vm._s(_vm.form.pendingPrincipal))]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [
                  _vm._v("剩余应还利息:"),
                ]),
                _c("div", [_vm._v(_vm._s(_vm.form.pendingInterest))]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [
                  _vm._v("剩余应还服务费:"),
                ]),
                _c("div", [_vm._v(_vm._s(_vm.form.pendingServiceFee))]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("罚息:")]),
                _c("div", [_vm._v(_vm._s(_vm.form.pendingOverdue))]),
              ]),
            ]),
          ]),
          _c(
            "el-divider",
            {
              staticClass: "title-divider",
              attrs: { "content-position": "left" },
            },
            [_vm._v("费率信息")]
          ),
          _c("div", { staticClass: "jg-acc" }, [
            _c("div", { staticClass: "card-box" }, [
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("资金方:")]),
                _c("div", [_vm._v(_vm._s(_vm.form.funderName))]),
              ]),
              _c("div", { staticClass: "card-item" }, [
                _c("div", { staticClass: "item_label" }, [_vm._v("企业归属:")]),
                _vm.form.channelId
                  ? _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.form.channelName))]),
                      _vm._v("\n            (\n            "),
                      _vm.form.channelLevel == 1
                        ? _c("span", [_vm._v("一级")])
                        : _vm._e(),
                      _vm.form.channelLevel == 2
                        ? _c("span", [_vm._v("二级")])
                        : _vm._e(),
                      _vm.form.channelLevel == 3
                        ? _c("span", [_vm._v("三级")])
                        : _vm._e(),
                      _vm._v("\n            )\n          "),
                    ])
                  : _c("div", [_vm._v("直客")]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-divider",
        { staticClass: "title-divider", attrs: { "content-position": "left" } },
        [_vm._v("融资订单")]
      ),
      _c("div", { staticStyle: { color: "#00a2ff" } }, [
        _c("span", [
          _vm._v(
            "融资商品订单数：" + _vm._s(_vm.orderNumberForm.orderCount) + "笔"
          ),
        ]),
        _c("span", { staticStyle: { "padding-left": "30px" } }, [
          _vm._v(
            "已结算商品订单：" +
              _vm._s(_vm.orderNumberForm.settledOrderCount) +
              "笔"
          ),
        ]),
      ]),
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          option: _vm.option,
          page: _vm.page,
          "table-loading": _vm.listLoading,
          data: _vm.list,
        },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "on-load": _vm.getList,
          "size-change": _vm.sizeChange,
          "current-change": _vm.currentChange,
          "search-change": _vm.handleFilter,
          "search-reset": _vm.resetFilter,
          "refresh-change": _vm.handleRefreshChange,
        },
        scopedSlots: _vm._u([
          {
            key: "statusDesc",
            fn: function (scope) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      effect: "dark",
                      type:
                        scope.row.statusDesc == "已还款"
                          ? "success"
                          : scope.row.statusDesc == "已逾期"
                          ? "danger"
                          : "",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(scope.row.statusDesc))])]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }