<template>
  <avue-crud
    ref="crud2"
    :table-loading="listLoading"
    :page.sync="page"
    :data="dataList"
    :option="option"
    @on-load="getList"
    @size-change="sizeChange"
    @current-change="currentChange"
    @refresh-change="handleRefreshChange"></avue-crud>
  <!-- <div>
      {{ data }}
    </div> -->
</template>

<script>
import { PAGE } from '@/const/global.js'
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      page: PAGE,
      listLoading: false,
      searchItmes: {},
      dataList: [],
      option: {
        // 搜索栏配置
        searchMenuSpan: 12,
        searchMenuPosition: 'left',
        // 表格主体配置
        border: false,
        index: false,
        indexLabel: '序号',
        stripe: true,
        headeralign: 'left',
        align: 'left',
        // 表格操作列配置
        menu: false,
        addBtn: false,
        searchShowBtn: false,
        column: [
          {
            label: '接受手机号',
            prop: 'mobile',
          },
          {
            label: '接收时间',
            prop: 'callTime',
          },
          {
            label: '短信内容',
            prop: 'smsContent',
          },
          {
            label: '发送人',
            prop: 'sendMobile',
          },
        ],
      },
    }
  },
  methods: {
    /** 获取列表数据 */
    getList(page, params) {
      console.log("🚀 ~ getList ~ page, params:", page, params)
      this.isLoading = true
      const api = 'bium-portal/controller/fin-ops/virtual_mobile/list_sms'
      params = params
        ? {
          // mobile: this.data.bindingMobile,
         id: this.data.id, 
        //  bindingMobile: this.data.bindingMobile 
          }
        : {
          // mobile: this.data.bindingMobile,
         id: this.data.id, 
        //  bindingMobile: this.data.bindingMobile 
          }
      params.pageNum = page.currentPage
      params.pageSize = page.pageSize

      // 调取接口
      this.doPost(api, params).then(({ data }) => {
        if (data.result.records) {
          this.dataList = data.result.records || []
          this.page.total = data.result.total || 0
        }
        this.isLoading = false
      })
    },
    /** 分页变化，存储 */
    sizeChange(pageSize) {
      this.page.pageSize = pageSize
    },
    /** 当前页码变化，存储 */
    currentChange(current) {
      this.page.currentPage = current
    },
    /** 手动刷新列表 */
    handleRefreshChange() {
      this.getList(this.page, this.searchItmes)
    },
  },
}
</script>
<style scoped lang="scss"></style>
