<template>
  <div :ref="refKey" class="qr-code"></div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  name: "Qrcode",
  props: {
    refKey: {
      type: String,
      default: "qrcodeRef",
    },
    pageUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.bindQRCode();
    });
  },
  methods: {
    bindQRCode() {
      const { refKey, pageUrl } = this;
      new QRCode(this.$refs[refKey], {
        text: pageUrl,
        width: 120,
        height: 120,
        colorDark: "#3d7bc0", //二维码颜色
        colorLight: "#fff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
