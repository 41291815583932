var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "product-financing-plan" },
    [
      _c(
        "basic-container",
        [
          _c("avue-crud", {
            ref: "crud",
            attrs: {
              data: _vm.dataList,
              option: _vm.option,
              page: _vm.page,
              "table-loading": _vm.isLoading,
            },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "on-load": _vm.getList,
              "size-change": _vm.sizeChange,
              "current-change": _vm.currentChange,
              "search-change": _vm.handleFilter,
              "search-reset": _vm.resetFilter,
              "refresh-change": _vm.handleRefreshChange,
            },
            scopedSlots: _vm._u([
              {
                key: "menuLeft",
                fn: function () {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-setting", type: "primary" },
                        on: { click: _vm.toConfigStandardPlan },
                      },
                      [_vm._v("直客方案管理")]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "channelName",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          scope.row.channelName == null
                            ? "直客"
                            : scope.row.channelName
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "menu",
                fn: function ({ size, row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: size,
                          icon: "el-icon-edit",
                          text: "",
                          type: "text",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toConfigPlanModal(row)
                          },
                        },
                      },
                      [_vm._v("配置融资方案\n        ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }