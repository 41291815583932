<template>
    <div class="record-box">
        <section class="acc-container">
            <div class="record-card mb20" v-for="(item, index) of dataList" :key="index">
                <div class="record-header flex-c-s">
                    <div>案例记录时间：{{ item.created }}</div>
                    <div>操作人：{{ item.userName }}</div>
                </div>
                <!-- <div class="record-content" v-if="index == 0">
                    订单审核
                    <div>
                        订单意见:通过
                    </div>
                </div> -->
                <div class="record-content">
                    {{ item.comment }}
                    </div>
            </div>
            <div class="dataValue" v-if="dataList.length == 0">
                暂无案例记录
            </div>
        </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
    name: 'CaseRecord',
    
    components: {},
    props: {
    rowForm: {
      type: Object,
      default: () => { }
    },
  },
    data() {
        return {
            dataList:[]
        }
    },
    created() { 
        this.getData()
    },

    methods: {
        getData() {
      this.doPost('bium-portal/controller/fin-ops/audit/listCasePage',{
        enterpriseId:this.rowForm.enterpriseId
      }).then(({ data }) => {
        this.dataList = data.result.records
      })
    },
    },
})
</script>

<style scoped lang="scss">
.acc-container {
    height: auto;
    min-height: 60px;

    .acc-tip {
        text-align: center;
    }

    .dis-act {
        color: #909399;
        background-color: #eee;
        border-color: #eee;
        cursor: not-allowed;
        margin: 0;

        i {
            font-style: normal;
            color: #f00;
        }
    }

    /deep/.el-loading-mask {
        top: -8px;
    }
}
.dataValue{
    text-align: center;
    line-height: 50px;
    font-size: 20px;
}
</style>