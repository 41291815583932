var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "cForm",
          staticStyle: { "padding-right": "20px" },
          attrs: {
            model: _vm.cForm,
            rules: _vm.cRules,
            "label-position": "right",
            "label-width": "120px",
            size: "small",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt10 inner-box" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户归属：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "500px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.cForm.channelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.cForm, "channelId", $$v)
                        },
                        expression: "cForm.channelId",
                      },
                    },
                    _vm._l(_vm.boxList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.cForm.channelId == null
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "billingMethod", label: "计息方式：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "500px" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.cForm.billingMethod,
                            callback: function ($$v) {
                              _vm.$set(_vm.cForm, "billingMethod", $$v)
                            },
                            expression: "cForm.billingMethod",
                          },
                        },
                        _vm._l(_vm.calculateList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "tc", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.submit("cForm")
                },
              },
            },
            [_vm._v("确认绑定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }